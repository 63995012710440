.tails-view-multi-product-widget {
    border-radius: 4px;
    width: calc(100% + 30px);
    margin: 0 -15px;
    background-color: $primary-white;
    padding: 15px 15px 1px 15px;
    display: inline-block;

    .widget-header {
        margin-bottom: 15px;
    }

    .offer {
        position: relative;
        @include flexbox(row, center, center);
        text-align: left;
        margin-bottom: 15px;
        overflow: hidden;
        background-color: $primary-white;

        .btn {
            border-bottom: 0;
            text-align: center;
            min-width: 100px;
        }

        .btn.remove {
            display: none;
        }

        &[data-status=taken] {
            .btn.add, .btn.add-once{
                display: none;
            }

            .btn.remove {
                display: block;
            }
        }

        &.discounted {
            .header {
                font-size: $font-size-body;
                @include at-least($screen-tablet) {
                    font-size: $font-size-m;
                }
            }

            .first-order {
                color: $secondary-01;

                .original-price {
                    text-decoration: line-through;
                }

                .discounted-price {
                    text-transform: uppercase;
                    font-weight: bold;
                }
            }

            .image {
                $image_size: 125px;
                @include size($image_size);
                @include min-size($image_size);
            }
        }

        .description-and-actions {
            @include flex-grow(2);
            padding-left: 15px;
        }

        .description {
            text-align: left;
            @include flexbox(row, flex-end, space-between);
            @include flex-wrap(wrap);

            .ongoing, .first-order {
                margin-top: 5px;
            }

            .packs {
                font-weight: bold;
            }

            .price {
                font-weight: normal;
                color: $secondary-01;
            }
        }

        .actions {
            width: 100%;
            text-align: right;
            padding-top: 5px;

            .btn {
                white-space: nowrap;
                margin-bottom: 5px;
            }
        }
        .image {
            $image_size: 100px;
            @include size($image_size);
            @include min-size($image_size);
            background-color: $primary-white;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            background-color: rgba(116, 204, 51, 0.5);
            border-radius: 8px;

            &.wet-food {
                background-image: url("/static/images/products/wet-food/thumb.png");
                background-color: mix($scss-secondary-01, white, 75);
            }

            &.treats {
                background-image: url("/static/images/products/dental-dailies/thumb.png");
                background-color: mix($scss-pastel-darker-frost, white, 85);
            }

            &.good-dog-treats {
                background-image: url("/static/images/products/good-dog-treats/thumb.png");
                background-color: mix($scss-secondary-02, white, 75);
            }

            &.salmon-treats {
                background-image: url("/static/images/products/salmon-treats/thumb.png");
                background-color: mix($scss-pastel-darker-lavender, white, 75);
            }

            &.duck-treats {
                background-image: url("/static/images/products/duck-treats/thumb.png");
                background-color: mix($scss-pastel-darker-lavender, white, 75);
            }

            &.lamb-treats {
                background-image: url("/static/images/products/lamb-treats/thumb.png");
                background-color: mix($scss-pastel-darker-lavender, white, 75);
            }

            &.pork-treats {
                background-image: url("/static/images/products/pork-treats/thumb.png");
                background-color: mix($scss-pastel-darker-lavender, white, 75);
            }

            &.poo-bags {
                background-image: url("/static/images/products/poo-bags/thumb.png");
                background-color: mix($scss-secondary-03, white, 75);
            }
        }
    }

    @include at-least($screen-tablet) {
        width: 100%;
        margin: 0;
        padding: 0;
        background-color: transparent;

        .offer {
            padding: 0 15px 0 0;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
            border-radius: 6px;

            .description-and-actions {
                @include flex-wrap(nowrap);

                .actions {
                    width: auto;
                    padding: 0;
                }
            }

            .image {
                border-radius: 0;
            }
        }
    }
}

.tails-view-multi-product-widget-v2,
.tails-view-wet-trial-upsell-modal {
    width: 100%;
    margin: 0;
    background-color: $primary-white;
    padding: 15px 15px 1px 15px;
    display: inline-block;

    h2.centered {
        width: 100%;
        margin: 20px 0 15px 0;
        padding: 0;
    }

    .offer {
        position: relative;
        @include flexbox(row, stretch, center);
        text-align: left;
        margin-bottom: 15px;
        overflow: hidden;
        background-color: $primary-white;
        border-radius: 10px;
        border: solid 1px $navy-04;

        .btn {
            border-bottom: 0;
            text-align: center;
        }

        .btn.remove {
            display: none;
        }

        &[data-status=taken] {
            .btn.add {
                display: none;
            }

            .btn.remove {
                display: block;
            }

            .quantity.not-taken {
                display: none;
            }
            
            .quantity.taken {
                display: block;

                .icon {
                    color: $secondary-01;
                }
            }
        }

        &[data-status=not_taken] {
            .quantity.not-taken {
                display: block;
            }

            .quantity.taken {
                display: none;
            }
        }

        &.discounted {
            .header {
                font-size: $font-size-body;
                @include at-least($screen-tablet) {
                    font-size: $font-size-m;
                }
            }

            .first-order {
                color: $secondary-01;
                font-weight: bold;
            }
        }

        .description-and-actions {
            @include flexbox(column, center, space-between);
            @include flex-grow(2);
            padding: 15px;

            .header {
                font-size: 0.9em;
                text-align: center;
                font-weight: bold;
                text-transform: none;
                margin-bottom: 5px;
            }

            .description {
                @include flexbox(column, center, flex-start);
                @include flex-wrap(wrap);
                
                p {
                    margin: 0;
                    text-align: center;
                }

                .recommended-quantity {
                    font-weight: bold;
                }
            }

            .actions {
                @include flexbox(column, center, center);
                width: 100%;
                margin-top: 10px;

                .btn {
                    width: 90%;
                    white-space: nowrap;
                    padding: 8px 10px;

                    &.add {
                        &:after {
                            @include icon($icon-plus-circle);
                            margin: 0 -8px 0 0;
                            font-size: 1.8em;
                        }
                    }

                    &.remove {
                        &:before {
                            @include icon($icon-minus-circle);
                            margin: 0px 8px 0 -10px;
                            font-size: 1.8em;
                        }
                    }
                }

                .modals {
                    margin-top: 5px;

                    p {
                        margin: 0;
                    }
                }
            }
        }

        .image {
            min-height: 195px;
            min-width: 130px;
            background-color: $primary-white;
            background-size: 120%;
            background-repeat: no-repeat;
            background-position-x: -20px;
            background-position-y: top;
            background-color: rgba(116, 204, 51, 0.5);
            position: relative;

            &.wet-food {
                background-image: url("/static/images/products/wet-food/thumb.png");
                background-color: $pastel-lighter-frost;
            }

            &.treats {
                background-image: url("/static/images/products/dental-dailies/thumb.png");
                background-color: mix($scss-secondary-01, white, 25);
            }

            &.good-dog-treats {
                background-image: url("/static/images/products/good-dog-treats/thumb.png");
                background-color: mix($scss-secondary-03, white, 25);
            }

            &.salmon-treats {
                background-image: url("/static/images/products/salmon-treats/thumb.png");
                background-color: mix($scss-pastel-darker-lavender, white, 35);
            }

            &.duck-treats {
                background-image: url("/static/images/products/duck-treats/thumb.png");
                background-color: mix($scss-pastel-darker-lavender, white, 35);
            }

            &.lamb-treats {
                background-image: url("/static/images/products/lamb-treats/thumb.png");
                background-color: mix($scss-pastel-darker-lavender, white, 35);
            }

            &.pork-treats {
                background-image: url("/static/images/products/pork-treats/thumb.png");
                background-color: mix($scss-pastel-darker-lavender, white, 35);
            }

            &.poo-bags {
                background-image: url("/static/images/products/poo-bags/thumb.png");
                background-color: mix($scss-navy-01, white, 15);
            }

            .price-circle {
                @include flexbox(column, center, center);
                background-color: $pastel-lighter-lavender;
                color: $navy-01;
                width: 88px;
                height: 88px;
                border-radius: 50%;
                padding: 7%;
                text-align: center;
                line-height: 1.2;
                right: -10px;
                top: 100px;
                position: absolute;

                .unit {
                    color: $navy-01;
                    font-size: 1.4rem;
                }

                .original-price {text-decoration: line-through;}
                .unit-price, .discounted-price {font-weight: bold;}
            }
        }

        &[data-product-id="wet-food"] {
            .image {
                background-position-x: -5px;
                background-size: 110%;
            }
        }
    }

    @include at-least($screen-tablet) {
        width: 100%;
        margin: 0;
        padding: 0;
        background-color: transparent;
        @include flexbox(row, stretch, space-between);
        @include flex-wrap(wrap);

        .offer {
            width: 49%;
            padding: 0;
            
            &[data-product-type="wet-food"] {
                width: 100%;

                .image {
                    $image_size: 200px;
                    @include min-size($image_size);
                    background-size: 100%;
                    background-position-x: left;
                    background-position-y: top;
                }

                .description-and-actions {
                    padding-left: 20px;

                    .header {
                        font-size: 1em;
                    }

                    .actions {
                        width: 200px;
                    }
                }
            }
            .description-and-actions {
                @include flex-wrap(nowrap);

                .actions {
                    width: auto;
                    padding: 0;
                }
            }

            .image {
                border-radius: 0;
            }
        }
    }
}

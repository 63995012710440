.signup-price-summary .order .buttons, .pricing-details-buttons {
    background-color: transparent;
    text-align: right;
    margin: auto;
    @include grid-blocks(6);
    width: 100%;

    > * {
        display: inline-block;
    }

    form {
        width: 100%;
    }

    label {
        margin: 0;
        padding: 5px 15px;
    }

    button, label {
        margin-top: 20px
    }

    .btn-primary {
        font-size: $font-size-body;
        white-space: nowrap;
    }

    @media screen and (max-width: $screen-phone) {
        button, label {
            width: 100%;
        }
    }

    .show-ongoing {
        .first {
            display: inline;
        }

        .ongoing {
            display: none;
        }
    }
}

.signup-price-summary {
    text-align: center;
    @include until($screen-tablet) {
        padding: 0;
    }

    .tails-view-bag {
        display: inline-block;
    }

    .pricing-details-buttons.buttons {
        &.tailored{
            margin-top: 60px;
        }
        @include flexbox(column, center, center);
        position: relative;
        text-align: center;
        .radio-toggle-button {
            label {
                margin: 25px 0;
            }
        }
        .btn, .paypal-button-wrapper {
            margin: 15px auto;
            font-size: $font-size-s;
            display: block;
            @include grid-blocks(8);
            @include at-least($screen-tablet) {
              width: 280px;
            }

            &.no-icon:before {
              display: none;
            }
        }

        .checkout-card {
            display: none;
        }

        .checkout-gocardless:after {
            margin-left: 0px;
            margin-right: 0px;
        }

        .paypal-button-wrapper {
            display: none;
        }

        .checkout-gocardless {
            display: none;
        }

        .checkout-ideal {
            display: none;
        }

        .radio-button-new {
            .p-option.gocardless {
                width: 100px;
                vertical-align: bottom;
            }
        }

        .radio-toggle-button {
            @include until($screen-tablet) {
                @include flexbox(row, center, left);
                label {
                    width: auto;
                }
            }
        }
        .p-option {
            height: 40px;
            width: 52px;
            display: inline-block;
            margin: 0 2px;
            background: {
                position: center center;
                size: cover;
                repeat: no-repeat;
            }
            &.paypal {
                background-image: url("/static/images/payment-icons/paypal.png");
            }
            &.master {
                background-image: url("/static/images/payment-icons/mastercard-128px.png");
            }
            &.master-alt {
                background-image: url("/static/images/payment-icons/mastercard-white.png");
            }

            &.visa {
                background-image: url("/static/images/payment-icons/visa-128px.png");
            }

            &.amex {
                background-image: url("/static/images/payment-icons/american-express-128px.png");
            }

            &.direct-debit {
                background-image: url("/static/images/payment-icons/direct-debit-128px.png");
                background-size: contain;
            }

            &.sepa-lastschrift {
                background-image: url("/static/images/payment-icons/sepa-lastschrift-128px.png");
                background-size: contain;
            }

            &.ideal {
                background-image: url("/static/images/payment-icons/ideal.png");
            }
        }

        .payment-options {
            &.paypal .paypal-button-wrapper {
                display: block;
            }

            &.card .checkout-card {
                display: block;
            }

            &.ideal .checkout-ideal {
                display: block;
            }

            &.gocardless .checkout-gocardless {
                display: block;
            }
        }
    }

    .pricing-details-buttons .btn-primary {
        display: none;
    }

    &.treats-widget-enabled {
        .order .buttons .btn-primary {
            display: none;
        }

        .pricing-details-buttons .btn-primary {
            display: inline-block;
        }
    }

    .tails-view-treats-widgets,
    .pet-roundal-selector {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    text-align: left;
    .container {
        padding-top: 50px;
    }
    .form-group {
        margin-bottom: 20px;
        white-space: normal;
    }
    h1 {
        margin-bottom: .6em;
        text-align: center;
    }
    .main {
        min-height: 100%;
        font-size: $font-size-body;
        .content {
            .container {
                @include until($screen-phone) {
                    padding: 0 12px;
                }
                .help-block {
                    color: $product-error;
                }
                max-width: $scss-container-max-width / 2;
                background-color: transparent;
                outline: transparent;
                label {
                    font-size: $font-size-body;
                    text-transform: none;
                    &.modal-toggle {
                        margin: 0;
                    }
                }
                .checklist label {
                    margin-bottom: 0;
                }
                .form-group.has-error input {
                    @include error-border;
                }
                fieldset {
                    width: 100%;
                }

                .toggle-buttons {
                    width: 100% !important;
                    label {
                        font-weight: normal;
                    }
                }
                .status.secure:before {
                    @include icon($icon-lock);
                    background-color: $secondary-01;
                    width: 34px;
                    height: 34px;
                    display: block;
                    border-radius: 50%;
                    color: $primary-white;
                    text-align: center;
                    font-size: 26px;
                    line-height: 34px;
                    margin-top: 4px;
                }
                .payment-summary {
                    width: 100%;
                    border-radius: 4px;
                    overflow: hidden;
                }
                .first-delivery {
                    width: 100%;
                    select {
                        width: 100%;
                    }
                }
            }
        }
        .trustpilot-widget {
            margin-top: 50px;
        }
    }
    .price-raf {
        display: flex;
        align-items: center;
        img {
            width: 80px;
            padding-right: 10px;
        }
    }
}

.signup-price-summary-redesign {
    .delivery-smallprint {
        white-space: normal;
        margin-top: 1.5em;
        font-size: $font-size-s;
        margin-right: 4px;
        text-align: left;
        overflow: hidden;
    }

    .background-header-design {
        background-color: $pastel-lighter-frost;
        height: 208px;
        position: absolute;
        z-index: -1;
        width: 100%;
        border-bottom-left-radius: 70% 25%;
        border-bottom-right-radius: 70% 25%;

        @include at-least($screen-tablet) {
          height: 228px;
          border-bottom-left-radius: 70% 35%;
          border-bottom-right-radius: 70% 35%;
        }
    }

    .background-header-design-experiment{
        background-color: #E8EFF7;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: -1;
    }

    .background-header-design-experiment + .content {
        padding-bottom: 0;

        > .container {
            padding-bottom: 0;
        }
    }

    .price-page-separator {
      height: $spacing-s;
      width: 150%;
      margin-left: -50%;
      background-color: $pastel-lighter-silver;
      position: absolute;
    }
    .delivery-disclaimer-wrapper {
        padding-top: 14px;
        width: 100%;
        text-align: left;
        .trial-delivery-disclaimer,
        .delivery-disclaimer {
            font-size: 13px;
            width: 100%;
            text-align: left;
        }
    }
    .pricing-details-buttons.buttons {
        padding-top: $spacing-xxl;
        text-align: center;
        position: relative;

        h3 {
          display: block;
        }

        .btn, .paypal-button-wrapper {
            margin: $spacing-xs auto;
            font-size: $font-size-s;
            display: block;
            @include grid-blocks(8);
            &.btn-secondary:before {
                @include icon($icon-paw);
            }
            &.no-icon:before {
              display: none;
            }
        }

        .checkout-card {
            display: none;
        }

        .checkout-gocardless:after {
            margin-left: 0px;
            margin-right: 0px;
        }

        .paypal-button-wrapper {
            display: none;
        }

        .checkout-gocardless {
            display: none;
        }

        .checkout-ideal {
            display: none;
        }

        .radio-button-new {
            input[type=radio] {
                border: 1px solid var(--primary-blueberry-10);

                &:checked {
                    background-color: var(--primary-white);
                    border: 1px solid var(--primary-blueberry);
                }

                &::before {
                    box-shadow: inset 1em 1em var(--primary-blueberry);
                }
            }

            .p-option.gocardless {
                width: 100px;
                vertical-align: bottom;
            }
        }

        .payment-options {
            &.paypal .paypal-button-wrapper {
                display: block;
            }

            &.card .checkout-card {
                display: block;
            }

            &.ideal .checkout-ideal {
                display: block;
            }

            &.gocardless .checkout-gocardless {
                display: block;
            }
        }

        .radio-toggle-button {
            @include until($screen-tablet) {
                @include flexbox(row, center, left);
                label {
                    width: auto;
                }
            }
        }

        .p-option {
            height: 40px;
            width: 52px;
            display: inline-block;
            margin: 0 2px;
            background: {
                position: center center;
                size: cover;
                repeat: no-repeat;
            }
            &.paypal {
                background-image: url("/static/images/payment-icons/paypal.png");
            }
            &.master {
                background-image: url("/static/images/payment-icons/mastercard-128px.png");
            }
            &.master-alt {
                background-image: url("/static/images/payment-icons/mastercard-white.png");
            }

            &.visa {
                background-image: url("/static/images/payment-icons/visa-128px.png");
            }

            &.amex {
                background-image: url("/static/images/payment-icons/american-express-128px.png");
            }

            &.direct-debit {
                background-image: url("/static/images/payment-icons/direct-debit-128px.png");
                background-size: contain;
            }

            &.sepa-lastschrift {
                background-image: url("/static/images/payment-icons/sepa-lastschrift-white-128px.png");
                background-size: contain;
            }

            &.ideal {
                background-image: url("/static/images/payment-icons/ideal.png");
            }
        }
        .loader-shroud {
            margin-top: $spacing-s;
            height: calc(100% + 32px);
        }
    }

    .pricing-total-section-dark-wrapper {
        width: 100vw;
        position: relative;
        left: calc(-50vw + 50%);
        background-color: $primary-blueberry;
        
        .container {
            max-width: 640px;
            margin: 0 auto;
        }

        h3 {
            font-size: 16px;
        }
    }

    .pricing-experiment-dark {
        padding: 2.6rem;

        .payment-options {
            @media (max-width: 425px) {
                width: 100%;
            }
        }

        &.buttons {
            padding-top: 0;
            @media (max-width: 425px) {
                text-align: left;
              }

            h3 {
                color: $primary-white;
            }

            .roe-delivery {
                padding-top: 30px;
            }
        }

    }

    .pricing-details-buttons .btn-primary {
        display: none;
    }

    &.treats-widget-enabled {
        .order .buttons .btn-primary {
            display: none;
        }

        .pricing-details-buttons .btn-primary {
            display: inline-block;
        }
    }

    .tails-view-treats-widgets,
    .pet-roundal-selector {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .main {
        min-height: 100%;
        font-size: $font-size-body;
        text-align: center;
        overflow: hidden;

        .content {
            .container {
                @include until($screen-phone) {
                  padding: 0 12px;
                }
                padding-top: $spacing-s;

                @include at-least($screen-tablet) {
                  padding-top: 50px;
                }

                .roe-spacing {
                    padding-top: 32px;

                    @include at-least($screen-tablet) {
                        padding-top: 0;
                    }
                }

                .pricing-dog-illustration {
                    @include flexbox(row, center, flex-end);
                    img {
                        width: 68px;
                        z-index: 1;
                        position: absolute;
                        margin-top: 40px;

                        @include at-least($screen-tablet) {
                            width: 90px;
                            margin-top: 20px;
                        }
                    }

                    h1 {
                        font-family: $handwritten-font-family;
                        margin: 0 auto;
                    }
                }

                h1 {
                  font-family: $handwritten-font-family;
                  margin: 0 auto;
                }

                .your-price-redesign-v3 {
                    margin-bottom: -40px;
                    @include at-least($screen-tablet) {
                        margin-bottom: -14px;
                      }
                }
                .help-block {
                    color: $product-error;
                }
                max-width: $scss-container-max-width / 2;
                background-color: transparent;
                outline: transparent;
                label {
                    font-size: $font-size-body;
                    text-transform: none;
                    &.modal-toggle {
                        margin: 0;
                    }
                }
                .checklist label {
                    margin-bottom: 0;
                }
                .form-group.has-error input {
                    @include error-border;
                }
                fieldset {
                    width: 100%;
                }

                .toggle-buttons {
                    width: 100% !important;
                    label {
                        font-weight: normal;
                    }
                }
                .status.secure:before {
                    @include icon($icon-lock);
                    background-color: $secondary-01;
                    width: 34px;
                    height: 34px;
                    display: block;
                    border-radius: 50%;
                    color: $primary-white;
                    text-align: center;
                    font-size: $font-size-l;
                    line-height: 34px;
                    margin-top: 4px;
                }
                .payment-summary {
                    width: 100%;
                    border-radius: 4px;
                    overflow: hidden;
                }
                .first-delivery {
                    width: 100%;
                    select {
                        width: 100%;
                    }
                }
            }
        }
        .trustpilot-widget {
            margin-top: 50px;
        }
    }
    .price-raf {
        display: flex;
        align-items: center;
        img {
            width: 80px;
            padding-right: 10px;
        }
    }
    .commitment-message {
      display: none;
    }

    .promo-toggle {
      cursor: pointer;
      font-size: var(--font-size-xs);
    }

    .multibox-exp-wrapper {
        margin-top: 1rem;
        display: flex;
        column-gap: 8px;
        .promo-code {
            font-weight: var(--font-weight-bold);
        }
    }
}

body.checkout {
    $screen-xl-desktop: 1520px;
    background-color: $pastel-lighter-frost;
    $breakpoint: $nav-max;

    .sticky-cta {
        width: initial;
        z-index: initial;
        padding: 0;
        text-align: initial;
        background-color: transparent;
        box-shadow: none;
        height: auto;
    }

    &.scroll-down {
        .sticky-cta.basket {
            top: 0;
        }
    }

    .container {
        max-width: blocks(6);
        margin: auto;
    }

    @include at-least($breakpoint) {
        .container {
            max-width: calc(100% - 430px);
            margin: auto;
            max-width: 640px;
            padding-bottom: $spacing-m;
        }
    }

    @include at-least($screen-xl-desktop) {
        .container {
            max-width: 100%;
            margin: 0;
        }
    }

    .pricing-total-section-dark-wrapper {
        .container {
            max-width: 640px;
            margin: 0 auto;
        }
    }

    .password-placeholder i {
        color: $navy-01;
    }

    h1 {
        text-align: center;
        padding: 30px;
        @include grid-blocks(6);
        margin: 0 auto 0;
        @include at-least($breakpoint) {
            margin-top: 0;
            margin-right: 30px;
        }
        @include at-least($screen-xl-desktop) {
            margin: auto;
        }
    }

    h1.below-progress {
        margin: 0 30px 0 auto;
        padding-top: 0;
        @include at-least($screen-xl-desktop) {
            margin: auto;
        }
    }

    .alert.alert-error  {
        p  {
            color: $primary-white;
        }
        a {
            color: $primary-text-link;
            text-decoration: underline;
            &:hover {
                color: $secondary-01;
                cursor: pointer;
            }
        }
    }



    .help-block.errors p {
        margin-top: $spacing-xxs;
        margin-bottom: 0;
        color: $product-error;
    }

    .form-group {
        input {
            margin-bottom: 0;
            max-width: 280px;
        }
        .password-field {
            max-width: 77%;
            @media (min-width: 420px) {
                max-width: 280px;
            }
        }
    }

    .form-group.has-error {
        label {
            color: $product-error;
        }
        input, select {
            border-color: $product-error;
        }
    }

    .consent-category.has-error {
        label, span {
            color: $product-error;
        }

        & .validation-error {
          margin-top: $spacing-xs;
        }
    }

    .form-group {
        margin-bottom: $spacing-m;
        &.address-search-postcode {
            max-width: 280px;
        }
        > .input-wrapper {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            width: 100%;
            align-items: flex-end;

            #search-postcode {
                max-width: 96px;
                margin-right: $spacing-xxs;
            }

            #search-postcode-redesign {
                max-width: 128px;
                text-transform: uppercase;
            }

            .postcode-lookup {
                position: unset;
                line-height: unset;
            }
        }
        &.address-search-postcode-redesign {
            max-width: 100%;

            .postcode-lookup-wrapper {
                display: flex;
                justify-content: flex-start;

                .postcode-lookup-redesign {
                    justify-self: center;
                    margin-top: $spacing-m;
                }
            }
        }
        > label {
            display: block;
        }
    }

    #card-element label {
        font-weight: 400;
    }

    .delivery-options {
        .delivery-option-home, .delivery-option-shops {
            display: none;
        }
        input[type='radio'] {
            display: none;
            &#home-delivery {
                &:checked ~ .delivery-content .delivery-option-home {
                    display: block;
                }
                &:checked ~ .delivery-tabs .tab[for='home-delivery'] {
                    border: 2px solid $secondary-01;
                    &:after {
                        transform: rotate(90deg);
                        transition: all 0.1s;
                    }
                }
            }
            &#ship-to-shop {
                &:checked ~ .delivery-content .delivery-option-shops {
                    display: block;
                }
                &:checked ~ .delivery-tabs .tab[for='ship-to-shop'] {
                    border: 2px solid $secondary-01;
                    &:after {
                        transform: rotate(90deg);
                        transition: all 0.1s;
                    }
                }
            }
        }
        .delivery-tabs {
            @include flexbox(column, center, space-evenly);
            margin-bottom: 20px;
            .tab {
                @include flexbox(row, center, space-between);
                border: 1px solid $secondary-01;
                padding: 20px;
                border-radius: 6px;
                margin: 5px;
                cursor: pointer;
                width: 100%;
                text-align: center;
                &:after {
                    @include icon($icon-chevron-right-circle-outline);
                    display: inline-block;
                    color: $secondary-01;
                    font-size: 3.4rem;
                    transition: all 0.2s;
                    transform: rotate(0deg);
                }
            }
            @include at-least($screen-desktop) {
                @include flexbox(row, center, space-evenly);
            }
        }
        .delivery-option-shops  {
            .form-group.postal_code {
                margin-bottom: 20px;
            }
            .shops-list {
                .opening-times {
                    display: none;
                    line-height: 1.3em;
                    margin-top: 5px;
                }
                input[type='radio'] {
                    &:checked + .radio-toggle-button label .opening-times {
                        display: block;
                    }
                }
                .radio-toggle-button {
                    margin: 10px 0;
                    display: block;
                }
            }
            input[type='radio'] {
                opacity: 0;
                position: absolute;
                left: 50%;
                bottom: 0;
                @include size(1px);
                &:checked + .radio-toggle-button label {
                    @include radio_toggle_on;
                }
            }
            .validation-error {
                &:before {
                  @include icon($icon-exclamation-circle);
                  @include inalign(middle);
                  font-size: 1.6em;
                  padding-right: 5px;
                }
                color: $product-error;
            }
        }
    }

    .radio-buttons {
        text-align: left;
        display: inline-block;
        .radio-toggle-button:first-child {
            margin-left: 0;
        }
        .radio-toggle-button span {
            font-weight: normal;
        }

        .radio-toggle-button {
            display: inline-block;
            margin: 0 25px;

            input[type=radio]:checked ~ label {
                @include radio_toggle_on;
            }
        }
    }




    @include at-least($screen-desktop) {
        .form-row {
            @include flexbox(row, center, center);
            > * {
                @include flex-grow(1);
                margin-left: 20px;
                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }

    .steps {
        @include grid-blocks(6);
        background: $primary-white;

        .step:not([data-status="active"]) {
            padding: 0;
            margin: 0;
        }

        @include at-least($breakpoint) {
            margin-left: auto;
            .trustpilot-mobile {
                display: none;
            }
        }

        @include at-least($screen-xl-desktop) {
            margin: auto;
        }

        .trustpilot-mobile {
            background-color: $primary-white;
        }

        .step-links {
            height: 100px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            position: relative;
            z-index: 1;

            @include at-least($screen-desktop) {
                &::after {
                    width: 60%;
                }
            }

            #address {
                &::before {
                    content: "";
                    position: absolute;
                    width: 155%;
                    height: 5px;
                    background: $primary-blueberry-30;
                    z-index: 0;
                    bottom: 62%;
                    right: 85%;

                    @include at-least($screen-tablet) {
                        width: 260%;
                    }
                }

                &::after {
                    content: "";
                    position: absolute;
                    width: 155%;
                    height: 5px;
                    background: $primary-blueberry-30;
                    z-index: 0;
                    bottom: 62%;
                    left: 85%;

                    @include at-least($screen-tablet) {
                        width: 260%;
                    }
                }
            }

            #address[data-status="active"] {
                &::before {
                    background: $primary-blueberry;
                }
            }

            #address[data-status="complete"] {
                &::before {
                    background: $primary-blueberry;
                }

                &::after {
                    background: $primary-blueberry;
                }
            }

            .step-link {
                position: relative;
                text-decoration: none;
                margin-top: $spacing-xs;

                .step-link-circle {
                    position: relative;
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    background: $primary-white;
                    margin: 0 auto;
                    z-index: 99;
                    margin-bottom: $spacing-xxs;
                    text-align: center;
                    border: 2px solid $primary-blueberry-30;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    p {
                        color: $primary-blueberry-30;
                        margin: 0 !important;
                    }

                    img {
                        width: 20px;
                    }
                }

                span {
                    color: var(--primary-black);
                }
            }

            .step-link[data-status="disabled"],
            .step-link[data-status="incomplete"] {
                .step-link-circle {
                    img {
                        display: none;
                    }
                }
            }

            .step-link[data-status="active"] {
                .step-link-circle {
                    border: 2px solid $primary-blueberry;

                    p {
                        color: $primary-blueberry;
                    }

                    img {
                        display: none;
                    }
                }
            }


            .step-link[data-status="complete"] {
                .step-link-circle {
                    background: $primary-blueberry;
                    border: 2px solid $primary-blueberry;
                    color: $primary-white;

                    p {
                        display: none;
                    }

                    img {
                        display: initial;
                    }
                }
            }
        }

        .step {
            position: relative;
            display: block;
            width: 100%;
            margin-bottom: 20px;
            overflow: hidden;
            padding-bottom: $spacing-m;
            padding-top: $spacing-l;

            .step-link {
                @include position(absolute, 0 0 null 0);
                @include flexbox(row, center, flex-start);
                min-height: 85px;
                z-index: 1;
                width: 100%;
                padding: 30px;
                text-decoration: none;
                color: $primary-colour;
                background-color: $primary-white;
                span {
                    @include transition(all .5s ease-in-out);
                }
            }

            .step-content {
                overflow: hidden;
                transition: max-height 0.5s;
                padding: 0 30px;
                background-color: $primary-white;

                .notification-redesign {
                    display: none;
                }

                .checkout-redesign-section-header {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    margin-bottom: $spacing-m;

                    .checkout-redesign-section-title {
                        font-family: $handwritten-font-family;
                    }
                }

                .email-section {
                    color: $navy-01;
                    padding-left: 0;
                }

                .email-suggestion-container a {
                    color: $primary-text-link;
                    text-decoration: underline;
                    &:hover {
                        color: $secondary-01;
                        cursor: pointer;
                    }
                }

                .courier-info {
                    @include flexbox(column, center, center);
                    margin-bottom: 30px;
                    @include at-least($screen-tablet) {
                        flex-direction: row;
                    }
                    img {
                        width: 140px;
                        @include at-least($screen-tablet) {
                            margin-left: 10px;
                        }
                    }
                }

                .notification.fr-payment {
                    margin: 0 auto;

                    & + .tails-view-payment-new {
                        .card-details {
                            padding-top: 20px;
                        }
                    }
                }
                .action.fr-delivery-dates {
                    line-height: 54px;
                }
                .checkout-info-box {
                    background-color: $pastel-frost;
                    margin: 14px 0;

                    .info-block {
                      padding: 14px 20px;

                        p {
                          margin: 0;
                        }
                    }
                }

                .phone-consent-reassurance, .phone-consent-capture, .address-consent {
                    padding: $spacing-xs $spacing-xs 2px $spacing-xs;
                    background-color: $pastel-lighter-beige;

                    > p {
                        margin: 0;
                    }

                    .consent-category {
                      display: flex;
                      min-height: 27px;
                      align-items: center;
                      flex-wrap: wrap;
                      margin: $spacing-xs 0;

                      & + .consent-category {
                        margin-top: $spacing-s;
                      }

                      .consent-category-name {
                        flex: 0 0 100%;
                        margin: 0 0 $spacing-xs 0;

                        @include at-least($screen-tablet) {
                            margin: 0;
                            flex: 0 0 25%;
                            min-width: 128px;
                        }
                      }
                    }
                    .radio-buttons {
                      margin-top: $spacing-xxs;

                      @include at-least($screen-tablet) {
                        margin: 0;
                      }

                      .radio-toggle-button {
                        margin: 0 $spacing-s 0 0;

                        > label::before {
                            top: -2px;
                        }

                        > label::after {
                            top: 3px;
                        }

                        & + .radio-toggle-button {
                            margin: 0;
                        }
                      }
                    }
                }

                .address-consent {
                    padding: $spacing-xs;

                    > p {
                        margin-bottom: $spacing-xs;
                    }
                }

                .phone-consent-capture {
                    margin-top: $spacing-s;
                    padding-bottom: 2px;
                    > p {
                        margin-top: 0;
                    }
                }
            }

            &[data-status=complete] {
                .step-link:after {
                    @include icon($icon-check);
                    color: $primary-cta;
                    font-size: 2.5rem;
                    margin-left: 10px;
                }
            }

            @keyframes slide-down {
                0% {
                    @include translated(0, -150%, 0);
                }
                100% {
                    @include translated(0, 0, 0);
                }
            }

            &[data-status=active] {
                .step-content {
                    padding-bottom: 30px;
                    @include animation(slide-down .7s normal forwards);
                }
                .step-link span {
                    @include flex-grow(1);
                    font-size: $font-size-m;
                    color: $primary-colour;
                    text-align: center;
                    font-weight: bold;
                }
            }
            &[data-status=disabled] {
                .step-link {
                    cursor: default;
                }
            }
            .mobile {
                @include flexbox(row, center, flex-start);
                .plus {
                    font-size: 24px;
                    font-weight: bold;
                    margin-top: $spacing-xxs;
                }
                .calling-code {
                    max-width: 3em;
                    padding-right: 0;
                    margin-right: $spacing-xs;
                    margin-left: $spacing-xxs;
                    text-align: left;
                }
                .number {
                    @include flex-grow(1);
                }
            }
        }
    }

    .basket {
        max-height: 100px;
        overflow: visible;

        .max-width {
            font-size: $font-size-s;
            @include grid-blocks(6);
            margin: auto;
            overflow: hidden;
            background-color: $primary-white;
            box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
            .replace {
                margin: 10px;
                .mobile-summary-title {
                    padding: 10px 15px;
                    h5 {
                        line-height: 30px;
                    }
                }
            }
            .intro {
                margin: 0;
                font-size: $font-size-s;
                padding-left: 15px;
            }
            .mobile-summary {
                display: block;
                height: 100px;
                @include grid-blocks(6);
                margin: auto;
                .bag-and-orders {
                    position: relative;
                    padding-left: 60px;
                    .bag {
                        background-position: center;
                        background-size: contain;
                        margin: 0 auto;
                        background-repeat: no-repeat;
                        background-image: url("//images.prismic.io/tails/74b463da-bac6-4d31-9977-cc2f6058af0a_product_food_dry.png");
                        position: absolute;
                        left: 0;
                        top: 0;
                        bottom: 0;
                        @include size(50px);
                        margin-left: 5px;
                    }
                    .order {
                        line-height: 30px;
                        font-size: 1.4rem;
                        color: $primary-white;
                        white-space: nowrap;
                        @include flexbox(row, center, space-between);
                        padding: 0 15px;
                        &.first {
                            background-color: $primary-colour
                        }
                        &.second {
                            background-color: $primary-white;
                            color: $primary-colour;
                        }
                    }
                }
                .actions {
                    width: 100%;
                    border-top: 1px solid $pastel-lighter-frost;
                    .show-more {
                        line-height: 40px;
                        text-align: left;
                        width: inherit;
                        margin-left: 10px;
                    }
                }
            }
        }

        // Desktop
        @include at-least($breakpoint) {
            max-height: none;
            overflow: auto;
            padding-top: calc(#{$nav-height} + 60px);
            bottom: 0;
            top: 0;
            right: 0;
            left: auto;
            max-width: 400px;
            position: fixed;
            background-color: $primary-white;
            .max-width {
                transform: none;
                box-shadow: none;
                .replace {
                    padding: 0 30px;
                    margin: 0;
                }
                .mobile-summary {
                    display: none;
                }
            }
        }
    }

    .basket-dropdown {
        min-width: 280px;
        max-width: 640px;
        margin: $spacing-s auto;
        border-top: 1px solid $pastel-darker-silver;
        border-bottom: 1px solid $pastel-darker-silver;
        cursor: pointer;

        &[open]{
            > summary::after {
                transform: rotate(180deg);
            }
        }

        &.closing {
            > summary::after {
                transform: rotate(0deg);
            }
        }

        > summary {
            display: flex;
            height: 48px;
            align-items: center;
            justify-content: space-between;

            &:focus-visible {
                outline: none;
            }

            @media (hover: hover) {
                &:hover span {
                    text-decoration: underline;
                    color: $primary-colour;
                }
            }

            &:active span {
                color: $primary-cta;
                text-decoration: underline;
            }

            span {
                flex: 0 0 calc(100% - 60px);
                font-size: $font-size-s;
                color: $primary-text-link;
                padding-left: $spacing-xxs;
                transition: color .2s ease;

                &:focus-visible {
                    outline: 0;
                    outline: 0;
                    box-shadow: 0 0 10px 2px $secondary-03;
                }
            }

            &::after {
                content: '';
                display: block;
                width: 48px;
                height: 48px;
                flex: 0 0 48px;
                margin-left: 12px;
                background-image: url("https://tails.cdn.prismic.io/tails/dae1ce8c-1790-40ac-8c9e-5b8d4ddcc661_accordion-chevron.svg");
                background-size: 28px;
                background-repeat: no-repeat;
                background-position: center center;
                transition: transform .4s ease;
            }
        }
        .basket-redesign {
            position: unset;
            margin: 0;
            padding: 0;
            max-width: unset;
            max-height: unset;
            overflow: visible;

            .payment-summary-experiment {

                .review-box {
                    display: none;
                }
            }

            #show-first:checked {
                ~ .tabs-comparison .tab {
                    &.first {
                        background-color: $pastel-lighter-frost;
                        color: $primary-colour;
                    }
                }
            }

            #show-next:checked {
                ~ .tabs-comparison .tab {
                    &.next {
                        background-color: $pastel-lighter-frost;
                        color: $primary-colour;
                    }
                }
            }

            .tab {
                background: #F8FAFD;
            }
        }
    }

    .pet-roundal-selector {
        background: #fff;
        padding: 10px;
        margin-left: -10px;
        margin-right: -10px;
        a {
            text-decoration: none;
        }
    }

    .upsell-widget-nav {
        background: #fff;
        display: flex;
        margin-left: -10px;
        margin-right: -10px;

        a {
            flex-grow: 1;
            text-align: center;
            padding: 10px;
            border-bottom: 2px solid #ccc;
            cursor: pointer;
            color: $navy-01;
            text-decoration: none;

            &.selected {
                background: $navy-01;
                color: #fff;
                border-bottom-color: #2583e2;
            }

            ~ a {
                border-left: 1px solid #e2e2e2;
            }
        }

        @include until ($screen-phone) {
            display: none;
        }
    }

    .why-try-tails {
        .widget-wrapper {
            @include at-least($screen-tablet) {
                @include padding(0 20px);
            }
            .widget-inner-wrapper {
                @include flexbox(row, flex-start, flex-start);
                .widget-text {
                    margin-left: 20px;
                    text-align: left;
                    p {
                        margin: 0;
                        &:first-of-type {
                            margin-bottom: 5px;
                        }
                    }
                }
            }
        }

        &.no-commitment-widget {
            width: 100%;
            background-color: $primary-white;
            border: none;
            margin: 10px 0 10px 0;
            padding-top: 17px;

            @include at-least($screen-tablet) {
              padding: $spacing-s;
            }

            & .widget-wrapper {
              padding: 0;
              margin: 0;
            }
          }
    }

    .paypal-details {
        .payment-inputs .radio-buttons-new {
            margin-bottom: $spacing-s;
        }
        .form-group .billing-question {
            margin-bottom: 20px;
        }
        .info-row {
            @include flexbox(row, center, flex-start);
            p {
                padding-left: 30px;
            }
        }
        img {
            width: 100%;
            max-width: 150px;
        }
    }

    .ideal-details {
        .address-search {
            display: none;
        }
        input[name=address-use_delivery_address] {
            display: none;
            &#delivery-address-no:checked {
                ~ fieldset .address-search {
                    display: block;
                }

                ~ .form-group .radio-buttons label[for=delivery-address-no] {
                    @include radio_toggle_on;
                }
            }

            &#delivery-address-yes:checked {
                ~ .form-group .radio-buttons label[for=delivery-address-yes] {
                    @include radio_toggle_on;
                }
            }
        }

        .header {
            margin: 20px 0;
            img {
                width: 100%;
                max-width: 100px;
            }
        }

        .form-group {
            margin-bottom: 20px;
        }
    }

    .step[data-step=your-payment-details] {
        .step-link {
            &:before {
                @include icon($icon-lock);
                font-size: $font-size-m;
                margin: 0 10px 0 0;
            }
        }
        .payment-cards {
            background-color: $pastel-lighter-frost;
            padding: 20px;

            .p-card {
                height: 33px;
                width: 53px;
                display: inline-block;
                margin: 0 2px 10px 0;
                background: {
                    position: center center;
                    size: cover;
                    repeat: no-repeat;
                }
                &.visa-logo {
                    background-image: url("/static/images/payment-icons/visa-128px.png");
                }
                &.mastercard-logo {
                    background-image: url("/static/images/payment-icons/mastercard-128px.png");
                }
                &.amex-logo {
                    background-image: url("/static/images/payment-icons/american-express-128px.png");
                }
                &.direct-debit-logo {
                    background-image: url("/static/images/payment-icons/direct-debit-128px.png");
                }
                &.sepa-lastschrift-logo {
                    background-image: url("/static/images/payment-icons/sepa-lastschrift-128px.png");
                }
                &.carte-bancaire-logo {
                    background-image: url("/static/images/payment-icons/carte-bancaire-128px.png");
                }
            }
            .stripe-logo {
                background-image: url("/static/images/payment-icons/powered_by_stripe.png");
                background: {
                    position: center center;
                    size: cover;
                    repeat: no-repeat;
                }
                height: 27px;
                width: 124px;
            }
        }
        .buttons-wrapper-aligned {
            .btn.icon-lock {
                &:before {
                    @include icon($icon-lock);
                    margin: 0;
                }
            }
            .btn.ideal {
                text-transform: none;
            }
        }
        .info-box  {
            background-color: $pastel-lighter-beige;
            padding: 20px;
            margin-bottom: 20px;
        }
        .checkout-legal {
            background-color: $pastel-lighter-frost;
            padding: 0 20px;
            min-height: 200px;
            height: 200px;
            overflow: auto;
            resize: vertical;

            .c16 {
                font-size: 1.4em;
                font-weight: bolder;
            }

            .c4 {
                font-size: 1.2em;
                font-weight: bold;
            }
        }
    }

    .local-details {
        display: none;
    }

    label[for="local-details-toggle"] {
        cursor: pointer;
        padding: 10px 0;
    }

    input#local-details-toggle {
        display: none;
        &:checked ~ .local-details {
            display: block;
        }
    }

    // Separate checkout AB Test
    .separate-checkout {
        .step {
            padding-top: 0px;

            .tails-view-vue-delivery {
                margin-top: 23px;
            }

            .tails-view-payment-new .payment-cards .sub-heading {
                margin-top: 65px;
            }

            form {
                margin-top: 30px;

                .form-group {
                    > label {
                        font-weight: normal;
                    }
                }
            }
        }
    }
}

body.signup.signup-price-summary,
body.signup.signup-price-summary-redesign,
body.signup.checkout {
    .trusted-shops-signup {
        @include flexbox(row, center, center);
        padding-bottom: 15px;

        img {
            height: 50px;
            width: 50px;
            margin-right: 10px;
        }
        h5 {
            font-size: $font-size-m;
            color: $primary-white;
        }
    }
}

body.signup.checkout .trusted-shops-signup {
    @include flexbox(row, center, flex-start);
}

.progress-holder {
    $screen-xl-desktop: 1520px;
    padding-left: 0;
    padding-right: 0;
    @include grid-blocks(6);
    display: flex;
    justify-content: center;
    max-width: 640px;
    margin: 150px 0 0 auto;
    @include at-least($nav-max) {
        margin-top: 0;
        margin-right: 30px;
    }
    @include at-least($screen-xl-desktop) {
        margin: auto;
    }

    .progress-bar {
        width: 100%;max-width: 325px;
        display: flex;
        padding: 25px 10px 35px;
        align-items: center;

        .step-circle {
            background-color: $pastel-darker-frost;
            width: 35px;
            height: 35px;
            border-radius: 50%;
            text-align: center;
            line-height: 35px;
            font-weight: bold;
            position: relative;
        }

        .active {
            background-color: $secondary-03;
        }

        .bardiv {
            border-top: 5px dotted $pastel-darker-frost;
            flex: 1;
            border-spacing: 10px;
        }
    }
}

#password-field {
    padding-right: 3em;
    letter-spacing: 1px;
}

.styleless-button {
    font-size: 100%;
    border: 0;
    padding: 0;
    background: transparent; // Firefox
  }

.toggle-password-visibility {
    display: inline;
    cursor: pointer;
    margin-left: 85%;
    top: -40px;
    position: relative;
    z-index: 2;
    font-size: 3.3rem;

    @media (min-width: 420px) {
        margin-left: 306px;
    }
}

.show-hide-password {
    max-height: 0;
}

.card-details {
    .card-errors {
        display: none;
    }
}

#box-notification {
    box-shadow: none;
    &:before {
        content: none;
    }
}

.box-style-notification {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    background: $product-success-background;

    .box-icon {
        font-family: "tails-icons";
        &:before {
            background-image: url('/static/icons/icon-success.svg');
            background-size: 1em 1em;
            height: 1em;
            width: 1em;
            content: '';
            display: inline-block;
        }
    }

    .box-notification-content {
        padding: 0 12px;
        width: 100%;

        p {
            margin: 0 auto;
            font-size: 13px;
            line-height: 2.1rem;
        }
    }
}

.terms-wrapper {
    margin-top: 20px;
}

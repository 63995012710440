body.choose-pause {
    h1 {
        margin-bottom: 30px;
    }

    .resume-info {
        display: none;
        text-align: center;
        padding: 15px 0;
        .value {
            @include grid-blocks(3);
            display: inline-block;
            font-weight: normal;
            background-color: $secondary-01;
            border-radius: 50px;
            margin-top: 5px;
            padding: 5px 15px;
            font-size: 1.4em;
            color: white;
        }
    }

    .resume-info.visible {
        display: block;
    }

    .error {
        color: $product-error;
        font-weight: bold;
    }

    .checklist-wrapper {
        label {
            display: inline;
        }
        span.text {
            margin-top: 3px;
        }
    }

}

body.confirm-pause {
    .background-curve-container .background-curve {
        background-color: #E8EFF7;
        border-radius: 0 0 50% 50%;
        position: relative;
        z-index: -1;
        height: 100%;
        left: -63vw;
        width: 230vw;

        @media only screen and (min-width: $screen-sm-min) {
            left: -15vw;
            width: 130vw;
        }
    }
    .background-curve-container {
        overflow: hidden;
        position: absolute;
        height: 400px;
        width: 100%;
        left: 0;
        top: -170px;
    }

    .checklist {
        li.tip {
            display: none;
            border: none;
        }
    }

    .title {
        margin-top: -30px;
        margin-bottom: 40px;
        text-align: center;
        img {
            width: 160px;
        }
    }

    .handwritten {
        font-family: $handwritten-font-family;
    }

    .content-card {
        border: 1px solid $pastel-darker-silver;
        background: $primary-white;
        border-radius: 12px;
        padding: 16px;
        width: 100%;
        @include at-least($screen-desktop) {
            padding: 32px;
            width: 90%;
            margin: auto;
        }
        .info {
            background-color: $pastel-lighter-silver;
            padding: 15px;
            border-radius: 5px;
            .handwritten {
                font-family: $default-font-family;
                font-size: 17px;
                font-weight: 700;
            }
        }
        .date {
            font-weight: 800;
            display: block;
        }
        .icon {
            font-size: 48px;
            width: 48px;
            height: 48px;
            margin-bottom: 5px;
        }
    }

    .actions{
        margin-top: 40px;
        .btn-secondary {
            width: 282px;
        }
    }
}

.icons-info-l {
  font-size: 96px;
  width: 96px;
  height: 96px;
}

.icons-info-m {
  font-size: 48px;
  width: 48px;
  height: 48px;
}

.icons-info-s {
  font-size: 32px;
  width: 32px;
  height: 32px;
}

.section.widget {
  @include padding(10px);
  @include flexbox(column, center, space-between);
  @include at-least($screen-tablet) {
    @include flexbox(row, center, flex-start);
  }
  margin-bottom: 20px;
  background-color: $primary-white;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  .image {
    $image_size: 100px;
    @include size($image_size);
    @include min-size($image_size);
    background-size: 130%;
    background-repeat: no-repeat;
    background-position: center;
    &.wet-food {
      background-image: url("/static/images/products/wet-food/thumb.png");
    }
    &.treats {
      background-image: url("/static/images/products/dental-dailies/thumb.png");
    }
    &.good-dog-treats {
      background-image: url("/static/images/products/good-dog-treats/thumb.png");
    }
    &.salmon-treats {
      background-image: url("/static/images/products/salmon-treats/thumb.png");
    }
    &.duck-treats {
      background-image: url("/static/images/products/duck-treats/thumb.png");
    }
    &.lamb-treats {
      background-image: url("/static/images/products/lamb-treats/thumb.png");
    }
    &.pork-treats {
      background-image: url("/static/images/products/pork-treats/thumb.png");
    }
    &.poo-bags {
      background-image: url("/static/images/products/poo-bags/thumb.png");
    }
  }
  div.description {
    padding: 5px 10px;
    flex-grow: 1;
    margin-bottom: 10px;
    @include at-least($screen-tablet) {
      margin: 0;
    }
    .loading.show {
      @include tails_spinner(25px, 5px);
      background-color: rgba(255, 255, 255, 0.8);
      position: absolute;
    }
    p {
      text-align: center;
      margin-top: 0;
      margin-bottom: 5px;
      @include at-least($screen-tablet) {
        text-align: left;
      }
      &:last-child {
        margin-bottom: 0;
      }
      &.title {
        color: $primary-colour;
        font-size: $font-size-body;
      }
      &.description {
        color: $primary-colour;
      }
      &.discount-summary {
        color: $secondary-01;
        font-weight: bold;
      }
      &.claim {
        color: $navy-01;
        font-style: italic;
        line-height: 1rem;
      }
      &.hide-loading {
        opacity: 0.2;
      }
    }
  }

  &.tails-view-feeding-plan-product {
    display: none;

    &.product-added {
      display: flex;
    }
  }

  .monthly-order {
    @include padding(10px);
    background-color: rgba(239, 239, 239, 0.3);
    border-radius: 10px;
    text-align: center;
    font-size: 1.4rem;
    flex-shrink: 0;
    .increment-decrement {
      &.counter {
        background-color: $pastel-lighter-frost;
        margin: 10px 0;
        input {
          padding: 0.2rem 0;
          background-color: #ffffff;
          border: none;
          color: $navy-01;
          font-size: 1.4rem;
          height: auto;
          min-height: max-content;
          width: 2em;
          font-weight: 400;
        }
        span.units {
          text-align: left;
          width: 3em;
        }
        button.plusminus {
          display: none;
          min-width: 30px;
          min-height: 30px;
          height: 30px;
          width: 30px;
          span.icon {
            color: $primary-white;
            line-height: 24px;
            font-size: 2.4rem;
          }
        }
      }
    }
  }

  &.has-js {
    .monthly-order {
      .increment-decrement {
        &.counter {
          input {
            background-color: $pastel-lighter-frost;
          }
        }
        button.plusminus {
          display: initial;
        }
      }
    }
    .feeding-plan-update-button {
      display: none;
    }
  }
}

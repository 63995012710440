//pi = payment institution

.pi { display:inline-block; background-repeat: no-repeat; background-size:contain; }

@mixin card($card-type) {
    .pi.card-#{$card-type} {
        background-image: url("/static/images/payment-icons/#{$card-type}-128px.png");
        width:46px;
        height:32px;
    }
}

@include card("visa");
@include card("american-express");
@include card("mastercard");
@include card("maestro");

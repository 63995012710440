.thankyou-prevent-overflow {
  max-width: 100%;
  overflow-x: hidden;
}

body.signup-complete-v3 {
  text-align: center;
  max-width: 100%;
  overflow-x: hidden;
  height: 100%;

  .content {
    .container {
      @include grid-blocks(10);
      padding-bottom: 0;

      h1, h2, h3, label, p, span {
        text-align: center;
      }

      h2 {
        margin-top: 40px;
        margin-bottom: 20px;
        font-size: 26px;
        line-height: 32px;
      }

      .section {
        &.header {
          @include padding($spacing-l $spacing-m $spacing-xl $spacing-m);
          margin-left: -$spacing-xs;
          margin-right: -$spacing-xs;
          background-color: var(--primary-blueberry-light-10);
          @include at-least($screen-tablet) {
            width: 100%;
            margin: 0;
          }

          .tails-dog {
            width: 100px;
            margin-bottom: 20px;
          }

          .title {
            font-family: $handwritten-font-family;
            font-size: 33px;
            margin-bottom: 24px;
          }

          .intro {
            p:nth-of-type(2) {
                font-size: 17px;
            }
          }
        }

        &.raf-widget-wrapper {
          position: relative;
          padding-left: 16px;
          padding-right: 16px;
          @include at-least($screen-tablet) {
            padding-left: 0px;
            padding-right: 0px;
          }

          .raf-widget-top-title {
            padding: $spacing-l 0 20px;
            margin: 0;
            text-align: center;
            font-size: $font-size-m;

            @include at-least($screen-desktop) {
              width: 600px;
              margin: 0 auto;
            }
          }
        }

        .footer-background {
          background: $pastel-lighter-beige;
          height: 600px;
          width: calc(100% + 31px);
          position: absolute;
          top: 0;
          left: -15px;
          z-index: -1;
          clip-path: ellipse(160% 100% at 50% 0);

          @include at-least($screen-desktop) {
            width: 100%;
            left: 0;
          }
        }

        & .intro p {
          @include margin(0 auto $spacing-s auto);
        }

        &.order-confirmation {
          margin-bottom: 40px;

          table.payment-summary {
            max-width: 100%;
          }
        }

        &.banners {
          .shop-banner {
            background-size: cover;
            background-position: center center;
            height: 300px;
            color: $navy-01;
            padding: $spacing-m $spacing-xs;
            @include grid-blocks(8);
            @include flexbox(column, center, space-between);

            h3 {
              text-align: left;
            }

            span {
              @include flex-grow(1);
              @include grid-blocks(3);
            }

            .btn {
              @include grid-blocks(2);
              justify-self: flex-end;
            }

            .product-info {
              background: transparentize($scss-primary-white, .1);
              padding: 20px;
            }

            @include at-least($screen-tablet) {
              height: 250px;
              padding: $spacing-xs $spacing-m;
              background: {
                position: center right;
                size: cover;
              }
              @include flexbox(column, start, center);
              span {
                text-align: left;
                flex-grow: 0;
                padding-bottom: $spacing-xs;
              }
              .product-info {
                @include grid-blocks(3);
                padding: 0;
                padding-bottom: 20px;
                background: none;
              }
            }

            &.full-range {
              background-image: url("/static/images/dashboard/full_range_mobile.jpg");
              @include at-least($screen-tablet) {
                background-image: url('/static/images/dashboard/full-range-banner-desktop.jpg');
              }
            }
          }
        }
      }

      .prismic-banner {
        padding: $spacing-xs 0;
        margin: 0 auto;

        .banner-content-container {
          border-radius: 8px;

          @include at-least($screen-tablet) {
            cursor: pointer;
            position: relative;
            max-width: 1280px;
            max-height: 310px;
          }


          .banner-content {
            text-align: center;
            min-width: 350px;
            max-width: 100%;
            position: relative;
            top: 0;
            left: 0;

            @include at-least($screen-tablet) {
              min-width: 420px;
              min-height: 50px;
              text-align: left;
              max-width: 30%;
              position: absolute;
              top: 12%;
              left: 10%;
            }

            h3 {
              padding: 10px;
               @include at-least($screen-tablet) {
                 text-align:left;
               }
            }

            .btn {
              margin: 10px 0;
            }

          }
        }

        @include at-least($screen-tablet) {
          width: 600px;
        }

        .banner-image-desktop {
          display: none;
          width: 100%;
          height: 100%;
          border-radius: 8px;

          @include at-least($screen-tablet) {
            display: block;
          }
        }

        .banner-image-mobile {
          display: block;
          width: 100%;
          border-radius: 8px;

          &.background {
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
          }

          @include at-least($screen-tablet) {
            display: none;
          }
        }
      }

      .delivery-details {
        p {
          text-align: left;
        }

        .row {
          background: white;
          border-radius: 4px;
          padding: 20px 20px;
          margin: 20px -10px;
          position: relative;
        }
      }

      .feedback-survey {
        margin: 20px -10px;
      }
    }
  }
  nav {
    .other-links {
      .basket {
        display: none;
      }
    }
  }

  h2 {
    margin-top: $spacing-m;
  }

  .first-delivery {
    text-align: left;
    padding-bottom: 0;

    .date-picker .form-group {
      position: relative;
      margin-bottom: 1.6em;
      margin-top: 1.6em;
    }

    .form-group.buttons {
      margin-top: 1em;
      margin-bottom: 1em;
    }
  }

  .panel-payment-summary {
    margin: 20px -10px;
    width: 100%;
    width: calc(100% + 20px);
    border-radius: 4px;
    overflow: hidden;

    .payment-summary {
      tr td:last-child {
        white-space: nowrap;
      }
    }
  }

  .stick-header {
    position: fixed;
    top: $nav-height-small;
    background: $secondary-01;
    left: 0;
    right: 0;
    text-align: center;
    padding: $spacing-xs;
    z-index: 10;

    a {
      color: white;
      text-decoration: underline !important;
    }
    @include at-least($screen-desktop) {
      display: none;
    }
  }

  .first-delivery {
    text-align: center;
    padding-bottom: $spacing-xs;
  }
  .wet-food-trial {
    position: relative;
    margin: 0 -$spacing-xs;

    .image {
      height: 715px;
      width: 100%;
      background-image: url("/static/images/products/wet-food/trial-banner-mobile2.jpg");
      background-position: center center;
    }

    .info {
      @include position(absolute, 0 0 null 0);
      padding: $spacing-xs;
      color: white;
      background-color: transparentize($scss-secondary-01, 0.4);

      .btn {
        width: 100%;
      }
    }

    @include at-least(400px) {
      .image {
        height: 412px;
        width: 100%;
        background-image: url("/static/images/products/wet-food/trial-banner-desktop3.jpg");
      }

      .info {
        @include position(absolute, 0 50% 0 0);
        min-width: 320px;

        .btn {
          width: auto;
          padding-left: $spacing-m;
          padding-right: $spacing-m;
        }
      }
    }
  }
}

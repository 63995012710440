body.modular-feeding-plan {
  .notification.info {
      position: relative;
      top: 25px;
      margin-bottom: 50px;
    }
  .section{
    @include grid-blocks(6);
    margin: 0 auto;
  }

  .feeding-plan-header {
    @include flexbox(column, center, flex-start);
    @include grid-blocks(12);
  
    .intro {
      padding: 0 1.2rem;
      margin: $spacing-xs 0;

      > h2 {
        margin-bottom: $spacing-xxs;
        font-family: $handwritten-font-family, sans-serif;
        text-align: center;
        font-size: $font-size-xl;
        line-height: 40px;
      }
    }

    @include at-least($screen-tablet) {
      @include flexbox(row, flex-start, center);
      margin: 0 auto $spacing-l auto;
      
      .intro {
        position: relative;
        margin: $spacing-l 0 0 -200px;
        padding: 0;

        > h2 {
          margin-bottom: $spacing-xs;
          font-size: $font-size-xxl;
          line-height: 52px;
        }
      }
    }

    @include at-least($screen-desktop) {
      .intro {
        margin-left: -56px;
        
        > h2 {
          text-align: left;
        }
      }
    }
  }

  .feeding-plan-breakdown {
    margin: 40px 0;
    .title {
      @include grid-blocks(6);
      padding: 20px;
      @include at-least($screen-phone) {
        margin: 20px auto;
        padding: 0;
      }
    }
  }

  .footer-intro {
    margin: 0 auto;
    .benefits {
      @include grid-blocks(8);
      margin: 20px auto;
      text-align: center;
      @include flexbox(column, center, space-around);
      hr {
        display: block;
        border: 0;
        border-top: 1px solid $navy-01;
        @include size(20% 1px);
        @include at-least($screen-phone) {
          display: none;
        }
      }
      .sub-heading {
        padding: 10px;
      }
      @include at-least($screen-phone) {
        text-align: left;
        @include flexbox(row, flex-start, flex-start);
      }
    }
    .included-items {
      @include at-least($screen-phone) {
        @include grid-blocks(6);
        margin: 20px auto;
        padding: 10px;
      }
      .items {
        background-color: $pastel-lighter-frost;
        border-radius: 0;
        min-height: fit-content;
        margin: 0 auto;
        .item {
          @include grid-blocks(5);
          margin: 0 auto;
          padding: 20px 0;
          @include flexbox(row, flex-start, flex-start);
          img {
            width: 60px;
            margin: 0 10px;
            background-color: $primary-white;
            border-radius: 8px;
            @include at-least($screen-phone) {
              margin: 0 20px;
            }
          }
        }
        @include at-least($screen-phone) {
          border-radius: 8px;
        }
      }
    }
    .cta-description {
      @include grid-blocks(6);
      margin: auto;
      padding: 20px;
    }
  }

  .footer-cta {
    @include flexbox(column, center, flex-start);
    @include grid-blocks(6);
    margin: auto;
    
    .btn {
      margin: 10px 0;
    }
  }
}

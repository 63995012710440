.dry-blend-details {
    .components {
        list-style: none;
        padding-left: 0;
        margin-top: 0;

        li {
            margin-bottom: 1em;
            position: relative;
        }

        li:nth-child(2) {
            color: $product-error;
            .percent {
                border-color: $product-error;
            }
        }

        li:nth-child(3) {
            color: darken($scss-navy-04, 20%);
            .percent {
                border-color: darken($scss-navy-04, 20%);
            }
        }

        .kibble {
            background: url(/static/images/products/dry-food/kibble-sample.png) top right no-repeat;
            background-size: 100% 100%;
            width: 34px;
            height: 34px;
            position: absolute;
            top: 0;
            right: 0;
        }

        .percent {
            display: block;
            font-size: 1.5em;
            font-weight: bold;
            height: 0.7em;
            border-bottom: 1px dashed $navy-01;
            margin: 0 3em 0.5em 0;

            span {
                background: #fff;
                padding-right: 0.5em;
            }
        }

        .name {
            display: block;
            font-weight: bold;
        }

        .status {
            display: block;
            font-size: 0.8em;
            font-style: italic;
        }
    }


    .claims {
        counter-reset: claim;
        margin-bottom: 10px !important;

        .claims-row {
            text-transform: lowercase;
            background-color: lighten($scss-pastel-lighter-frost, 35%);
            list-style: none;

            @media (min-width: $screen-sm-min) {
                border-bottom: 2px solid #fff;
            }

            .claims-claim {
                background-color: lighten($scss-pastel-darker-frost, 35%);
                padding-top: 10px;

                border-bottom: 2px solid #fff;

                @media (min-width: $screen-sm-min) {
                    display: table-cell;
                    width: 50%;
                    border-bottom: none;

                    &:not(:last-child) {
                        border-right: 2px solid white;
                    }
                }

                div {
                    padding: 1em 1em 1em 3em;
                    border-width: 0 0 2px 0;
                    position: relative;
                    overflow: hidden;

                    &:before {
                        display: table-cell;
                        content: counter(claim);
                        counter-increment: claim;
                        position: absolute;
                        font-size: 1.1em;
                        font-weight: bold;
                        left: 1em;
                        width: 1.4em;
                        height: 1.4em;
                        text-align: center;
                        line-height: 1.5em;
                        border-radius: 2em;
                        background-color: $navy-01;
                        color: #fff;
                    }

                }

                p {
                    margin: 0;
                }
            }
        }
    }

    span.ingredient {
        cursor: pointer;
        color: $secondary-03;
        white-space: nowrap;

        &:hover,
        &:focus,
        &:active {
            text-decoration: underline;
        }
    }
}


.clipboard {
    padding: 20px;
    margin: 30px auto;
    margin-top: 60px;
    max-width: 650px;
    border: 20px solid rgb(234, 216, 193);
    position: relative;
    background: white;
    z-index: inherit;

    &.clipboard-small {
        margin-top: 20px;
    }

    .for-example {
        position: absolute;
        font-size: 1.3rem;
        top: -48px;
        left: -16px;
        font-style: italic;

        @media (min-width: $screen-sm-min) {
            font-size: $font-size-body;
        }
    }

    &:after {
        display: block;
        position: absolute;
        content: " ";
        width: 150px;
        height: 93px;
        top: -60px;
        left: 50%;
        margin-left: -75px;
        background-repeat: no-repeat;
        background-size: 100%;
        z-index: 2;
    }

    &.clipboard-small:after {
        width: 100px;
        height: 62px;
        top: -40px;
        margin-left: -50px;
        left: 50%;
    }

    h2 {
        border-bottom: 2px solid $navy-01;
    }

    .lines-wrap {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;

        .lines {
            position: absolute;
            top: 50px;
            left: 0;
            width: 100%;
            height: 100%;

            background: #fff;
            background: -webkit-gradient(linear, 0 0, 0 100%, from(#d9eaf3), color-stop(4%, #fff)) 0 4px;
            background: -webkit-linear-gradient(top, #d9eaf3 0%, #fff 8%) 0 4px;
            background: -moz-linear-gradient(top, #d9eaf3 0%, #fff 8%) 0 4px;
            background: -ms-linear-gradient(top, #d9eaf3 0%, #fff 8%) 0 4px;
            background: -o-linear-gradient(top, #d9eaf3 0%, #fff 8%) 0 4px;
            background: linear-gradient(top, #d9eaf3 0%, #fff 8%) 0 4px;
            -webkit-background-size: 100% 30px;
            -moz-background-size: 100% 30px;
            -ms-background-size: 100% 30px;
            -o-background-size: 100% 30px;
            background-size: 100% 30px;
        }

        .margin-line {
            position: absolute;
            top: 0;
            left: 50px;
            height: 100%;
            width:2px;
            border-left: 1px solid #efe4e4;
        }
    }

    .kibble-chart {
        text-align: center;
        img {
            max-width: 200px;
            width: 100%;
        }
    }

    .clipboard-content {
        position: relative;
    }

    .bespoke-blend {
        font-weight: bold;
        font-size: 1.4rem;
        color: $product-error;
        font-style: italic;
        text-align: center;
    }
    .use-scoop {
        text-transform: uppercase;
        text-align: center;
        color: $product-error;
        font-weight: bold;
        max-width: 600px;
        margin: 10px auto;
    }
}

.scoop-instructions {
    min-height: 200px;

    font-weight: bold;
    text-transform: uppercase;

    position: relative;

    .scoop-and-number {
        margin: auto;
        @media (max-width: $screen-xs-max) {
            width: 200px;
        }
    }

    .scoop-number {
        display: inline-block;
        position: relative;

        background: url(/static/images/ding.png) no-repeat;
        background-size: 100%;
        color: white;
        font-size: 3.8rem;
        line-height: 38px;
        padding: 28px 20px;

        margin-top: 20px;

        @media (min-width: $screen-sm-min) {
            font-size: 68px;
            line-height: 68px;
            padding: 40px 30px;
        }
    }
    .scoop-image {
        position: absolute;
        top: -10px;
        left: 70px;
        height: 100px;
        z-index: 100;
        @media (min-width: $screen-sm-min) {
            top: 30px;
            left: 140px;
            height: 150px;
            margin-left: -30px;
            margin-top: -40px;
        }
    }
    .scoop-setting {
        width: 100%;
        font-size: $font-size-m;
        padding-top: 10px;
        margin-top: 10px;

        @media (min-width: $screen-sm-min) {
            font-size: 3.2rem;
        }

        .level-scoops {
            font-weight: bold;
        }
        .setting {
            text-align: center;
            background: $product-error;
            color: white;
            font-size: 3.2rem;
            line-height: 1.2;
            padding: 5px;

            @media (min-width: $screen-sm-min) {
                font-size: 44px;
            }
        }
    }
}


.scoop-instructions-small {
    min-height: 150px;

    font-weight: bold;
    text-transform: uppercase;

    position: relative;

    .scoop-and-number {
        margin: auto;
        @media (max-width: $screen-xs-max) {
            width: 150px;
        }
    }

    .scoop-number {
        display: inline-block;
        position: relative;

        background: url(/static/images/ding.png) no-repeat;
        background-size: 100%;
        color: white;
        font-size: 3rem;
        line-height: 30px;
        padding: 21px 15px;

        margin-top: 15px;

        @media (min-width: $screen-sm-min) {
            font-size: 51px;
            line-height: 51px;
            padding: 30px 22px;
        }
    }
    .scoop-image {
        position: absolute;
        top: -7px;
        left: 52px;
        height: 75px;
        z-index: 100;
        @media (min-width: $screen-sm-min) {
            top: 22px;
            left: 105px;
            height: 112px;
            margin-left: -22px;
            margin-top: -30px;
        }
    }
    .scoop-setting {
        width: 100%;
        font-size: $font-size-s;
        padding-top: 7px;
        margin-top: 7px;
        text-align: center;

        @media (min-width: $screen-sm-min) {
            font-size: $font-size-body;
        }

        .level-scoops {
            font-weight: bold;
        }
        .setting {
            text-align: center;
            background: $product-error;
            color: white;
            font-size: $font-size-m;
            line-height: 1.2;
            padding: 5px;

            @media (min-width: $screen-sm-min) {
                font-size: 3rem;
            }
        }
    }
}

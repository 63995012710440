.avatar {
  border-radius: 4px;
  object-fit: cover;

  &.small-avatar {
    width: 40px;
    height: 40px;
  }

  &.medium-avatar {
    width: 48px;
    height: 48px;
  }

  &.large-avatar {
    width: 64px;
    height: 64px;
  }

  &.default-avatar {
    background-color: $secondary-pear-light;
  }

  &.default-avatar.small-avatar {
    object-position: 8px 12px;
  }

  &.default-avatar.medium-avatar {
    object-position: 10px 12px;
  }

  &.default-avatar.large-avatar {
    object-position: 12px 16px;
  }
}

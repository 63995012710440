.green-stripe {
  margin-top: 50px;
  margin-bottom: 25px;
  background-color: $secondary-01;
  .section {
    &.price {
      @include padding(20px null);
      position: relative;
      p {
        span {
          color: $primary-white;
        }
        color: $primary-white;
        margin-top: 0;
        margin-bottom: 10px;
        &.price-intro {
          font-size: $font-size-s;
        }
        &.price-main {
          font-size: 2.1rem;
          text-transform: capitalize;
        }
        &.price-footer {
          font-size: 1.4rem;
          margin-bottom: 0;
        }
      }
      .before-discount {
        text-decoration: line-through;
      }
      .after-discount {
        text-transform: uppercase;
      }
      .price-loading.show {
        background-color: rgba(112, 204, 135, 0.8);
        position: absolute;
        padding-top: 20px;
        padding-bottom: 20px;
        top: 0;
        height: 100%;
        width: 100%;
        .spinner {
          @include tails_spinner(35px, 5px);
          display: block;
        }
      }
    }
  }
}

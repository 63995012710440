.buttons-wrapper-aligned {
    display: inline-block;
    width: 100%;
    text-align: center;

    .btn {
        margin: 15px 0;
    }

    &.left {
        text-align: left;

        .btn {
            margin-right: 10px;
        }
    }

    &.right {
        text-align: right;
    }
}

.buttons-wrapper-aligned-redesign {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.buttons-wrapper-stacked {
    width: fit-content;
    margin: 0 auto;

    .btn {
        display: block;
        margin: 15px 0;
    }
}

.btn {
    position: relative;
    display: inline-block;
    overflow: clip;
    width: auto;
    min-width: 200px;
    min-height: 48px;
    padding: 8px 16px;
    border: 0 solid transparent;
    border-radius: 4px;
    cursor: pointer;
    font-size: 18px;
    font-weight: var(--font-weight-regular);
    line-height: 30px;
    outline: none !important;
    text-align: center;
    text-decoration: none;
    text-overflow: ellipsis;
    transition: 100ms all linear;
    white-space: nowrap;

    a {
        text-decoration: none;
    }

    > span {
        @include inalign(middle);
        text-align: center;
        min-width: calc(100% - 42px);
    }

    > .sessioncamhidetext {
        display: initial;
        vertical-align: bottom;
    }

    $icon-size: 24px;

    &:before, &:after, .icon {
        @include inalign(middle);
        font-size: $icon-size;
        @include size($icon-size);
        line-height: $icon-size !important;
    }

    &:before {
        margin-right: 8px;
        margin-left: -8px;
    }

    &:after {
        margin-right: -8px;
        margin-left: 8px;
    }

    &:active {
        box-shadow: none;
    }

    &.back, &.btn-back {
        text-align: center;

        &:before {
            display: none;
        }
    }

    &.next, &.btn-next {
        text-align: center;

        &:after {
            display: none;
        }
    }

    &.btn-icon-only {
        min-width: 40px;
        padding: $spacing-xxs 0;

        &:before {
            margin: 0;
        }
    }

    &:focus-visible {
        box-shadow: 0 0 10px 2px $secondary-03;
    }
}

.btn-primary {
    background-color: var(--primary-cta);
    color: var(--primary-cta-text);

    &:hover {
        background-color: var(--primary-cta-hover);
        color: var(--primary-cta-text);
    }

    &:focus-visible {
        background-color: var(--primary-cta);
        box-shadow: 0 0 2px 2px var(--cta-focus);
        color: var(--primary-cta-text);
    }

    &:active {
        background-color: var(--primary-cta-pressed);
        color: var(--primary-cta-text);
    }

    &:before, &:after {
        color: var(--primary-cta-text);
    }

    &[disabled] {
        background-color: var(--cta-disabled);
        color: var(--cta-disabled-text);
        cursor: not-allowed;
        pointer-events: none;
    }
}

.on-dark.btn-primary {
    background-color: $primary-blueberry-light;
    color: var(--primary-black);
    
    &:hover {
        background-color: var(--primary-blueberry-light-30);    
    }
}

.btn-primary-hero {
    @extend .btn-primary;
}

.btn-red,
.btn-danger {
    background-color: $product-error;
    border-bottom-color: darken($scss-product-error, 10%);
    color: $primary-white;

    &:hover,
    &:focus,
    &[disabled],
    &[disabled]:focus,
    &[disabled]:active,
    &:active {
        background-color: lighten($scss-product-error, 3%);
        border-bottom-color: darken($scss-product-error, 10%);
        color: $primary-white;
    }
}

.btn-secondary {
    border: 1px solid var(--secondary-cta-text);
    background-color: var(--secondary-cta);
    color: var(--secondary-cta-text);

    &.edit {
        box-shadow: none;
    }

    &:before, &:after {
        color: var(--secondary-cta-text);
    }

    &:hover {
        border: 1px solid var(--secondary-cta-text);
        background-color: var(--secondary-cta-hover);
    }

    &:focus {
        box-shadow: unset;
    }

    &:focus-visible {
        border: 1px solid var(--secondary-cta-pressed);
        background-color: var(--secondary-cta);
        box-shadow: 0 0 2px 2px var(--cta-focus);
    }

    &:active {
        border: 1px solid var(--secondary-cta-pressed);
        background-color: var(--secondary-cta);
    }

    .icon {
        color: var(--secondary-cta-text);
    }

    a {
        color: var(--secondary-cta-text);
    }

    &[disabled] {
        background-color: $cta-disabled;
        color: $cta-disabled-text;
        cursor: not-allowed;
        pointer-events: none;
    }
}

.on-dark.btn-secondary {
    color: var(--primary-white);
    border: 1px solid var(--primary-white);
}

.btn-progress {
    position: relative;

    cursor: default;
    pointer-events: none;
    $color: rgba(255, 255, 255, 0.2);
    $angle: -45deg;
    background-image: -webkit-linear-gradient($angle, $color 25%, transparent 25%, transparent 50%, $color 50%, $color 75%, transparent 75%, transparent);
    background-image: linear-gradient($angle, $color 25%, transparent 25%, transparent 50%, $color 50%, $color 75%, transparent 75%, transparent);
    background-size: 40px 40px;
    @include animation(stripes 1.5s linear infinite);
}

.link-progress {
    position: relative;
    cursor: default;
    $color: rgba(255, 255, 255, 0.2);
    $angle: -45deg;
    background-image: -webkit-linear-gradient($angle, $color 25%, transparent 25%, transparent 50%, $color 50%, $color 75%, transparent 75%, transparent);
    background-image: linear-gradient($angle, $color 25%, transparent 25%, transparent 50%, $color 50%, $color 75%, transparent 75%, transparent);
    background-size: 40px 40px;
    @include animation(stripes 1.5s linear infinite);
}

.btn-disabled {
    background-color: var(--cta-disabled);
    color: var(--cta-disabled-text);
    cursor: not-allowed;
    pointer-events: none;
}

@include keyframes(stripes) {
  from  { background-position: 0 0; }
  to    { background-position: 40px 0; }
}

.btn-group {
    $colour: $scss-secondary-03;

    input[type="radio"] {
        content: "";
        display: none;
    }

    label {
        background: lighten($colour, 15);
        border-bottom-color: lighten($colour, 10);
        color: $primary-white;
        text-transform: none;
        font-weight: normal;

        &:hover {
            background: lighten($colour, 20);
            border-bottom-color: lighten($colour, 15);
            color: $primary-white;
        }

        &.active {
            background: darken($colour, 5);
            border-bottom-color: darken($colour, 10);
            color: $primary-white;
        }
    }

    &.check {
        label {
            &:before {
                position: absolute;
                z-index: -1;
                top: 11px;
                left: 13px;
                content: " ";
                display: block;
                width: 22px;
                height: 22px;
                margin-left: -4px;
                margin-top: -2px;
                text-align: center;
            }

            &.active:before {
                font-family: "tails-icons";
                content: $icon-check;
                color: $pastel-lighter-frost;
            }
        }

        label {
            line-height: 1.1;
            padding-left: 35px;
            padding-right: 15px;
            padding-top: 10px;
            padding-bottom: 7px;
        }

        label.btn {
            font-size: $font-size-s;
            font-weight: normal;
        }
    }
}

.radio label strong {
    text-transform: uppercase;
}

.watch-video {
    .play-button.play-button {
        color: $secondary-03;
        text-shadow: 0 3px 0 darken($scss-secondary-03, 10);
        border-top: 0 solid transparent;

        &:hover {
            text-shadow: 0 2px 0 darken($scss-secondary-03, 10);
            border-top: 2px solid transparent;
        }

        &:active {
            text-shadow: 0 1px 0 darken($scss-secondary-03, 10);
            border-top: 4px solid transparent;
        }
    }
}


@mixin toggle_on {
    border-radius: 0 !important;
    border: none !important;
    box-shadow: none !important;
    background-color: $primary-white !important;
    color: $navy-01 !important;
    border-bottom: none !important;
}

.toggle-buttons {
    padding: 10px;
    background: $pastel-lighter-frost;
    box-shadow: none;
    border-radius: 0;
    @include flexbox(row, space-between, center);

    &.or {
        span {
            $_size: 40px;
            border-radius: 50%;
            @include size($_size);
            font-family: $default-font-family;
            background-color: $pastel-lighter-frost;
            position: absolute;
            text-align: center;
            color: $primary-white;
            line-height: $_size;
            z-index: 1;
        }
    }

    input[type=radio] {
        display: none;
    }

    input:checked + label {
        @include toggle_on;
    }

    .toggle-button {
        position: relative;
        display: block;
        border-radius: 0;
        border: none;
        margin-right: 7px;
        box-shadow: none;
        background-color: $primary-white;
        @include flex-grow(1);
        @include user-select(none);

        input[type=checkbox] {
            display: none;
        }

        &:last-child {
            margin-right: 0;
        }

        label {
            cursor: pointer;
            width: 100%;
            color: $navy-01;
            background-color: $pastel-lighter-frost;
            margin: 0;
            text-align: center;
            min-width: blocks(1);
            font-size: $font-size-s;
            text-transform: none;
            font-weight: normal;
            border: none !important;
            min-height: 40px;
            padding: 0;
            line-height: 40px;
            @include transition(all 0.3s);
        }
    }
}

.toggle-buttons-tray-selector {
    text-align: left;
    display: flex;
    gap: 24px;
    flex-direction: row;
    input[type="radio"]{
        margin: 0;
    }
    @include until($screen-desktop){
        flex-direction: column;
        gap: 16px;
    }
}

@mixin range {
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 50%;
    background-color: $secondary-03;
    border-bottom: 3px solid $navy-01;
}

input[type="range"] {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -moz-tap-highlight-color: rgba(255, 255, 255, 0);
    @include appearance(none);
    height: 20px;
    padding: 1px 2px;
    outline: none; /* no focus outline */
    margin: 0 auto 0 auto;
    vertical-align: middle;
    width: 100%;
    background-color: $pastel-lighter-frost;
    border-radius: 50px;
    cursor: pointer;

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        @include range;
    }
}
input[type="range"]::-moz-range-track {
    background-color: transparent;
    outline: none;
}
input[type="range"]::-moz-range-thumb {
    -moz-appearance: none;
    @include range;
}

input[type="range"]::-ms-track {
    border: inherit;
    color: transparent;
    background: transparent;
    outline: none;
}

input[type="range"]::-ms-thumb {
    @include range;
}

input[type="range"]::-ms-fill-lower,
input[type="range"]::-ms-fill-upper {
    background: transparent;
}

input[type="range"]::-ms-tooltip {
    display: none;
}

.btn-brand-facebook {
    color: $primary-white;

    &, &:focus {
        background: #3b5998;
        border-color: #2a4784;
    }

    &:after {
        @include icon($icon-facebook-circle);
        color: $primary-white;
        margin-left: 0;
    }

    &:hover,
    &:active {
        background: lighten(#3b5998, 10%);
        border-color: lighten(#2a4784, 10%);
        color: $primary-white;
    }

    &.redesign {
        padding: $spacing-xxs $spacing-s;

        &, &:focus-within {
            background: #3b5998;
            border-color: #2a4784;
        }

        &:focus-within {
            color: $primary-white;
        }

        &:after {
            display: none;
        }

        &:before {
            @include icon($icon-facebook-circle);
            color: $primary-white;
            margin-left: 0;
        }
    }
}

.btn-brand-google {
    @include flexbox(row, center, flex-start);
    height: 50px;
    width: 250px;
    min-width: 250px;
    max-width: 270px;
    margin: 0 auto;
    border-radius: 2px;
    background-color: #4285f4;
    color: $primary-white;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.25);
    padding: 1px;

    .google-icon {
        height: 48px;
        max-width: 48px;
        min-width: 48px;
        background-image: url("/static/images/social/google-icon.png");
        background-repeat: no-repeat;
        background-position: left;
        background-size: 48px;
    }

    span {
        flex-grow: 2;
        min-width: auto;
        max-width: none;
    }

    &:hover,
    &:focus,
    &:active {
        background-color: #4285f4;
        color: $primary-white;
        -webkit-box-shadow: 0 0 3px 3px rgba(66,133,244,.3);
        box-shadow: 0 0 3px 3px rgba(66,133,244,.3);
    }
}

.radio-buttons-new {
    legend {
        text-align: left;
        margin-bottom: $spacing-s;
    }

    .hint {
        margin-bottom: $spacing-xs;
        color: $navy-02;
    }

    &.short.inline-radio {
        .radio-button-new {
            display: inline-grid;

            & + .radio-button-new {
                margin-top: 0;
                margin-left: $spacing-s;
            }
        }
    }

    @media screen and (min-width: $screen-tablet) {
        &.inline-radio {
            .radio-button-new {
                display: inline-grid;

                & + .radio-button-new {
                    margin-top: 0;
                    margin-left: $spacing-s;
                }
            }
        }
    }
}

.radio-button-new {
    cursor: pointer;
    position: relative;
    display: block;
    font-size: $font-size-body;
    display: grid;
    grid-template-columns: 24px auto;
    gap: $spacing-xxs;

    & + .radio-button-new {
        margin-top: $spacing-s;
    }

    input[type=radio] {
        -webkit-appearance: none;
        appearance: none;
        background-color: $primary-white;
        margin: 0;
        width: 24px;
        height: 24px;
        border: 1px solid $primary-colour;
        border-radius: 50%;
        transform: translateY(-0.075em);
        display: grid;
        place-content: center;
        cursor: pointer;

        &:checked {
            background-color: $product-selected;
            border: 1px solid $product-selected;

            &:active {
                border-color: $product-selected;
            }

            &:disabled {
                background-color: $navy-02;
                border-color: $navy-02;
            }
        }

        &::before {
            content: "";
            width: 8px;
            height: 8px;
            border-radius: 50%;
            transform: scale(0);
            transition: 120ms transform ease-in-out;
            box-shadow: inset 1em 1em $primary-white;
            /* Windows High Contrast Mode */
            background-color: $primary-white;
        }

        &:checked::before {
            transform: scale(1);
        }

        &:focus-visible {
            box-shadow: 0 0 4px 2px #ffc33e;
            box-shadow: 0 0 4px 2px $secondary-03;
            outline: none;
        }

        &:active {
            border-color: $navy-02;
        }

        &:disabled {
            background-color: $pastel-lighter-silver;
            border-color: $pastel-darker-silver;
            cursor: not-allowed;
        }
    }

    div {

        text-align: left;
    }
}

.btn-share-whatsapp {
    background: #075e54;
    color: $primary-white !important;
}

.btn-share-facebook {
    background: #4267B2;
    color: $primary-white !important;
}

.btn-share-messenger {
    background: #0099FF;
    color: $primary-white !important;
}

.radio-buttons {
    text-align: center;

    input[type=radio] {
        display: none;
    }

    .radio-toggle-button {
        display: inline-block;
        margin: 0 25px;

        input[type=radio]:checked ~ label {
            @include radio_toggle_on;
        }

        label {
            cursor: pointer;
            position: relative;

            &:before {
                display: block;
                position: absolute;
                left: 0px;
                top: 0px;
                content: "";
                width: 25px;
                height: 25px;
                border-radius: 50%;
                box-shadow: 0 0 0 1px $primary-colour;
                background-color: $primary-white;
            }

            > span {
              padding-left: 35px;
            }

            &:after {
                display: block;
                position: absolute;
                left: 5px;
                top: 5px;
                content: "";
                width: 15px;
                height: 15px;
                background: $secondary-02;
                border-radius: 50%;
                opacity: 0;
                transform: scale(.1);
                transition: 200ms all ease;
            }

            &.disabled {
                pointer-events: none;
                cursor: not-allowed;
                color: $navy-04;

                &:before {
                    background-color: $pastel-lighter-frost;
                }

                &:after {
                    background-color: $pastel-lighter-frost;
                }
            }
        }

        span {
            @include inalign(middle);
            padding-left: 5px;
            font-weight: bold;
        }
    }

    &.left {
        text-align: left;

        .radio-toggle-button {
            margin: 0;

            span {
                font-weight: normal;
            }
        }
    }
}

.flow-buttons {
    margin: 0 auto;
    max-width: 640px;
    @include flexbox(column, center, flex-start);
    @include grid-blocks(7);

    .btn {
        margin-bottom: 20px;
    }

    @include at-least($screen-tablet) {
        @include flexbox(row, center, flex-start);

        a.back, a.btn {
            margin-left: 20px;
        }

        .btn {
            margin-bottom: 0;
        }
    }
}

.flow-buttons-modal {
    @include at-least($screen-tablet) {
        @include flexbox(column, center, flex-start);

        a.back, a.btn {
            margin-left: unset;
        }

        .btn {
            margin-bottom: 20px;
        }
    }
}

.btn-bold {
    font-weight: bold;
}

.btn .icon {
    margin-right: 10px;
}

.toggle-btn .delivery-dates-btn {
    max-width: 282px;
    margin-left: auto;
    margin-right: auto;
}

.shop-tile {
  margin: 20px 10px;
  background-color: $primary-white;
  border-radius: 8px;
  text-align: center;
  display: flex;
  flex-direction: column;
  @include at-least($screen-tablet) {
    @include grid-blocks(4);
  }

  .tile-image {
    width: 100%;
    height: 210px;
    background-size: cover;
    background-position: center;
    border-radius: 8px 8px 0 0;
    @include at-least($screen-phone) {
      height: 260px;
    }
  }

  .product-info {
    padding: 15px;
    flex-grow: 2;

    h4 {
      padding-bottom: 15px;
    }
  }

  .button {
    .btn {
      margin-bottom: 20px;
      font-weight: bold;
      font-size: $font-size-s;
      @include grid-blocks(2);

      .icon {
        font-size: $font-size-m;
        position: absolute;
        min-width: 50px;
        right: 0;
      }
    }
  }
}


.shop-banner {
  @include until($screen-tablet) {
    max-width: 640px;
    height: auto;
    margin: 0 auto;
  }

  .show-popup {
    cursor: pointer;
    position: relative;
    max-width: 1280px;
    max-height: 310px;

    .banner-content {
      min-width: 420px;
      min-height: 50px;
      text-align: left;
      max-width: 30%;
      padding: 15px;
      position: absolute;
      top: 20%;
      left: 10%;
      @include until($screen-tablet) {
        text-align: center;
        min-width: 350px;
        max-width: 100%;
        position: relative;
        top: 0;
        left: 0;
        h3 {
          padding: 10px;
        }
      }

      .btn {
        margin: 20px 0;
        @include until($screen-tablet) {
          margin: 10px 0;
        }
      }
    }

    img.banner-image-desktop {
      height: 310px;
      width: 1280px;
      background-repeat: no-repeat;
      background-size: contain;
      @include until($screen-tablet) {
        display: none;
      }
    }

    img.banner-image-mobile {
      display: none;
      @include until($screen-tablet) {
        max-width: 100%;
        display: block;
      }
    }

    @include until($screen-tablet) {
      @include flexbox(column, center, center);
      max-height: fit-content;
    }
  }
}

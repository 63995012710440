.table {
    border: 1px solid #979797;
}

.table > thead > tr > th,
.table > thead > tr > td,
.table > tbody > tr > th,
.table > tbody > tr > td {
    border-top: 0;
}

.table-striped > tbody {
    & > tr:nth-child(odd) {
        & > td,
        & > th {
            background-color: white;
        }
    }
}

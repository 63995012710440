.tails-view.tails-view-carousel-wrapper {

  .section.widget-tooltip, .section.carousel {
    .image {
      $image_size: 100px;
      @include size($image_size);
      @include min-size($image_size);
      background-size: 130%;
      background-repeat: no-repeat;
      background-position: center;
      &.wet-food {
        background-image: url("/static/images/products/wet-food/thumb.png");
      }
      &.treats {
        background-image: url("/static/images/products/dental-dailies/thumb.png");
      }
      &.good-dog-treats {
        background-image: url("/static/images/products/good-dog-treats/thumb.png");
      }
      &.salmon-treats {
        background-image: url("/static/images/products/salmon-treats/thumb.png");
      }
      &.duck-treats {
        background-image: url("/static/images/products/duck-treats/thumb.png");
      }
      &.lamb-treats {
        background-image: url("/static/images/products/lamb-treats/thumb.png");
      }
      &.pork-treats {
        background-image: url("/static/images/products/pork-treats/thumb.png");
      }
      &.poo-bags {
        background-image: url("/static/images/products/poo-bags/thumb.png");
      }
    }
  }

  .section.carousel {
    overflow: hidden;
    .slick-list.draggable {
      .slick-track {
        display: inline-flex;
      }
    }
    .tile {
      outline: none;
      position: relative;
      display: inline-block; //needed for non-js browsers
      &.slick-current {
        label {
          opacity: 1;
          border: 2px solid $navy-01;
        }
      }
      &.product-in-order {
        cursor: not-allowed;
        user-select: none;
        pointer-events: none;
        &:after {
          @include icon($icon-check-circle);
          color: $secondary-01;
          top: 5px;
          right: 10px;
          position: absolute;
        }
        label {
          cursor: not-allowed;
          user-select: none;
          pointer-events: none;
          border: 2px solid transparent;
          filter: grayscale(100%);
          opacity: 0.5;
          &:hover {
            opacity: 0.5;
          }
        }
      }
      label {
        background-color: $primary-white;
        border: 2px solid transparent;
        opacity: 0.5;
        margin: 0;
        padding: 10px;
        border-radius: 5px;
        min-height: 160px;
        &:hover {
          opacity: 1;
          cursor: pointer;
        }
        .image {
          margin: 0 auto;
        }
        p {
          margin: 0;
          text-align: center;
          height: 50px;
          display: table-cell;
          vertical-align: middle;
          width: 115px;
        }
      }
    }
  }

  .section .pointer {
    display: none;
    @include at-least($screen-tablet) {
      @include triangle(32px, $navy-01, down);
      @include transition(all 0.3s ease-in-out);
      @include transform(translate3d(50px, 0, 0));
      top: -30px;
      margin-bottom: -23px;
      position: relative;
    }
  }

  .section.widget-tooltip {
    position: relative;
    overflow: hidden;
    .widget-tooltip-wrapper {
      min-width: 100%;
      &.product-in-order {
        display: none;
      }
      &.product-not-in-order {
        display: block;
      }
      input[type=radio] {
        display: none;
      }
      input[type=radio]:checked ~ .tooltip {
        display: block;
      }
      .tooltip {
        min-width: 100%;
        max-width: 100%;
        margin-bottom: 30px;
        display: none;
        .main-block {
          @include flexbox(column, center, space-between);
          @include padding(10px);
          width: 100%;
          background-color: $navy-01;
          border-radius: 10px;
          @include at-least($screen-tablet) {
            @include flexbox(row, center, flex-start);
          }
          div.description {
            padding: 5px 10px;
            flex-grow: 1;
            margin-bottom: 10px;
            @include at-least($screen-tablet) {
              margin: 0;
            }
            p {
              color: $primary-white;
              text-align: center;
              margin-top: 0;
              margin-bottom: 5px;
              @include at-least($screen-tablet) {
                text-align: left;
              }
              &:last-child {
                margin-bottom: 0;
              }
              &.title {
                font-size: $font-size-body;
              }
              &.description {
              }
              &.discount-summary {
                font-weight: bold;
              }
              &.claim {
                color: $primary-white;
                font-style: italic;
                line-height: 1rem;
              }
            }
          }
          .add-product {
            flex-shrink: 0;
            button {
              &.btn {
                &.btn-secondary-hollow:after {
                  @include icon($icon-plus-circle);
                  color: $primary-white;
                }
              }
            }
          }
        }
      }
    }
  }

}

body.home {
  .update-banner {
    .promo {
      @include promo();
      height: 30px;
      @include at-least($screen-tablet) {
        height: 30px;
      }
      text-align: center;
      .offer-text {
        color: $primary-white;
      }
      font-size: 1.4rem;
    }
  }
  background-color: $primary-white;

  .main {
    background: {
        image: url(/static/images/tail-sand-bg.png);
        size: contain;
        position: center center;
        repeat: no-repeat;
      };
  }

  .sticky-cta[data-state='hide'] {
    @include translated(0, -100%, 0);
    @include transition(all 0.3s);
  }

  &.scroll-down {
    .sticky-cta[data-state='show'] {
      @include translated(0, 0, 0);
    }
  }

  .section {
    @include container($container-max-width);
  }
  .hero-header {
    width: 100%;
  }
}


.update-banner {
  color: $primary-white;
  background-color: $secondary-01;
  margin: auto;
  width: 100%;
  display: inline-block;
  padding: 10px 15px;
  text-align: center;
  border-radius: 0;
  &.alert {
    background: $product-error-background;
  }

  &.information {
    background: $product-info-background;
  }

  p {
    margin: 0;
    padding: 0;
    color: $primary-white;
  }
}

.promo-change-banner {
  background-color: $product-info-background;
  color: $navy-01;
  font-weight: bold;
  text-transform: uppercase;
  position: absolute;
  z-indeX: 1000;
  width: 100%;
  margin-top: -30px;
  padding: 50px 20px 10px;
  text-align: center;
  font-size: 1.4rem;

  strong {
    color: $primary-white;
  }

  p {
    margin: 0;
  }

  @media (min-width: $screen-xs-min) {
    font-size: $font-size-s;
  }
}

body.order-days {

  //background curve
  .background-curve-container .background-curve {
    background-color: #E8EFF7;
    border-radius: 0 0 50% 50%;
    position: relative;
    z-index: -1;
    height: 100%;
    left: -63vw;
    width: 230vw;

    @media only screen and (min-width: $screen-sm-min) {
      left: -15vw;
      width: 130vw;
    }
  }

  .background-curve-container {
    overflow: hidden;
    position: absolute;
    height: 400px;
    width: 100%;
    left: 0;
    top: -170px;
  }

  .background-curve-container.higher {
    top: -280px;

    @media only screen and (min-width: $screen-desktop) {
      top: -320px;
    }
  }

  //hero text
  .hero-text {
    max-width: 520px;
    text-align: center;
    margin: 20px auto;
    width: 89%;
    margin-top: 120px;

    .handwritten {
      font-family: $handwritten-font-family;
    }

    @media only screen and (max-width: $screen-desktop) {
      margin-top: 80px;
    }

    p {
      margin-top: 20px;
      font-weight: 600;
    }
  }

  //card & container
  .content-card {
    margin: auto;
    display: flex;
    align-items: center;

    &.price-table {
      border-radius: 12px;
      box-shadow: 0 6px 7px 1px rgba(0, 0, 0, 0.13);
      width: 95%;
    }

    &.price-extras {
      margin-top: 30px;
      flex-direction: column;
      width: 100%;
    }

    &.delivery-date {
      justify-content: start;
      flex-direction: row;
      align-items: flex-start;
      background-color: $pastel-lighter-silver;
      margin-top: 30px;
      padding: 15px;
      width: 100%;
    }
    &.info {
      background-color: $pastel-lighter-silver;
      padding: 15px;
    }

    &.next-delivery {
      background-color: $primary-white;
      border-radius: 12px;
      box-shadow: 0 6px 7px 1px rgba(0, 0, 0, 0.13);
      align-items: flex-start;
      text-align: left;
      margin: 30px auto;
      padding: 15px;
      width: 95%;
    }
  }

  .order-days.price-table-wrapper {
    width: 100%;

    .payment-summary-container {
      border-radius: 12px;
    }

    tr {
      height: 80px;
    }

    tr:nth-child(2n) {
      background-color: $pastel-lighter-silver;
    }

    tr:nth-child(2n+1) {
      background-color: $primary-white;
    }

    tr.non-order-items-separator, tr.non-order-item {
      display: none;
    }
  }

  .line-item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    padding: 0 15px;
    border-bottom: 2px solid $pastel-lighter-silver;
    .price {
      font-weight: 600;
    }
    &.highlight {
      background: $navy-00;
      p {
        color: $primary-white;
      }
    }
  }

  .with-icon {
    .calendar-icon, .delivery-icon {
      font-size: 48px;
      width: 48px;
      height: 48px;
      margin-bottom: 5px;
      background: radial-gradient(0.75em circle at right 0.40625em bottom 0.40625em, $pastel-rose 50%, transparent 51%);

    }
    p {
      width: 100%;
      padding: 0 20px;
      margin: 0;

      .date {
        font-weight: 600;
      }
    }
  }


  @media only screen and (min-width: $screen-sm-min) {
    .price-table, .price-extras, .delivery-date, .info, .next-delivery {
      max-width: 435px;
    }
    .delivery-date, .info {
      border-radius: 12px;
    }
  }

  // actions
  .actions {
    margin: 30px auto;
    max-width: 450px;
    display: flex;
    flex-direction: column;

    .btn {
      min-width: 250px;
      margin-bottom: 20px;
    }
  }

  //error page
  .error-container {
    min-height: 450px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .hero-text {
      margin-top: 20px;
    }
  }
}

.width-fix {
  width: 100vw !important;
}

.getting-started-container {
  $desktop-modal-width: 900px;
  $desktop-padding: 40px;

  h3 {
    padding-bottom: 8px;
  }

  h6 {
    font-size: 2.1rem;
    margin: 0;
    padding: 0;
    padding-top: 10px;

    &.avatar-pet-name {
      overflow-wrap: break-word;
    }
  }

  h5 {
    margin-left: 0px;
  }

  .close {
    display: inline-block;
    float: right;
    margin-right: -15px;
    margin-top: -15px;
    cursor: pointer;
  }

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.45);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;


  .getting-started-inner {
    background: #fff;
    box-shadow: 0 0 9px #777;
    border-radius: 10px;
    max-height: 72vh;
    overflow-y: scroll;
  }

  .getting-started-tutorial {
    width: $desktop-modal-width;
    padding: $desktop-padding;

    .next-step {
      float: right;
      font-weight: 500;
      margin-bottom: 40px;
    }

    .prev-step {
      display: flex;
      margin-bottom: 27px;
      font-weight: 500;
      text-decoration: none !important;
    }

    p a {
      padding-top: 10px;
      display: block;
      text-decoration: underline !important;
    }

    p {
      padding-top: 10px;
      display: block;
    }

    .subheading {
      font-size: larger;
    }

    .close {
      display: inline-block;
      float: right;
      margin-right: -15px;
      margin-top: -15px;
      cursor: pointer;
    }

    p {
      margin-block-start: 0em;
      margin-block-end: 34px;
      padding-right: 80px;
    }

    ul {
      padding-inline-start: 0px;

      li.ingredient-list{
        display: inline-flex;
      }
      li .prebiotic-image {
        height: 39px;
        width: 48px;
      }
      li .beet-image {
        height: 52px;
        width: 45px;
      }
      li .chondroitin-glucosamine {
        height: 52px;
        width: 45px;
      }
      li .omega3 {
        height: 32px;
      }
      li .ingredient-text p .ingredient-title {
        font-weight: bold;
      }
      img {
        margin-right: 14px;
        margin-top: 15px;
      }
    }

    &.does-overflow {
      height: 72vh;
    }
  }


  .btn .next-step-right-chevron {
    display: inline;
  }

  .next-step .next-step-right-chevron svg {
    display: inline;
    width: auto;
    height: 17px;
    margin-left: 8px;
  }

  .getting-started-tutorial a .back-arrow svg {
    display: inline;
    width: auto;
    height: 17px;
    padding-right: 10px;
    margin-top: 3px;
  }

  .prev-step:hover {
    .back-arrow {
      svg {
        path {
          fill: #6DE2AD !important;
        }
      }
      cursor: pointer;
    }
  }
  
  .getting-started-tutorial .key-ingredients-title {
    font-weight: bold;
    margin-left: 0px;
  }

  .getting-started-tutorial .whats-in-dogs-food {
    font-weight: bold;
  }

  .getting-started-tutorial .getting-started {
    font-weight: bold;
  }

  .getting-started-tutorial .ingredients-rows {
    text-align: center;
    padding: 0px;
  }

  .getting-started-tutorial .ingredients-rows img {
    height: 40px;
  }

  .getting-started-tutorial .ingredients-rows.ingredients-text {
    font-weight: bold;
  }

  .getting-started-tutorial p .bold {
    font-weight: bold;
  }

  .getting-started-tutorial .add-a-photo {
    margin-left: 0px;
  }

  .seven-day-switch ul li * {
    display: inline-block;
  }

  .seven-day-switch ul li .switch-text {
    display: block;
    padding-left: 18px;
  }

  .seven-day-switch ul {
    list-style-type: none;
    padding-left: 0px;
  }

  .seven-day-switch ul li p {
    margin-block-end: 0px;
    font-size: 17px;
    padding-top: 0px;
    padding-bottom: 0px;
    display: table-row;
  }

  .seven-day-switch ul li .frac {
    font-size: 18px;
    display: inline-block;
  }

  .seven-day-switch ul li {
    display: flex;
    padding-top: 25px;
  }

  .seven-day-switch ul li .nearly-there {
    margin-top: 28px;
    margin-bottom: 28px;
    font-style: italic;
    padding-left: 82px;
  }

  .seven-day-switch ul li h6 {
    font-size: 18px;
    padding-top: 30px;
    display: table-row;
  }

  .seven-day-switch .placeholder {
    height: 50px;
    width: 60px;
    display: none;
    border-radius: 4px;
    background-color: #70cc87;
  }

  .getting-started-prompt {
    max-width: 550px;
    text-align: center;
    padding-top: 40px;
  }

  .getting-started-prompt p {
    padding-top: 15px;
    padding-left: 60px;
    padding-right: 60px;
  }

  .getting-started-prompt .buttons-wrapper-stacked {
    padding-top: 15px;
    width: 100%;
    padding-left: 100px;
    padding-right: 100px;
  }

  .getting-started-prompt .btn-secondary {
    box-shadow: none;
    color: #2f7f70;
  }

  .getting-started-prompt .buttons-wrapper-stacked button {
    width: 100%;
    padding-bottom: 10px;
  }

  .pad-8 {
    padding-bottom: 8px;
  }

  .pad-16 {
    padding-bottom: 16px;
  }

  .pad-32 {
    padding-bottom: 32px;
  }

  .pad-40 {
    padding-bottom: 40px;
  }


  .step {
    margin-bottom: 32px;
    display: flex;
  }

  .step h5 {
    font-size: large;
    padding-top: 10px;
    margin-left: 12px;
  }

  .step button {
    margin-left: auto;
    width: 160px;
    vertical-align: top;
  }

  .step .btn-visited {
    margin-left: auto;
    box-shadow: inset 0 0 0 2px #0a1f65;
    background-color: #fff;
    color: #0a1f65;
    font-weight: 400;
  }

  .step div,
  h5,
  button {
    margin-right: 10px;
    margin-left: 10px;
  }

  .step #number-circle {
    height: 40px;
    width: 40px;
    border: solid;
    border-color: #030e2d;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    padding-top: 6px;
    font-weight: 700;
    border-width: 2px;
  }

  .photo-container-outer {
    display: flex;
  }

  .photo-container {
    display: inline-block;
    width: 144px;

    h6 {
      text-align: center;
    }

    margin-bottom: 20px;
    margin-right: 40px;
    border-radius: 50%;

    canvas {
      border-radius: 50%;
      overflow: hidden;
      cursor: pointer;
    }

    .pet-image-container {
      display: grid;
    }

    .pet-image-circle {
      border-radius: 50%;
      cursor: pointer;
      background: #6DE2AD;
      padding-left: 20px;
      width: 144px;
      height: 144px;

      p {
        position: relative;
        text-align: -webkit-center;
        text-align: -moz-center;
        margin-left: -13px;
        margin-top: -7px;
        padding: 0;
      }
    }

    .pet-image {
      width: 35px;
      margin-top: 38px;
      margin-left: 36px;
      margin-right: 36px;
    }

    .pet-photo {
      padding-left: 0px;
    }

    .croppa-container {
      background-color: transparent;
    }
  }

  .tutorial-continue {
    float: right;
    margin-bottom: 40px;
  }

  .title {
    display: flex;
  }

  @media screen and (max-width: 992px) {
    h6 {
      font-size: 1.9rem;
    }
    .getting-started-inner {
      max-width: 100%;
      max-height: 100% !important;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      border-radius: 0px;
    }
    .getting-started-prompt {
      padding-top: 25vh;

      h3 {
        padding-left: 20px;
        padding-right: 20px;
      }

      p {
        padding-left: 20px;
        padding-right: 20px;
      }

      .buttons-wrapper-stacked {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
    .getting-started-tutorial {
      width: 100%;
      padding: 1em;
      height: initial;
    }
    .getting-started-tutorial .close {
      position: absolute;
      top: 0;
      right: 0;
      margin-right: 15px;
      margin-top: 15px;
      background: $primary-white;
      padding: 10px;
      border-radius: 50%;
    }
    .step div,
    h5,
    button {
      margin-left: 10px;
    }
    .getting-started-tutorial p {
      margin-block-start: 0em;
      margin-block-end: 34px;
      padding-right: 20px;
    }
    .step button {
      margin-left: auto;
      width: 100%;
      margin-top: 1em;
    }
    .step {
      margin-bottom: 48px;
      display: block;
    }
    .photo-container {
      margin-right: 20px;
    }
    .button.btn-secondary.next-step {
      font-size: smaller;
    }
    .under-button {
      padding-top: 100px;
    }
  }

  @media screen and (min-device-width: 650px) and (max-device-width: 991px) {
    .getting-started-tutorial {
      padding: 100px;
    }
    .under-button {
      padding-top: 100px;
    }
  }

  ::-webkit-scrollbar-track {
    background: none;
  }
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 5px;
  }
}

#mti-experiments-hat-icon.fa-graduation-cap {
  height: 20px;
  margin-right: 32px;
  margin-top: 4px;
  cursor: pointer;
}

#mti-experiments-tooltip {
  background: #040f2c;
  color: white;
  font-weight: bold;
  padding: 4px 8px;
  font-size: 13px;
  border-radius: 4px;
  top: 9px !important;
  font-weight: 400;
  display: none;

  #arrow,
  #arrow::before {
    position: absolute;
    width: 8px;
    height: 8px;
    z-index: -1;
  }

  #arrow::before {
    content: "";
    transform: rotate(45deg);
    background: #040f2c;
  }

  &[data-popper-placement^="top"] > #arrow {
    bottom: -4px;
  }

  &[data-popper-placement^="bottom"] > #arrow {
    top: -4px;
  }

  &[data-popper-placement^="left"] > #arrow {
    right: -4px;
  }

  &[data-popper-placement^="right"] > #arrow {
    left: -4px;
  }

  &[data-show] {
    display: block;
  }
}

.mti-experiments-notification-dot {
  height: 10px;
  width: 10px;
  top: -12px;
  right: 38px;
  background-color: #ed5f55;
  border-radius: 50%;
  display: inline-block;
  position: relative;
}

.exclusions-preferences-form {
  .section {
    margin: 20px 0 40px 0;

    .info-box-wrapper {
      padding: 20px;
      margin: 0 0 30px;
      width: 100%;

      &.exclusion-preferences {
        background-color: var(--primary-blueberry-light-10);
      }
    }
  }
  .question {
    margin: 20px 0;
  }

  .radio-buttons-new {
    margin: 20px 0;

    &.inline-radio {
      .radio-button-new {
          display: inline-grid;

          & + .radio-button-new {
              margin-top: 0;
              margin-left: $spacing-s;
          }
      }
    }

    label {
      margin-bottom: 0;
    }
  }

  .choices {
    margin: 20px 0;
  }

  .exclusions-choices {
    display: none;

    &.shown {
      display: block;
    }
  }

  .preferences-choices {
    display: none;

    &.shown {
      display: block;
    }
  }

  #incompatible-allergen-explanation {
    display: none;
  }

  //exclusions
  #exclusions-yes:checked ~ .radio-buttons #exclusions-yes-label,
  #exclusions-no:checked ~ .radio-buttons #exclusions-no-label {
    @include radio_toggle_on;
  }
  #exclusions-no:checked ~ .exclusions-choices {
      display: none;
  }
  #exclusions-yes:checked ~ .exclusions-choices {
      display: block;
  }

  // preferences
  #preferences-yes:checked ~ .radio-buttons #preferences-yes-label,
  #preferences-no:checked ~ .radio-buttons #preferences-no-label {
    @include radio_toggle_on;
  }

  .hypoallergenic {
    @include until($screen-phone) {
      @include flexbox(column, top, flex-start);
    }
    @include flexbox(row, top, space-between);
    margin: 20px 0 10px 0;

    label:before {
        min-width: 32px;
        min-height: 32px;
        line-height: 32px;
        width: 32px;
        height: 32px;
    }

    .wrapper & {
      flex: 1 1 0;
    }
  }
  .hypoallergenic-info-text {
    display: flex;
    margin-top: 5px;
    align-items: start;
    @include until($screen-tablet) {
      padding: 5px 0 0 3px;
    }
    .notify-icon {
        background-color: $primary-blueberry-10;
        color: $primary-black;
        padding: 5px;
        border-radius: 50%;
    }
    .hypoallergenic-help {
        margin-bottom: 0 !important;
        margin-left: 5px;
    }
  }
}

.raf-widget-v3 {
    border-radius: 8px;
    padding: $spacing-m 16px;
    text-align: center;
    background: $primary-white;
    box-shadow: 0 2px 4px 0 $pastel-darker-silver;
    border: 1px solid $pastel-darker-silver;
    position: relative;

    @include at-least($screen-desktop) {
        padding: $spacing-m 32px;
        width: 600px;
        margin: 0 auto;
    }

    .btn {
        max-width: 296px;
        display: block;
    }

    .raf-widget-banner {
        background: $primary-white url("/static/images/signup/raf-widget-header.jpeg") no-repeat center top / cover;
        clip-path: ellipse(100% 100% at 50% 0);
        margin: -32px -16px -32px -16px;
        height: 187px;
        background-size: 130%;
        background-position-x: 30%;
        border-radius: 8px;

        @include at-least($screen-tablet) {
            background-size: cover;
            background-position-y: -33px;
        }

        @include at-least($screen-desktop) {
            margin: -32px -32px -80px -32px;
            width: 598px;
            height: 210px;
            background-size: 598px 247px;
            background-position: 0 -7px;
        }
    }

    .raf-widget-header {
        margin-top: 70px;

        @include at-least($screen-tablet) {
            margin-top: 115px;
        }
    }

    .raf-widget-title {
        font-family: $handwritten-font-family;
        font-size: 26px;
        line-height: 32px;
    }
    
    h4, h5 {
        text-align: center;
        margin-bottom: 10px;
    }

    .raf-widget-code {
        background-color: $pastel-lighter-frost;
        padding: $spacing-xs $spacing-xxs;
        border-radius: 4px;
        
        .share-link-now {
            margin-bottom: 4px;
            font-weight: bold;
        }

        .raf-widget-your-code {
            #your-code {
                width: 100%;
                margin-bottom: $spacing-xxs;
            }

            .btn-share.link, .native-share {
                margin: 0 auto 0 auto;

                .share-link {
                    &.copied {
                        display: none;
                    }
                }

                &.copy-active {
                    .copy {
                        display: none;
                    }
                    .copied {
                        display: block;
                    }
                }
            }
        }
    }

    .raf-widget-share {
        h5 {
            margin-top: $spacing-xs;
        }

        .raf-widget-share-buttons {
            margin: $spacing-xs 0;
            .btn {
                margin: $spacing-xs auto;
            }
        }
        p {
            font-size: $font-size-s;
        }
    }
    
    .raf-widget-footer {
        margin-top: $spacing-xs;
        
        * {
            font-size: $font-size-xs;
        }
    }
}

body.contact-us-form {

    text-align: left;
    .content {
        padding-top: 30px;
        @include grid-blocks(6);
        h1 {
            padding-bottom: 20px;
        }
        .form-group {
            label {
                font-size: $font-size-body;
                text-transform: none;
                text-indent: 10px;
            }
        }
    }
}
body.contact-us {

    text-align: center;
    background: none;
    outline: none;

    .content {
        background-color: $pastel-lighter-frost;
        .container {
            padding-top: 50px;
            max-width: blocks(8);
            background: none;
            outline: none;
            @include at-least($screen-desktop) {
                padding-top: 110px;
            }
        }
    }

    .quick-links, .faq {
        margin-bottom: 50px;
    }
    .quick-links {
        h1 {
            margin-bottom: 30px;
        }
        .quick-link {
            @include grid-blocks(12);
            background-color: $secondary-03;
            color: white;
            @include inalign(middle);
            font-size: $font-size-body;
            border-radius: 4px;
            margin: 5px;
            padding: 10px;
            text-align: left;
            line-height: 44px;
            @include at-least($screen-tablet) {
                @include grid-blocks(3);
            }
            .text, .icon {
                @include inalign(middle);
            }
            .icon {
                font-size: 50px;
                margin-right: 10px;
            }
            .text {
            }
        }
    }

    .quick-links.secondary {
        .quick-link {
            background-color: white;
            color: $secondary-03;
            .icon {
                @include size(50px);
                font-size: 2.4rem;
                line-height: 50px;
                border-radius: 50%;
                background-color: $secondary-03;
                color: white;
                text-align: center;
            }
        }
        #phone {
            display: none;
            &:checked ~ label {
                background-color: $secondary-01;
                color: white;
                .on {
                    display: inline-block;
                }
                .off {
                    display: none;
                }
                .icon {
                    background-color: white;
                    color: $secondary-01;
                }
                &.disabled {
                    background-color: $navy-04;
                    .icon {
                        color: $navy-04;
                    }
                }
            }
        }
        label {
            cursor: pointer;
            transition: all 0.3s;
            .on {
                display: none;
            }
            .icon {
                transition: all 0.3s;
            }
        }

    }

    .faq {
        @include inalign(middle);
        background: white;
        border-radius: 8px;
        padding: 30px;
        @include grid-blocks(6);
        margin-left: -15px;
        margin-right: -15px;

        .search-panel {
            white-space: normal !important;
        }

        input[type="text"] {
            background: rgba(82, 157, 232, 0.5) !important;
            @include placeholder {
                color: $navy-01;
                font-size: $font-size-body;
            }
        }
    }
}

// MODALS
label.modal-toggle {
    font-weight: 400;
    color: $primary-text-link;
    text-decoration: underline;
    cursor: pointer;
}

.modal-checkbox {
    display: none;
}

.modal-cover {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    background: $primary-colour;
    width: 100%;
    z-index: 2;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease-in-out;
}

.modal {
    @include transition(opacity 0.3s, transform 0.3s);
    background-color: $primary-white;
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
    border-radius: 6px;
    transform: rotateX(90deg) scale(.7);
    opacity: 0;
    overflow: hidden;
    pointer-events: none;
    position: fixed;
    margin: 10px;
    top: 10%;
    left: 0;
    width: calc(100% - 20px);
    text-align: center;
    line-height: 1;
    z-index: 3;
    padding: $spacing-s;
    @include at-least($screen-tablet) {
        width: 610px;
        top: 20%;
        left: auto;
        margin: 0;
    }
    p:last-of-type {
        margin-bottom: 0;
    }
    label.close {
        cursor: pointer;
        top: 10px;
        right: 10px;
        position: absolute;
        text-decoration: none;
        &:before {
            @include icon($icon-cross-circle);
            color: $navy-01;
        }
    }
    .modal-body {
        padding: 30px 10px 30px 10px;
        @include at-least($screen-tablet) {
            padding: 30px;
        }
    }
}

.modal-checkout {
    @include at-least($screen-tablet) {
        top: 10%;
        width: 90%;
        left: unset;
    }
}

.modal-checkbox:checked ~ .modal {
    opacity: 1;
    transform: scale(1);
    pointer-events: initial;
}


.modal-checkbox:checked ~ .modal-cover {
    opacity: 0.4;
    pointer-events: initial;
}

.modal-conversation-flow {
    left: 50%;
    transform: scale(0) translateX(calc(-50% - 15px));
    -webkit-transform: scale(0) translateX(calc(-50% - 15px));
    -moz-transform: scale(0) translateX(calc(-50% - 15px));
    -ms-transform: scale(0) translateX(calc(-50% - 15px));
}

.modal-checkbox-conversation-flow:checked ~ .modal {
     transform: scale(1) translateX(calc(-50% - 15px));
    -webkit-transform: scale(1) translateX(calc(-50% - 15px));
    -moz-transform: scale(1) translateX(calc(-50% - 15px));
    -ms-transform: scale(1) translateX(calc(-50% - 15px));
}


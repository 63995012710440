$transition-speed: 0.3s;
$desktop-min-width: $screen-desktop;
$screen-two-columns-switch: 640px;
$manual-shop-side-filters-panel-width: 252px;
$recipe-tile-width: 264px;

@mixin counter {
    display: inline-block;
    height: 100%;
    background-color: $pastel-lighter-frost;
    border-radius: 50px;
    padding: 8px;
    &:before {
        display: inline-block;
        content: '';
        height: 100%;
        vertical-align: middle;
    }

    * {
        display: inline-block;
        vertical-align: middle;
    }

    button {
        padding: 0;
        min-width: 40px;
        height: 40px;
        span {
            margin-left: 0 !important;
            margin: 0;
            line-height: 40px;
            font-size: 2.4rem;
            width: auto;
        }
    }

    input {
        height: 40px;
        padding: 0;
        border-radius: 50px;
        border: 1px solid $navy-00;
        text-align: center;
        width: 3em;
        font-size: 3rem;
        outline: none;
        box-shadow: none;
        margin: 0;
    }
}

@include keyframes(spindown) {
    0% {
        transform: rotateZ(-180deg);
    }
    100% {
        transform: rotateZ(0deg);
    }
}

body.shop.treats {

    header.hero-header {
        max-height: 400px;
        @include hero-header(('/static/images/treats/hero-desktop-dd.jpg','/static/images/treats/hero-mobile-dd.jpg'));
        .background-image {
            background-position: top right !important;
        }
        .hero-text h1 {
            text-align: center;
        }
        .badge {
            @include grid-blocks(1);
        }
    }

    .intro-text {
        font-size: 1.4em;
        text-align: center;
    }

    .section {
        padding-top: 0;
        padding-bottom: 0;
        .equals:before {
            content: "=";
            font-weight: bold;
            font-size: 50px;
            padding: 0 10px;
        }
    }

    .portion {
        p {
            margin-bottom: 5px;
        }
    }

    .section.recommended-amount {
        .segment {
            max-width: 700px;
        }

        .perday {
            width: 100px;
        }

        div.packs {
            margin-left: 20px;
            margin-right: 20px;

            .inner {
                text-align: center;
                background-color: $navy-01;
                color: $primary-white;
                width: 100px;
                height: 100px;
                @include display(flex);
                @include flex-direction(column);
                @include justify-content(center);
                @include align-items(center);
                border-radius: 100%;

                .count {
                    font-size: 34px;
                    font-weight: bold;
                    line-height: 1;
                }
            }
        }
    }

    .utility-bar {
        .cart {
            justify-content: flex-end;
            .checkout:after {
                content: $icon-arrow-right-circle;
            }
        }
        .filters {
            display: none;
        }
        .icon {
            font-size: 44px;
            display: inline-block;
            vertical-align: middle;
            justify-content: flex-end;
            color: $secondary-03;
            @include until($screen-phone) {
                display: none;
            }
        }
        @include keyframes(pop) {
            0% {
                transform: scale3d(1, 1, 1);
            }
            50% {
                transform: scale3d(1.05, 1.05, 1.05);
            }
            100% {
                transform: scale3d(1, 1, 1);
            }
        }
        @include keyframes(pop2) {
            0% {
                transform: scale3d(1, 1, 1);
            }
            50% {
                transform: scale3d(1.05, 1.05, 1.05);
            }
            100% {
                transform: scale3d(1, 1, 1);
            }
        }
        .buttons {
            overflow: visible;
            .selection {
                &.pop, &.pop2 {
                    animation-duration: 0.15s;
                    animation-timing-function: ease-in-out;
                }
                &.pop {
                    animation-name: pop;
                }
                &.pop2 {
                    animation-name: pop2;
                }
            }
        }
        .label {
            margin-left: 15px;
            font-size: 2.4rem;
            vertical-align: middle;
            flex-grow: 1;
            text-align: left;
            @include until($screen-tablet) {
                display: none;
            }
        }
    }

    .products {
        ul {
            border-bottom: none;
            li {
                vertical-align: top;

                .product.treat {
                    text-align: center;
                    padding-top: 10px !important;
                    transition: background-color 0.3s;
                    .image, .info {
                        @include inalign(middle);
                    }
                    .image {
                        position: relative;
                        cursor: pointer;
                        &:before,&:after {
                            text-align: center;
                            position: absolute;
                            padding: 5px 10px;
                            left: 0;
                            right: 0;
                            height: 50px;
                            color: $navy-01;
                            font-size: 1.5em;
                        }
                        &:after {
                            content: attr(data-size);
                            bottom: 0;
                        }
                        .wrapper {
                            position: relative;
                            display: inline-block;
                             padding-bottom: 10px;
                            span {
                                position: absolute;
                                left: 0;
                                right: 0;
                                padding: 10px;
                                font-size: 2.5rem;
                                background: #fcb53b;
                                color: $primary-white;
                                top: -10px;
                            }
                            &:before {
                                @include icon($icon-info-circle);
                                @include position(absolute, null null 30px 20px);
                                font-size: 3rem;
                                color: $secondary-03;
                            }
                            img {
                                display: inline-block;
                                width: 100%;
                                max-width: blocks(3);
                                margin: 30px 0;
                                border-radius: 15px;
                            }
                        }
                    }
                    .pill {
                        display: inline-block;
                    }
                    .info {
                        width: 100%;
                        .flex {
                            display: flex;
                            flex-direction: column;
                        }
                        .controls {
                            order: 1;
                        }
                        .benefits {
                            display: none;
                            text-align: left;
                            order: 2;
                            max-width: 380px;
                            margin: auto;
                            ul {
                                border-bottom: none;
                                //list-style-image: url("/static/images/treats/check.png");
                                margin-left: 60px;
                                li {
                                    display: inline-block;
                                    position: relative;
                                    &:before {
                                        @include icon($icon-check-circle);
                                        position: absolute;
                                        left: -45px;
                                        top: 6px;
                                        font-size: 1.5em;
                                    }
                                    span {
                                        @include inalign(middle);
                                        font-weight: bold;
                                        padding-bottom: 25px;
                                        //max-width: calc(100% - 47px);
                                    }
                                }
                            }
                        }
                    }
                    .input-panel {
                        margin: 20px auto;
                        text-align: center;
                        .counter {
                            @include counter;
                            margin-bottom: 15px;
                            vertical-align: middle;
                        }
                        > .btn {
                            margin-bottom: 15px;
                        }
                        .price {
                            margin-bottom: 15px;
                            display: inline-block;
                        }
                    }
                    &.recommended {
                        padding: 30px 0;
                        .benefits {
                            display: block;
                        }
                        .setquantity {
                            padding: 8px 30px;
                            > * {
                                line-height: 1.2;
                            }
                        }
                        @include at-least($screen-tablet) {
                            text-align: left;
                            font-size: 0;
                            .image {
                                width: 50%;
                                text-align: center;
                                &:before,&:after {
                                    font-size: 2.5rem;
                                    padding: 10px 0;
                                }
                                .wrapper {
                                    &:before {
                                        font-size: 50px;
                                    }
                                    img {
                                        max-width: 500px;
                                    }
                                }
                            }
                            .info {
                                .flex {
                                    display: inline-block;
                                }
                                width: 50%;
                                font-size: $font-size-body;
                                padding: 15px;
                                text-align: center;
                                .benefits {
                                    padding-bottom: 20px;
                                }
                                .controls {
                                    text-align: left;
                                    white-space: nowrap;
                                }
                            }
                        }
                    }

                    > .details {
                        display: none;
                        font-size: 0;
                        background-color: transparent;
                        > * {
                            display: inline-block;
                            vertical-align: top;
                            max-width: 625px;
                            font-size: $font-size-body;
                            width: 100%;
                        }
                    }
                }
                &.filtered {
                    display: none !important;
                }
                &.details {
                    .product.treat {
                        background-color: $pastel-lighter-frost;
                        > .details {
                            display: block;
                        }
                        .image {
                            .wrapper:before {
                                content: $icon-arrow-down-circle;
                                animation: spindown 0.3s;
                            }
                        }
                    }
                }
            }
        }
        .excluded-recipes {

            h1 {
                text-align: center;
                padding: .5em 15px;
            }
            .excluded {
                text-align: center;
                padding-bottom: 40px;
                font-size: 0;
                .tails-view-shop-product {
                    display: inline-block;
                    font-size: $font-size-body;
                    width: 100%;
                    max-width: 400px;
                    .exclusion-reason {
                        font-size: $font-size-body;
                    }
                }
            }

        }
    }

}

body.shop.wet-food-shop {

    header.hero-header {
        @include hero-header(('/static/images/products/wet-food/bannerWetManual@3x.jpg', '/static/images/products/wet-food/bannerWetManualMobile@3x.png'));
        .hero-text h1 {
            text-align: center;
        }
        justify-content: flex-end;
        padding-right: 80px;
    }

    .trial-prices {
        @include flexbox(row, center, center);
        @include flex-wrap(wrap);
        background-color: $pastel-lighter-frost;
        padding: 15px;
        table {
            border: none;
            background-color: $primary-white;
            margin: 0 15px;
            @include grid-blocks(3);
            th {
                text-align: center;
                color: $primary-white;
                background-color: $navy-01;
                line-height: 2;
            }
            &.discount {
                th {
                    background-color: $secondary-01;
                }
            }
        }
    }

    .products {

        .excluded-recipes ul {
            display: none;
        }

        &.show-excluded-recipes .excluded-recipes ul {
            display: block;
        }

        .excluded-recipes-banner {
            background: $pastel-lighter-frost;
            padding: 10px 20px;
            text-align: center;

            p.summary {
                display: none;
            }

            &.zero {

                p, button {
                    display: none;
                }

                p.summary {
                    display: block;
                }
            }
        }
        .accordion-excluded-recipes{
            margin: 8px 12px 24px 12px;
            @include at-least($screen-tablet){
                margin: 8px 48px 24px 48px;
            }
            border-bottom: 1px solid $pastel-darker-silver;
            border-top: 1px solid $pastel-darker-silver;
            .accordion-title {
                color: $primary-text-link;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 15px 8px;
                margin: 0;
                cursor: pointer;
                .icon {
                    padding-right: 10px;
                }
                &.hidden{
                    display: none;
                }
            }
        }

        .roundal.old {
            font-family: $handwritten-font-family;
            font-size: $font-size-m;
            line-height: $font-size-m;
            text-align: center;
            background: $secondary-02;
            border-radius: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 8px;
            width: 80px;
        }

        .roundal.new {
            text-align: center;
            background: $pastel-lighter-beige;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 4px 8px;
            font-weight: bold;

            font-size: $font-size-xs;
            line-height: 2;
        }

        @include at-least($screen-desktop) {
          .roundal.old {
              position: absolute;
              top: 16px;
              left: 16px;
              height: 80px;
              border-radius: 50%;
          }
          .roundal.new {
              position: absolute;
              top: 16px;
              left: 16px;
          }
        }
    }

    .products {


        &[data-mode='automatic'] {
            .product .controls {
                //display: none;
                .btn {
                    display: none;
                }
                input {
                    pointer-events: none;
                    border: none;
                }
            }
        }

        li {
        margin: 0;
        overflow: auto;

        opacity: 1;
        max-height: 800px;
        transition: max-height ($transition-speed * 0.66), opacity ($transition-speed * 0.33) ($transition-speed * 0.66), background-color $transition-speed;

        .product {
            border-top: 2px solid $pastel-lighter-frost;
            overflow: hidden;
            min-height: 80px;

            .smallprint {
              color: $navy-02;
            }
        }
        &:first-child .product{
            border-top: 0;
        }

        .top {
            display: flex;
            flex-direction: column;
            padding: 8px 20px;
            @include until($screen-desktop){
                flex-direction: row;
                align-items: center;
                gap: 0 8px;
                padding: 8px 20px 0 20px;
            }
            position: relative;
            width: 100%;
            .btn, input[type=text] {
                &:disabled {
                    background-color: lightgrey;
                    box-shadow: 0 0 1px $navy-04;
                    color: $navy-04 !important;
                    .icon {
                        color: $navy-04 !important;
                    }
                }
            }
            .product-top-wrapper{
                display: flex;
                flex-direction: column;
                .product-name.bold {
                    margin: 8px 0 0 0;
                }
                .product-price-details {
                    margin: 8px 0 0 0;
                }
                .product-details-link{
                    color: $primary-text-link;
                    font-size: $font-size-xs;
                    margin: 8px 0;
                    .icon {
                        padding-left: 5px;
                        display: none;
                        &.icon-chevron-up,.icon-chevron-down{
                            text-decoration:none;
                        }
                        &.toggled-details{
                            display:inline-block;
                        }
                    }
                }
            }

        }

        .details {
            max-height: 0;
            overflow: hidden;
            transition: max-height $transition-speed;
        }

        &.details {
            .details {
                max-height: 900px
            }
        }

        img {
            vertical-align: middle;
            margin: 0 auto;
            border-radius: 12px;
            @include until($screen-desktop) {
                max-width: 90px;
            }
        }

        h3 {
            vertical-align: middle;
            padding: 0 10px;
            width: 100%;
            text-align: left;
        }

        .controls {
            vertical-align: middle;
        }

        button {
            padding: 0;
            min-width: 40px;
            height: 40px;
            span {
                margin-left: 0 !important;
            }
        }

        button.buy-number {
            line-height: 36px;
            border: 1px solid $navy-00;
            background: $primary-white;

            span {
                display: none;
            }
        }

        .input-panel {
            transform: translateZ(0);
            visibility: hidden;
            background: rgba(239, 239, 239, 0.9);
            position: absolute;
            right: 0;
            top: 0;
            margin-right: -100%;
            height: 100%;
            padding: 8px 20px;
            border-left: 2px solid #CECECE;

            white-space: nowrap;
            transition: margin-right 0.3s, visibility 0.3s;

            > div {
                @include counter;
            }
        }

        &.input .input-panel {
            visibility: visible;
            margin-right: 0;
        }

        &.excluded .input-panel input {
            border-color: $navy-04;
            color: $navy-04;
        }

        &.filtered {
            opacity: 0;
            max-height: 0;
            transition: opacity ($transition-speed * 0.33), max-height ($transition-speed * 0.66) ($transition-speed * 0.33);
        }

        .exclusion-reason {
            word-spacing: normal;
            background: $secondary-03;
            border-radius: 50px;
            padding: 5px 10px;
            color: $primary-white;
            margin: 8px 15px;
        }

        @media (min-width: $desktop-min-width) {
            display: inline-block;
            vertical-align: top;
            width: 33.33%;
            margin-right: -4px;
            max-height: 700px;
            max-width: 450px;
            opacity: 1;
            transition: max-width ($transition-speed * 0.66), max-height ($transition-speed * 0.66), opacity ($transition-speed * 0.33) ($transition-speed * 0.66), background-color ($transition-speed * 0.33) ($transition-speed);
            overflow: hidden;
            background-color: $primary-white;

            .product {
                border-top: 0;
                margin: 20px;
            }

            img, h3, .controls {
                display: block;
                width: auto;
                text-align: center;
                margin-bottom: 0.5em;
            }

            img {
                margin-bottom: 0.5em;
                width: 100%;
                height: 100%;
            }

            .exclusion-reason {
                margin-left: 0;
                margin-right: 0;
            }

            &.details {
                max-height: 1350px;
                transition: background-color 0s;
            }

            button.buy {
                display: none;
            }

            .input-panel {
                background: transparent;
                display: block;
                position: static;
                visibility: visible;
                margin-right: 0;
                height: auto;
                border: 0;
                right: auto;
                top: auto;
                padding: 0;

                * {
                    position: static;
                    display: inline-block;
                    vertical-align: middle;
                    top: auto;
                    margin-top: 0;
                    padding: 0;
                    font-size: inherit;
                }

                button.remove {
                    display: none;
                }
            }

            &.filtered {
                opacity: 0;
                max-height: 0;
                max-width: 0;
                transition: opacity ($transition-speed * 0.33), max-width ($transition-speed * 0.66) ($transition-speed * 0.33), max-height ($transition-speed * 0.66) ($transition-speed * 0.33);
            }
        }

    }
    }

}

body.shop {

    text-align: left;
    font-size: $font-size-body;

    .alert {
        &.warning {
            border-radius: 4px;
            background-color: $product-warning-background;
            color: $primary-colour;
            padding: 15px;
        }
    }

    .content {
        padding-bottom: 0;
        .container {
            padding: 0;
        }
    }

    .shop-header {
        background-size: cover;
        padding: 20px 20px;
        min-height: 300px;
        text-align: center;

        h1 {
            margin-right: 20px;

            span {
                display: block;
            }
        }

        @media (min-width: $screen-xs-min) {
            text-align: left;
            padding: 60px;
            background-size: cover;
            h1 {
                margin-right: 150px;
            }
        }
    }

    .recommended-amount {
        .wet-other-update-banner {
            background-color: $secondary-03;
            color: $primary-white;
            padding: 15px 20px;
            max-width: 600px;
            border-radius: 4px;
            margin: 0 auto;

            p {
                margin-bottom: 1em;
                max-width: none;
            }
        }
    }

    .pet-selector p {
        color: $navy-01;
        text-align: center;
        text-shadow: $primary-white 0 0 5px;
    }

    .dropdown {
        /* Size & position */
        position: relative;
        max-width: 300px;
        margin: 0 auto;
        z-index: 10;

        /* Styles */
        background: $primary-white;
        border-radius: 50px;
        box-shadow: 0 3px 0 rgba(0, 0, 0, 0.2);
        cursor: pointer;
        outline: none;

        color: $secondary-03;

        &:after {
            @include icon($icon-arrow-down-circle);
            position: absolute;
            top: 3px;
            right: 4px;
            font-size: 3.8rem;
            height: 40px;
            pointer-events: none;
            @include transition(all 0.3s);
        }

        span {
            display: block;
            padding: 10px 20px;
        }

        ul {
            opacity: 0;
            pointer-events: none;
            position: absolute;
            padding: 0;
            margin: 12px 0 0;
            top: 100%;
            left: 0;
            right: 0;
            transition: all 0.3s ease-out;
            list-style: none;

            &:before {
                content: "";
                width: 0;
                height: 0;
                position: absolute;
                top: 0;
                right: 50%;
                margin-top: -6px;
                margin-left: -50%;
                border-width: 0 6px 6px 6px;
                border-style: solid;
                border-color: $primary-white transparent;
            }
        }

        &.active {
            &:after {
                @include rotate(180);
            }

            ul {
                pointer-events: auto;
                opacity: 1;
            }
        }

        li {
            padding: 0;
            margin: 0;
            background: $primary-white;

            &:first-of-type {
                border-top-left-radius: 20px;
                border-top-right-radius: 20px;
            }

            &:last-of-type {
                border-bottom-left-radius: 20px;
                border-bottom-right-radius: 20px;
                box-shadow: 0 3px 0 rgba(0, 0, 0, 0.2);
            }
        }

        li a {
            display: block;
            padding: 10px 20px;

            &:hover {
                text-decoration: none;
                color: lighten($scss-secondary-03, 10%)
            }
        }
    }

    .section {
        padding: 20px;
        text-align: center;
        font-size: $font-size-body;

        h3 {
            text-align: center;
            margin-bottom: 0.5em;
        }
        &.tray-size-selector {
            &.checkout{
                display: none;
            }
            display: flex;
            flex-direction: column;
            gap: 16px 0;
            background-color: $pastel-lighter-frost;
            padding: 0;
            .size-selector-title, .size-selector-subtitle {
                padding: 0;
                margin: 0;
            }
            .size-selector-title {
                margin-top: 24px;
                font-size: 2rem;
            }
            .size-selector-subtitle, .toggle-buttons-tray-selector {
                padding-left: 48px;
            }
            .size-selector-subtitle, .toggle-buttons-tray-selector {
                text-align: left;
                @include until($screen-desktop){
                    padding-left: 12px;
                }
            }
        }
    }

    .toggle-buttons {
        margin: auto;
        @include grid-blocks(4);
    }

    .toggle-button {
        label {
            text-transform: none;
        }
    }

    .tray-size-selector {
        position: relative;

        &.loading:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        button {
            display: none;
        }

        .spwf-modal {
          background: top / cover no-repeat url(/static/images/products/wet-food/upsellPopupAutoMobile@2x.png);

          top: 50px;
          max-height: calc(100% - 70px);
          overflow-y: auto;

          h3, p, a {
            color: $primary-white;
            text-align: center;
          }

          label.close {
            top: 16px;
            right: 16px;

            &:before {
              content: $icon-times;
              font-size: 32px;
              color: $primary-white;
            }
          }

          .btn-primary {
              display: block;
              max-width: 280px;
              margin: 16px auto;
              border-radius: 16px;
              background: $primary-white;
          }

          .modal-body {
              margin-top: 70%;
          }
        }
        @include at-least($screen-tablet) {
          .spwf-modal {
            background: top / cover no-repeat url(/static/images/products/wet-food/upsellPopupAuto@2x.png);
            width: $screen-tablet;
            max-height: calc(100% - 136px);

            left: calc(50% - 400px);
            top: 112px;

            .modal-body {
              position: relative;
              margin-top: 280px;
              bottom: 0;
            }
          }
        }
    }

    .section.tails-view-selection-method-toggle {
        .mode {
            @include grid-blocks(6);
            @include flexbox(column, center, center);
            margin: auto;

        }
        &[data-mode=manual] {
            .mode.automatic {
                display: none;
            }
            .btn-primary {
                margin: 20px 0;
            }
        }
        &[data-mode=automatic] {
            .mode.manual {
                display: none;
            }
        }
    }

    .section.recommended-amount {
        position: relative;
        z-index: 0;
        text-align: center;
        background-color: $pastel-lighter-frost;

        .segment {
            @include grid-blocks(6);
            margin: 0 auto;
            overflow: hidden;
            padding: 20px 0;

            .bold {
                display: block;
                font-weight: bold;
                margin-bottom: 20px;
            }

            .icon.type {
                font-size: 26px;
                color: $secondary-03;
                font-weight: normal;
            }

            @include at-least($screen-tablet) {
                .bold {
                    display: inline-block;
                    margin-bottom: 0;
                }
            }

            .portion {
                display: flex;
                justify-content: center;

                p, span {
                    margin: auto 5px;
                }
                span.tray-size,
                span.trays-per-order,
                span.tray-fraction {
                    margin: auto 0;
                }
                .icon {
                    font-size: 10px;
                }
            }
        }
    }

    .utility-bar {
        background: $pastel-lighter-frost;
        color: $navy-01;
        position: relative;
        overflow: hidden;

        label {
            font-weight: bold;
        }

        &.checkout {
            .checkout-summary {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            .cart {
                display: none;
            }
        }

        &[data-mode='automatic'] {
            .empty, .filters, .filter-drawer .checkbox-wrapper-alt {
                display: none !important;
            }
        }

        &.fixed {
            position: fixed;
            top: $nav-height-small;
            left: 0;
            right: 0;
            z-index: 1;


            .filters {
                display: none;
            }

            @include at-least($nav-max) {
                top: $nav-height
            }
        }

        .filters {
            display: block;
            width: 100%;
            background-color: #fff;
            text-align: center;
            padding: 16px 12px 24px 12px;
            &.checkout {
                display: none;
            }
            @media (min-width: $desktop-min-width) {
                display: none;
            }

            button.filter {
                width: 248px;
                height: 48px;
                border-radius: 16px;
                display: inline-block;
            }
        }
    }

    .cart, .checkout-summary {
        padding: 24px 12px;
        @include at-least($screen-desktop) {
            padding: 24px 48px;
        }
    }

    .cart {
        text-align: center;
        @include at-least($screen-desktop) {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
        }
        .outer-filter-drawer {
            display: inherit;
            justify-content: inherit;
            @include until($screen-desktop) {
                z-index: 1;
                position: fixed;
                width: 0%;
                top: 0%;
                height: 100%;
                right: 0%;
                background-color: rgba(0, 0, 0, 0.2);
                &.open {
                    width: 100%;
                }
            }
        }
        .filter-drawer {
            display: flex;
            flex-direction: row;
            gap: 16px;
            align-items: flex-start;
            overflow: hidden;
            @include flex-grow(1);
            @include until($screen-desktop) {
                flex-direction: column;
                background-color: #fff;
                position: fixed;
                top: $nav-height-small;
                right: 0%;
                z-index: 2;
                width: 0%;
                max-width: $manual-shop-side-filters-panel-width;
                height: calc(100% - #{$nav-height-small});
                gap: 0;
                align-items: flex-end;
                &.open {
                    width: 100%;
                    overflow: auto;
                }
            }
            .filter-drawer-title {
                display: none;
                @include until($screen-desktop) {
                    display: flex;
                    justify-content: space-between;
                    flex-direction: column;
                    gap: 16px
                }
                .filter-drawer-header,.filter-drawer-description {
                    margin: 0;
                    text-align: left;
                    padding: 0 16px;
                }
                .filter-drawer-header {
                    margin-top: 24px;
                    display: flex;
                    justify-content: space-between;
                    font-size: $font-size-m;
                    font-weight: 600;
                    width: $manual-shop-side-filters-panel-width;
                }
                .filter-drawer-description{
                    margin-bottom: 16px;
                }
            }
            .accordion.filter {
                border-top: 1px solid $pastel-darker-silver;
                @include until($screen-desktop) {
                    width: 236px;
                    &:last-child {
                        border-bottom: 1px solid $pastel-darker-silver;
                    }
                }
                @include at-least($screen-desktop) {
                    min-width: 190px;
                    border-bottom: 1px solid $pastel-darker-silver;
                }
                ul.checklist-wrapper-accordion-filter {
                    list-style: none;
                    padding-inline-start: 0;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 16px;
                    margin-left: 8px;
                    li.checkbox-wrapper-alt-accordion-filter {
                        display: flex;
                        align-items: center;
                    }
                    input.filter-input[type=checkbox] {
                        display: flex;
                        align-items: flex-start;
                        justify-content: center;
                        margin: 0;
                        appearance: none;
                        width: 24px;
                        height: 24px;
                        border-radius: 3px;
                        border: 1px solid $primary-colour;
                        cursor: pointer;
                        transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
                        background-color: #fff;
                        &::before {
                            content: '';
                            width: 5px;
                            height: 14px;
                            border-style: solid;
                            border-color: #fff;
                            border-width: 0 2px 2px 0;
                            transform: rotate(45deg);
                            opacity: 0;
                        }
                        &:checked {
                            color: #fff;
                            border-color: $product-selected;
                            background: $product-selected;
                            &::before {
                              opacity: 1;
                            }
                        }
                        &:disabled {
                            border: 1px solid $pastel-darker-silver;
                            background-color: $pastel-lighter-silver;
                            cursor: not-allowed;
                        }
                    }
                    label {
                        margin-left: 8px;
                        font-weight: normal;
                    }
                }
                .accordion-title {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    color: $primary-text-link;
                    margin: 16px 0 16px 8px;
                    text-align: left;
                    cursor: pointer;
                    .icon {
                        padding-left: 100px;
                        padding-right: 16px;
                    }
                    .icon.icon-chevron-up {
                        display: none;
                    }
                }
                .accordion-content {
                    display: none;
                }
            }
        }
        .checkbox-wrapper-alt {
            margin: 10px;
        }
        .buttons {
            overflow: hidden;
            display: inline-block;
            @include until($screen-desktop) {
                display: flex;
                justify-content: space-between;
            }
            vertical-align: middle;
            height: 100%;
            top: 0;
            right: 0;
            white-space: normal;
            bottom: 0;
            text-align: center;

            label {
                display: block;
                text-align: center;
                justify-content: flex-end;
            }

            .selection, .checkout {
                @include inalign(middle);
            }
            .selection {
                margin-right: 10px;
                padding: 6px 14px 4px;
                line-height: 35px;
                background-color: $secondary-01;
                border-radius: 50px;
                color: $primary-white;
                @include until(321px) {
                    margin-right: 0;
                }
                > * {
                    @include inalign(middle);
                }
                .empty {
                    @include inalign(middle);
                    cursor: pointer;
                    margin-right: -9px;
                    margin-top: -3px;
                    height: 32px;
                    text-decoration: none;
                    &:before {
                        @include icon($icon-cross-circle);
                        color: $primary-white;
                        font-size: 3.2rem;
                    }
                }
            }
            .checkout {
                &:after {
                    @include icon($icon-shop);
                    @include inalign(middle);
                    font-size: 3.2rem;
                    margin-right: -10px;
                    margin-left: 10px;
                    margin-top: -1px;
                }

                .selected {
                    color: $navy-01;
                }
            }
        }
    }

    .checkout-summary {
        display: none;
        overflow: hidden;

        .label {
            color: $primary-colour;
            margin-bottom: 24px;
            font-size: $font-size-m;
            font-weight: 600;
            background-color: transparent;
            padding: 0;
        }
        .actions {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            flex-flow: row-reverse;
            @include until($screen-desktop) {
                flex-direction: column;
                gap:16px;
                .button-wrapper {
                    width: 100%;
                    text-align: left;
                }
            }
        }
        .actions, .info {
            text-align: center;
            width: 100%;
        }
        p {
            text-align: right;
            border-radius: 50px;
            background-color: $secondary-01;
            padding: 8px 30px;
            display: inline-block;
            color: $primary-white;
            margin-bottom: 10px;
        }

        @include at-least($screen-desktop) {
            button.order {
                width: auto;
            }
        }
    }

    .products {
        position: relative;
        .message.empty {
            display: none;
        }

        &.loading:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: $primary-white;
            opacity: 0.8;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                .product {
                    > .details {
                        background-color: $pastel-lighter-frost;
                        display: block;
                        text-align: left;
                        font-size: $font-size-s;

                        & > div {
                            padding: 20px;
                        }

                        h4 {
                            margin-bottom: 0.5em;
                        }

                        table {
                            margin-bottom: 0;
                        }

                        .analytical-constituents span {
                            white-space: nowrap;
                        }
                    }
                }
                .contains {
                    font-size: $font-size-body;
                    font-weight: bold;
                    text-align: center;
                    display: inline-block;
                    margin-bottom: 5px;
                }

            }
        }

        .message {
            background: $pastel-lighter-frost;
            color: $primary-white;
            border-radius: 10px;
            padding: 15px 20px 15px 54px;
            max-width: 500px;
            margin: 30px auto;
            position: relative;

            &:before {
                @include icon($icon-info-circle);
                color: $primary-white;
                position: absolute;
                top: 15px;
                left: 20px;
                font-size: 2.4rem;
            }
        }
    }

    .exclusion-reason {
        text-align: center;
        word-spacing: 5px;
        .wrapper {
            display: inline-block;
            text-align: center;
            .icon {
                font-size: 44px;
                color: $secondary-03;
            }
            .exclusion {
                font-size: $font-size-s;
                word-spacing: normal;
            }
        }
    }

    .your-pets {
        background: $pastel-lighter-frost;
        text-align: left;

        h3 {
            text-align: left;
        }
        ul {
            list-style: none;
            padding: 0;
            margin: 0.5em 0;
        }
        li {
            margin: 1em 0;
        }
        a {
            margin-top: 0.5em;
        }
    }

    // TODO: Move out to generic styles and a mixin
    .ribbon {
        font-size: $font-size-s;
        width: 90%;
        max-width: 250px;
        position: relative;
        background: $secondary-03;
        color: $navy-01;
        text-align: center;
        height: 100px;
        line-height: 100px;
        margin: 2em auto 1em;

        &:before,
        &:after {
            content: "";
            position: absolute;
            display: block;
            top: -1em;
            border: 1.5em solid #EF9532;
            z-index: -1;
        }

        &:before {
            left: -1.5em;
            border-left-color: transparent;
            border-width: 50px 1.5em 50px 0.5em;
        }

        &:after {
            right: -1.5em;
            border-left-width: 1.5em;
            border-right-color: transparent;
            border-width: 50px 0.5em 50px 1.5em;
        }

        .ribbon-content {
            display: inline-block;

            &:before,
            &:after {
                content: "";
                position: absolute;
                display: block;
                border-style: solid;
                border-color: transparent transparent #D28127 transparent;
                top: -1em;
            }

            &:before {
                left: 0;
                border-width: 0 0 1em 0.5em;
            }

            &:after {
                right: 0;
                border-width: 0 0.5em 1em 0;
                z-index: 0;
            }

            * {
                line-height: 1em;
            }

            strong {
                display: block;
                font-weight: bold;
                font-size: 2em;
                line-height: 2em;
            }
        }
    }
}

.treats-exclusions-alert {
    ul.suitability-issues {
        padding-left: 0;
        list-style: none;

        > li:not(.detail) ~ li:not(.detail) {
            border-top: 1px solid #ccc;
        }

        > li:not(.detail) {
            padding: 10px 0;
        }

        > li .show-detail {
            cursor: pointer;

            &:before {
                color: $secondary-03 !important;
                @include icon($icon-question-circle);
            }
        }

        > li.detail {
            display: none;
            list-style: none;
            background: $primary-white;
            border-radius: 10px;
            padding: 10px;
            margin-bottom: 20px;
            margin-top: 10px;
        }
    }

    .exclusion-reason {
        padding-left: 0;
        margin-top: 10px;
    }

    .alert-window {
        max-height: 100vh;
        overflow: auto;
    }
}

body.wet-food-shop-auto {
    header.hero-header {
        .hero-text h1 {
            text-align: center;
        }
        max-height: 180px;
        @include at-least($screen-phone) {
            max-height: 400px;
        }
        margin-bottom: 40px;
        display: none;

        @include hero-header((
            '/static/images/products/wet-food/Hero-desktop-mixing.jpg',
            '/static/images/products/wet-food/Hero_mobile_mixing_resized.jpg'
        ));
        .hero-text {
            display: none;
        }
    }
    .hero-banner {
        margin: 30px 0;
    }
    .container {
        max-width: blocks(8);
        padding: 0 15px;
    }

    .tray_counts,
    .hero-banner,
    .price,
    .banner,
    .bundle-info,
    .trays {
        display: none;
        &.selected {
            display: block;
        }
    }

    .disabled {
        color: $navy-03;
    }

    h3 {
        margin-bottom: 20px;
        a {
            font-size: initial;
            font-weight: initial;
        }
    }

    .price {
        white-space: nowrap;
        .total {
            font-size: 26px;
            font-weight: bold;
        }
    }

    .checklist-wrapper {
        padding: 0;
        display: grid;
        grid-column-gap: 20px;
        grid-row-gap: 20px;
        grid-template-columns: repeat(2, 1fr);
        padding-bottom: 15px;
        max-width: blocks(6);
        li {
            margin: 0;
        }
    }

    .checkbox-wrapper-alt > label {
        justify-content: flex-start;
    }

    .layouts {
        text-align: right;
        margin-bottom: 20px;
        .options {
            display: inline-block;
            border: 2px solid $navy-01;
            border-radius: 4px;
            label {
                display: inline-block;
                height: 44px;
                width: 44px;
                font-size: 0;
                text-align: center;
                cursor: pointer;
                transition: background-color 0.3s;
                &:before {
                    @include icon($icon-question-circle);
                    font-size: 24px;
                }
                &:first-child {
                    border-right: 1px solid $navy-01;
                }

                &[for=layout-a]:before {
                    @include icon($icon-list);
                    line-height: 44px;
                }

                &[for=layout-b]:before {
                    @include icon($icon-grid);
                    line-height: 44px;
                }
            }
        }
    }

    input[name=layout] {
        display: none;
    }

    #layout-a:checked ~ .layouts .options label[for=layout-a] {
        background-color: $secondary-02;
    }

    #layout-b:checked ~ .layouts .options label[for=layout-b] {
        background-color: $secondary-02;
    }

    #layout-a:checked ~ .products {
        .product {
            .description {
                display: block;
            }
        }
    }

    #layout-b:checked ~ .products {
        .product {
            align-items: center;
        }
        .product img {
            min-width: 120px;
            margin-right: 0;
        }

        .description {
            display: none;
        }

        grid-template-columns: repeat(3, auto);
        @include at-least(600px) {
            grid-template-columns: repeat(5, auto);
        }
        @include at-least($screen-desktop) {
            grid-template-columns: repeat(6, auto);
        }
    }

    .products {
        display: none;
        grid-gap: 20px;
        padding-bottom: 100px;
        grid-template-columns: auto;
        @include at-least($screen-two-columns-switch) {
            grid-template-columns: repeat(2, auto);
        }
        @include at-least($screen-tablet) {
            justify-content: left;
            grid-template-columns: repeat(2, auto);
        }
        @include at-least($screen-desktop) {
            .product img {
                min-width: 120px;
            }
        }
        &.selected {
            display: grid;
        }
    }

    .product {
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-start;

        justify-content: center;
        transition: all 0.3s;
        overflow: hidden;
        text-decoration: none;
        color: $primary-colour;
        .description {
            font-size: 16px;
            line-height: 24px;
            padding-top: 10px;
            flex-grow: 1;
            .value {
                font-weight: bold;
            }
        }
        img {
            width: 80px;
            min-width: 80px;
            margin-right: 10px;
            border-radius: 12px;
        }
        &[data-quantity="0"] {
            opacity: 0.5;
            filter: grayscale(.3);
            order: 99;
        }
        @include at-least($screen-tablet) {
            justify-content: left;
        }
    }

    #classic, #premium {
        display: none;
    }

    .range-wrapper {
        margin: 40px 0;
    }

    .modal {
        top: 13%;
    }
    .spwf-modal {
        background: top / cover no-repeat url(/static/images/products/wet-food/upsellPopupAutoMobile@2x.png);
        top: 50px;
        max-height: calc(100% - 70px);
        overflow-y: auto;

        h3, p, a {
          color: $primary-white;
          text-align: center;
        }
        label.close {
          top: 16px;
          right: 16px;

          &:before {
            content: $icon-times;
            font-size: 32px;
            color: $primary-white;
          }
        }

        .btn-primary {
            display: block;
            max-width: 280px;
            margin: 16px auto;
            border-radius: 16px;
            background: $primary-white;
        }

        .modal-body {
            margin-top: 65%;
        }
    }
    @include at-least($screen-tablet) {
      .spwf-modal {
        background: top / cover no-repeat url(/static/images/products/wet-food/upsellPopupAuto@2x.png);
        width: $screen-tablet;
        max-height: calc(100% - 136px);

        left: calc(50% - 400px);
        top: 112px;

        .modal-body {
          position: relative;
          margin-top: 280px;
          bottom: 0;
        }
      }
    }
}

body.wet-food-shop-auto-new {
    .hero-banner {
        margin: 16px auto;
        overflow: hidden;
        max-width: blocks(8);
        max-height: 382px;

        .hero-header {
            @include at-least($screen-tablet) {
                left: 0 !important;
                width: 100% !important;
            }
        }

        .hero-header,
        .header-image {
            border-radius: 0 0 50% 50%;
            z-index: -5;
            position: relative;
            height: 36vh;
            max-height: 379px;
            left: -13vw;
            width: 124vw;
            @include at-least($screen-tablet) {
                position: unset;
            }
        }

        .header-image {
            overflow: hidden;
            height: 100%;
            width: 100%;
            left: 0;
            background-size: cover;
            border-radius: inherit;
            @include at-least($screen-tablet) {
                overflow: hidden;
                border-radius: 0;
                left: 0;
                background-position: left;
                background-repeat: no-repeat;
                min-width: 100vw;
                background-size: contain;
            }

            .background-image {
                height: 245px;
            }

            &.hero-classic {
                background-image: url('/static/images/products/wet-food/Hero_mobile_mixing_resized.jpg');
                @include at-least($screen-tablet) {
                    background-image: url('/static/images/products/wet-food/Hero-desktop-mixing.jpg');
                }
            }

            &.hero-premium {
                background-image: url('/static/images/products/wet-food/Hero_mobile_mixing_resized.jpg');
                @include at-least($screen-tablet) {
                    background-image: url('/static/images/products/wet-food/Hero-desktop-mixing.jpg');
                }
            }
        }

        .hero-text {
            display: none;
            @include at-least($screen-tablet) {
                display: block;
                margin-top: -28vh;
                float: right;
                padding-right: 35px;
                text-align: center;
            }
        }

        &.premium-js {
            .hero-header {
                height: 35vh;
                max-height: 270px;
            }

            .hero-text {
                margin-top: -20vh;
            }
        ;
        }
    }

    .container {
        @include at-least($screen-tablet) {
            max-width: blocks(8);
            padding: 0;
        }

        .bundle-info {
            display: block;
            text-align: center;
            @include at-least($screen-tablet) {
                display: none;
            }
        }
    }

    .jeff {
        font-family: $handwritten-font-family;
    }

    .tray_counts,
    .hero-banner,
    .price,
    .banner,
    .bundle-info,
    .trays {
        display: none;

        &.selected {
            display: block;
        }
    }

    .disabled {
        color: $navy-03;
    }

    h3 {
        text-align: center;

        a {
            font-size: $font-size-s;
            font-weight: 500;
        }
    }

    .price {
        white-space: nowrap;

        .total {
            font-size: 26px;
            font-weight: bold;
        }
    }
    .selection-box {
        display: none;
        border: 1px solid $pastel-silver;
        border-radius: 12px;

        &.selected {
            display: grid;
        }

        .selection-box-content {
            padding: 0 24px 24px 24px;

            .summary {
                border-bottom: 1px solid $pastel-darker-silver;
                display: flex;
                justify-content: space-between;
                padding: 16px 8px;

                .left {
                    display: flex;
                    gap: 10px;

                    .upper {
                        text-transform: capitalize;
                    }
                }
            }

            .price-total {
                text-align: right;
                font-size: $font-size-s;
                font-weight: 600;
                padding: 16px 8px 16px 0;
                border-bottom: 1px solid $pastel-darker-silver;

                .total {
                    font-size: $font-size-m;
                }
            }
        }

        .background-curve-container .background-curve {
            background-color: $navy-01;
            border-radius: 0 0 50% 50%;
            position: relative;
            z-index: -1;
            height: 100%;
            left: -19vw;
            width: 124vw;
            text-align: center;
            line-height: 100px;

            @include at-least($screen-tablet) {
                left: -10%;
                width: 120%;
            }

            h3 {
                color: white;
                line-height: 100px;
            }
        }

        .background-curve-container {
            overflow: hidden;
            height: 100px;
            width: 100%;
            left: 0;
            top: -170px;
            border-radius: inherit;
        }

    }

    .filter-bar {
        height: calc(100% + -50px);
        width: 0;
        position: fixed;
        z-index: 1;
        top: 50px;
        right: 0;
        overflow-x: hidden;
        overflow-y: scroll;
        display: flex;
        justify-content: flex-end;
        background-color: rgba(0, 0, 0, 0.2);
        @media screen and (max-height: 450px) {
            padding-top: 15px;
        }
        @include at-least($screen-tablet) {
            padding: 16px;
            border: 1px solid $pastel-silver;
            border-radius: 12px;
            max-width: 268px;
            min-width: 232px;
            height: auto;
            position: initial;
            z-index: 1;
            top: 50px;
            right: 0;
            overflow-x: auto;
            overflow-y: auto;
            display: flex;
            justify-content: flex-end;
            background-color: initial;
        }

        .closebtn {
            display: block;
            @include at-least($screen-tablet) {
                display: none;
            }
        }

        .inner {
            background-color: white;
            height: calc(100% + 50%);
            width: 80%;
            opacity: 1;
            padding: 75px 8px;
            @include at-least($screen-tablet) {
                height: auto;
                width: 100%;
                opacity: 1;
                padding: 0;
            }
        }

        .closebtn {
            position: absolute;
            top: 26px;
            right: 18px;
            font-size: 28px;
        }

        .description {
            border-bottom: 1px solid #DCDEE0;
            padding-bottom: 16px;
        }
    }


    .content-contain {
        display: flex;
        align-items: flex-start;
        gap: 48px;

        .right-side {
            margin: 0 12px;
        }
    }

    .accordion {
        border-bottom: 1px solid $pastel-darker-silver;

        .accordion-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 15px 8px 0 8px;
            cursor: pointer;

            @include at-least($screen-tablet) {
                padding: 15px 8px;
            }

            .icon {
                padding-right: 10px;
            }
        }

        .accordion-content {
            display: none;
            &.accordion-content-included {
                    display: block;
                }
            .checklist-wrapper {
                padding-left: 16px;
            }
        }
        .icon-included-close.icon-chevron-up {
            display: block;
        }

        .icon-included-open.icon-chevron-down {
            display: none;
                                }
        .upper {
            text-transform: capitalize;
        }

        .icon-chevron-up {
            display: none;
        }

        &.tray {
            .accordion-title {
                color: $primary-text-link;
            }
        }
    }

    .btns {
        margin-top: 25px;

        @include at-least($screen-tablet) {
            text-align: center;
            display: flex;
            gap: 16px;
            align-items: center;
        }

        .btn {
            width: 100%;
            margin-bottom: 16px;
            border-radius: 16px;
            line-height: 48px;
            padding: 0;

            @include at-least($screen-tablet) {
                width: 296px;
            }
        }

        p {
            text-align: center;
            margin: auto;
            @include at-least($screen-tablet) {
                max-width: 255px;
            }
        }

        .update {
            @include at-least($screen-tablet) {
                display: none;
            }
        }
    }

    .checklist-wrapper {
        li {
            margin-bottom: 16px;
        }

        input[type=radio]:checked + label:before {
            font-size: 2rem;
        }

        input[type=checkbox]:checked + label:before {
            font-size: 2rem;
        }
    }

    .checkbox-wrapper-alt > label {
        justify-content: flex-start;
        padding-bottom: 16px;

        &:before {
            max-width: 24px;
            min-width: 24px;
            height: 24px;
            min-height: 24px;
            line-height: 24px;
        }
    }

    .tray {
        &.included {
            .product {
                &[data-quantity="0"] {
                    display: none;
                }
            }
        }

        &.excluded {
            .product {
                &[data-quantity]:not([data-quantity='0']) {
                    display: none;
                }
            }
        }

    }

    .products {
        display: none;
        grid-gap: 20px;
        padding-bottom: 20px;
        grid-template-columns: auto;
        @include at-least($screen-two-columns-switch) {
            grid-template-columns: repeat(2, auto);
        }
        @include at-least($screen-tablet) {
            justify-content: flex-start;
            grid-template-columns: repeat(2, auto);
        }
        @include at-least($screen-desktop) {
            .product img {
                min-width: 120px;
            }
        }

        &.selected {
            display: grid;
        }

        .name {
            margin-bottom: 8px;
        }

        .product-inner {
            display: flex;
            flex-flow: row nowrap;
            align-items: flex-start;
            justify-content: center;
            transition: all 0.3s;
            overflow: hidden;
            text-decoration: none;
            color: $primary-colour;
            @include at-least($screen-tablet) {
                justify-content: left;
            }

            .description {
                font-size: 16px;
                margin-top: auto;
                flex-grow: 1;

                .value {
                    font-weight: bold;
                }
            }

            img {
                width: 104px;
                min-width: 104px;
                margin-right: 16px;
                border-radius: 12px;
            }

            .classic {
                background-color: $navy-04;
            }

            .premium {
                background-color: $navy-01;
            }
        }
    }

    .classic-banner {
        border: 1px solid pink;
    }

    .modal {
        top: 13%;
    }

    .spwf-modal {
        background: top / cover no-repeat url(/static/images/products/wet-food/upsellPopupAutoMobile@2x.png);
        top: 50px;
        max-height: calc(100% - 70px);
        overflow-y: auto;

        h3, p, a {
            color: $primary-white;
            text-align: center;
        }

        label.close {
            top: 16px;
            right: 16px;

            &:before {
                content: $icon-times;
                font-size: 32px;
                color: $primary-white;
            }
        }

        .btn-primary {
            display: block;
            max-width: 280px;
            margin: 16px auto;
            border-radius: 16px;
            background: $primary-white;
        }

        .modal-body {
            margin-top: 65%;
        }
    }
}

body.wet-food-shop-auto-mix {

    .content {
        padding-bottom: 40px;
    }

    .range-wrapper.new {
        margin: 0 0 20px 0 !important;

        .mixed-upsell.banner {
            background-color: $pastel-lighter-frost;
            padding: 0;
            overflow: hidden;
            margin: 0 0 24px 0;

            @include at-least($screen-tablet) {
                padding: 0 60px;
            }

            h4 {
                padding-left: 12px;
                margin-top: -28px;

                @include at-least($screen-tablet) {
                    padding-left: 0;
                }
            }

            .pawprints {
                @include at-least($screen-tablet) {
                    margin-left: -96px;
                }
            }
        }

        .mixed-banner {
            margin: 16px 12px;
            background-color: white;
            display: flex;
            flex-direction: column;
            gap: 0;
            align-items: center;
            max-width: blocks(8);
            border-radius: 12px;
            overflow: hidden;

            @include at-least($screen-tablet) {
                flex-direction: row;
                gap: 10px;
                margin: 16px 0 24px 0;
                max-height: 240px;

                &.with-price {
                    max-height: none;
                }
            }

            .image {
                background: center / cover no-repeat url('/static/images/wet-food/upsell-banner-mixed.jpg');
                width: 100%;
                height: 100%;
                min-height: 230px;

                @include at-least($screen-tablet) {
                    height: 315px;
                    width: 700px;
                }
            }

            .price-increase {
                font-size: 26px;
            }

            small {
                font-weight: 600;
            }

            .heading {
                font-weight: bold;
            }

            .banner-content {
                padding: 20px 16px 24px 16px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                @include at-least($screen-tablet) {
                    max-width: 370px;
                }

                .description {
                    margin-top: 0;
                }

                .btn {
                    width: 100%;
                    max-width: 100%;
                    margin-bottom: 0;
                    border-radius: 16px;
                    line-height: 48px;
                    padding: 0;

                    @include at-least($screen-tablet) {
                        width: 280px;
                        margin: 16px 0;
                    }
                }

                .actions {
                    margin-top: 22px;
                    width: 100%;

                    @include at-least($screen-tablet) {
                        justify-content: flex-start;
                        margin-top: 0;
                    }
                }
            }
        }

    }

    .hero-banner {
        margin: 16px auto;
        overflow: hidden;
        max-width: blocks(8);
        max-height: 382px;

        .hero-header {
            @include at-least($screen-tablet) {
                left: 0 !important;
                width: 100% !important;
            }
        }

        .hero-header,
        .header-image {
            border-radius: 0 0 50% 50%;
            z-index: -5;
            position: relative;
            height: 224px;
            max-height: 379px;
            left: -6vw;
            width: 124vw;
            @include at-least($screen-tablet) {
                position: unset;
                height: 38vh;
            }
        }

        .header-image {
            overflow: hidden;
            height: 100%;
            width: 90%;
            left: 0;
            background-size: cover;
            background-position: center;
            border-radius: inherit;
            background-image: url('/static/images/products/wet-food/Alt-mixed-WF-hero-Mobile.jpg');
            @include at-least($screen-tablet) {
                background-image: url('/static/images/products/wet-food/Alt-mixed-WF-hero-Desktop.jpg');
                overflow: hidden;
                border-radius: 0;
                left: 0;
                background-position: left;
                background-repeat: no-repeat;
                min-width: 100vw;
                background-size: contain;
            }

            .background-image {
                height: 245px;
            }
        }

        .hero-text {
            display: none;
            @include at-least($screen-tablet) {
                display: block;
                margin-top: -28vh;
                float: right;
                padding-right: 35px;
                text-align: center;
            }
        }

        &.premium-js {
            .hero-header {
                height: 35vh;
                max-height: 270px;
            }

            .hero-text {
                margin-top: -20vh;
            }
        }
    }

    .container {
        @include at-least($screen-tablet) {
            max-width: blocks(8);
            padding: 0;
        }

        .bundle-info {
            display: block;
            text-align: center;
            padding-bottom: 24px;
            @include at-least($screen-tablet) {
                display: none;
            }
        }
    }

    .jeff {
        font-family: $handwritten-font-family;
    }

    .tray_counts,
    .price,
    .banner,
    .bundle-info {
        display: none;

        &.selected {
            display: block;
        }
    }

    .greyed {
        color: $navy-03 !important;
    }

    h3 {
        text-align: center;

        a {
            font-size: $font-size-s;
            font-weight: 500;
        }
    }

    .price {
        white-space: nowrap;

        .total {
            font-size: 26px;
            font-weight: bold;
        }
    }

    .selection-box {
        display: none;
        border: 1px solid $pastel-silver;
        border-radius: 12px;

        &.selected {
            display: grid;
        }

        .selection-box-content {
            padding: 0 24px 24px 24px;
            @media (max-width: $screen-tablet) {
                padding: 0 16px 24px 16px;
            }

            .summary {
                display: none;
                justify-content: center;
                padding: 24px 0 24px 0;
                @include at-least($screen-tablet) {
                    padding: 16px 8px 16px 8px;
                }

                &.selected {
                    display: flex;
                }

                .left {
                    display: flex;
                    gap: 10px;

                    .tray-count {
                        margin: 8px 0;
                    }

                    .upper {
                        text-transform: capitalize;
                    }
                }
            }

            .price-total {
                font-size: $font-size-s;
                font-weight: 600;

                .total {
                    font-size: $font-size-m;
                }
            }
        }

        .background-curve-container {
            background-color: $navy-01;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            height: fit-content;
            border-bottom-right-radius: 50% 10%;
            border-bottom-left-radius: 50% 10%;
            border-top-right-radius: inherit;
            border-top-left-radius: inherit;
            h3.jeff {
                color: white;
                width: 74%;
                margin: 6% auto;
                &.preview-selection {
                    display: none;
                }
            }
        }
    }

    .filter-bar {
        height: calc(100% + -50px);
        width: 0;
        position: fixed;
        z-index: 1;
        top: 50px;
        right: 0;
        overflow-x: hidden;
        overflow-y: scroll;
        display: flex;
        justify-content: flex-end;
        background-color: rgba(0, 0, 0, 0.2);
        @media screen and (max-height: 450px) {
            padding-top: 15px;
        }
        @include at-least($screen-tablet) {
            padding: 16px;
            border: 1px solid $pastel-silver;
            border-radius: 12px;
            max-width: 268px;
            min-width: 232px;
            height: auto;
            position: initial;
            z-index: 1;
            top: 50px;
            right: 0;
            overflow-x: auto;
            overflow-y: auto;
            display: flex;
            justify-content: flex-end;
            background-color: initial;
        }

        .closebtn {
            display: block;
            @include at-least($screen-tablet) {
                display: none;
            }
        }

        .inner {
            background-color: white;
            height: calc(100% + 50%);
            width: 80%;
            opacity: 1;
            padding: 75px 8px;
            @include at-least($screen-tablet) {
                height: auto;
                width: 100%;
                opacity: 1;
                padding: 0;
            }
        }

        .closebtn {
            position: absolute;
            top: 26px;
            right: 18px;
            font-size: 28px;
        }

        .description {
            padding-bottom: 16px;
            margin: 0;
        }
    }


    .content-contain {
        display: flex;
        align-items: flex-start;
        gap: 48px;

        .right-side {
            margin: 0 12px;
            width: 100%;
        }
    }

    .accordion {
        .accordion-content {
            display: none;
            &.accordion-content-included{
                display: block;
                @include at-least($screen-two-columns-switch){
                    display: grid;
                    grid-row-gap: 16px;
                }
                &.title-hidden-pad {
                    padding-top: 16px;
                    @include until($screen-sm-min) {
                        padding-top: 0;
                    }
                }
            }

            .checklist-wrapper {
                padding-left: 16px;
            }
            .product {
                width: $recipe-tile-width;
            }
        }
        .accordion-content-if-desktop-grid{
            display: block;
            @include at-least($screen-two-columns-switch){
                display: grid;
                grid-row-gap: 24px;
            }
        }
        &.included{
            .products{
                &.selected, &:nth-child(2) {
                    @include until($screen-two-columns-switch){
                        .product:last-child{
                            border-bottom: 0;
                        }
                    }
                }
            }
        }
        &.excluded{
            .products:not(.selected){
                @include until($screen-two-columns-switch){
                    .product:last-child{
                        border-bottom: 0;
                    }
                }
            }
        }
        &:first-child{
            border-top: 1px solid $pastel-darker-silver;
        }
        border-bottom: 1px solid $pastel-darker-silver;

        .accordion-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 16px 8px;
            margin: 0;
            cursor: pointer;
            color: $primary-text-link;

            .icon {
                padding-right: 10px;
            }
        }

        .upper {
            text-transform: capitalize;
        }

        .icon-chevron-up {
            display: none;
        }

        .icon-included-close.icon-chevron-up {
            display: block;
        }
        .icon-included-open.icon-chevron-down {
            display: none;
        }

        &.tray {
            .accordion-title {
                color: $primary-text-link;
            }
        }
    }

    .btns {
        padding: 0 8px;
        text-align: center;

        @include at-least($screen-tablet) {
            text-align: center;
            display: flex;
            gap: 16px;
            align-items: center;
            justify-content: center;
        }

        .edit-info {
            margin-bottom: 16px;

            @include at-least($screen-tablet) {
                display: none !important;;
            }
        }

        .btn {
            width: 100%;
            max-width: 280px;
            margin: 0 auto 16px auto;
            border-radius: 16px;
            line-height: 48px;
            padding: 0;

            @include at-least($screen-tablet) {
                width: 296px;
                margin: initial;
            }

            &.btn-secondary {
                margin-bottom: 0;
            }
            @include until($screen-sm-min){
                &.preview-selection {
                    margin-bottom: 16px;
                }
            }
    }

        p {
            text-align: center;
            margin: 24px auto 0;
        }

        .update {
            @include at-least($screen-tablet) {
                display: none !important;
            }
        }
    }

    .edit-mode-btns {
        display: none;
        padding-bottom: 24px;

        @include at-least($screen-tablet) {
            padding-bottom: 0;
        }

        &.display {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            @include at-least($screen-tablet) {
                flex-direction: row-reverse;
                gap: 32px;
                flex-wrap: wrap;
                padding-bottom: 24px;
                width: 100%;
            }
        }
    }

    .checklist-wrapper {
        li {
            margin-bottom: 16px;
        }

        input[type=radio]:checked + label:before {
            font-size: 2rem;
        }

        input[type=checkbox]:checked + label:before {
            font-size: 2rem;
        }
    }

    .checkbox-wrapper-alt > label {
        justify-content: flex-start;
        padding-bottom: 16px;

        &:before {
            max-width: 24px;
            min-width: 24px;
            height: 24px;
            min-height: 24px;
            line-height: 24px;
        }
    }


    .tray {
        &.included {
            .product {
                &[data-quantity="0"] {
                    display: none;
                }
            }
        }

        &.excluded {
            .product {
                &[data-quantity]:not([data-quantity='0']) {
                    display: none;
                }
            }
        }

    }

    .products {
        display: grid;
        grid-template-columns: auto;
        @include at-least($screen-two-columns-switch) {
            grid-template-columns: repeat(2, auto);
            grid-gap: 24px 34px;
        }
        @include at-least($screen-tablet) {
            justify-content: space-between;
            grid-template-columns: repeat(2, auto);
        }
        @include at-least($screen-desktop) {
            .product img {
                min-width: 120px;
            }
        }
        .product {
            padding: 8px 0;
            @include until($screen-two-columns-switch) {
                border-bottom: 1px solid $pastel-darker-silver;
                padding: 16px 0;
            }

            .name {
                font-size: $font-size-xs;
                margin-bottom: 8px;
            }

            .product-inner {
                display: flex;
                flex-flow: row nowrap;
                align-items: flex-start;
                justify-content: center;
                transition: all 0.3s;
                overflow: hidden;
                text-decoration: none;
                color: $primary-colour;
                @include at-least($screen-tablet) {
                    justify-content: left;
                }

                .description {
                    font-size: $font-size-xs;
                    margin-top: auto;
                    flex-grow: 1;

                    .value {
                        font-weight: bold;
                    }

                    .tray {
                        margin: .7rem 0;
                    }
                }

                .image-container{
                    margin-right: 16px;
                    border-radius: 12px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    &.classic {
                        background-color: $navy-04;
                    }
                    &.premium {
                        background-color: $navy-01;
                    }
                    img {
                        width: 80px;
                        min-width: 80px;
                    }
                }
            }

        }
    }

    .classic-banner {
        border: 1px solid pink;
    }

    .modal {
        top: 13%;
    }

    .spwf-modal {
        background: top / cover no-repeat url(/static/images/products/wet-food/upsellPopupAutoMobile@2x.png);
        top: 50px;
        max-height: calc(100% - 70px);
        overflow-y: auto;

        h3, p, a {
            color: $primary-white;
            text-align: center;
        }

        label.close {
            top: 16px;
            right: 16px;

            &:before {
                content: $icon-times;
                font-size: 32px;
                color: $primary-white;
            }
        }

        .btn-primary {
            display: block;
            max-width: 280px;
            margin: 16px auto;
            border-radius: 16px;
            background: $primary-white;
        }

        .modal-body {
            margin-top: 65%;
        }
    }
}

body.product-details {
    .product-details {
        position: relative;
        img {
            width: 280px;
            max-width: 100%;
            background-color: $pastel-lighter-frost;
            border-radius: 8px;
            margin: 20px auto;
        }
        @include at-least($screen-desktop) {
            .product-details {
                left: 150px;
                padding-top: 40px;
                img {
                    position: absolute;
                    top: 0;
                    left: -300px;
                }
            }
        }
    }
    .wet-food-details {
        display: flex;
        flex-direction: column;
        img {
            width: 280px;
            height: 285px;
            max-width: 100%;
            max-height: 100%;
            background-color: $pastel-lighter-frost;
            border-radius: 8px;
            margin: 20px auto;
        }
        .back-link-container{
            margin-bottom: 20px;
        }
        .details-image-container{
            display: flex;
            flex-direction: column;
            gap: 30px 30px;
        }
        .details-container{
            h3{
                margin-bottom: 30px;
            }
            display: flex;
            flex-direction: column;
        }
        @include at-least($screen-desktop) {
            .details-image-container{
                flex-direction: row;
                gap: 30px 30px;
            }
        }
    }
}

body.wet-food-shop-auto.confirmation {
    padding-top: 45px;

    label[for=show-selection] {
        text-decoration: underline;
        color: $primary-text-link;
        user-select: none;
        -moz-user-select: none;
        cursor: pointer;
        margin-bottom: 20px;
    }
    #show-selection {
        display: none;
    }
    #show-selection:checked ~ .products {
        display: grid;
    }
    .products {
        display: none;
        img {
            max-width: 80px;
            min-width: 80px;
        }
        padding-bottom: 0;
    }
    .btn {
        margin-bottom: 20px;
    }
}

body.wet-food-shop-auto, body.wet-food-shop-auto-new, body.wet-food-shop, body.wet-food-shop-auto-mix {
    .notifications {
        position: absolute;
        top: 0;
        left: 40px;
        right: 40px;
        text-align: center;
    }
    .pause-box {
        border: 1px solid $pastel-silver;
        border-radius: 12px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 24px;
        padding: 16px;
        gap: 0 16px;
        &.manual-wf-shop{
            margin: 24px 48px 40px 48px;
        }
        @include until($screen-tablet) {
            margin: 24px 12px 0 12px;
            &.manual-wf-shop{
                margin: 24px 12px 40px 12px;
            }
        }
        div.copy-and-cta{
            display: flex;
            flex-direction: column;
        }
    }
}

body.wet-food-shop-auto, body.wet-food-shop-auto-new, body.wet-food-shop-auto-mix {
    a {
        font-weight: 500;
    }
    .range-wrapper.new {
        margin: 20px 0;
        .banner {
            padding: 20px;
            &.premium-upsell {
                background-color: $pastel-lighter-beige;
            }
        }.premium-upsell h4 {
        text-align: left;
        margin-bottom: 20px;
        }
        .premium-banner {
            background-color: white;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;
            max-width: blocks(12);
            border-radius: 12px;
            overflow: hidden;
            .image {
                background: center / cover no-repeat url('/static/images/wet-food/upsell-banner-header-desktop.jpg');
                height: 315px;
                width: 700px;
            }
            .heading {
                font-weight: bold;
            }
            .banner-content {
                padding: 20px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }
            .btn {
                width: 280px;
                max-width: 100%;
            }
        }
        @include until(530px) {
            .premium-banner {
                flex-direction: column;
                gap: 0;
                .image {
                    background: center / cover no-repeat url('/static/images/wet-food/upsell-banner-header-mobile.jpg');
                    width: 100%;
                    aspect-ratio: 2;
                    height: auto;
                    max-height: 200px;
                    min-height: 150px;
                }
                .actions {
                    width: 100%;
                }
                .btn {
                    width: 100%;
                }
            }
        }
    }


    .range-wrapper.old {
        margin: 24px -15px;
        text-align: center;

        h3 {
            margin: 20px 0;
        }

        .btn {
            margin: 10px 0;
        }

        a {
            margin: 10px;
        }

        input {
            display: none;
        }

        .premium-banner {
            background: top / cover no-repeat url(/static/images/products/wet-food/bannerUpsellSPManualMobile@2x.png);
            text-align: left;
            padding: 16px 12px;
            margin: 20px auto;
            @media (min-width: $screen-xs-max) {
                background: 80% 0 / cover no-repeat url(/static/images/products/wet-food/bannerUpsellSPManual@2x.png);
                padding: 16px 24px;
                margin-top: 24px;
                .banner-content {
                    width: 55%;
                    .actions {
                        justify-content: flex-start;
                        gap: 8px;
                    }
                }
                p.description{
                    max-width: 300px;
                    margin-bottom: 0;
                }
            }

            .description {
                font-size: $font-size-xs;
            }

            h3, p, a {
                color: $primary-white;
                text-align: left;
            }

            label {
                cursor: pointer;
            }

            .btn-primary {
                display: inline-block;
            }

            .btn-secondary {
                display: inline-block;
                background: $primary-white;
                width: 160px;
                border-radius: 16px;
                line-height: 48px;
                padding: 0;
            }


            @include until($screen-tablet) {
                .banner-content {
                    margin-top: 240px;
                }
            }
        }

        .classic-banner {
            border: 1px solid pink;
        }

        .classic-upsell {
            margin: 24px 12px 16px 12px;

            a {
                margin: 0;
            }

            .btn {
                width: 240px;
                border-radius: 16px;
                line-height: 48px;
                padding: 0;
            }
        }
    }
    .accordion{
        &.included .accordion-content-included{
            .no-wet-food-available{
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-bottom: 24px;
                .no-wet-food-image{
                    width: 120px;
                    height: 120px;
                }
                .text-link {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 4px;
                    p {
                        margin: 0;
                        &.nothing-suitable{
                            font-weight: 600;
                        }
                    }
                }
            }
        }
        &.excluded .products .product-group {
            &[data-quantity="0"] {
                .product-inner,
                .name {
                    opacity: 0.5;
                    filter: grayscale(.3);
                    order: 99;
                }
            }
            .product-excluded-warning {
                background-color: $product-warning-background;
                border: 1px solid $secondary-03;
                border-radius: 8px;
                padding: 16px;
                display: flex;
                flex-direction: row;
                gap: 8px;
                margin-top: 8px;
                height: 80px;
                img.icon-warning {
                    min-width: unset;
                    width: 24px;
                    height: 24px;
                }
                p {
                    margin: 0;
                }
            }
        }

    }
    .footer {
        z-index: -1;
    }
}

body.wet-food-shop {
    .footer{
        z-index: -1;
    }
}

body.discover-wet-food {
    text-align: left;

    .content .container {
        padding: 0;
    }

    ul, ol {
        list-style: none;
        padding: 0;
    }

    li {
        margin: 0;
    }

    header.hero-header.top {
        @include hero-header(('/static/images/products/wet-food/banner.jpg','/static/images/products/wet-food/banner-mobile.jpg'));
        .hero-text h1 {
            text-align: center;
        }
    }

    header.hero-header.bottom {
        @include hero-header(('/static/images/products/wet-food/wetfood_1_desktop.jpg','/static/images/products/wet-food/wetfood_1_mobile.jpg'));
        .hero-text h1 {
            text-align: center;
        }
    }

    .claims {
        padding: 20px;
        background-color: $pastel-lighter-frost;

        ul {
            text-align: center;
        }

        li {
            margin-bottom: 20px;

            &:before {
                @include icon;
                content: $icon-check-circle;
                color: $secondary-01;
                display: block;
                font-size: 41px;
                margin-bottom: 10px;
            }
        }

        @media (min-width: $screen-desktop) {
            padding: 20px 60px;
            li {
                display: inline-block;
                width: 33%;
                vertical-align: top;
            }
        }
    }

    .recipes {
        text-align: center;
        padding: 20px;
        background-color: #fff;

        > div {
            position: relative;
        }

        h3:first-of-type {
            margin-top: 2em;
        }

        button.next,
        button.previous {
            position: absolute;
            top: 25%;
            left: -40px;
            min-width: 30px;
            min-height: 30px;
            padding: 6px;
            border: none;
            background: none;
            font-size: 3rem;
            line-height: 1em;
            outline: none;
            color: $secondary-03;

            @media (min-width: $screen-desktop) {
                top: 35%;
            }

            &:hover,
            &:focus,
            &:active
            {
                color: lighten($scss-secondary-03, 5%)
            }

            &:before {
                @include icon;
                content: $icon-arrow-left-circle;
            }

            &.slick-hidden {
                display: none;
            }

            span {
                display: none;
            }
        }

        button.next {
            left: auto;
            right: -40px;

            &:before {
                content: $icon-arrow-right-circle;
            }
        }

        ul {
            text-align: center;
            margin-bottom: 20px;
            overflow: hidden;
        }

        li {
            display: inline-block;
            width: 100%;
            vertical-align: top;

            @media (min-width: $screen-desktop) {
                padding: 10px;
            }
        }

        ul.slick-dots {
            text-align: center;
            margin-top: 20px;
            li {
                width: auto;
                padding: 5px;

                button {
                    background-color: transparent;
                    border: 1px solid $secondary-03;
                }

                &.slick-active button {
                    background-color: $secondary-03;
                }
            }

        }

        img {
            @include grid-blocks(2);
        }

        .tails-view-wet-recipe-carousel {
            margin: 0 30px;
        }
    }

    .pricing {
        background-color: $pastel-lighter-frost;
        padding: 20px;

        li {
            position: relative;
            z-index: 1;

            > * {
                display: inline-block;
                vertical-align: middle;
            }

            .price {
                white-space: nowrap;
            }
        }

        li:nth-child(2) {
            text-align: left;
            img {
                width: 45%;
            }
        }

        img {
            width: 50%;
            margin: 5px;
        }

        div {
            width: 40%;
            text-align: center;
        }

        h3 {
            margin-bottom: 0.5em;
        }

        @media (min-width: $screen-desktop) {
            text-align: center;

            li {
                display: inline-block;
                width: 45%;
            }

            h3 {
                margin-bottom: 1em;
            }

            img {
                margin: 20px;
            }

            li:nth-child(2) {
                text-align: left;
                img {
                    width: 40%;
                }
            }
        }
    }

    .section {
        padding: 0 20px 20px;
        position: relative;
        background-color: #fff;
        z-index: 0;
    }

    .delivery.section {
        background: url(/static/images/products/wet-food/wetfood_1_desktop.jpg) no-repeat center;
        -webkit-background-size: cover;
        background-size: cover;
        position: relative;
        height: 530px;

        .panel {
            @include display(flex);
            @include align-items(center);
            @include justify-content(center);
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            background: rgba(255, 255, 255, .8);
            max-width: 400px;
            width: 100%;
            padding: 20px;

            .inner {
                width: 100%;
                max-width: 400px;

                h2 {
                    margin: 0 0 15px;
                }

                p {
                    margin: 0;
                }
            }
        }
    }

    .how-it-works {
        background-color: $pastel-lighter-frost;

        counter-reset: hiw;

        li {
            position: relative;
            margin: 0 0 1em 2.5em;
        }
        li:before {
            counter-increment: hiw;
            content: counter(hiw);
            position: absolute;
            left: -2em;
            border-radius: 50px;
            background-color: $secondary-03;
            color: $primary-white;
            min-width: 25px;
            text-align: center;
            font-weight: bold;
        }
    }

    @media (min-width: $screen-desktop)  {

        .section.how-it-works div {
            padding: 30px;
        }
        .section.how-it-works div h2 {
            padding-top: 0;
            margin-top: 0;
        }
        .section.how-it-works div *:last-child{
            padding-bottom: 0;
            margin-bottom: 0;
        }

    }

    // TODO: Move out to generic styles and a mixin
    .ribbon {
        font-size: $font-size-body;
        font-weight: bold;
        width: 90%;
        max-width: 250px;
        position: relative;
        background: $secondary-03;
        color: #fff;
        text-align: center;
        margin: 0 auto;
        line-height: 2em;

        &:before,
        &:after {
            content: "";
            position: absolute;
            display: block;
            top: -0.5em;
            border: 1.5em solid #EF9532;
            z-index: -1;
        }

        &:before {
            left: -1em;
            border-left-color: transparent;
            border-width: 1em 1em 1em 0.5em;
        }

        &:after {
            right: -1em;
            border-right-color: transparent;
            border-width: 1em 0.5em 1em 1em;
        }
    }

    .accordion {
        padding: 20px;
        background-color: $pastel-lighter-frost;
    }

    .ribbon-content {
        display: inline-block;

        &:before,
        &:after {
            content: "";
            position: absolute;
            display: block;
            border-style: solid;
            border-color: transparent transparent #D28127 transparent;
            top: -0.5em;
        }

        &:before {
            left: 0;
            border-width: 0 0 0.5em 0.5em;
        }

        &:after {
            right: 0;
            border-width: 0 0.5em 0.5em 0;
            z-index: 0;
        }

        * {
            display: block;
            line-height: 1em;
        }

        strong {
            font-weight: bold;
            font-size: 2em;
            line-height: 2em;
        }
    }

}

body.discover-treats {

    background-color: white;
    &.propet {
        .hero {
            background: transparent url('/static/images/treats/hero-mobile-dd.jpg') no-repeat top center;
            background-size: cover;
            @include at-least($screen-tablet) {
                background: transparent url('/static/images/treats/hero-desktop-dd.jpg') no-repeat top center;
                background-size: cover;
            }
        }
    }

    .section {
        .content {
            @include grid-blocks(12);
        }
    }

    .btn {
        @include grid-blocks(3);
        display: block;
        margin: auto;
    }

    .hero {
        background: transparent url('/static/images/treats/discover-hero-mobile.jpg') no-repeat top center;
        background-size: cover;
        height: 507px;
        padding-bottom: 50px;
        width: 100%;
        color: white;
        &:after {
            height: 20px;
        }
        position: relative;
        .chevron {
            @include position(absolute, null 0 0 0);
            text-align: center;
            height: 65px;
            &:before {
                @include icon($icon-arrow-down-circle);
                @include inalign(middle);
                font-size: $font-size-l;
                color: white;
            }
            &:after {
                content: "";
                @include inalign(middle);
                height: 100%;
                width: 0;
            }
            @include at-least($screen-desktop) {
                height: 80px;
            }
        }

        @include flexbox(column, center, flex-end);
        @include at-least($screen-tablet) {
            min-height: 0;
            background: transparent url('/static/images/treats/discover-hero-desktop.jpg') no-repeat top center;
            background-size: cover;
            height: 704px;
            padding-bottom: 70px;
        }
        .info {
            white-space: nowrap;
            font-size: 0;
            max-width: 100%;
            .introducing {
                display: none;
                margin-bottom: 10px;
            }
            .introducing-with-badge {
                display: inline-block;
                margin-right: -80px;
                margin-bottom: 10px;
            }
            @include at-least($screen-desktop) {
                .introducing {
                    display: block;
                }
                .introducing-with-badge {
                    display: none;
                }
            }
            .badge {
                display: none;
                background: transparent url('/static/images/treats/stamp.png') no-repeat top center;
                height: 250px;
                width: 250px;
                background-size: 100%;
                margin-right: 30px;
                @include at-least($screen-desktop) {
                    @include inalign(top);
                }
            }
            .text {
                @include inalign(top);
                @include grid-blocks(6);
                padding: 15px;
                text-align: center;
                font-size: $font-size-body;
                white-space: normal;
                @include at-least($screen-desktop) {
                    text-align: left;
                }
                h1 {
                    padding-bottom: 15px;
                }
            }
            .btn {
                display: inline-block;
            }
        }

        .select-wrapper.fixed {
            position: fixed;
            top: 50px;
            left: 0;
            right: 0;
            background: $navy-01;
            padding: 16px;
            z-index: 10;
            text-align: center;

            a {
                margin: 0 auto;
            }

            @media (min-width: $screen-desktop) {
                top: 80px;
            }
        }
    }


    .section.claims {
        background-color: rgba(82, 157, 232, 0.43);
        @include at-least($screen-tablet) {
            margin: 15px;
        }
        .content {
            margin: auto;
            padding: 50px 30px;
            text-align: center;
            h1 {
                margin-bottom: 30px;
            }
            p {
                color: white;
            }
            .claims {
                font-size: 0;
                text-align: center;
                .claim {
                    @include inalign(top);
                    font-size: $font-size-body;
                    width: 100%;
                    @include at-least($screen-lg-desktop) {
                        width: 33.333%;
                    }
                    p {
                        display: inline-block;
                        @include grid-blocks(4);
                        padding: 0 15px;
                    }
                    .image {
                        max-width: 50vw;
                    }
                }
            }
        }
    }

    .section.range {
        text-align: center;
        padding: 30px 15px;
        .content {
            margin: auto;
            font-size: 0;
            padding-bottom: 30px;
            h1 {
                padding-bottom: 30px;
            }
            .products {
                    @include flexbox(column, center, center);
                @include at-least($screen-tablet) {
                    @include flexbox(row, center, space-around);
                }
                .product {
                    @include inalign(top);
                    p {
                        padding-top: 15px;
                    }
                    width: 100%;
                    @include at-least($screen-tablet) {
                        width: 50%;
                    }
                    @include at-least($screen-lg-desktop) {
                        width: 25%;
                    }
                    img {
                        width: 100%;
                        max-width: 30vmax;
                    }
                }
            }
        }
    }

    .section.delivery {
        .content {
            position: relative;
            max-width: none;
            padding-bottom: 0;
            .image {
                display: block;
                background: transparent url('/static/images/treats/doorstep-mobile.jpg') no-repeat bottom center;
                background-size: cover;
                height: 286px;
                padding-bottom: 30px;
                @include at-least($screen-tablet) {
                    min-height: 0;
                    background: transparent url('/static/images/treats/doorstep-desktop.jpg') no-repeat bottom center;
                    height: 682px;
                    max-height: 50vw;
                    background-size: cover;
                    padding-bottom: 50px;
                }
            }
            .info {
                margin: auto;
                @include grid-blocks(6);

                .box {
                    padding: 30px 15px;
                }
                @include at-least($screen-desktop) {
                    @include position(absolute, 0 0 0 0);
                    max-width: $container-max-width;
                    &:before {
                        content: "";
                        height: 100%;
                        width: 0;
                        @include inalign(middle);
                    }
                    .box {
                        @include inalign(middle);
                        max-width: 80%;
                        background-color: rgba(255,255,255,0.75);
                        padding: 30px;
                        @include grid-blocks(6);
                    }
                }
            }
        }
    }
}

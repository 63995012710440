.slick-slider {
    margin-bottom: 30px;
}
.slick-track {
    cursor: hand;
}
.slick-dots {
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    width: 100%;
    padding: 15px 0;
    li {
        position: relative;
        display: inline-block;
        @include size(20px);
        margin: 0 10px;
        padding: 0;
        cursor: pointer;
        button {
            border: 0;
            display: block;
            @include size(16px);
            outline: none;
            line-height: 0;
            font-size: 0;
            color: transparent;
            padding: 5px;
            cursor: pointer;
            background-color: $primary-colour;
            border-radius: 50%;
            @include transition(all 0.3s);
            &:hover, &:focus {
                outline: none;
            }
        }
        &.slick-active button{
            background-color: $secondary-01;
            @include transform(scale(1.2));
            @include transform(scale3d(1.2, 1.2, 1));
        }
    }
}



body.cancellation-confirmed {
    h1 {
        margin-bottom: .5em;
    }
    p.big {
        font-size: 2.4rem;
    }
    a.btn {
        float: right;
        margin-top: 1em;
    }
}

body.cancellation-confirm {
    p {
        text-align: center;
    }
    .list-unstyled {
        padding-left: 0;
        list-style: none;
    }

    li > a {
        width: 240px;
        margin-bottom: 30px;
    }
    .cancellation-buttons {
        @include flexbox(column, center, center);
        a {
            margin: 10px 0;
        }
    }
}

body.cancellation-reasons {
    .pet-roundal {
        margin: auto;
        display: block;
    }
    p {
        text-align: left;
    }
    .internal-reason {
        padding: 2px;
        background: lighten($scss-product-error, 30%);
        &:before {
            content: 'internal reason';
            display: block;
            font-size: 1.3rem;
            font-weight: bold;
        }
    }
}

body.cancellation {

    .hero-header {
        @include hero-header(('/static/images/dogs/leaving.jpg'));
        .hero-text {
            display: none;
        }
    }
    h1 {
        text-align: center;
    }
    &.cancellation-confirm {
        .didnt-like-the-food {
            ul {
                background-color: $pastel-lighter-frost;
                list-style: none;
                padding: 30px 20px !important;

                @include display(flex);
                @include flex-direction(row);
                @include flex-wrap(wrap);
                @include justify-content(center);
                li {

                    margin-bottom: 15px;
                    color: #0b2541;
                    max-width: 285px;
                    img {
                        max-height: 150px;
                    }
                    h5 {
                        text-align: center;
                    }
                }
            }
            a {
                font-size: $font-size-m;
                padding-left: 30px;
                padding-right: 30px;
                @include grid-blocks(3);
            }
        }
    }

    form {
        text-align: center;
        a.btn-primary:before {
            @include icon($icon-chevron-left);
            font-size: 1em;
            vertical-align: top;
        }
    }

    .alert-window {
        .bd {
            text-align: left;
        }
    }

    .btn.icon-after {
        text-transform: lowercase;
        &:after {
            margin-left: 20px;
            @include icon($icon-chevron-right);
        }
    }

    .btn.icon-before {
        text-transform: lowercase;
        &:before {
            margin-right: 20px;
            @include icon($icon-chevron-left);
        }
    }
}
// Note: The
#CancellationConfirmation {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  * {
    text-align: left;
  }
  h1 {
    font-size: 32px;
  }
  @media (min-width: $screen-md-min) {
    * {
      text-align: center;
    }
    h1 {
      font-size: 43px;
    }
  }
}
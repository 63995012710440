
.form-group.with-anchor .input-wrapper {
    position: relative;
    > a {
        position: absolute;
        top: 0;
        right: 10px;
        line-height: 44px;
    }
}

.ticker-tape {
    margin: 3px;
    font-weight: normal;

    @media (min-width: $screen-sm-min) {
        margin: 5px;
    }

    span {
        display: inline-block;
        color: $navy-01;
        font-style: italic;
        background: $primary-white;
        text-transform: uppercase;
        white-space: nowrap;

        padding-left: 3px;
        padding-right: 7px;
        margin-top: 3px;
        margin-left: -3px;

        @media (min-width: $screen-sm-min) {
            padding-left: 5px;
            padding-right: 10px;
            margin-top: 5px;
            margin-left: -5px;
        }
    }

    &.ticker-tape-blue {
        span {
            background: $navy-01;
            color: $primary-white;
        }
    }
    &.ticker-tape-red {
        span {
            background: $product-error;
            color: $primary-white;
        }
    }
    &.ticker-tape-gold {
        span {
            background: $secondary-03;
            color: $primary-white;
        }
    }
}


@mixin tails_spinner($size, $width) {
    @include size($size);
    @include inalign(middle);
    background: transparent;
    border-radius: 50%;
    margin: $size / 2 auto;
    border: $width solid transparent;
    box-sizing: content-box;
    @include border-color($pastel-lighter-frost $secondary-03 $pastel-lighter-frost $navy-01);
    @include animation(spin .6s linear infinite);
}

.alerts {
    padding: 0 15px 40px 15px;
    &:empty {
        padding-bottom: 0;
    }
}


input:invalid+.validation-errors {
    display: block;
}

input.validity:valid+.validation-errors {
    display: none;
}

.validation-errors {
    background: none;
    border: none;
    color: $product-error;
}

@mixin with-tip {
    display: none !important;
    &:checked ~ label {
        color: $primary-text-link;
        .tip {
            opacity: 1;
            transform: scale(1);
            &.bottom {
                @include translated(0, 60px, 0);
            }
        }
    }
    ~ label {
        vertical-align: middle;
        .tip {
            position: absolute;
            background-color: $primary-white;
            font-size: $font-size-s;
            border-radius: 4px;
            color: $navy-01;
            line-height: 1.4;
            z-index: 100;
            @include transition-property(transform, opacity);
            @include transition-duration(0.3s);
            transition-timing-function: cubic-bezier(0, 1.49, 0.56, 1.2);

            &.bottom {
                @include translated(0, 50%, 0);
            }
            font-weight: normal;
            width: 98%;
            left: 2%;
            margin-top: -50px;
            opacity: 0;
            transform: scale(.1);
            overflow: hidden;
            padding: 15px;
            border: 1px solid #DEDEDE;
            box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);
            pointer-events: none;
            white-space: normal;
            h3 {
                text-align: center;
            }
            .close {
                cursor: pointer;
                pointer-events: all;
                float: right;
                font-size: 3rem;
                color: #CACACA;
                opacity: 1;
                &:hover {
                    color: $secondary-03;
                }
            }
        }
        .icon {
            position: relative;
        }
        &:hover {
            color: $secondary-01;
        }
    }
}

@mixin ribbon-subheader($size) {
    h2, label {
        $half: $size / 2;
        position: relative;
        display: inline-block;
        padding: 0 $size;
        color: $primary-white;
        background-color: $navy-01;
        white-space: nowrap;

        height: $size;
        line-height: $size;

        &:before, &:after {
            content: "";
            background-color: $navy-01;
            border-style: solid;
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
        }

        &:before {
            border-color: transparent transparent transparent $primary-white;
            border-width: $half 0 $half $half;
            left: 0;
        }

        &:after {
            border-width: $half $half $half 0;
            border-color: transparent $primary-white transparent transparent;
            right: 0;
        }
    }

}

@mixin toggle_on {
    background-color: $secondary-03;
    color: $primary-white;
    border-bottom: none;
    box-shadow: inset 0 4px 0 0 $secondary-03;
    border-bottom: 4px solid transparent;

    &:hover {
        background-color: lighten($scss-secondary-03, 5%);
    }
}

@mixin radio_toggle_on {
   &:after {
       transform: scale(1);
       opacity: 1;
   }
}

@mixin pet-roundal($size) {
    @include size($size);
    background-color: $pastel-rose;
    color: $primary-white;
    margin: auto;
    border-radius: 50%;
    line-height: $size;
    font-size: $size;
    text-align: center;
    text-transform: uppercase;
    display: inline-block;
}

@mixin input_style($border-radius:null, $border-bottom:null) {
    @if ($border-radius && $border-bottom) {
        $border-radius: $border-radius;
        $border-bottom: $border-bottom;
    }
    @else {
        $border-radius: 0;
        $border-bottom: none;
    }

    height: 48px;
    min-width: 56px;
    max-width: 296px;
    box-sizing: border-box;
    padding: 11px $spacing-xxs;
    line-height: $spacing-s;
    margin-top: $spacing-xxs;
    margin-bottom: $spacing-m;
    font-size: $spacing-xs;
    font-weight: 400;
    color: $navy-00;
    border: 1px solid;
    border-color: $navy-01;
    border-radius: 4px;
    outline: none;
    -webkit-appearance: none;

    &:focus {
        box-shadow: 0 0 4px 2px $secondary-03;
    }

    &:disabled {
        background: $pastel-lighter-silver;
        border: $pastel-darker-silver;
        color: $navy-02;
    }
    @include placeholder {
        color: $navy-01;
    }
    &.error {
        @include error-border;
        + .status:before {
            @include icon($icon-exclamation);
            color: $product-error;
            font-size: 3.2rem;
        }
    }
    &#name {
        margin-bottom: 0;
    }
}

@mixin checklist {
    text-align: left;
    li {
        padding: 10px;

        color: $navy-01;
        margin: 10px 0;
        border-radius: 4px;
        font-weight: normal;
        label {
            width: 100%;
            display: table;
            margin-bottom: 0;
            padding-left: 0;
            &:before {
                @include size(20px);
                @include inalign(middle);
                background-color: $primary-white;
                line-height: 20px;
                min-width: 20px;
                margin-right: 0;
            }
            .text {
                padding-left: 10px;
                display: table-cell;
            }
            .subtle {
                font-weight: normal;
                font-size: 1.4rem;
                color: $navy-01;
                display: block;
            }
            .help:hover{
                text-decoration: none;
            }
        }
        input:checked ~ label:before {
            background-color: $secondary-03;
            border-color: $secondary-03;
        }
        input:disabled ~ label:before {
            background-color: $navy-04 !important;
            border-color: $navy-04 !important;
        }
    }
}

.ticker-tape {
    margin: 3px;
    font-weight: normal;

    @media (min-width: $screen-sm-min) {
        margin: 5px;
    }

    span {
        display: inline-block;
        color: $navy-01;
        font-style: italic;
        background: $primary-white;
        text-transform: uppercase;
        white-space: nowrap;

        padding-left: 3px;
        padding-right: 7px;
        margin-top: 3px;
        margin-left: -3px;

        @media (min-width: $screen-sm-min) {
            padding-left: 5px;
            padding-right: 10px;
            margin-top: 5px;
            margin-left: -5px;
        }
    }

    &.ticker-tape-blue {
        span {
            background: $navy-01;
            color: $primary-white;
        }
    }
    &.ticker-tape-red {
        span {
            background: $product-error;
            color: $primary-white;
        }
    }
    &.ticker-tape-gold {
        span {
            background: $secondary-03;
            color: $primary-white;
        }
    }
}

.alerts {
    padding: 0 15px 40px 15px;
    &:empty {
        padding-bottom: 0;
    }
}

.alert {
    position: relative;
    border-radius: 4px;
    padding: 15px;
    color: $primary-white;
    font-weight: normal;
    &.alert-danger, &.alert-error {
        background-color: $product-error;
    }
    .close {
        text-decoration: none;
        color: inherit;
        @include position(absolute, 6px 7px null null);
        &:before {
            @include icon($icon-times);
        }
        span {
            display: none;
        }
    }
    &:empty {
        display: none;
    }
    &.alert-info, &.alert-success {
        @include text-box($pastel-lighter-frost);
        max-width: $scss-container-max-width / 2;
        margin: auto;
        border: none;
    }
    &.alert-default {
        background-color: $pastel-lighter-frost;
        max-width: $scss-container-max-width / 2;
        margin: 30px auto;
        border: none;
    }
    &.alert-warning {
        color: $primary-colour;
        font-weight: normal;
        background-color: $secondary-03-10;
        max-width: $scss-container-max-width / 2;
        margin: auto;
        border: 2px solid $secondary-03;
        &:before {
            @include icon($icon-exclamation);
            color: $secondary-03;
            vertical-align: middle;
            font-size: $font-size-l;
            margin-right: 10px;
        }
    }
}

.ribbon-wrapper {
    @include ribbon-subheader(60px);

    width: 100%;
    margin-bottom: 20px;
    text-align: center;
    h2, label {
        margin-bottom: 30px;

        font-size: $font-size-m;
        @include at-least(340px) {
            font-size: 1.5em;
        }
    }
}



.pet-roundal {
    $size: 150px;
    @include pet-roundal($size);
    position: relative;

    .icons-info-profile-avatar {
        width: 90%;
        margin: 5% 10% 10% 10%;
    }

    .action {
        display: none;
        @include position(absolute, null 0 0 null);
        background-color: $secondary-01;
        border-radius: 50%;
        height: 44px;
        width: 44px;
        font-size: $font-size-m;
        line-height: 46px;
        color: $primary-white;
        font-weight: normal;

        &.edit {
            background-color: $secondary-01;
        }
    }
}

.loader-animation {
    height: 200px;
    width: 200px;
    background-image: url('/static/images/loader-sprite.png');
    background-size: cover;
    animation: positionX 3s steps(13) infinite;

    &.medium {
        height: 160px;
        width: 160px;
    }

    &.small {
        height: 128px;
        width: 128px;
    }

    &.x-small {
        height: 112px;
        width: 112px;
    }
}

@keyframes positionX {
    from {
        background-position-x: 1300%;
    }
    to {
        background-position-x: 0;
    }
}

.loader-shroud {
    @include position(absolute, 0 0 0 0);
    background-color: rgba(255, 255, 255, 0.95);
    max-width: 100%;
    height: 100%;
    margin: auto;
    opacity: 0;
    text-align: center;
    @include transition(opacity 0.3s);
    z-index: 1000;
    pointer-events: none;

    &.show {
        opacity: 1;
        pointer-events: initial;
    }

    &.opaque {
        background-color: white;
    }

    .loader-container {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        z-index: 1000;
        padding: $spacing-s 12px;

        .loader-message {
            position: relative;
            font-family: $handwritten-font-family;
            font-size: $font-size-l;
            font-weight: 500;

            .loader-message-ellipsis {
                position: relative;
                &::after {
                    position: absolute;
                    bottom: 0;
                    left: 100%;
                    overflow: hidden;
                    vertical-align: bottom;
                    content: "...";
                    width: 8px;
                    animation: ellipsis steps(3) 1.5s infinite;
                }
                @keyframes ellipsis {
                    to {
                        width: 24px;
                    }
                }
            }
        }
    }
}

.flash {
    display: block;
    text-align: center;
    padding: 10px;
    background-color: $secondary-01;
    color: $primary-white;
    z-index: 1;
}

.date-box {
    display: inline-block;
    background: $primary-white;
    border-radius: 3px;
    overflow: hidden;
    text-align: center;
    line-height: 1;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.05);
    .month {
        font-size: 1.4rem;
        background-color: $navy-01;
        font-weight: normal;
        color: $primary-white;
        padding: 5px;
    }
    .body {
        position: relative;
        .weekday {
            font-size: 1.4rem;
            padding-top: 5px;
        }
        .day {
            font-size: 2.4rem;
            padding: 5px 20px;
        }
        .icon {
            @include position(absolute, 0 0 0 0);
            line-height: 60px;
            font-size: $font-size-l;
            background: $primary-white;
        }
    }
    @include until($screen-tablet) {
        .month {
            font-size: 1.4rem;
        }
        .weekday {
            font-size: 1.4rem;
        }
        .day {
            font-size: 2.4rem;
        }
        .dash {
            margin: 0 0.1em;
        }
    }
}

.flex-row {
    @include flexbox(row);
    @include flex-flow(wrap);
    @include at-least($screen-tablet) {
        @include flex-flow(nowrap);
    }
    .flex-col {
        margin-bottom: 15px;
        margin-right: 5px;
        &:last-child {
            margin-right: 0;
        }
    }
}

.panel-white {
    background: rgba(255,255,255,0.7);
    padding: 15px !important;
    border-radius: 4px;
}

.slider-select {
    width: 100%;
    .options {
        white-space: nowrap;
        width: 100%;
        display: table;
        margin-bottom: 20px;
        @include user-select(none);
        .old {
            display: none;
        }
        input[type=radio] {
            &:checked + label .icon{
                @include transform(scale(1.4));
                opacity: 1;
            }
        }
        .option {
            display: table-cell;
            text-align: center;
            .icon {
                @include transition(all 0.3s);
                color: $navy-01;
                display: inline-block;
                cursor: pointer;
                font-size: 14vw;
                @media screen and (min-width: $screen-tablet) {
                    font-size: 8vw;
                }
                @media screen and (min-width: $screen-lg-desktop) {
                    font-size: 100px;
                }
                opacity: .6;
            }
        }
    }
    input[type=range] {
        background: $pastel-lighter-frost;
        width: 100%;
        margin-top: 30px;
        margin-bottom: 20px;
        @include until($screen-phone) {
            margin: 5px 0;
        }
    }
    .descriptions {
        p {
            &:first-child {
                font-weight: normal;
                + p {
                    font-size: $font-size-s;
                }
            }
        }

        text-align: center;
        .description {
            display: none;
            p {
                font-size: $font-size-s;
            }
            &.selected {
                display: block;
                p:first-child {
                    font-weight: bold;
                    color: $navy-01;
                }
            }
        }
    }
}

input[type=text], input[type=email], input[type=password], input[type=number], input[type=tel],  textarea {
    @include input_style(0, 1px solid $navy-01);
    &.td-form-field {
        @include input_style(2px);
    }
}

select {
    @include appearance(none);
    &::-ms-expand {
        display: none;
    }
    &:disabled {
        color: $navy-04;
        background-color: $pastel-lighter-frost;
        border-bottom-color: #ccc;
        & + .action {
            color: $navy-04;
        }
    }
}

.checklist.checklist-white {
    > li {
        background-color: $primary-white;
        > label:before {
            background-color: $primary-white;
        }
    }
}

.checklist {
    li > label, > label {
        cursor: pointer;
        margin-left: 0 !important;
        margin-top: 0;
        margin-bottom: 5px;
        display: table-row;
        &:focus {
            outline: none;
        }
        &:before {
            content: '';
            color: transparent;
            @include inalign(middle);
            text-align: center;
            font-size: 0;
            border-radius: 4px;
            margin-right: 15px;
            height: 40px;
            width: 32px;
            text-indent: 1px;
            line-height: 40px;
            box-sizing: content-box;
            border: 2px solid $primary-grey;
        }

        &:focus:before {
            outline: 2px solid $primary-blueberry-light;
        }

        > * {
            display: table-cell;
            vertical-align: middle;
            width: 100%;
            padding-left: 20px;
        }
    }
    input[type=radio] {
        &+ label {
            &:before {
                border-radius: 50%;
            }
        }
    }
    input[type=checkbox], input[type=radio] {
        display: none;
        &:checked {
            &+ label {
                &:before {
                    background-color: $primary-white;
                    border: 6px solid $primary-blueberry;
                    font-size: 26px;
                    @include size(12px);
                    @include inalign(middle);
                    line-height: 12px;
                    min-width: 12px;
                }
            }
        }
        &:disabled {
            &+ label {
                opacity: .5;
                color: $primary-colour;
                span {
                    color: $primary-colour;
                }
                &:before {
                    content: "";
                    color: $primary-white;
                    font-size: 26px;
                    cursor: not-allowed;
                    border-color: $navy-04 !important;
                }
            }
        }
        &.excluded:hover + label:before {
            border-color: $product-error !important;
        }
        &.excluded:checked + label:before {
            content: $icon-times;
            background-color: $product-error !important;
            border-color: $product-error !important;
            color: $primary-white !important;
        }
    }
}

.checklist {
    list-style-type: none;
    padding: 0;
    @include checklist();
    li {
        display: block;
    }

    ul.checklist {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

.pill {
    padding: 5px 30px;
    background-color: $pastel-lighter-frost;
    border-radius: 50px;
    &.pill-error {
        background-color: transparent;
        color: $product-error;
    }
    &.pill-info {
        background-color: $secondary-01;
        color: $primary-white;
    }
    &.pill-warning {
        background-color: $secondary-03;
        color: $primary-white;
    }
    &.pill-white {
        background-color: $primary-white;
        color: $navy-01;
    }
}

.hr {
    @include flexbox(row, center, center);
    margin: 30px 0;

    text-transform: uppercase;
    font-weight: normal;
    font-size: $font-size-m;
    .hr-left, .hr-right {
        flex-grow: 2;
        margin: 0 15px;
        border-radius: 5px;
        background: rgba(255, 255, 255, 0.2);
        height: 5px;
        text-transform: uppercase;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }
}
.table.table-striped{
    border: none;
    tbody {
        tr:nth-child(odd) td {
            background-color: rgba($navy-01, 0.1);
        }
    }
}

nav {
    a {
        color: $navy-01;
        text-decoration: none;
    }

}

.multiselect {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: left;
    word-spacing: 10px;
    li {
        @include inalign(middle);
        word-spacing: normal;
    }
    .label {
        @include inalign(middle);
        text-align: center;
        color: $navy-01;
        font-weight: 400;
        min-height: 40px;
        line-height: 40px;
        padding: 0 10px;
        background-color: $primary-white;
        border: 1px solid $navy-01;
        @include transition(all 0.3s);
        cursor: pointer;
        @include user-select(none);
    }
    input[type=checkbox] {
        display: none;
    }
    input[type=checkbox]:checked + .label {
        background-color: $navy-01;
        color: $primary-white;
        border-color: $navy-01;
    }
    &.exclusive input[type=checkbox]:checked + .label {
        text-decoration: line-through;
    }
    input[type=checkbox]:disabled + .label {
        background-color: $pastel-lighter-frost;
        border-color: $pastel-lighter-frost;
    }
}

.multi-select-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    @include until($screen-phone) {
        grid-template-columns: 1fr 1fr;
    }
    grid-row-gap: 0.5em;
    text-align: left;
    word-spacing: 10px;

    .label {
        @include inalign(middle);
        text-align: center;
        color: $navy-01;
        font-weight: 400;
        min-height: 40px;
        line-height: 40px;
        padding: 0 10px;
        background-color: $primary-white;
        border: 1px solid $navy-01;
        border-radius: 16px;
        @include transition(all 0.3s);
        cursor: pointer;
        @include user-select(none);
        min-width: 95%;
    }
    input[type=checkbox] {
        display: none;
    }
    input[type=checkbox]:checked + .label {
        background-color: $navy-01;
        color: $primary-white;
        border-color: $navy-01;
    }
    &.exclusive input[type=checkbox]:checked + .label {
        text-decoration: line-through;
    }
    input[type=checkbox]:disabled + .label {
        background-color: $pastel-lighter-frost;
        border-color: $pastel-lighter-frost;
    }
}


$checkbox-size: 24px;

.checkbox-list {
    legend {
        text-align: left;

        p {
            margin-bottom: 0;
            color: $navy-02;
        }
    }

    &.icons {
        .checkbox-wrapper + .checkbox-wrapper {
            margin-top: 3.2rem;
        }
    }
}

.checkbox-wrapper {
    display: grid;
    grid-template-columns: $checkbox-size auto;
    gap: $spacing-xxs;
    position: relative;

    .icon-anything {
        background: url('/static/images/signup/feeding-plan/icons_info_likes.svg') no-repeat;
        background-size: contain;
    }
    .icon-beef {
        background: url('/static/images/signup/feeding-plan/icons_info_beef.svg') no-repeat;
        background-size: contain;
    }
    .icon-chicken {
        background: url('/static/images/signup/feeding-plan/icons_info_chicken.svg') no-repeat;
        background-size: contain;
    }
    .icon-lamb {
        background: url('/static/images/signup/feeding-plan/icons_info_lamb.svg') no-repeat;
        background-size: contain;
    }
    .icon-fish {
        background: url('/static/images/signup/feeding-plan/icons_info_fish.svg') no-repeat;
        background-size: contain;
    }
    .icon-pate {
        background: url('/static/images/signup/feeding-plan/icons_info_wet-food.svg') no-repeat;
        background-size: contain;
    }
    .icon-jelly {
        background: url('/static/images/signup/feeding-plan/icons_info_jelly.svg') no-repeat;
        background-size: contain;
    }
    .icon-gravy {
        background: url('/static/images/signup/feeding-plan/icons_info_gravy.svg') no-repeat;
        background-size: contain;
    }

    + .checkbox-wrapper {
        margin-top: $spacing-s;
    }

    > span {
        line-height: 1.5;
    }

    .checkbox-icon {
        display: none;

        + span {
            padding-right: $spacing-xxl;
        }
    }

    input[type=checkbox] {
        -webkit-appearance: none;
        appearance: none;
        background-color: $primary-white;
        margin: 0;
        width: $checkbox-size;
        height: $checkbox-size;
        min-height: $checkbox-size;
        max-height: $checkbox-size;
        border: 1px solid $navy-01;
        border-radius: 3px;
        display: grid;
        place-content: center;
        cursor: pointer;
        transition: background-color 120ms ease-in-out, border-color 120ms ease-in-out;

        &::before {
            content: "";
            width: 14px;
            height: 14px;
            clip-path: polygon(8% 51%, 36% 78%, 91% 7%, 100% 14%, 37% 95%, 0 60%);
            transform: scale(0);
            transition: 120ms transform ease-in-out;
            box-shadow: inset 1em 1em $primary-white;
            background-color: $primary-white;
        }

        &:hover {
            border-color: $navy-00;
        }

        &:active {
            border-color: $navy-02;
        }

        &:focus-visible {
            outline: 0;
            box-shadow: 0 0 10px 2px $secondary-03;
        }      

        &:disabled {
            cursor: not-allowed;
            background-color: $pastel-lighter-silver;
            border-color: $pastel-darker-silver;
        }

        &:checked {
            background-color: $product-selected;
            border-color: $product-selected;
            position: relative;

            &::before {
                transform: scale(1);
            }

            &:hover {
                background-color: $product-selected-darker;
                border-color: $product-selected-darker;
            }

            &:active {
                background-color: $product-selected-lighter;
                border-color: $product-selected-lighter;
            }

            &:disabled {
                cursor: not-allowed;
                background-color: $navy-02;
                border-color: $navy-02;
            }

            + .checkbox-icon {
                display: block;
                position: absolute;
                right: 0;
                height: 48px;
                width: 48px;
                bottom: 0px;

                &::after {
                    content: " ";
                    height: 36px;
                    width: 36px;
                    border-radius: 50%;
                    background-color: $pastel-rose;
                    position: absolute;
                    bottom: 0;
                    right:0;
                    z-index: -1;
                }
            }
        }
    }
    
    .text { 
        span {
            line-height: 1.5;
        }

        p {
            color: $navy-02;
            margin-bottom: 0;
        }
    }
}

.checkbox-wrapper-alt {
    input[type=checkbox], input[type=radio] {
        display: none;

        &:checked + label, &:checked + .label-container label {
            &:before {
                font-size: 3rem;
                text-align: center;
            }
        }
    }

    > label, .label-container label {
        @include flexbox(row, top, space-between);
        cursor: pointer;
        font-weight: normal;
        @include user-select(none);
        align-items: center;

        &::before {
            display: block;
            text-align: center;
            color: $navy-01;
            background-color: $primary-white;
            border: 1px solid $navy-01;
            border-radius: 3px;
            @include icon($icon-check);
            @include size($checkbox-size);
            min-width: $checkbox-size;
            min-height: $checkbox-size;
            line-height: $checkbox-size;
            font-size: 0;
            margin-right: 1rem;
            box-shadow: none;

            @include transition(all 0.3s);
        }

        .text {
            text-align: left;
            @include flex-grow(1);
            line-height: $font-size-s;

            span, p {
                margin: 0;
                line-height: $font-size-m;
            }
        }

        span {
            line-height: $checkbox-size;
        }
    }

    &.centered {
        label {
            @include justify-content(center);
            .text {
                @include flex-grow(unset);
            }
        }
    }
    &.health-issue {
        .issue-description-container  {
            display: flex;
            flex-direction: column;
            flex: 1;
            .issue-description {
                font-size: $font-size-body;
            }
        }
        .issue-description.extra-info {
            margin-top: 10px;
            margin-left: 57px;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            &:before {
                @include icon($icon-information);
                font-size: 34px;
                margin-top: 5px;
                margin-right: 15px;
            }
        }
    }
    &.allergens {
        margin-top: 0;
    }
}

.checkbox-wrapper-alt {
    $checkbox-size: 45px;
    input[type=checkbox], input[type=radio] {
        display: none;
        &:checked + label, &:checked + .label-container label {
            &:before {
                font-size: 3rem;
                text-align: center;
            }
        }
    }
    span {
        line-height: $checkbox-size;
        @media (max-width: 350px){
            line-height: unset;
        }
    }
    > label, .label-container label {
        @include flexbox(row, top, space-between);
        cursor: pointer;
        font-weight: normal;
        @include user-select(none);
        align-items: center;

        &:before {
            text-align: center;
            @include icon($icon-check);
            display: block;
            @include size($checkbox-size);
            color: $navy-01;
            line-height: $checkbox-size;
            border: 1px solid $navy-01;
            background-color: $primary-white;
            font-size: 0;
            @include transition(all 0.3s);
            min-width: $checkbox-size;
            min-height: $checkbox-size;
            border-radius: 5px;
            margin-right: 1rem;
            box-shadow: none;
        }
        .text {
            text-align: left;
            @include flex-grow(1);
            line-height: $font-size-s;

            span, p {
                margin: 0;
                line-height: $font-size-m;
            }
        }
    }
    &.centered {
        label {
            @include justify-content(center);
            .text {
                @include flex-grow(unset);
            }
        }
    }
    &.health-issue {
        .issue-description-container {
            display: flex;
            flex-direction: column;
            flex: 1;
            .issue-description {
                font-size: $font-size-body;
            }
        }
        .issue-description.extra-info {
            margin-top: 10px;
            margin-left: 57px;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            &:before {
              @include icon($icon-information);
              font-size: 34px;
              margin-top: 5px;
              margin-right: 15px;
            }
        }
    }
    &.allergens {
        margin-top: 0;
    }
}

.checklist-wrapper {
    list-style: none;

    li {
        margin: 20px auto;

        input:disabled ~ label {
             cursor: default;
            &:before {
                background-color: $pastel-lighter-frost;
                border-color: $pastel-lighter-frost;
                content: "";
            }
            span {
                color: $navy-04;
            }
        }
    }

    &.no-indent {
        padding-left: 0;
    }

    &.allergens {
        columns: 2;
        li {
            display: inline-block;
            width: 100%
        }
    }
}
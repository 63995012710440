.ad-tile-container {
  position: relative;
  text-align: center;
  margin: 0 auto 30px;

  .card {
    border-radius: $border-radius-xxs;
    padding: 0;
    display: flex;
    flex-direction: column-reverse;
    margin: auto;
    background-color: $secondary-potato-light-10;
    @include at-least($screen-tablet) {
      flex-direction: row;
    }
  }
  .ad-tile-img-container {
    width: 100%;
    border-top-left-radius: $border-radius-xxs;
    border-top-right-radius: $border-radius-xxs;
    position: relative;
    @include at-least($screen-tablet) {
        overflow: hidden;
    }

    @include at-least($screen-tablet) {
      width: 40%;
      border-top-left-radius: 0;
      border-top-right-radius: $border-radius-xxs;
      border-bottom-right-radius: $border-radius-xxs;
    }
    .sticker {
      font-size: $font-size-s;
      font-weight: $font-weight-regular;
      transform: rotate(0);
      width: 108px;
      height: 108px;
      position: absolute;
      z-index: 9999;
      top: 25px;
      right: -3px;

      @include until($screen-tablet) {
          right: 4px;
          top: 55px;
      }
      @include until($screen-xs) {
        top: 14px;
      }
    }
    img {
      width: 100%;
      border-top-left-radius: $border-radius-xxs;
      border-top-right-radius: $border-radius-xxs;
      object-fit: cover;
      @include at-least($screen-tablet) {
        border-top-right-radius: $border-radius-xxs;
        border-bottom-right-radius: $border-radius-xxs;
        border-top-left-radius: 0;
      }
      @include until($screen-tablet) {
        aspect-ratio: 2.85;
        object-position: 0 -46px;
      }
    }
  }

  .ad-tile-content {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-around;
    padding: 20px;
    @include at-least($screen-tablet) {
      width: 60%;
      padding: 16px 24px;
    }

    a {
      min-width: 48px;
      @include until($screen-tablet) {
        max-width: 100%;
      }
    }

    h4 {
      color: $secondary-potato;
      font-size: $font-size-l;
      @include until($screen-tablet) {
        margin-bottom: $spacing-xs;
      }
    }
  }
}

body.errors{
  .main {
    min-height: calc(100% - 490px);
  }
  .content, .container {
    padding: 0;
  }
  .error-dog {
    .text-wrapper {
      max-width: 90%;
      margin: 0 auto;
      padding-top: 30px;
      p {
        margin: 25px 0;
      }
      .links {
        padding-top: 20px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-flow: column;
        @include until($screen-tablet) {
          grid-template-columns: 1fr;
        }
        .help-link {
          grid-column-start: 1;
          padding: 3px 0;
          &:nth-child(n+5) {
            grid-column: 2;
            @include until($screen-tablet) {
              padding: 5px 0;
              grid-column: 1;
            }
          }
        }
      }
      h1 {
        padding-top: 40px;
      }

    }
    @include at-least($screen-desktop) {
      width: 100%;
      height: 800px;
      background-position: bottom;
    }
    .dog-image {
      max-height: 400px;
      height: 100vh;
      width: 100%;
      margin: 50px 0;
      background: url('/static/images/dogs/error-dogs/patch-404.png') no-repeat center bottom / cover;
    }
  }
}

body.wet-food, body.treats.ingredients,body.ingredients {
    background: url('/static/images/tail-sand-bg.png') no-repeat center center / cover;

    h1 {
        text-align: center;
        padding: 50px 15px;
    }

    .hero-header {
        @include hero-header(('/static/images/ingredients/bear.jpg','/static/images/ingredients/bear-mobile.jpg'));
        margin-top: 0;
    }

    font-size: $font-size-body;
    .product-list {
        @include flexbox(row, flex-start, space-between);
        @include flex-wrap(wrap);
        @include until($screen-tablet) {
            flex-flow: column wrap;
        }
        padding: 0;
        list-style: none;
        .product {
            background: $pastel-lighter-frost;
            flex: 1 1 33%;
            padding: 20px 30px;
            margin: 10px 5px;
            transition: transform 200ms ease-in-out;
            transform: translateX(5%);
            &:nth-of-type(odd) {
                transform: translateX(-5%);
            }

            .images {
                text-align: center;
                img {
                    max-width: 250px;
                    border-radius: 12px;
                }
            }


            h3 {
                padding-top: 20px;
            }
            h4 {
                padding-bottom: 20px;
            }

        }

    }
    .product-category {
        .product-description {
            overflow: hidden;
            max-height: 0;
            .product {
                transform: translate(0, -100%);
                transition: 0.3s all;
            }

        }
        input[type=checkbox] {
            display: none;
            &:checked {
                 ~ .product-description {
                    max-height: none;

                    .product {
                        transform: translate(0, 0);
                    }
                }
                ~ label {
                    span:after {
                        transform: rotate(180deg);
                    }
                }
            }
        }
        label {
            @include flexbox(row,center,flex-start);
            cursor: pointer;
            @include user-select(none);
            span {
                display: inline-block;
                padding: 0 10px;
                &:after {
                    @include icon($icon-chevron-down);
                    color: $secondary-03;
                    font-size: 1.4em;
                    display: inline-block;
                    transition: 50ms transform linear;
                }
            }
        }
    }
}

/*
 * Default body styles
 */
html, body {
    // needed for transulcent container
    height: 100%;
    height: calc(100% - 50px);
    @media screen and (min-width: $nav-max) {
        height: calc(100% - 80px);
    }

}

*[hidden] {
    display: none !important;
}

body {
    padding: 0;
    margin: 0;
    color: $primary-colour;
    font-family: $default-font-family;
    font-size: $font-size-body;
	text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
	-webkit-font-smoothing: subpixel-antialiased;
    -webkit-text-size-adjust: 100%;


    * {
        box-sizing: border-box;
    }


    .content {
        min-height: 500px;
        padding-bottom: 160px;
        .container {
            width: inherit;
            max-width: $container-max-width;
            margin: 0 auto;
            h1.update-info{
                margin-bottom: 1.6rem;
            }
            .update-info{
                padding: 0 12px;
            }
            .updated-plan-section{
                .updated-plan-title{
                    font-weight: 500;
                }
                background-color: $pastel-lighter-frost;
                padding: 16px 12px;
                margin: 14px 0 17px 0;
                dl {
                    margin-bottom: 0;
                }
                p.total-calories-message{
                    margin-bottom: 0;
                }
                p.recurring-cost-info{
                    margin-bottom: 0;
                }
                span.recurring-cost-title{
                    font-weight: bold;
                    display: inline;
                    &:after {
                        content: ": ";
                        margin-right: 0.3em;
                    }
                }
            }
            .no-more-eligible-container{
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 8px;
                margin-top: 10px;
                .no-more-eligible-warning{
                    font-weight: 600;
                    margin: 0;
                }
                .no-more-eligible-icon{
                    width: 24px;
                }
            }
        }
        .raf-banner-experiment {
            margin: 20px;
            @include at-least($screen-tablet) {
                margin: 30px auto;
                padding: 20px;
                @include grid-blocks(10);
            }
    
            h4 {
                margin-bottom: 20px;
                color: $navy-00;
            }
            .background-image {
                border-radius: 8px;
                padding: 0;
                background-repeat: no-repeat;
                background-size: cover;
                background-position-y: 100px;
                height: auto;
                min-height: 350px;
                width: auto;
                max-width: 400px;
                margin: 0 auto;
                @include at-least($screen-tablet) {
                    padding: 0;
                    min-height: 205px;
                    max-width: 100%;
                    height: 205px;
                    margin-top: unset;
                    background-position: top;
                    background-size: cover;
                }
            }

            .raf-container {
                width: 100%;
                height: 100%;
                overflow: hidden;
                @include at-least($screen-tablet) {
                    display: flex;
                    align-items: center;
                }
            }
    
            .raf-banner-wrap {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                @include at-least($screen-tablet) {
                    background-color: white;
                    height: 300px;
                    width: 300px;
                    margin: 0 auto;
                    margin-left: 2%;
                    border-radius: 50%;
                }
                position: relative;

                h4 {
                    @include at-least($screen-tablet) {
                        max-width: 260px;
                    }
                    text-align: center;
                    max-width: 300px;
                }

                .btn {
                    min-width: unset;
                    width: 240px;
                    box-sizing: border-box;
                }
            }
        }
    }


    &.half {
        padding-top: 50px;
        @include at-least($nav-max) {
            padding-top: 80px;
        }
        .content {
            .container {
                @include grid-blocks(6);
            }
        }
    }

    .top-nav,.sticky-cta, .top-nav .logo {
        transition: 300ms all ease;
    }

    &.scroll-down {
        .top-nav {
            @include translated(0,-100%,0);
            .logo {
                transform: scale(.1);
                opacity: 0;
            }
        }

        .sticky-cta {
            top: 0;
        }
    }
}

.hide, .hidden {
    display: none !important;
}


select {
    border: 1px solid $primary-colour;
    background-color: $primary-white;
    border-radius: 3px;
    padding: 12px;
    padding-right: 45px;
    font-weight: normal;
    font-size: $font-size-body;
    box-sizing: content-box;
}

select ~ .action:before{
    @include icon($icon-chevron-down);
    font-size: 2.5rem;
}

.input-wrapper {
    position: relative;
    display: inline-block;
    .status, .action, .action-shifted {
        @include position(absolute, 0px 10px null null);
        line-height: 40px;
        &:before {
            vertical-align: middle;
        }
    }
    .action {
        color: $primary-colour;
        line-height: 45px;
        pointer-events: none;
    }
    .action-shifted {
        line-height: 63px;
    }
    .status {
        &:before {
            font-size: 34px;
        }
        &.success:before, &[data-status="success"]:before{
            @include icon($icon-check-circle);
            color: $product-success;
        }
    }
}

.form-control {
    display: block;
    width: 100%;
}

select.form-control {
    width: calc(100% - 50px);
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance:textfield;
}

// Mixin itself
@mixin clearfix() {
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}

// Usage as a mixin
.clearfix {
  @include clearfix;
}

label {
    display: inline-block;
    font-weight: 400;
    font-size: $font-size-s;
    color: $navy-00;
}

fieldset {
    padding: 0;
    margin: 0;
    border: 0;
}

input, select, textarea, button {
    font-family:inherit;
}

body.native-android {
    text-rendering: initial;
}

.force-repaint { -webkit-animation: bugfix infinite 1s; }

@-webkit-keyframes bugfix {
    from { fill: 0; }
    to { fill: 0; }
}

/*
 * Forms, widgets
 */
.alerts {
    .container {
        padding-top: 20px;
    }
}
.alert-error {
    @extend .alert-danger !optional;
}
.btn-error {
    @extend .btn-danger !optional;
}
.label-error {
    @extend .label-danger !optional;
}
.panel-error {
    @extend .panel-danger !optional;
}
.progress-bar-error {
    @extend .progress-bar-danger !optional;
}
.text-error {
    @extend .text-danger !optional;
}
.list-group-item-error {
    @extend .list-group-item-danger !optional;
}
.bg-error {
    @extend .bg-danger !optional;
}

fieldset legend {
    display: block;
    width: 100%;
    padding: 0;
    margin-bottom: 20px;
    line-height: inherit;
    border: 0;
    border-bottom: none;
    font-weight: normal;
    border-bottom: none;
    text-transform: none;
}

.form-group label {
    span {
        display: block;
    }

    &.optional:after {
        margin-left: $spacing-xxs;
        content: "(optional)";
        text-transform: none;
        font-size: $font-size-s;
    }
}
html[lang=fr] {
    .form-group label.optional:after {
        content: "(facultatif)";
    }
}
.form-group > label {
    span {
        display: block;
    }
}
.form-group {
    .hint-text {
        font-size: 13px;
        margin: $spacing-xxs 0;
    }
}
select {
    -webkit-appearance: none;
    -webkit-border-radius: 0;

    &.center {
        text-align: center;
    }
}
// this is a hack to get input add-ons to line up
.input-group {
    .input-group-addon,
    .input-group-btn .btn,
    .input-group-btn i {
        line-height: 1.3 !important;
    }
}
.has-error {
    .help-block {
        font-weight: bold;
    }
    input[type="checkbox"]:after,
    input[type="radio"]:after {
        border: 1px solid $product-error;
    }
}
/**
 * Tables
 */

table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    margin-bottom: 20px;

    tr {
        td {
            padding: 8px;
            line-height: 1.42857;
        }
    }
    > tfoot {

        & > tr:first-child {
            & > th,
            & > td {
                border-top-width: 3px;
            }
        }

        & > tr {
            & > th,
            & > td {
                border-top-width: 0;
            }
        }
    }
}

/*
 * Lists
 */
dl {
    margin-top: 0;
}
dt {
    font-weight: bold;
    display: inline;
    &:after {
        content: ": ";
        margin-right: 0.3em;
    }
}

dd {
    display: inline;
    margin: 0;
    &:after {
        content: ' ';
        display: block;
        margin: 0 0 0.2em 0;
    }
}
ul.tick-list {
    padding-left: 10px;
    list-style: none;

    li {
        display: table-row;
        line-height: 1.6;
        margin-bottom: 5px;
        font-weight: bold;
        font-style: italic;
    }

    li:before {
        width: 7%;
        display: table-cell;
        content: "\2713";
        font-size: $font-size-body;
        margin-right: 10px;
        position: relative;
        height: 100%;
    }

    &.tick-list-large {
        font-size: $font-size-s;
    }
    @mixin tick-list-color($color) {
        li {
            color: $color;
        }
        li:before {
            color: $color;
        }
    }
    &.tick-list-gold {
        @include tick-list-color($secondary-03);
    }
    &.tick-list-blue {
        @include tick-list-color($navy-01)
    }
}
/*
 * Figures & captions
 */
figure {
    margin-bottom: 10px;
    img {
        width: 100%;
    }
    figcaption {
        margin-top: 7px;
        color: $navy-01;
        text-transform: uppercase;
        text-align: center;
        font-weight: normal;

        font-size: 1.2rem;

        @media (min-width: $screen-xs-min) {
            font-size: 1.4rem;
        }
        @media (min-width: $screen-sm-min) {
            font-size: $font-size-s;
        }
        @media (min-width: $screen-md-min) {
            font-size: $font-size-body;
        }
    }
}
/*
 * Panels
 */
.panel {
    background: $primary-white;
    margin-bottom: 20px;
    border-radius: 3px;
    overflow: hidden;
    padding-bottom: 15px;

    .panel-body {

        & > *:last-child {
            margin-bottom: 0;
        }
    }
    .panel-heading {
        margin-bottom: 12px;

        h1, h2 {
            .fa{
                text-indent: 0;
            }
            &.with-icon::before{
                @include icon;
                padding: 0px 15px;
                padding-bottom: 1px;
                background-color: $navy-01;
                display: inline-block;
                margin-left: -15px;
                text-align: center;
                margin-right: 15px;
                text-indent: 3px;
                border-right: 4px solid white;
                line-height: inherit;
            }
            &.success::before{
                content: $icon-check;
                background-color: $product-success;
            }
            &.error::before{
                content: $icon-exclamation-circle;
                background: none;
                margin: 10px 0;
            }
            a {
                height: 48px;
                line-height: 48px;
                padding-right: 15px;
                color: white;
                background-color: rgba(255, 255, 255, 0.1);
                display: block;
                transition: background-color 0.3s;
                -webkit-transition: background-color 0.3s;
                &:hover{
                    background-color: rgba(255, 255, 255, 0.3);
                    text-decoration: none;
                }
            }
        }
        .step {
            font-size: 1.5rem;
            display: block;
            padding: 0;
            padding-bottom: 5px;
            line-height: 30px;

            @media (min-width: $screen-sm-min) {
                float: right;
                border-left: 4px solid white;
                padding-left: 5px;
                padding-right: 20px;
                line-height: 50px;
            }
        }
    }
    .panel-heading-blank {
        padding-bottom: 7px;
        margin-bottom: 12px;
    }
    @mixin panel-color($color) {
        .panel-heading {
            border-color: $color;
        }
    }
    .panel-body, .panel-footer{
        padding: 5px 15px;
    }
    &.panel-blue {
        @include panel-color($navy-01);
    }
    &.panel-red {
        @include panel-color($product-error);
    }
    &.panel-gold {
        @include panel-color(darken($scss-secondary-03, 10));
    }
    &.panel-silver {
        @include panel-color(darken($scss-navy-04, 20));
    }
    &.panel-dark {
        background: $navy-01;
        color: $primary-white;
    }
}
.panel-wrapper {
    /*
     * Wraps panels nested inside columns to give them a consistent height white background
     */
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 20px;
    padding-bottom: 14px;

    background: $primary-white;

    // Remove excessive vertical space when panels stack
    .panel {
        padding-bottom: 0;
        margin-bottom: 0;
    }

    & > [class*='col-'] {
        padding-left: 0;
        padding-right: 0;
    }
}
.arrows {
    padding: 0;
    list-style-type: none;
}
.arrows li,
.arrow {
    background: $navy-04;
    color: white;
    position: relative;

    height: 20px;
    line-height: 20px;
    margin: 4px 0;
    padding: 0 10px;
    font-size: 1.2rem;

    font-weight: bold;
    text-align: center;
    text-transform: uppercase;

    @media (min-width: $screen-xs-min) {
        font-size: 1.4rem;
        height: 24px;
        line-height: 24px;
        margin: 6px 0;
        padding: 0 10px;
    }


    @media (min-width: $screen-md-min) {
        font-size: $font-size-body;
        height: 30px;
        line-height: 30px;
        margin: 10px 0;
        padding: 0 14px;
    }

    span {
        font-weight: normal;
        text-transform: none;
    }

    &.arrow-left {
        text-align: left;
        &:before {
            display: block;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            top: 0;
            position: absolute;
            pointer-events: none;
            border-right-color: $navy-04;
            border-width: 10px;
            left: -20px;

            @media (min-width: $screen-xs-min) {
                border-width: 12px;
                left: -24px;
            }

            @media (min-width: $screen-md-min) {
                border-width: 15px;
                left: -30px;
            }
        }
    }
    &.arrow-right {
        text-align: left;
        &:after {
            display: block;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            top: 0;
            position: absolute;
            pointer-events: none;
            border-left-color: $navy-04;
            border-width: 10px;
            right: -20px;

            @media (min-width: $screen-xs-min) {
                border-width: 12px;
                right: -24px;
            }

            @media (min-width: $screen-md-min) {
                border-width: 15px;
                right: -30px;
            }
        }
    }

    @mixin arrow-color($color) {
        background: $color;
        &:before {
            border-right-color: $color;
        }
        &:after {
            border-left-color: $color;
        }
    }

    &.arrow-blue, &.arrow-dark-steel {
        @include arrow-color($navy-01);
    }
    &.arrow-red {
        @include arrow-color($product-error);
    }
    &.arrow-silver {
        @include arrow-color($navy-04);
    }
    &.arrow-gold, &.arrow-cyan {
        @include arrow-color($secondary-03);
    }
}

.block-wide {

    .container {
        background-color: white;

        .contained {
            padding: 15px;

            @media (min-width: $screen-sm-min) {
                padding: 50px 90px;
            }
            @media (min-width: $screen-md-min) {
                padding: 50px 120px;
            }
            @media (min-width: $screen-lg-min) {
                padding: 50px 200px;
            }
        }
    }

    p {
        @media (min-width: $screen-sm-min) {
            font-size: $font-size-body;
        }
    }

    &.block-silver {
        .container {
            background: lighten($scss-navy-04, 18);
        }
    }
}

.block-arrow {
    position: relative;

    &:after {
        width: 0;
        height: 0;

        position: absolute;
        top: 100%;
        left: 50%;
        content: " ";
        border: solid transparent;
        border-top-color: white;
        border-width: 10px;
        margin-left: -5px;
        z-index: 100;

        @media (min-width: $screen-xs-min) {
            border-width: 20px;
            margin-left: -10px;
        }
    }
}

/*
 * Bootstrap tweaks & helpers
 */
.row-less-gutter [class*='col-']:not(:first-child):not(:last-child) {
    padding-right: 3px;
    padding-left:  3px;
}
.row-less-gutter [class*='col-']:first-child {
    padding-right: 3px;
}
.row-less-gutter [class*='col-']:last-child {
    padding-left: 3px;
}

.row-no-gutter [class*='col-'] {
    padding-right: 0px;
    padding-left:  0px;
}
.row-margin-bottom {
    margin-bottom: 10px;
}
.well{
  box-shadow: none;
}

/* only for column content visible */
.col-lg-1>div {background-color: $pastel-lighter-frost;}


.col-single-wide {
    @extend .container;
    max-width: 700px !important;
}

.col-single {
    @extend .container;
    max-width: 500px !important;
}

.col-single-thin {
    @extend .container;
    max-width: 400px !important;
}

/*
 * Trustpilot widgets
 */
.trustpilot-bar {
    background: $primary-white;
    padding: 10px;
    margin-bottom: 10px;
}
body.terms{
    .bold {
        font-weight: bold;
    }
    .quick-nav {
        text-align: center;
        background: rgba(255, 255, 255, 0.7);
        padding: 15px;
        margin: 30px 0;

        .in-page-nav {
            text-align: center;
            padding: 0;
            font-size: 0;
            .btn {
                font-size: $font-size-body;
                width: 100%;
                margin: 4px;
                display: inline-block;
                a {
                    color: white;
                }
            }
        }
    }
    .panel:first-child {
        margin-top: 20px;
    }

    .main {
        padding-top: 60px;
    }
}

/*
 *  Home.scss shared
 */

.hp-video {
    position: relative;
    img {
        width: 100%;
        &.small {
            display: none;
        }
        &.big {
            display: inline-block;
        }
    }
    .wrapper {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        &::before {
            content: " ";
            display: inline-block;
            vertical-align: middle;
            height: 100%;
        }
        > * {
            display: inline-block;
            vertical-align: middle;
            width: 33.3%;
        }
        h2 {
            text-align: right;
        }
        .watch-video {
            text-align: center;
            font-size: 2rem;
        }
    }
    @media screen and (max-width: $screen-tablet) {
        img.small {
            display: inline-block;
        }
        img.big {
            display: none;
        }
        .wrapper {
            position: relative;
            background-color: white;
            padding-bottom: 30px;
            text-align: center;
            > * {
                text-align: center !important;
            }
            h2 {
                min-width: 300px;
            }
            .watch-video {
                font-size: 1.4rem;
            }
        }
    }
    @media screen and (max-width: $screen-phone) {
        .wrapper {
            h2 {
                margin-bottom: 15px;
            }
        }
    }
}

.testimonial-window {
    transform: translateZ(0);
    overflow: hidden;

    blockquote {
    float: left;
    width: 8.333333333%;
    margin: 0;
    position: relative;

    padding: 20px;

    @media (min-width: $screen-sm-min)  {
        padding: 20px 40px;
    }

    img {
        margin-top: 10px;
        margin-right: 8px;
        margin-left: 8px;
        float: right;
        border: 8px solid white;
        width: 100px;

        @media (min-width: $screen-sm-min) {
            width: 180px;
        }
    }

    p {
        position: relative;
        margin-bottom: 10px;

        @media (min-width: $screen-sm-min)  {
            font-size: $font-size-s;

            &:before,
            &:after {
                position: absolute;
                color: lighten($scss-navy-04, 5);
                font-weight: bold;
                font-size: 70px;
                line-height: 1;
            }
            &:before {
                top: -10px;
                left: -40px;
                content: "\201c";
                transform: scale(-1, 1); // No right quote in avenir
            }
            &:after {
                top: -10px;
                right: -40px;
                content: "\201d";
            }
        }
    }

    footer {
        color: $navy-01;
        font-weight: normal;
        font-size: $font-size-s;
        text-align: center;
    }

}
}

.testimonial-wrapper {
    margin-left: -100%;
    width: 1200%;
}

.terms-list {
    margin-bottom: 25px !important;
    li {
        line-height: 2;
    }
}
@media (max-width: $screen-xs-max) {
    .col-xs-auto { width: auto; }
}
@media (min-width: $screen-sm-min) {
    .col-sm-auto { width: auto; }
}
@media (min-width: $screen-md-min) {
    .col-md-auto { width: auto; }
}
@media (min-width: $screen-lg-min) {
    .col-lg-auto { width: auto; }
}

.sticky-cta {
    position: fixed;
    top: $nav-height-small;
    background-color: lighten($scss-pastel-lighter-frost, 7%);
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
    height: 70px;
    padding: 10px 15px;

    .btn-primary-hero {
        font-size: $font-size-s;
        letter-spacing: 2px;
        line-height: 2;
        box-shadow: 0 2px 2px 0 rgba(0,0,0,0.1);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @include grid-blocks(4);
    }

    @include at-least($nav-max) {
        top: $nav-height;
        height: 90px;

        .btn-primary-hero {
            font-size: $font-size-m;
            line-height: 2;
        }
    }

    &[data-initial-state=show] ~ .main {
        margin-top: 120px;
        @include at-least($nav-max) {
            margin-top: 180px;
        }
    }
}

.static-cta {
    text-align: center;
    margin: 15px 0;
}

.sticky-padder {
    visibility: hidden;
}

.sticky-cta, .sticky-padder {
    width: 100%;
    z-index: 1;
    text-align: center;
    padding: 10px 15px;

    @include at-least($nav-max) {
        padding: 17px 15px;
        .btn {
            font-size: $font-size-m;
            line-height: 2;
        }
    }
}


.wet-food-banner,
.dental-dailies-banner,
.salmon-banner {
    @include grid-blocks(8);
    margin: 20px auto;
    color: $navy-01;
    font-size: 0;
    text-align: center;
    white-space: nowrap;

    &:before {
        content: "";
        height: 100%;
        @include inalign(middle);
    }

    .text {
        @include inalign(bottom);
        font-size: $font-size-body;
        padding: 30px 15px;
        margin-bottom: 15px;
        white-space: normal;

        *:nth-child(1) {
            font-weight: bold;
            font-size: $font-size-body;
            .icon {
                font-size: 1.8em;
                font-weight: normal;
            }
        }
        *:nth-child(2) {
            font-weight: bold;
            text-transform: uppercase;
            font-size: $font-size-m;
        }
        .btn {
            @include grid-blocks(3);
            margin-top: 15px;
        }
    }

    @include at-least($screen-tablet) {
        background-position: center;
        background-size: cover;

        .text {
            vertical-align: bottom;
            max-width: 400px;
            margin-bottom: 0;
            padding: 30px 15px;
            *:nth-child(1) {
                font-size: 2.4rem;
            }
            *:nth-child(2) {
                font-size: 34px;
            }
            *:nth-child(3) {
            }
        }
    }
}

.wet-food-banner {
    background-image: url(/static/images/treats/wet-food-banner-mobile.jpg);
    background-size: cover;
    background-position: center top;

    height: 60vw;
    min-height: 320px;
    @include at-least($screen-tablet) {
        background: url(/static/images/dashboard/wet-food-banner-desktop.jpg);
        background-position: -50px 0;
        background-size: cover;
        min-height: 0;
        height: 277px;
        text-align: right;
    }
}

.dental-dailies-banner {
    background: url(/static/images/dashboard/dental-dailies-banner-mobile.jpg);
    background-position: top center;
    background-size: cover;
    height: 320px;

    @include at-least($screen-tablet) {
    background: url(/static/images/dashboard/dental-dailies-banner-desktop.jpg);
        height: 277px;
        text-align: left;
    }
}

.salmon-banner {
    background: url(/static/images/dashboard/salmon-banner-mobile.gif);
    background-position: top center;
    background-size: cover;
    height: 320px;
    @include flexbox(row, center, flex-start);

    .text {
        height: 100%;
        width: 100%;
        padding: 10px;
        margin: 0;
        @include flexbox(column, center, flex-start);
        .title {
            font-weight: bold;
            flex-grow: 1;
        }
        .description {
            display: none;
        }
    }

    @include at-least($screen-tablet) {
        background: url(/static/images/dashboard/salmon-banner-desktop.gif);
        background-size: cover;
        background-position-x: -52px;
        height: 270px;
        padding: 10px;
        text-align: center;
        @include flexbox(row, center, flex-end);

        .text {
            background-color: $primary-white;
            height: 85%;
            width: 340px;
            padding: 10px;
            @include flexbox(column, center, space-between);
            .head {
                font-size: 34px;
            }
            .description {
                text-transform: lowercase;
                display: block;
                flex-grow: 1;
            }
        }
    }
}


.tails-view-dog-message-module {
    @include flexbox(row, flex-end, center);
        margin: 0 auto;
        margin-bottom: 40px;
    .dog {
        background-repeat: no-repeat;
        background-size: contain, cover;
        background-position: bottom;
        height: 65px;
        width: 65px;
        min-width: 45px;
        margin-right: 10px;
        @include at-least($screen-tablet) {
            height: 45px;
            width: 50px;
        }
    }
    .message {
        &.paper {
            background-color: $pastel-lighter-frost;
        }
        &.white {
            background-color: $primary-white;
        }
        padding: 15px;
        color: $navy-01;
        border-radius: 20px 20px 20px 0;
        margin: 0;
        text-align: left;
        a.close {
            @include flexbox(row, inherit, inherit);
            color: $navy-01;
            margin-top: 5px;
        }
    }
}

.increment-decrement.counter {
    display: inline-block;
    height: 100%;
    border-radius: 50px;
    padding: 8px;
    margin-bottom: 15px;
    vertical-align: middle;

    * {
        display: inline-block;
        vertical-align: middle;
    }

    .btn {
        padding: 0;
        min-width: 40px;
        height: 40px;

        span {
            margin-left: 0 !important;
            line-height: 40px;
            font-size: 2.4rem;
            width: auto;
        }
    }

    input, input[type="text"] {
        height: 40px;
        padding: 0;
        color: $secondary-03;
        text-align: center;
        width: 3em;
        font-size: 3rem;
        outline: none;
        border-bottom: 1px solid $navy-01;
    }

    input:focus, input[type="text"]:focus {
        box-shadow: none;
    }

    input:invalid, input[type="text"]:invalid {
        border-bottom-color: $product-error;
    }
}

body.error {
    .alert {
        text-align: center;
        .pill {
            margin-top: 15px;
            display: inline-block;
            color: $product-error;
            font-weight: bold;
            background-color: white;
        }
    }
}

@mixin pet-roundal-selector($size) {
    $border-width: 3px;

    text-align: center;

    .slider {
        white-space: nowrap;
        font-size: 0;
        overflow: hidden;
        padding: 5px 0;
        margin-left: auto;
        margin-right: auto;
    }

    .pet {
        @include pet-roundal($size);
        line-height: $size - $border-width;
        background: $primary-white;
        border: $border-width solid #e6e6e6;
        color: $navy-04;
        margin: 0 10px;
        vertical-align: top;
        position: relative;
        -webkit-transition: all 200ms;
        -moz-transition: all 200ms;
        -ms-transition: all 200ms;
        -o-transition: all 200ms;
        transition: all 200ms;

        &:hover {
            cursor: pointer;
            border-color: $navy-01;
            color: $navy-01;
        }

        &:before {
            @include icon($icon-check);
            line-height: $size - $border-width;
            font-size: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;

            -webkit-transition: all 200ms;
            -moz-transition: all 200ms;
            -ms-transition: all 200ms;
            -o-transition: all 200ms;
            transition: all 200ms;
        }

        &.success {
            background: $product-success-background;
            border-color: $product-success;
            color: $primary-white;
            font-size: 0;

            &:before {
                font-size: ($size / 2);
            }

            &:hover {
                cursor: pointer;
                border-color: $navy-01;
                color: $primary-white;
                background: $navy-01;
            }
        }

        &.selected {
            border-color: $navy-01;
            background: $navy-01;
            color: $primary-white;
        }
    }
}

.pet-roundal-selector {
    @include pet-roundal-selector(50px);
}

.cs-only {
    background-color: #ffda95 !important;
}

.cs-only-text {
    color: #ffda95 !important;
}

.sticky-push {
    top: 0;
}


input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #fff inset, 0 4px 0 -3px transparent; // thank you chrome for forcing a box shadow hack
}

// max width for content in account is set to 300px in _account.scss
// could change it there but unsure what the wider consequences could be so decided to play it safe
body.account.update-pet-details .main .content {
  max-width: unset;
}
.update-pet-details-header {
  text-align: start;
  font-size: $font-size-l;
  font-weight: 400;
  line-height: 32px;
}

body.update-pet-details {
  padding-top: 0;
  margin-top: 0;
  color: $navy-00;
  padding-right: 12px;
  padding-left: 12px;

  .btn-no-expand {
    width: auto;
  }

  .breed-wrap {
    @media (max-width: 767px) {
      select {
        max-width: 60%;
      }

      .btn-no-expand {
        min-width: unset;
      }
    }
  }

  &.pet-health-issues {
    margin-top: 0;

    ul {
      padding-inline-start: 0;
    }
  }

  .footer-navigation {
    a {
      padding: 0 10px;
    }
  }

}

body.update-name {
  h5.new-name {
   color: $primary-black;
  }
}

body.update_weight {
  h4.predicted-weight {
    font-family: $default-font-family;
    color: $primary-black;
  }
}

.dogname {
  text-align: center;
  font-weight: bold;
  font-size: 2rem;

  form {
    width: 100%;
    flex: 1;
    @include flexbox(column, center, center);

    .form-details {
      @include flexbox(row, center, center);
    }

    label {
      padding: 0 5px;
      font-size: .8em;
      flex: 1 0 28%;
    }
  }

  input {
    margin: 0 15px;
  }

  @include flexbox(row, flex-start, space-between);
  @include at-least($screen-tablet) {
    font-size: 2.4rem;
  }
  @include until($screen-phone) {
    flex-flow: column wrap;
    input {
      width: 100%;
      margin: 0;
    }
    .btn-primary {
      width: 60%;
    }
  }
  @include until($screen-tablet) {
    form, form .form-details {
      flex-direction: column;
      align-items: center;
    }
  }
}

.step-explanation {
  text-align: left;
  font-size: $font-size-body;
  padding: 0;
  margin: 20px 0;
  @include at-least($screen-desktop) {
    font-size: $font-size-body;
  }
}

.actions {
  @include flexbox(row, center, center);

  .btn {
    margin: 0 2px;
  }
}

.remove-breed.btn {
  &.invisible {
    visibility: hidden;
  }
}

.tails-view-weight-change {
  .form-group {
    text-align: center;
  }
}

.activity-level-wrapper {
  p {
    text-align: start;
  }

  margin-bottom: 40px;
}

.form.health,
.choose-exclusions {
  ul {
    list-style: none;
    padding: 0;

    li {
      min-height: 52px;
    }

    .checkbox-wrapper-alt {
      p {
        font-size: $font-size-s;
      }
    }
  }

  .tails-view-exclusions {
    position: relative;

    .text {
      text-align: center;
    }
  }

  .multiselect {
    text-align: center;
  }

  .with-tip {
    margin-left: 10px;
  }
}

.tails-view-textures {
  text-align: center;
}

//STYLING FOR FEEDING PREFERENCES
.feeding-preferences {
  @include grid-blocks(6);
  @include flexbox(column, flex-start, flex-start);
  margin: 0 auto;

  input[type=radio] {
    display: none;
  }

  .feeding-preference-wrapper {
    @include grid-blocks(6);
  }

  .option-dry-wet-treats, .option-dry-wet, .option-dry {
    @include grid-blocks(6);
    border-radius: 8px;
    padding: 15px;
    border: 2px solid $pastel-lighter-frost;
    @include at-least($screen-phone) {
      padding: 20px;
    }

    .title {
      font-size: $font-size-m;
      font-weight: bold;
    }
  }

  #dry-wet-treats:checked ~ .option-dry-wet-treats,
  #dry-wet:checked ~ .option-dry-wet,
  #dry:checked ~ .option-dry {
    background-color: $primary-white;
    border-radius: 8px;
    border: 1px solid $navy-01;
    box-shadow: 0 0 0 2px $secondary-02;
  }

  #dry-wet-treats:checked ~ .image-dry-wet-treats {
    display: block;
    height: 200px;
    margin-bottom: 10px;
    background: url('/static/images/products/feeding-preference/dry-wet-treats.png') no-repeat center bottom / contain;
    @include at-least($screen-phone) {
      height: 360px;
    }
  }

  #dry-wet:checked ~ .image-dry-wet {
    display: block;
    height: 200px;
    margin-bottom: 10px;
    background: url('/static/images/products/feeding-preference/dry-wet.png') no-repeat center bottom / contain;
    @include at-least($screen-phone) {
      height: 360px;
    }
  }

  #dry:checked ~ .image-dry {
    display: block;
    height: 200px;
    margin-bottom: 10px;
    background: url('/static/images/products/feeding-preference/dry.png') no-repeat center bottom / contain;
    @include at-least($screen-phone) {
      height: 360px;
    }
  }

  /* Styling for the multi select feeding prefs page */
  .step-out .image-wrapper {
    display: none;
  }

  &.multi {
    .image-dry {
      width: 200px;
      @include at-least($screen-phone) {
        width: 360px;
      }
    }

    .image-dry-only {
      display: block;
      height: 200px;
      margin-bottom: 10px;
      shape-outside: circle(50%);
      background: url('/static/images/consultation/feeding-preferences/Dry.png') no-repeat center / contain;
      @include at-least($screen-tablet) {
        display: none;
      }
    }

    #feeding_pref_dry-checkbox:before {
      @include icon($icon-check);
      line-height: 45px;
    }

    .checklist-wrapper {
      padding: 0;
    }

    .checkbox-wrapper-alt {
      margin: 10px auto;
    }

    .checklist-wrapper li label {
      min-height: 70px;
      vertical-align: middle;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0;
    }

    .checklist-wrapper li label span {
      font-weight: bold;
      color: $primary-colour;
    }

    .checklist-wrapper li input:disabled ~ label span {
      color: $primary-colour;
    }


    @keyframes fp-slide {
      from {
        right: -1500px;
        left: 1500px;
        opacity: 1;
      }
      to {
        opacity: 1;
      }
    }
    @keyframes fp-slide-left {
      from {
        right: 1500px;
        left: -1500px;
        opacity: 1;
      }
      to {
        opacity: 1;
      }
    }
    @keyframes fp-fadeout {
      from {
        opacity: 1;
        display: block;
      }
      to {
        opacity: 0;
        display: none;
      }
    }

    .image-wrapper {
      position: relative;
      width: 250px;
      margin: auto;
      @include at-least($screen-tablet) {
        width: 360px;
      }
    }

    .image {
      position: absolute;
      height: 120px;
      width: 120px;
      opacity: 0;
      display: none;
      animation: fp-fadeout 0.5s ease-in-out;
    }

    .image-wet {
      top: -375px;
      left: 0;
      background: url('/static/images/consultation/feeding-preferences/wet\ tray_top.png') no-repeat center / contain;
    }

    .image-wet-alt {
      top: -310px;
      left: -35px;
      z-index: -1;
      background: url('/static/images/consultation/feeding-preferences/wet\ tray_behind.png') no-repeat center / contain;
    }

    .image-treats {
      top: -435px;
      right: -40px;
      height: 200px;
      background: url('/static/images/consultation/feeding-preferences/Treats\ group.png') no-repeat center / contain;
    }

    @include at-least($screen-tablet) {
      .image-wet {
        top: -450px;
        left: 600px;
        width: 200px;
        height: 200px;
        transform: rotate(35deg);
      }
      .image-wet-alt {
        top: -370px;
        left: 680px;
        width: 180px;
        height: 180px;
        transform: rotate(10deg);
      }
      .image-treats {
        top: -360px;
        right: -520px;
        width: 400px;
        height: 300px;
        transform: rotate(75deg) scaleY(-1);
      }
    }

    /* Animations */
    #feeding_pref_wet:checked ~ .image-wrapper .image-wet,
    #feeding_pref_wet:checked ~ .image-wrapper .image-wet-alt,
    #feeding_pref_treats:checked ~ .image-wrapper .image-treats {
      opacity: 1;
      display: block;
      animation: fp-slide 0.6s ease-in-out;
    }

    @include until($screen-tablet) {
      #feeding_pref_wet:checked ~ .image-wrapper .image-wet,
      #feeding_pref_wet:checked ~ .image-wrapper .image-wet-alt {
        animation: fp-slide-left 0.6s ease-in-out;
      }
    }

    .pill {
      border-radius: 2rem;
      background: $pastel-lighter-frost;
      font-size: $font-size-xs;
      padding: 0.2rem 1.5rem;
      font-weight: normal !important;
    }
  }
}

@include at-least($screen-tablet) {
  #form_feeding-preference-multi {
    .image-dry-only-desktop {
      float: right;
      height: 400px;
      width: 400px;
      margin: -65px -200px 10px 0;
      shape-outside: circle(50%);
      background: url('/static/images/consultation/feeding-preferences/Dry.png') no-repeat center / contain;
      display: none;
      @include at-least($screen-tablet) {
        display: block;
      }
    }

    .control-wrapper {
      .headline-main {
        min-height: 200px;
        display: flex;
        align-items: center;
      }

      display: inline-block;
      width: calc(100% - 200px);
    }
  }
}

.update-gender {
  .question {
    text-align: left;
    display: block;
    margin: auto;
    float: none;
    font-size: $font-size-body;
    @include at-least($screen-tablet) {
      font-size: $font-size-body;
    }
    margin-bottom: 20px;

    .radio-buttons {
      @include flexbox(column, null, flex-start);

      .radio-toggle-button {
        margin: 0;
        padding-top: 10px;
        line-height: 1.5;
        text-align: left;

        label {
          span {
            font-weight: normal;
            padding-left: 50px;
          }
        }
      }
    }

    .toggle-buttons {
      width: 100%;

      .toggle-button {
        width: 50%;
      }
    }
  }

  &.flow-puppy {
    + .explanation .description {
      display: inline;
    }
  }

  .as-child {
    display: flex;
    flex-direction: row;

    .vl {
      border-left: 4px solid $secondary-02;
      margin-left: 11px;
      height: inherit;
    }

    p {
      margin-top: -5px;
    }

    p, .radio-buttons {
      margin-left: 35px;
    }
  }

  .neutered, .nursing, .male, .female, input[type=radio] {
    display: none;
  }

  #male:checked ~ .gender label[for=male],
  #female:checked ~ .gender label[for=female],
  #neutered-no:checked ~ .neutered label[for=neutered-no],
  #nursing-yes:checked ~ .nursing label[for=nursing-yes] {
    @include radio_toggle_on;
  }

  #male:checked ~ .neutered .male {
    display: block;
  }

  #female:checked ~ .neutered .female {
    display: block;
  }

  #male:checked ~ .neutered {
    display: block;
  }

  #female:checked ~ .neutered {
    display: block;
  }

  #neutered-no:checked ~ .nursing {
    display: block;
  }

  #neutered-yes:checked ~ .nursing {
    display: none;
  }

  #male:checked ~ .nursing {
    display: none;
  }

  .warning {
    display: none;
    color: $primary-colour;
  }

  #nursing-yes:checked ~ .nursing .warning {
    display: block;
  }

  .warning {
    margin-top: 30px;

  }
}

.flavours-list, .textures-list {
  text-align: left;

  .checkbox-wrapper-alt > label .text, ul.checklist-wrapper {
    padding-left: 0;
  }
}

//STYLING FOR THE BUILD RECIPE STEP AB TEST
#form_build-recipe {
  .section {
    border-radius: 8px;
    padding: 25px 20px 20px 20px;
    margin: 20px 0;

    input[type=radio] {
      display: none;
    }

    &.exclusions {
      background-color: $pastel-lighter-frost;

      #exclusions-yes:checked ~ .radio-buttons #exclusions-yes-label,
      #exclusions-no:checked ~ .radio-buttons #exclusions-no-label {
        @include radio_toggle_on;
      }

      #exclusions-yes:checked ~ .hypoallergenic {
        display: block;
      }
    }

    &.hypoallergenic {
      display: none;
      margin-top: 0;
      padding: 10px 0 0 0;

      #yes:checked ~ .radio-buttons #hypo-yes-label,
      #no:checked ~ .radio-buttons #hypo-no-label {
        @include radio_toggle_on;
      }

      #yes:checked ~ .preference-choices {
        display: block;
      }
    }

    &.preferences {
      background-color: $pastel-lighter-lavender;

      #preferences-yes:checked ~ .radio-buttons #preferences-yes-label,
      #preferences-no:checked ~ .radio-buttons #preferences-no-label {
        @include radio_toggle_on;
      }

      #preferences-no:checked ~ .preference-choices {
        display: none;
      }

      #preferences-yes:checked ~ .preference-choices {
        display: block;
      }
    }

    &.flavours {
      background-color: $secondary-03;
    }

    .multi-select {
      margin-top: 20px;
    }
  }

  .dog-img {
    height: 80px;
    display: flex;
    margin-bottom: -45px;

    &.left {
      margin-right: auto;
    }

    &.right {
      margin-left: auto;
    }
  }

  .question {
    margin: 5px 0 20px 0;
  }
}

body.raf-home {
  .content {
    padding-bottom: 20px;

    .container {
      padding: 0;

      .section {
        @media (max-width: $screen-phone) {
          &.header-v4 {
            display: none !important;
          }

          &.header-v4-mobile {
            display: block !important;
          }
        }

        &.header {
          @include flexbox(column, center, flex-start);
          .header-content {
            width: 100%;
            .header-copy {
              text-align: center;
              padding: 40px 20px 0 20px;
            }
            img {
              width: 100%;
              margin-top: -30px;
            }
          }

          @include at-least($screen-tablet) {
            @include flexbox(row, flex-start, flex-end);
            background-image: url('/static/images/fyf/v2/HP-hero-_2x.jpg');
            background-repeat: no-repeat;
            background-position-x: right;
            background-size: cover;
            height: 500px;
            .header-content {
              width: 600px;
              padding-right: 80px;
              .header-copy {
                padding: 40px 0 0 0;
              }
              img { display: none; }
            }
          }

          h1 {
            font-family: $handwritten-font-family;
          }
          p {
            font-size: $font-size-m;
          }
        }

        &.header-v4 {
          .header-copy {
            h1 {
              font-size: $font-size-xxl;
            }
          }

          @include at-least($screen-tablet) {
            background-image: url('/static/images/fyf/v4/Hero-Yellow-Desktop.png') !important;
            height: 298px !important;

            .header-content {
              padding-right: 0 !important;
            }

            .header-dogs {
              width: 600px;
              padding-left: 131px;
              display: flex;
              justify-content: center;
              align-items: center;
              flex: 1;
              position: relative;

              .raf-v4-dog-widget {
                background-color: transparent !important;

                &:first-child {
                  transform: translateX(15px);
                }

                &:last-child {
                  transform: translateX(-10px);
                }

                .copy-box {
                  padding: $spacing-xs $spacing-xs $spacing-s $spacing-xs
                }

                .copy {
                  font-weight: 400;
                  font-size: $font-size-s;
                }

                .discount-box {
                  text-align: center;
                }
              }

              .arrow-widget-img {
                z-index: 1;
                transform: translateY(25px);
              }
            }
          }

          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        &.header-v4-mobile {
          background-image: url('/static/images/fyf/v4/Hero-Yellow-Mobile.png') !important;
          background-size: contain;
          background-repeat: round;
          height: 351px;

          .header-dogs {
            display: flex;
            justify-content: space-between;
            padding-top: 41px;
            padding-left: 31px;
            padding-right: 31px;

            .raf-v4-dog-widget {
              background-color: transparent !important;

              .copy-box {
                padding: $spacing-xs $spacing-xs $spacing-s $spacing-xs
              }

              .copy {
                font-weight: 400;
                font-size: $font-size-s;
                text-align: center;
              }

              .discount-box {
                text-align: center;
              }
            }
          }

          .header-content {
            margin-top: $spacing-m;
          }
        }

        &.feature-alternate {
          @include flexbox(column-reverse, flex-start, center);
          margin: 0 auto;
          padding: 30px;
          h3 {
            margin-bottom: 16px;
          }
          img {
            width: 100%;
            padding: 0;
          }
          div {
            padding: 0 0 30px 0;
          }

          @include at-least($screen-tablet) {
            @include grid-blocks(10);
            @include flexbox(row, flex-start, center);
            margin: 0 auto;
            padding: 30px;
            img { width: 400px; }
            div { padding: 0 30px; }
          }
          &.row-reverse {
            @include at-least($screen-tablet) {
              @include flexbox(row-reverse, flex-start, center);
            }
          }
          &.bg-pastel-lighter-beige {
            background-color: $pastel-lighter-beige;
          }
          ol {
            margin: 0.7rem 0 0 -10px;
            list-style-type: none;
            counter-reset: mycounter;
            li {
              &::before {
                content: "" counter(mycounter) " ";
                counter-increment: mycounter;
                margin: 2.5px 0 0 -25px;
                position: absolute;
                color: $secondary-04;
                font-family: $handwritten-font-family;
                font-size: $font-size-m;
              }
              &:nth-of-type(9)~li::before {
                content: counter(mycounter) " ";
              }
            }
          }
        }

        &.top-tips {
          padding: 30px;
          h3, h5, p {
            text-align: left;
          }
          h3 {
            margin-bottom: 16px;
          }
          @include at-least($screen-tablet) {
            @include grid-blocks(10);
            margin: 0 auto 30px auto;
            h3, h5, p {
              text-align: center;
            }
          }
          .features-wrapper {
            @include at-least($screen-tablet) {
              @include flexbox(row, center, space-between);
            }
            .feature {
              @include flexbox(column-reverse, center, space-between);
              padding: 10px 0;

              @include at-least($screen-tablet) {
                @include flexbox(column, center, space-between);
                padding: 10px;
              }

              h5 {
                width: 100%;
              }

              img {
                width: 100%;
                margin-bottom: 20px;
                @include at-least($screen-tablet) {
                  width: 280px;
                }
              }
            }
          }

          &.top-tips-v4 {
            padding-top: 52px !important;

            @media (max-width: $screen-phone) {
              h3 {
                text-align: center !important;
              }
            }

            .features-wrapper {
              .feature {
                flex-direction: column;

                img {
                  width: 210px;
                }

                div {
                  h5, p {
                    text-align: center;
                  }
                }
              }
            }
          }
        }

        &.credit-values {
          margin: 30px auto;
          padding: 20px;
          text-align: center;
          @include at-least($screen-tablet) {
            padding: 30px 80px;
            width: 600px;
          }
          .credit_info {
            &.claim {
              button {
                padding: 10px 30px;
                font-weight: bold;
                text-transform: uppercase;
                margin-bottom: 20px;
              }
            }
            &.balance {
              p {
                margin: 0;

                &:first-child {
                  margin-bottom: 10px;
                }
              }
            }
          }
        }

        &.credit-values-v4 {
          background-color: $pastel-lavender;
          display: flex;
          text-align: center;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          @include at-least($screen-tablet) {
            padding: 0 80px;
            width: 100%;
            min-height: 232px;
          }
          .credit_info {
            @media (max-width: $screen-phone) {
              padding: 53px 12px 35px;
            }

            &.claim {
              h3 {
                font-size: 20px;
              }

              button {
                padding: 10px 30px;
                font-weight: bold;
                text-transform: uppercase;
                margin-bottom: 20px;
              }
              
              p {
                font-size: $font-size-xs;
              }
            }
            &.balance {
              padding-top: 0;

              p {
                margin: 0;

                &:first-child {
                  margin-bottom: 10px;
                }
              }
            }

            h3 {
              margin-bottom: 9px;
            }

            h2 {
              margin-bottom: 13px;
            }
          }
        }

        &.terms {
          padding: 0 20px;
          p {
            text-align: center;
          }

          &.terms-v4 {
            padding-bottom: 68px !important;
          }
        }

        &.share-widget {
          background-color: $pastel-lavender;
          .raf-widget.tails-view-fyf-share {
            background-color: $pastel-lavender !important;
          }
          .raf-widget-header, hr {
            display: none;
          }
          .raf-widget-code h5, .raf-widget-share h5 {
            font-weight: 400;
            font-size: $font-size-l;
          }
        }

        &.share-widget-v4 {
          .raf-widget {
            padding: 0 80px;

            @media (max-width: $screen-phone) {
              padding-left: 12px;
              padding-right: 12px;
            }

            h5 {
              margin-bottom: 0 !important;
            }

            .raf-widget-code {
              padding-top: 46px;
              padding-bottom: 68px;
            }
          }

          .raf-widget.tails-view-fyf-share {
            display: flex;
            text-align: center;
            align-items: center;
            justify-content: center;
          }
          .raf-widget-header, hr {
            width: 100%;
          }

          hr {
            margin: 0;
          }

          .raf-widget-code {
            width: 100%;

            @include at-least($screen-tablet) {
              padding: 0 200px 0 200px
            }

            @media (max-width: $screen-phone) {
              hr {
                display: none;
              }

              .native-share {
                display: block !important;
              }
            }
          }

          .raf-widget-code h5, .raf-widget-share h5 {
            font-weight: 400;
            font-size: $font-size-l;
          }

          .raf-widget-code-header h5, .raf-widget-code-footer h5 {
            font-family: $handwritten-font-family;
          }

          .raf-widget-code-footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 36px;

            @media (max-width: $screen-phone) {
              display: none;
            }

            .share-socials-footer {
              @include at_least($screen-tablet) {
                flex: 0 1 calc(50% - 10px);

                & + .flex-item {
                  margin-left: 20px;
                }
              }

              img {
                width: 45px;
                margin: 0 5px 0 0;
                cursor: pointer;
              }

              span {
                font-size: $base-font-size;
                font-weight: 400;
                text-align: left;
              }

              &.share-socials-icon {
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                  margin-left: 7px;
                }

                span {
                  margin-left: 16px;
                  margin-right: 10px;
                }
              }
            }
          }

          .raf-widget-code-share {
            display: flex;
            width: 100%;
            text-align: left;
            align-items: center;
            justify-content: space-between;

            .raf-widget-your-code {
              border: 0;
              margin-bottom: 26px !important;

              @include at_least($screen-tablet) {
                flex: 0 1 calc(50% - 10px);

                & + .flex-item {
                  margin-left: 20px;
                }
              }

              @media (max-width: $screen-phone) {
                width: 100%;

                &:nth-child(2), .share-message {
                  display: none !important;
                }
              }

              h5 {
                text-align: left;
                font-size: $font-size-s;
                font-weight: 500;
              }

              .share-message {
                font-size: $base-font-size;
                font-weight: 400;
              }

              .input-container {
                position: relative;

                input {
                  background-color: $pastel-lighter-silver;
                  width: 100%;
                  max-width: 100% !important;
                  padding: 0 70px 0 10px !important;
                  box-sizing: border-box;
                  margin-bottom: $spacing-xxs;

                  @media (max-width: $screen-phone) {
                    padding: 0 100px 0 10px !important;
                  }

                  &::placeholder {
                    opacity: 0.5;
                  }

                  &[value] {
                    &::after {
                      content: '';
                      white-space: nowrap;
                      display: inline-block;
                      width: 0.5em;
                      overflow: hidden;
                      vertical-align: bottom;
                      text-overflow: ellipsis;
                    }
                  }
                }

                .copy-link, .copied-link {
                  position: absolute;
                  top: 35%;
                  right: 10px;
                  color: $primary-colour;
                }
              }
            }
          }
        }
      }
    }
  }
}

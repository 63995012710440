.product-shot-hero {
  @include grid-blocks(12);
  display: block;
  color: #41444b;

  //MOBILE
  .product-shot-hero-img {
    background: center / cover no-repeat url('/static/images/consultation/product-shot-hero/1280x560_hero_dry.jpg');
    width: 100%;
    height: 183px;
    -webkit-clip-path: circle(700px at 50% -518px);
    clip-path: circle(700px at 50% -518px);
    position: relative;

    &.dry-wet {
      background-image: url('/static/images/consultation/product-shot-hero/1280x560_hero_mixed.jpg');
    }
    &.dry-wet-treats {
      background-image: url('/static/images/consultation/product-shot-hero/1280x560_hero_dry_wet_treats.jpg');
    }

    &.premium-wet {
      background-image: url('/static/images/consultation/product-shot-hero/1280x560_hero_mixed_premium.jpg');
    }
    &.dry-wet-treats.premium-wet {
      background-image: url('/static/images/consultation/product-shot-hero/1280x560_hero_dry_wet_treats_premium.jpg');
    }
  }

  .product-shot-hero-text {
    position: absolute;
    font-weight: bold;

    top: 23%;
    left: calc(50% - 10px);
    width: auto;

    display: inline-block;
    transform: rotate(-0.24rad);
  }

  .dry-wet .product-shot-hero-text {
    top: 24%;
    left: calc(50% - 6px);

    transform: rotate(-0.045turn);
  }

  .dry-wet-treats .product-shot-hero-text {
    top: 26%;
    left: calc(50% - 7px);

    transform: rotate(-0.049turn);
  }

  .premium-wet .product-shot-hero-text {
    top: 34%;
    left: calc(50% - 9px);

    transform: rotate(-0.045turn) scale(0.9);
  }

  // TABLET
  @include at-least($screen-tablet) {
    .product-shot-hero-img {
      height: 360px;
      width: 680px;
      background-size: cover;
      background-position: 65% 50%;
      -webkit-clip-path: circle(470px at 30% -31%);
      clip-path: circle(470px at 30% -31%);
      z-index: -1;
    }

    .product-shot-hero-text {
      text-transform: capitalize;
      top: 25%;
      left: 33%;
    }

    .dry-wet .product-shot-hero-text {
      top: 27%;
      left: 34%;
    }

    .dry-wet-treats .product-shot-hero-text {
      top: 28.5%;
      left: 33.5%;
    }

    .premium-wet .product-shot-hero-text {
      top: 37%;
      left: 38.5%;
    }
  }
}

@mixin grey-line() {
  content: "";
  width: 2px;
  height: 91%;
  background: rgba(0, 0, 0, .1);
  position: absolute;
  top: 5px;
  left: 80px;
  @include until(900px) {
    display: none;
  }
}

body.account {
  .main .content {
    max-width: 300px;
    @media (min-width: 475px) {
      max-width: 640px;
    }
    @media (min-width: $screen-desktop) {
      max-width: unset;
    }
  }

  .slick-dots li button {
    background-color: $primary-colour;
    width: 24px;
    height: 24px;
  }

  .slick-dots li.slick-active button {
    background-color: $secondary-01;
  }

  .feedback {
    background-color: tint($scss-secondary-03, 50);
    padding: 10px;
  }

  .alerts {
    padding-left: 0;
    padding-right: 0;

    .alert-error {
      width: 100%;
      height: auto;
    }
  }

  .edit-gender {
    .radio-buttons {
      padding: 20px 0;
      text-align: left;
    }
  }

  input[type='submit'] {
    display: inline-block;
  }
}

body.account.pets {
  background: none;

  a {
    text-decoration: none;
    outline: none;
  }

  .sticky-header {
    //display: none;
    @include transform(translate(0, -100%));
    @include transition(all 0.3s);

    &.show {
      //display: block;
      @include transform(translate(0, 0));
    }

    &.passed {
      @include transform(translate(0, -100%));
    }

    @include position(fixed, $nav-height 0 null 0);
    @include until($nav-max) {
      @include position(fixed, $nav-height-small 0 null 0);
    }
    z-index: 1;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
    background-color: $pastel-darker-frost;
    text-align: center;
    padding: 5px 0;

    .pet-header {
      color: $navy-01;
      @include transition(opacity 0.3s);

      &.transition {
        opacity: 0;
      }

      .roundal {
        @include inalign(middle);
        @include size(48px);
        line-height: 55px;
        font-size: 4.5rem;
        margin-right: 10px;
      }

      .name {
        @include inalign(middle);
        text-transform: none;
        font-size: $font-size-m;
        font-weight: bold;
        margin: 0;
      }
    }
  }

  .date-box {
    text-transform: none;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.05);

    .month {
      font-size: 1.4rem;
      background-color: $navy-01;
    }

    .weekday {
      font-size: 1.4rem;
    }

    .day {
      font-size: 2.4rem;
      padding: 5px 20px;
    }

    @include until($screen-tablet) {
      .month {
        font-size: 1.4rem;
      }
      .weekday {
        font-size: 1.4rem;
      }
      .day {
        font-size: 2.4rem;
      }
      .dash {
        margin: 0 0.1em;
      }
    }
  }

  .section.messages {
    padding: 0;
    margin-top: 110px;

    .alerts {
      text-align: center;
      padding: 0;
      white-space: nowrap;

      .alert {
        display: inline-block;
        @include grid-blocks(6);
        margin: 15px 10px;
        width: calc(100% - 20px);
        z-index: 2;

        .icon {
          @include inalign(top);

          &:before {
            font-size: 50px;
            @include icon($icon-exclamation-circle);
            color: $primary-white;
          }
        }

        .message {
          @include inalign(top);
          white-space: normal;
          text-align: left;
          padding-left: 15px;
          width: calc(100% - 65px);

          .action {
            padding-top: 15px;
          }

          @include at-least($screen-tablet) {
            text-align: center;
          }

        }
      }
    }
  }

  .section.notifications {
    text-align: center;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
    padding-bottom: 30px;

    .slick-slider {
      margin-bottom: 0;
    }

    .slick-list {
    }

    .slick-dots {
      padding: 0;
      margin: 0;
      background-color: $primary-white;
    }

    .wrapper {
      @include inalign(middle);
      width: 100%;
      outline: none;
      white-space: normal;
      @include user-select(none);
      background-color: mix($scss-product-error, white, 10);

    }

    .hp-banner {
      display: inline-block;
      position: relative;
      outline: none;

      padding: 10px;
      line-height: 1;
      text-align: center;

      &:link, &:visited, &:active, &:hover {
        text-decoration: none;
      }

      &.fyf {
        @include flexbox(row, center, center);
        @include grid-blocks(12);
        margin: auto;

        .logo {
          max-width: 140px;
        }

        .text {
          padding-left: 15px;

          > * {
            color: $product-error;
          }

          .hidden-xs {
            display: none;
            margin: 8px 0 0 0;
          }

          @include at-least($screen-tablet) {
            .hidden-xs {
              display: block;
            }
          }
        }
      }

      &.fyf-xmas-postcard {
        background-color: #f9f9f9;

        img {
          max-width: 100%;

          &.desktop {
            display: none;
          }

          @include at-least($screen-desktop) {
            &.desktop {
              display: block;
            }
            &.mobile {
              display: none;
            }
          }
        }
      }
    }
  }

  .section.delivery-details {
    background-color: $pastel-lighter-frost;
    width: 100%;
    overflow: hidden;

    .slider {
      white-space: nowrap;
      @include transition(all 0.3s ease-out);

      .next-delivery {
        display: inline-block;
        vertical-align: middle;
        width: 100%;

        .for-pets-string {
          font-size: 2.4rem;
        }

        .for-pets {
          display: inline-block;
          background-color: $primary-white;
          border-radius: 50px;
          padding: 10px;

          .roundal {
            @include size(50px);
            @include inalign(middle);
            opacity: .2;
            font-size: $font-size-body;
            font-weight: bold;
            cursor: pointer;
            @include filter(grayscale(75%));
            @include transition(all 0.3s);

            &:hover {
              opacity: .5;
              @include filter(grayscale(50%));
            }

            &.selected {
              opacity: 1;
              @include filter(grayscale(0%));
            }
          }
        }

        .row {
          max-width: $container-max-width;
          margin: auto;

          .block {
            .header {
              .icon {
                font-size: 60px;
                margin: 20px;
              }
            }

            &.window {
              .icon {
                color: $secondary-01;
              }

              .month {
                background-color: $secondary-01;
              }
            }

            &.deadline {
              .icon {
                color: $product-error;
              }

              .month {
                background-color: $product-error;
              }
            }

            @include until($screen-tablet) {
              white-space: nowrap;
              margin-bottom: 10px;
              .header {
                text-align: left;
                display: inline-block;
                vertical-align: middle;
                width: 50%;

                .icon {
                  display: inline-block;
                  vertical-align: middle;
                }

                .icon {
                  font-size: $font-size-l;
                  margin: 0;
                }
              }
              > * {
                @include inalign(middle);
              }
              .date {
                width: 50%;
                text-align: right;

                .dash {
                  margin: 0;
                  font-size: $font-size-s;

                  &:before {
                    content: "to";
                    font-family: $default-font-family;
                    font-size: $font-size-s;
                    font-weight: normal;
                    line-height: 1;
                    color: $navy-01;
                  }
                }
              }
            }
          }
        }

        .btn {
          margin-top: 20px;
        }
      }
    }
  }

  .section.pets {
    text-align: center;
    padding: 0;
    width: 100%;
    overflow: hidden;

    .welcome {
      background-color: $secondary-01;
      width: 100%;
      color: $primary-white;
      padding: 10px 0;
    }

    > .header {
      background-color: $primary-white;
      background-size: cover;
      padding-top: 80px;
      padding-bottom: 90px;
    }

    .slick-dots {
      margin: 0;
    }

    .pet-header {
      margin-bottom: 0;

      color: $navy-01;
      background-color: $primary-white;

      .roundal {
        margin: auto;
        margin-top: 27px;

        .action {
          display: block !important;
        }
      }

      .roundal-background {
        width: 100%;
        height: 90px;
        margin-top: -75px;
        background-color: $pastel-lighter-frost;
      }

      .slick-dots {
        background-color: $pastel-lighter-frost;
      }

      .subheader {
        max-width: $scss-container-max-width / 2;
        margin: auto;
        display: table;

        .name {
          max-width: $scss-container-max-width / 2;
          display: table-cell;
          width: 100%;
          vertical-align: middle;
          overflow-wrap: break-word;
          color: $primary-colour;
        }

        .action {
          display: table-cell;
          vertical-align: middle;
          color: $primary-colour;
          font-size: 54px;
          padding: 0;
          cursor: pointer;
          @include until($screen-tablet) {
            font-size: 2.8rem;
          }
        }
      }
    }

    .pets-wrapper {
      @include transition(all 0.3s ease-out);
      white-space: nowrap;
      margin-bottom: 0;
      background-color: $pastel-lighter-frost;

      &.slick-initialized {
        cursor: pointer;
        cursor: move;
        cursor: hand;
        cursor: grab;
        cursor: -moz-grab;
        cursor: -webkit-grab;
        outline: none;

        &.grabbing {
          cursor: grabbing;
          cursor: -moz-grabbing;
          cursor: -webkit-grabbing;
        }
      }

      .pet {
        @include inalign(top);
        white-space: normal;
        padding-bottom: 30px;
        width: 100%;
        outline: none;

        &.first.last {
          .action {
            display: none;
          }
        }

        &[data-status=pause], &[data-status=cancelled], &[data-status=complete] {
          .roundal {
            background-color: $navy-04 !important;
          }
        }

        .segment {
          max-width: ($scss-container-max-width / 12) * 8.5;
          margin: auto;
          margin-top: 30px;
          width: 90%;
          border-radius: 4px;
          overflow: hidden;
          background-color: $primary-white;
          font-size: $font-size-body;
          @include until($screen-tablet) {
            font-size: $font-size-body;
            margin-top: 0;
          }

          h5 {
            font-size: $font-size-l;
            @include until($screen-tablet) {
              font-size: $font-size-l;
            }
          }

          a {
            font-size: $font-size-body;
            @include until($screen-tablet) {
              font-size: $font-size-body;
            }
          }

          > .header {
            @include inalign(top);
            background-color: $pastel-lighter-frost;
            display: table-cell;
            padding: 20px 10px 10px;
            @include flexbox(row, center, space-between);
            flex-wrap: wrap;
            width: 100%;
            @include at-least($screen-tablet) {
              text-align: right;
            }

            .edit-text {
              text-decoration: underline;
              font-weight: normal;
            }
          }

          > .info {
            position: relative;
            padding: 20px;
            background-color: $primary-white;

            &.restart {
              text-align: center;

              .btn {
                margin-top: 0;
              }
            }

            .blocks {
              display: table;
              @include flexbox(row, null, space-around);

              .block {
                display: flex;
                flex-direction: column;
              }
            }

            .bold {
              font-weight: bold;
            }

            .edit {
              text-decoration: none;
              @include until($screen-tablet) {
                display: none;
              }
              position: absolute;
              top: 10px;
              right: 10px;

              &:before {
                @include icon($icon-edit-circle);
                color: $secondary-03;
                font-size: 3.2rem;
              }
            }

            .portion {
              .icon.type {
                font-size: 50px;
                font-weight: normal;
              }

              > * {
                @include inalign(middle);
              }

              &.no-margin-top {
                @include at-least($screen-tablet) {
                  margin-top: 0;
                }
              }
            }

            .blocks {
              flex-direction: column;
              flex-wrap: nowrap;

              .block {
                flex-wrap: nowrap;
                flex-direction: column;
                @include until($screen-phone) {
                  @include flexbox(row, center, space-between);
                  @include align-items(center);
                }

                > .change-wet-food, > .last-updated {
                  margin-top: 10px;
                  @include until($screen-tablet) {
                    display: block;
                  }

                  .link {
                    display: inline-block;

                  }
                }
              }
            }

            .link {
              display: block;
            }

            @include until($screen-tablet) {
              display: block;
            }
          }

          > .info-left {
            display: flex;

            .image.wet-food-image {
              width: 130px;
              height: 130px;
            }

            .information-box {
              margin: auto auto auto 20px;

              .link {
                display: block;
              }
            }
          }

          @include until($screen-tablet) {
            border-radius: 0;
          }

          &.dry-food, &.wet-food, &.treats, &.condition {
            max-width: ($scss-container-max-width / 12) * 5.5;
            text-align: left;
          }

          &.dry-food, &.wet-food, &.treats {
            .icons-info {
              margin-right: 10px;
            }
          }

          &.dry-food {
            .portion .icon.type {
              color: $navy-01;
              font-size: 34px;
            }

            .portion {
              @include until($screen-phone) {
                width: 100%;
                justify-items: center;
                align-items: center;

                .icon-times {
                  margin: auto 20px auto 10px;
                  font-size: $font-size-xs;
                }
              }

              span {
                @include at-least($screen-tablet) {
                  margin-right: 10px;
                }
              }
            }
          }

          &.wet-food {
            .portion .icon.type {
              color: $navy-01;
              font-size: 26px;
            }
          }

          &.treats {
            .icon.type {
              font-size: 34px !important;
              color: #89C4F4;
            }

            .amount {
              line-height: 0.8 !important;
              padding: 12px 0;

              .packs {
                font-size: 1.4rem;
                text-transform: uppercase;
                font-weight: normal;
              }
            }
          }

          &.condition {
            .blocks {
              flex-direction: column;
              flex-wrap: nowrap;

              .block {
                @include align-items(center);
                @include until($screen-phone) {
                  @include flexbox(row, center, space-between);
                }
                flex-wrap: nowrap;
                flex-direction: row;
                justify-content: space-around;
              }
            }

            .kg {
              min-width: 60px;
              height: 60px;
              border-radius: 50%;
              background-color: $secondary-02;
              color: $primary-colour;
              text-align: center;
              line-height: 60px;
              margin-right: 15px;
            }

            .weight, .condition {
              margin: 10px;
              text-align: center;
              @include until($screen-phone) {
                flex-wrap: wrap;
              }
              @include inalign(middle);

              .icon {
                font-size: 35px;
                @include inalign(middle);
              }

              > img {
                height: 73px;
                width: 73px;
              }

              > * {
                @include inalign(middle);
              }

              .block-description {
                display: block;
                text-align: center;
                margin-top: 7px;

                div:nth-of-type(1) {
                  font-weight: bold;
                }
              }
            }

            .condition {
              .icon {
                font-size: 80px;
                margin-top: -25px;
              }
            }
          }

          &.delivery {
            .info {
              padding: 20px;
            }

            .blocks {
              @include flexbox(row, start, space-between);
              text-align: center;
              width: 100%;

              .mobile-only.bardiv {
                border-top: 2px solid $pastel-lighter-frost;
                padding: 0;
                width: 170px;
                margin: auto;
                @include at-least($screen-phone) {
                  display: none;
                }
              }

              .block {
                text-align: -webkit-center;
                text-align: -moz-center;

                .icon {
                  font-size: 35px;
                  width: 60px;
                  height: 60px;
                  border-radius: 50%;
                  background-color: $secondary-02;
                  color: $primary-colour;
                  text-align: center;
                  line-height: 60px;
                  @include at-least($screen-tablet) {
                    font-size: 45px;
                    width: 80px;
                    height: 80px;
                    line-height: 80px;
                  }
                }

                .infobox {
                  @include at-least($screen-tablet) {
                    padding-top: 10px;
                  }

                  .date {
                    font-weight: bold;
                  }
                }

                &.payment {
                  outline: none;
                  color: $navy-01;

                  .icon {
                    background-color: $pastel-darker-frost
                  }
                }

                &.window {
                  outline: none;
                  color: $navy-01;

                  .message {
                    padding: 10px 0;
                    font-weight: normal;
                    width: 100%;
                    max-width: none;

                    &.error {
                      color: $product-error;
                    }

                    @include until($screen-phone) {
                      display: inline-block;
                      max-width: 175px;
                      text-align: center;
                    }
                  }

                  .icon {
                    background-color: $secondary-03;
                  }

                }

                &.deadline {
                  .icon {
                    background-color: $secondary-02;
                  }
                }
              }

              @include until($screen-phone) {
                @include flex-direction(column);
                .block {
                  display: block;
                  @include flexbox(row);
                  margin: 0 -20px;
                  padding: 10px 20px 10px 20px;
                  text-align: left;
                  max-width: none;

                  .header, .date {
                    @include inalign(middle);
                  }

                  .header {
                    text-align: left;
                    padding-right: 20px;
                    color: $primary-colour;
                  }

                  .date {
                    @include flex-grow(1);
                  }

                  &.deadline {
                    padding-top: 0;
                  }

                }
              }
            }

            .shipment {
              text-align: center;
              width: 100%;

              .status-text {
                padding: 5px 15px;
                margin-right: auto;
                margin-left: auto;
              }

              .delivery-progress {
                padding: 0 40px 0 40px;
                align-items: center;
                @include until($screen-tablet) {
                  align-items: baseline;
                  padding-inline-start: 0;
                }

                .step {
                  .name {
                    margin: 15px 0;
                  }
                }

                .bardiv {
                  border-top: 10px dotted $pastel-lighter-sage;
                  flex-grow: 1;
                  padding-bottom: 35px;
                  border-spacing: 10px;
                  @include until($screen-tablet) {
                    padding-bottom: 10px;
                  }
                  @include until($screen-phone) {
                    border-top: 2px solid $pastel-lighter-sage;
                    padding: 0;
                    width: 170px;
                    margin: auto;
                  }
                }
              }

              .delivery-confirmation {
                margin-top: 30px;
                padding: 15px;
                background: $pastel-lighter-frost;
                border-radius: 20px;
              }
            }

            .date-box {
              .month, .weekday {
                font-size: $font-size-body;
              }

              .day {
                font-size: 2.4rem;
              }
            }
          }
        }

        .btn {
          margin: 20px auto 0 auto;
          width: 85%;
          @include at-least($screen-phone) {
            margin: 10px 20px 0 20px;
            width: 250px;

          }
        }

        .horizontal-buttons {
          @include at-least($screen-phone) {
            flex-flow: row;
          }
        }

        .restart-cta {
          > p {
            margin: 1em 0 .5em;
          }

          > .btn {
            margin-top: 0;
          }
        }
      }
    }
  }

  .section.tiles {
    background-color: $pastel-lighter-frost;
    padding-top: 10px;
    padding-bottom: 10px;
    justify-content: center;
    @include at-least($screen-tablet) {
      display: flex;
    }
  }

  .section.quick-links {
    text-align: center;
    background-color: $primary-white;
    margin: 20px;

    h3 {
      margin-bottom: 20px;
    }

    .links-group {
      @include flexbox(row wrap, center, center);
      @include container($screen-tablet);
      @include until($screen-tablet) {
        flex-flow: column wrap;
      }

      .links-column {
        width: 100%;
        display: grid;
        grid-gap: 10px;
        @include until($screen-tablet) {
          grid-template-columns: repeat(1, 1fr);
          padding: 0 10px;
        }
        @include at-least($screen-tablet) {
          grid-template-columns: repeat(2, 1fr);
        }

        .link {
          display: inline-block;
          width: 100%;
          border: 1px solid $primary-colour;
          color: $primary-colour;
          font-size: $font-size-body;
          border-radius: 50px;
          text-align: left;
          padding: 5px;

          > * {
            @include inalign(middle);
          }

          .icon {
            font-size: $font-size-m;
            margin: 5px;
            margin-right: 10px;
          }
        }
      }
    }

    .group {
      @include flexbox(row);
      @include flex-wrap(wrap);
      width: 100%;
      max-width: ($scss-container-max-width / 12) * 8;
      margin: auto;
      font-size: 0;

      &.account {
        .link {
          background-color: $product-error;
        }
      }
    }

    .btn {
      margin-top: 20px;
    }
  }

  .section.new-pet {
    display: none;
    background-color: $pastel-lighter-frost;
    color: $navy-01;
    text-align: center;
    padding: 30px 15px;

    &.show {
      display: block;
    }

    .input-wrapper {
      margin-bottom: 30px;
    }

    .btn, .input-wrapper {
      width: 100%;
      display: inline-block;
      vertical-align: middle;
    }

    input {
      width: 100%;
    }

    @include at-least($screen-tablet) {
      .btn, input {
        width: auto;
      }
    }
  }

  #add-dog:target {
    display: block;
  }

  .raf-banner {
    @include flexbox(column, center, space-between);

    h4 {
      margin-bottom: 20px;
    }

    img {
      height: 150px;
      width: auto;
      margin: 20px 0 -20px 0;
    }

    background-color: $secondary-03;
    padding: 20px;
    margin: 20px;
    border-radius: 8px;

    .btn.btn-secondary {
      color: $primary-colour;
      text-transform: uppercase;
      font-weight: bold;
      background: transparent;
      box-shadow: inset 0 0 0 1px $primary-colour;

      &.next:after {
        color: $primary-colour;
        vertical-align: bottom;
      }
    }

    @include at-least($screen-desktop) {
      @include flexbox(row, center, space-between);
      @include grid-blocks(10);
      img {
        height: 180px;
        width: auto;
        margin: 0 0 -25px 0;
      }
      margin: 30px auto;
      padding: 25px 80px;
    }

    .wrap {
      text-align: center;
      @include at-least($screen-desktop) {
        @include grid-blocks(4);
        text-align: left;
      }
    }
  }

  .raf-new-design {
    @include flexbox(column-reverse, center, space-between);
    background-color: $product-warning-background;
    padding: 40px;
    margin: 20px;
    border-radius: 8px;

    h4 {
      font-family: $handwritten-font-family;
      font-weight: 400;
      font-size: $font-size-l;
      padding-bottom: 6px;

      @media (max-width: $screen-phone) {
        padding: 18px 40px;
        text-align: center;
      }
    }

    a {
      font-size: $font-size-s;
      font-weight: 500;
      text-decoration: underline;
      color: $primary-colour;

      @media (max-width: $screen-phone) {
        text-align: center;
      }
    }

    @include at-least($screen-desktop) {
      @include flexbox(row, center, space-between);
      @include grid-blocks(10);
      margin: 30px auto;
      padding: 25px 80px;
    }

    .raf-dog {
      text-align: center;
      padding: 20px 0;

      a {
        text-decoration: none;
      }

      .raf-v4-dog-widget {
        background-color: transparent;
        width: auto;
      }
    }
  }

  .raf-banner-experiment {
    margin: 20px;
    @include at-least($screen-tablet) {
        margin: 30px auto;
        padding: 20px;
        @include grid-blocks(10);
    }

    h4 {
        margin-bottom: 20px;
        color: $navy-00;
    }
    .background-image {
        border-radius: 8px;
        padding: 0;
        background-repeat: no-repeat;
        background-size: cover;
        background-position-y: 100px;
        height: auto;
        min-height: 350px;
        width: auto;
        max-width: 400px;
        margin: 0 auto;
        @include at-least($screen-tablet) {
            padding: 0;
            min-height: 205px;
            max-width: 100%;
            height: 205px;
            margin-top: unset;
            background-position: top;
            background-size: cover;
        }
    }

    .raf-container {
        width: 100%;
        height: 100%;
        overflow: hidden;
        @include at-least($screen-tablet) {
            display: flex;
            align-items: center;
        }
    }

    .raf-banner-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @include at-least($screen-tablet) {
            background-color: white;
            height: 300px;
            width: 300px;
            margin: 0 auto;
            margin-left: 2%;
            border-radius: 50%;
        }
        position: relative;

        h4 {
            @include at-least($screen-tablet) {
                max-width: 260px;
            }
            text-align: center;
            max-width: 300px;
        }

        .btn {
            min-width: unset;
            width: 240px;
            box-sizing: border-box;
        }
    }
  }
}

body.pick-pet {
  padding: 80px 20px 0 20px;
}

body.pick-pet .main {
  p {
    padding: 30px 0 15px 0;
  }

  .container {
    @include grid-blocks(6);
  }

  .pets {
    list-style: none;
    width: 100%;
    margin: auto;
    padding-bottom: 50px;

    .pet:nth-of-type(5n+1) {
      .pet-roundal {
        background-color: $pastel-rose;
      }
    }
    .pet:nth-of-type(5n+2) {
      .pet-roundal {
        background-color: $pastel-lighter-sage;
      }
    }
    .pet:nth-of-type(5n+3) {
      .pet-roundal {
        background-color: $pastel-lavender;
      }
    }
    .pet:nth-of-type(5n+4) {
      .pet-roundal {
        background-color: $pastel-beige;
      }
    }
    .pet:nth-of-type(5n+5) {
      .pet-roundal {
        background-color: $pastel-frost;
      }
    }
    .photo.disabled {
      .pet-roundal {
        background-color: $navy-04;
        opacity: .5;
      }
    }

    .disabled {
      color:$navy-03;
    }

    .availability-warning {
      display: flex;
      align-items: center;
      max-width: 75%;
      gap: 9px;
      margin-top: -30px;
      margin-left: 40px;

      p {
        margin: 0.7rem 0 0.7rem 0;
      }

      @include at-least($screen-tablet) {
        margin-top: -32px;
        margin-left: 78px;
      }

      p {
        font-size: $font-size-xs;
        padding: 0;
      }
    }
    .pet {
      a {
        display: table;
        width: 100%;
        margin-bottom: 10px;
        font-weight: bold;
        text-decoration: none;

        > * {
          display: table-cell;
          vertical-align: middle;
        }

        .roundal {
          @include size(48px);
          line-height: 50px;
          font-size: $font-size-body;
          margin: 10px;
          text-align: center;
          vertical-align: middle;
        }

        .name {
          padding-left: 15px;
          width: 100%;
          text-align: left;
        }

        .arrowicon {
          font-size: 34px;
          color: $primary-white;
          min-width: 70px;
          text-align: center;
          border-bottom-color: #4478ab;

          &:before {
            @include icon($icon-chevron-right);
            color: $secondary-03;
            font-size: 4.8rem;
          }

          &.disabled{
            &:before {
              color: $navy-04;
            }
          }
        }
      }
    }
  }
}

body.confirm-pause, body.confirm-cancel, body.pause-delay, body.confirm-change-date, body.wet-other-update-body, body.one-click-wet-body,
body.change-delivery-date, body.confirm-change-date-multiple, body.change-date-complete, body.pregnant-warning,
body.update-pet-details, body.reactivation.reactivation-address-verification {

  text-align: left;

  .content {
    .container {
      padding-top: 80px;
      max-width: ($scss-container-max-width / 12) * 6;
      background: transparent;
      outline: none;
    }
  }

  hr {
    margin-top: 30px;
    margin-bottom: 30px;
    border-top: 3px solid rgba(0, 0, 0, 0.05);
    border-bottom: 1px solid rgb(255, 255, 255);
  }
}

body.update-pet-details {
  .content {
    .container {
      padding-top: 50px;
    }
  }
}

body.change-delivery-date {
  text-align: center;

  .input-wrapper {
    width: 100%;
  }

  .delivery-date {
    @include inalign(middle);
    margin-top: 25px;
  }

  .warning {
    font-size: $font-size-body;
    font-weight: bold;
    margin: 30px 0;
  }

  .warning:first-child {
    margin-top: 0;
  }
}

body.confirm-delivery {
  text-align: left;
}

body.change-date-complete {
  text-align: center;
}

body.pause-delay {
  .alert-warning {
    border-radius: 4px;
    background-color: $secondary-03;
    border: none;
    color: $primary-white;
    padding: 15px;

    .status::before {
      @include icon($icon-exclamation-circle);
      font-size: $font-size-l;
      float: left;
      margin-right: 15px;
    }
  }

  .input-wrapper {

    select {

      margin-bottom: 15px;

    }
  }

  .alert {
    margin-bottom: 15px;
  }

  .change-date.input-wrapper {
    @include flexbox(row wrap, flex-end, center);
    flex-wrap: wrap;
    text-align: right;
    width: auto;

    .wrapper {
      display: flex;

      .action {
        position: relative;
        top: 10px;
        right: 14px;
      }

      flex-grow: 8;
    }

    select {
      flex: 1 0;
      font: $default-font-family;
      color: $navy-01;
      font-weight: normal;
    }
  }

  h1 {
    text-align: center;
  }

  .btn {
    min-width: 200px;
  }
}

.orders {

  ul {
    list-style: none;
    margin-bottom: 10px;
    padding: 0;
  }

  h4 {
    margin: 0.2em 0;
  }

}

.tails-view-shipment-details {
  table {
    .col-per-day,
    .col-total {
      text-align: right;
    }

    .col-per-day {
      @extend .hidden-xs;
    }
  }

  &.has-error {
    .message {
      text-align: center;
      color: $primary-colour;
      padding: 15px;
      border-radius: 4px;

      &.error {
        background-color: $product-error-background;
        border: 1px solid $product-error;
      }

      &.success {
        background-color: $product-success-background;
        border: 1px solid $product-success;
      }
    }


  }

  .padded {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.shipment {
  > h3 {
    padding: .5em;

    > span {
      display: inline-block;
      vertical-align: middle;
    }

    .status {
      display: none;
    }

    .icon {
      font-size: 1.6em;
    }
  }

  &.failed {
    color: $product-error;

    h3 {
      .status {
        display: inline-block;
      }

      .status::before {
        content: $icon-exclamation-circle;
        font-family: "tails-icons";
        font-weight: normal;
        font-size: 1.6em;
      }
    }
  }

  .well {
    padding-right: 10px;
    padding-bottom: 10px;
  }
}

.signup-feeding-plan {
  .panel {
    padding: 14px;

    .panel-heading {
      color: $navy-01;
      background: transparent;
      border-bottom: 2px solid;
      padding-bottom: 7px;
      text-indent: 0px;
      margin-bottom: 10px;

    }

    .panel-body {
      padding: 0px;
    }
  }
}

.your-details {
  a.with-icon {
    padding: 0;

    .icon {
      font-size: 1.2em;
    }
  }
}

.delivery-changed {
  .panel-body {
    text-align: center;
  }

  .date-shift {
    padding: 10px 0;

    > * {
      display: inline-block;
      vertical-align: middle;
    }

    .arrow-right {
      font-size: 3rem;
      padding: 0 10px;
    }
  }
}

.settings-address {
  .delivery-instructions {
    &.hide {
      display: block !important;
    }
  }
}

.next-delivery {
  text-align: center;
  margin-bottom: 1em;

  h3 {
    text-align: center;
    margin-bottom: .6em;
    padding: 30px 0 15px 0;
    font-weight: $font-weight-bold;
    font-family: $default-font-family;
    color: $primary-black;
    font-size: $font-size-s;
  }
}

.date-range {
  white-space: nowrap;

  .date {
    > * {
      display: inline-block;
      vertical-align: middle;
    }

    .dash {
      font-size: 2em;
      margin: 0 .2em;
    }
  }
}

.christmas-options {
  .content {
    background: url(/static/images/christmas-star-bg.png);
  }

  .date {
    display: block;
    max-width: 250px;
    margin: 1em auto;
    background: $pastel-lighter-frost;
    border-radius: 5px;
    color: $navy-01;
    padding: 10px 5px;
    text-align: center;

    span {
      margin-top: 0.3em;
      display: block;
      font-weight: bold;
      font-size: $font-size-body;

      &:before {
        @include icon($icon-calendar);
        display: inline;
        font-size: $font-size-body;
        margin-right: 5px;
      }
    }
  }

  p {
    text-align: center;
  }

  p.thankyou {
    text-align: center;
    margin: 2em 0;

    span {
      display: inline-block;
      color: $product-error;
      padding: 5px 0;
      font-weight: bold;
      width: auto;
      text-transform: uppercase;
      border: 2px solid $product-error;
      border-left: 0;
      border-right: 0;
    }
  }

  ul.options {
    list-style: none;
    margin: 1em 0;
    padding: 0;
    text-align: center;

    strong {
      white-space: nowrap;
    }

    > li {
      margin: 0 0 20px 0;
      border-radius: 5px;
      box-shadow: $navy-04 2px 2px;
      vertical-align: top;
      background: $pastel-lighter-frost;
      padding: 5px 5px 20px;
      font-size: 1.2rem;

      h3 {
        background: $product-error;
        color: $primary-white;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        padding: 5px 10px;
        margin: -5px -5px 5px;

        span {
          display: block;
          font-size: 1.4rem;
          text-transform: none;
        }
      }

      &:nth-child(2) h3 {
        background: $secondary-03;
      }

      &:nth-child(3) h3 {
        background: $secondary-01;
      }


      ul {
        list-style: none;
        margin: 0;
        padding: 10px;
        text-align: left;
      }

      li {
        position: relative;
        margin: 0 0 1em;
        text-indent: 14px;
      }

      li:before {
        display: inline;
        content: "\2746 ";
        font-size: $font-size-s;
        left: -14px;
        top: -3px;
        position: absolute;
      }
    }

    @media (min-width: $screen-desktop) {
      > li {
        display: inline-block;
        width: 30%;
        margin: 0 5px 0 0;
      }
    }

  }
}

.wet-other-update p {
  font-size: $font-size-m;
}

// Most of this should be removed
.one-click-wet-body {

  .wet-other-update,
  .one-click-wet {
    text-align: center;
    margin-top: -50px;
    margin-bottom: 20px;
    border-radius: 4px;

    .form-group {
      margin: 1em 0;
      overflow: hidden;
    }

    a.wet-food-not-sure,
    a.other-food-not-sure {
      display: block;
      font-size: $font-size-xs;
      margin: 1em 0 0;
    }

    h1, h2, h3 {
      text-align: left;
    }

    p {
      margin: 2rem 0;
      text-align: left;
    }

    .warning,
    .error,
    .success,
    .info {
      margin: 1em 0;
      position: relative;
      text-align: left;

      input[type=checkbox] + label:before {
        height: 20px;
        width: 20px;
        color: $navy-01;
        background-color: $primary-white;
        line-height: 20px;
        border: 1px solid $navy-01
      }

      .close {
        color: $primary-white;
        font-weight: normal;
        opacity: 0.5;

        &:hover, &:focus {
          opacity: 0.8;
        }
      }

      .capitalize {
        text-transform: capitalize;
      }

      .header {
        font-weight: bold;
        background-color: transparent;
        margin-bottom: 10px;
      }

    }

    .warning {
      .form-group.checkbox-wrapper-alt {
        label {
          width: 140px;
        }
      }
    }

    .success {
      background-color: $product-success-background;

      &:before {
        content: $icon-check-circle;
      }
    }

    .info {
      background-color: transparent;

      p {
        color: $secondary-01;
      }

      &:before {
        @include icon($icon-question-circle);
        color: $secondary-01;
      }
    }

    .error {
      color: $product-error;
    }

    .errors {
      margin: 2rem 0;

      .alert {
        font-size: $font-size-s;
        line-height: $font-size-s;
      }

      .alert-error {
        background: $product-error-background;
        border: 2px solid $product-error;
        text-align: left;

        .checkbox-wrapper-alt label {
          color: black;
          font-size: $font-size-body;
          line-height: $font-size-m;
        }

        .checkbox-wrapper-alt label::before {
          margin-right: 1rem
        }
      }
    }

    .close::after {
      cursor: pointer;
      position: absolute;
      right: 20px;
      top: 10px;
      font-size: 10px;
      color: $navy-04;
      opacity: 1;
      text-decoration: none;
      @include icon($icon-close);

      &:hover {
        color: $secondary-03;
      }
    }

    .alert-error.error {
      color: $primary-white;

      &:before {
        @include icon($icon-exclamation-circle);
        font-size: 3rem;
        position: absolute;
        left: 15px;
        top: 5px;
      }
    }

    ul, li {
      list-style: none;
      text-indent: 0;
      padding: 0;
      margin: 0;
    }

    input[type=text] {
      width: 8em;
      padding: 10px;
      margin-right: 0.5rem;

      border: 1px solid $navy-01;
      border-radius: 5px;

      &.with-warning {
        box-shadow: inset 0 0 4px 1px $secondary-03;
        color: $secondary-03;
      }

      &.with-error {
        box-shadow: inset 0 0 4px 1px $product-error;
        color: $product-error;
      }
    }

    label {
      text-transform: none;
      text-align: left;
    }

    label[for="wet-grams-per-day"],
    label[for="other-kcal-percent"] {
      font-weight: normal;
      margin: 0 0 1.4rem;
    }

    .wet-equivalents {
      font-size: 1.4rem;
      padding: 10px;
      background: $secondary-03;
      color: $primary-white;
      border-radius: 5px;
      display: inline-block;
      margin: 10px auto 0;
      position: relative;
      box-shadow: 0 2px 0 rgba(0, 0, 0, 0.2);

      p {
        margin: 0 0 0.5em;
      }

      &:before {
        content: "";
        position: absolute;
        top: -10px;
        left: 50%;
        margin-left: -10px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 10px 10px 10px;
        border-color: transparent transparent $secondary-03 transparent;
      }
    }

    .daily-portion-breakdown {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 1rem;
      align-items: center;

      @include at-least($screen-tablet) {
        grid-template-columns: repeat(3, 1fr);
      }

      li {
        background: $primary-colour-10;
        border-radius: 5px;
        padding: 1rem;
      }

      .portion {
        margin: 1rem 0 0;
        text-align: center;

        span {
          white-space: nowrap;
          font-size: $font-size-m;
          line-height: 5rem;

          &.times {
            display: inline-block;
            padding: 0 1rem;
          }

          &.amount {
            display: inline-block;
            background: $pastel-lighter-lavender;
            color: $primary-colour;
            border-radius: 50%;
            text-align: center;
            width: 5rem;
            height: 5rem;
            font-weight: bold;
          }

          &.measure {
            font-size: $font-size-s;
          }
        }
      }
    }

    .price-change {
      margin: 2rem 0;

      details {
        summary {
          text-decoration: underline;
          text-align: left;
          color: $primary-text-link;
          cursor: pointer;
          padding-bottom: 2rem;
        }

        summary::marker {
          display: none;
        }
      }

      .price-change-table {
        display: table;
        width: 100%;
        border-top: 1px solid $primary-colour-40;
        margin: 2rem 0;

        .price-change-row {
          display: table-row;

          .price-change-cell {
            display: table-cell;
            text-align: left;
            border-bottom: 1px solid $primary-colour-40;
            padding: 1rem;
          }

          .price-change-cell:last-child {
            text-align: right;
          }

          .price-change-discount {
            border-bottom: none;
          }

          .price-change-total {
            border-top: 1px solid $primary-colour;
            border-bottom: 1px solid $primary-colour;
          }
        }
      }
    }
  }
}


body.deliveries-details, body.deliveries {

  h1 {
    margin-bottom: 30px;
    text-align: left;
  }

  dt {
    text-transform: none;
  }

  .shipment {
    margin-bottom: 15px;
  }

  .tails-shipments {
    margin-bottom: 50px;

    h1.headline-main {
      font-size: $font-size-title-l;
      font-family: $display-font-family;
      font-weight: $font-weight-bold;

    }

    h1, h3 {
      padding-left: 15px;
      font-family: $default-font-family;
    }
    h2 {
      font-family: $default-font-family;
      font-size: $font-size-s;
      font-weight: $font-weight-bold;
      color: $primary-black;
    }
    h3 {
      font-size: $font-size-s;
      color: $primary-black;
    }
  }

  .delivery-window {
    text-align: center;
    margin: 30px 0;

    .title {
      font-size: $font-size-m;
      font-weight: normal;
    }

    .pill {
      margin-top: 5px;
      display: inline-block;
    }
  }
}

body.deliveries-details {
  .plusminus-oneoff {
    background: $primary-white;
    .icon {
        font-size: $font-size-body;
        color: $navy-01;
    }
  }

  div.grid-container-col-food {
    display: grid;
    grid-auto-rows: max-content;
    padding-bottom: 0px;
  }
  div.grid-container-col-total {
    display: grid;
    grid-auto-rows: max-content;
    padding-bottom: 0px;
    a.rm-oneoff, a.rm-treats{
      text-transform: capitalize;
      font-weight: normal;
    }
  }
  .content {
    padding-bottom: 10px;

    .tails-view-add-scoop-wrap {
      background: $primary-white;
      text-align: center;
      padding: 15px;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(.1);
      opacity: 0;
      max-width: 500px;
      min-width: 300px;
      transition: 300ms all cubic-bezier(0, 0.37, 0.31, 1.17);

      label[for=add_scoop] {
        position: absolute;
        right: 12px;
        top: 6px;

        &:after {
          @include icon($icon-cross-circle);
          color: $pastel-lighter-frost;
        }
      }

      .tails-view-add-scoop-form {
        .pet-scoop {
          text-align: left;
          padding: 8px 0;
        }
        button {
          text-transform: none !important;
        }
      }
    }

    input[id="add_scoop"] {
      display: none;

      &:checked ~ .tails-view-add-scoop-wrap {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
        z-index: 20;
      }
    }
  }

  .actions {
    @include flexbox(row, center, center);

    text-align: right;
  }

  .tails-view-alerts {
    display: none;
  }

  .actions {
    @include flexbox(column, center, center);
  }

  #change-date_toggle {
    display: none;

    &:checked ~ .change-date-wrap {
      transform: translateY(0);
      opacity: 1;
      max-height: 100%;
    }
  }

  .change-date-wrap {
    max-height: 0;
    overflow: hidden;
    transform: translateY(5%);
    opacity: 0;
    overflow: hidden;
    transition: 250ms all ease-in-out;

    h3 {
      text-align: center;
    }

    form {
      @include flexbox(row, center, space-around);

      .select_wrap {
        select {
          width: 100%;
        }

        @include position(relative);

        &:after {
          @include icon($icon-chevron-down);
          @include position(absolute);
          top: 30%;
          right: 4px;
          color: $secondary-03;
        }
      }

      .btn {
        border-radius: 0;
        margin: 0 5px;
        align-self: stretch;
      }
    }

    p {
      padding: 15px 0;
      text-align: center;
    }
  }

  .modal-overlay {
    display: none;
    transition: 500ms all ease-in-out 700ms;
    box-shadow: inset 0 0 0 1000px transparentize($scss-primary-colour, 1);
  }

  &.modal-open {
    @include position(fixed, 0 0 0 0);

    .modal-overlay {
      display: block;
      @include position(fixed, 0 0 0 0);
      z-index: 1;
      box-shadow: inset 0 0 0 1000px transparentize($scss-primary-colour, .7);
    }
  }


  .alert-window.add-scoop-popup {
    background: $primary-white;

    .dismiss-icon {
      cursor: pointer;
      position: absolute;
      right: 10px;
      top: 10px;

      &:before {
        @include icon($icon-cross-circle);
      }
    }
  }

  .add-scoop-toggle {
    margin: 5px 0;
  }
}

body.pet-photo {

  padding-top: 30px;
  text-align: center;
  @include at-least($screen-tablet) {
    padding-top: 80px;
  }

  &.settings {
    .form {
      width: 100%;
      margin: auto;
      @include grid-blocks(6);

      background: $primary-white;
      border: 1px solid #E2E2E2;
      border-radius: 15px;
      border-bottom: 5px solid $navy-04;
      box-shadow: 0 3px 15px rgba(0, 0, 0, 0.05);

      &:before {
        content: "";
        display: inline-block;
        width: 0;
        vertical-align: middle;
        height: 100%;
      }

      padding: 10px 15px 30px 15px;

      input {
        display: none;
      }

      .thumbnail {
        display: none;
      }

      @include keyframes(drop-in) {
        0% {
          @include transform(translate3d(0, -200%, 0));
          opacity: 0;
        }
        100% {
          @include transform(translate3d(0, 0, 0));
          opacity: 1;
        }
      }

      .save {
        display: none;
        @include position(absolute, null 8px 8px null);
        background-color: $secondary-03;
        @include size(50px);
        text-align: center;
        border-radius: 50%;
        line-height: 50px;
        cursor: pointer;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
        transition: all 0.3s ease-out;

        &:hover, &:active {
          background-color: lighten($scss-secondary-03, 5);
        }

        &:before {
          @include icon($icon-check);
          color: $primary-white;
        }

        &.show {
          display: block;
        }
      }

      .loader {
        @include position(absolute, 0 0 0 0);
        opacity: 0;
        background-color: rgba(255, 255, 255, 0.7);
        pointer-events: none;
        transition: opacity 0.8s;

        &:before {
          content: "";
          @include inalign(middle);
          height: 100%;
          width: 0;
        }
      }

      &[data-status="loading"] {
        .loader {
          opacity: 1;
          display: block;
          pointer-events: all;
        }

        .save {
          display: block;
          background-color: transparent;
          z-index: 2;
          cursor: progress;

          &:before {
            content: "";
            @include position(absolute, 0 0 0 0);
            @include inalign(middle);
            background: transparent;
            border-radius: 50%;
            border: 8px solid transparent;
            box-sizing: content-box;
            @include border-color($product-error $secondary-03 $navy-01 $secondary-01);
            @include animation(spin .4s linear infinite);
          }
        }
      }

      .result {
        position: relative;
        margin-top: -20px;
        margin-bottom: 20px;

        img {
          border-radius: 50%;
          max-width: 100%;
        }

        .action {
          @include position(absolute, null 14px 14px null);
          background-color: $secondary-03;
          border-radius: 50%;
          color: $primary-white;
          font-weight: normal;

          height: 60px;
          width: 60px;
          font-size: 3rem;
          line-height: 66px;
          text-decoration: none;
          transition: all 0.3s ease-out;

          &:hover, &:active {
            background-color: lighten($scss-secondary-03, 5);
          }

          &:before {
            @include icon($icon-camera);
          }
        }
      }

      &.dragover[data-status="empty"] {
        .result {
          background-image: none !important;
          background-color: $secondary-03;
          border-color: $primary-white;
        }
      }

      &[data-status="empty"] {
        .result {
          @include size(250px);
          border-radius: 50%;
          @include inalign(middle);
          transition: all 0.3s;
          background-color: $secondary-01;

          &:before {
            @include icon($icon-upload3);
            font-size: 100px;
            color: $primary-white;
            line-height: 250px;
            text-align: center;
          }

          img {
            display: none;
          }
        }
      }

      &[data-status="edit"], &[data-status="loading"] {
        .result {
          display: none;
        }
      }

      &[data-status="success"], &[data-status="current"] {
        .result {
          display: inline-block;
        }
      }

      &[data-status="success"] {
        .result {
          margin-top: 0;
        }

        .canvas {
          display: none;
        }
      }

      .wrapper {
        position: relative;
        @include inalign(middle);
        width: 100%;

        .canvas {
          width: 100%;
        }

        .canvas-wrapper {
          position: relative;
          overflow: hidden;
          max-height: 60vh;
        }

        .image {
          margin: 0 auto;
          border-radius: 50%;
          @include size(250px);
          background-color: $secondary-03;
          transition: all 0.3s ease-in-out;
          margin-bottom: 30px;
          font-size: 0;
          background-position: center;
          background-size: cover;

          &:before {
            @include grey-line;
          }

          .progress {
            @include inalign(middle);
            font-size: 60px;
            color: $primary-white;
            text-align: center;
          }

          position: relative;
          overflow: hidden;

          img {
            @include position(absolute, 0 0 0 0);
          }
        }

        .cropper-crop-box {
          .cropper-view-box {
            border-radius: 50%;
          }
        }
      }

      .error {
        display: none;
        background: $product-error;
        border-radius: 50px;
        padding: 8px 30px;
        margin: 10px 0;
        color: $primary-white;

        &.show {
          display: inline-block;
        }

        &[data-type=success] {
          display: inline-block;
          background-color: $secondary-01;
        }
      }

      .actions {

        margin-top: 15px;
      }
    }
  }
}

.order-details {
  display: none;
}

.order-details-checkbox {
  display: none;

  &:checked ~ .order-details {
    display: inline-block;
  }
}

.tails-view-signed-in-pet-weight{
  display: flex;
  .signed-in-pet-weight-input-group {
    font-size: 24px !important;
    display: flex;

    input {
      max-width: 64px;
    }

    .action {
      color: $navy-00;
      font-size: $font-size-s;
      margin-top: 10px;
      margin-left: 6px;
      right: 3px;
      text-align: center;
    }
  }
}


.profile-update-condition {
  text-align: center;

  h1 {
    margin-bottom: 30px;

  }

  .condition-section {
    margin-bottom: 15px;

    legend {
      text-transform: none;
    }

    .description {
      padding: 15px;
    }
  }
}

.profile-update-exclusions {
  .radio-buttons {
    margin: 40px auto;
  }

}

.profile-update-exclusions-preferences {
  .form-wrapper {
    @include at-least($screen-tablet){
      width: 640px;
    }
  }
}

.profile-update-confirm {
  .panel-white {
    margin-bottom: 30px;
  }
}

.panel-white {
  .need-to-pause {
    font-size: $font-size-m;
    font-weight: $font-weight-bold;
    color: $primary-black;
    font-family: $default-font-family;

  }
  .btn {
    @include until($screen-tablet) {
      width: 100%;
    }
  }
}

.buttons-wrapper-delivery {
  .btn.btn-primary {
    width: fit-content;
    min-width: 215px;
    display: block;
    margin: 7.5px auto;
    @include until($screen-tablet) {
      width: 100%;
    }
  }

  .delivery-control {
    text-align: center;

    .btn.btn-primary {
      display: inline-block;
    }
  }

  .message {
    text-align: left;
  }
}

.update-dry-portion {
  .question {
    text-align: left;
    display: block;
    margin: auto;
    margin: 40px 0;

    .radio-buttons {
      @include flexbox(row, null, flex-start);
      margin: 40px 0;
    }

    .toggle-buttons {
      width: 100%;

      .toggle-button {
        width: 50%;
      }
    }
  }

  .buttons, .row, .panel-heading {
    margin: 20px 0;
  }


  .scoops-wrapper, .grams-wrapper, input[type=radio] {
    display: none;
  }

  #grams:checked ~ .dry_food_format label[for=grams],
  #scoops:checked ~ .dry_food_format label[for=scoops] {
    @include radio_toggle_on;
  }

  #grams:checked ~ .grams-wrapper {
    display: block;
  }

  #scoops:checked ~ .scoops-wrapper {
    display: block;
  }

  #grams:checked ~ .errors {
    display: none;
  }

  #scoops:checked ~ .errors {
    display: none;
  }
}

.confirm-dry-portion {
  .panel-heading, .plan {
    margin: 20px 0;
  }
}

.complete-dry-portion {
  .panel-heading {
    margin: 20px 0;
  }

  .text-wrapper {
    padding: 20px;
  }

  .buttons {
    margin: 40px 0;
  }
}

.choose-payment-method {
  .radio-buttons {
    text-align: left;
    display: flex;
            flex-flow: column wrap;
            align-items: baseline;
            .radio-toggle-button {
                &.stripe {
                    order: -1;
                }
                &.paypal {
                    order: 0;
                }
                &.gocardless {
                    order: 1;
                }
            }

    .radio-toggle-button {
      display: block;
      padding-bottom: 10px;

      label {
        span {
          font-weight: normal;
        }
      }
    }
  }

  .horizontal-buttons {
    padding-top: 15px;

    a, button {
      margin-top: 12px;
      margin-bottom: 12px;
    }
  }
}

body.settings.choose-method {
  .paypal-button-wrapper {
    @include at-least($screen-tablet) {
      max-width: 300px;
    }
    width: 100%;
    align-self: center;
    display: none;
  }

  input[type=radio] {
    display: none;
  }

  input[data-provider=stripe]:checked {
    ~ .horizontal-buttons {
      .paypal-button-wrapper {
        display: none;
      }
    }

    ~ .radio-buttons label[data-for-slug=stripe] {
      @include radio_toggle_on;
    }
  }

  input[data-provider=paypal]:checked {
    ~ .horizontal-buttons {
      button[type=submit] {
        display: none;
      }
        .paypal-button-wrapper {
            display: inline-block;
        }
    }

    ~ .radio-buttons label[data-for-slug=paypal] {
      @include radio_toggle_on;
    }
  }

    input[data-provider=gocardless]:checked {
      ~ .radio-buttons label[data-for-slug=gocardless] {
        @include radio_toggle_on;
      }
    }


  .radio-toggle-button.restricted {
    pointer-events: none;
    span, p {
      color: $navy-04;
    }
    p {
      margin-left: 35px;
    }
  }
}

body.settings {

  .payment-cards {
    padding: 20px;
    text-align: center;

    .p-card {
      height: 33px;
      width: 53px;
      display: inline-block;
      margin: 0 2px 10px 0;
      background: {
        position: center center;
        size: cover;
        repeat: no-repeat;
      }

      &.visa-logo {
        background-image: url("/static/images/payment-icons/visa-128px.png");
      }

      &.mastercard-logo {
        background-image: url("/static/images/payment-icons/mastercard-128px.png");
      }

      &.amex-logo {
        background-image: url("/static/images/payment-icons/american-express-128px.png");
      }
      &.direct-debit {
          background-image: url("/static/images/payment-icons/direct-debit-128px.png");
          background-image: contain;
      }
      &.sepa-lastschrift-logo {
          background-image: url("/static/images/payment-icons/sepa-lastschrift-128px.png");
          background-size: contain;
      }
      &.carte-bancaire-logo {
        background-image: url("/static/images/payment-icons/carte-bancaire-128px.png");
      }
    }
  }
  .change-unavailable {
    h5 {
      font-weight: 100;
      font-size: 2rem;
    }
    p {
      color: darken($scss-pastel-lighter-frost, 50%);
    }
  }
}

.welcome-to-the-pack {
    max-width: ($scss-container-max-width / 12) * 5.5;
    padding-top: 65px;
    @include until($screen-tablet) {
        padding: 25px 20px 20px;
    }
    margin: 0 auto;
    overflow: hidden;
    text-align: left;
}

.tails-view-change-password {
    .help-block.errors p {
        color: $product-error;
    }
}


.one-off-home {
  .dry-food {
    padding: 0 20px;
    width: 100%;
  }
  .line-item {
    display: flex;
    gap: 20px;
  }
  .bag {
    background: $pastel-lighter-sage;
    border-radius: 8px;
    img {
      width: 100px;
    }
  }
  .more-details {
    margin: 0 !important;
  }
  details {
    max-width: none !important;
  }
  summary {
    &:before {
      content: "See Details";
    }
    margin: 0 !important;
    .title {
      display: none;
    }
  }
  .account-buttons-container {
    gap: 20px;
    padding: 0 20px !important;
  }
  .buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 20px 0;
    a {
      min-width: 320px;
    }
  }
  .additives-table {
    overflow: auto !important;
  }
}

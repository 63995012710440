body.cannot-access-this-page {
  .main {
    @include flexbox(column, center, center);
    .content {
      @include flexbox(column, center, center);
      padding-bottom: 0;
      .container {
        @include at-least($screen-desktop) {
           max-width: 800px;
        }
        @include flexbox(column, center, center);
        .title {
           @include flexbox(column, center, center);
          h1 {
            text-align: center;
          }
          img {
            margin: 25px 0;
          }
        }
        .error-message {
          p {
            text-align: center;
          }
        }
      }
    }
  }
}

@mixin icon($icon:""){
	font-family: 'tails-icons';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	@if $icon != "" {
        content: $icon;
	}
}

.icon {
    @include icon;
}

.icon-price-pig {
  &:before {
    content: $icon-price-pig; 
  }
}
.icon-poo-bag {
  &:before {
    content: $icon-poo-bag; 
  }
}
.icon-lock1 {
  &:before {
    content: $icon-lock1; 
  }
}
.icon-salmon {
  &:before {
    content: $icon-salmon; 
  }
}
.icon-phone1 {
  &:before {
    content: $icon-phone1; 
  }
}
.icon-email {
  &:before {
    content: $icon-email; 
  }
}
.icon-edit1 {
  &:before {
    content: $icon-edit1; 
  }
}
.icon-minus1 {
  &:before {
    content: $icon-minus1; 
  }
}
.icon-column {
  &:before {
    content: $icon-column; 
  }
}
.icon-poobag-holder {
  &:before {
    content: $icon-poobag-holder; 
  }
}
.icon-treat-selection {
  &:before {
    content: $icon-treat-selection; 
  }
}
.icon-bone1 {
  &:before {
    content: $icon-bone1; 
  }
}
.icon-duck {
  &:before {
    content: $icon-duck; 
  }
}
.icon-arrow-vertical {
  &:before {
    content: $icon-arrow-vertical; 
  }
}
.icon-upload {
  &:before {
    content: $icon-upload; 
  }
}
.icon-clock {
  &:before {
    content: $icon-clock; 
  }
}
.icon-price-dollar {
  &:before {
    content: $icon-price-dollar; 
  }
}
.icon-credit-card {
  &:before {
    content: $icon-credit-card; 
  }
}
.icon-pig {
  &:before {
    content: $icon-pig; 
  }
}
.icon-checklist {
  &:before {
    content: $icon-checklist; 
  }
}
.icon-download {
  &:before {
    content: $icon-download; 
  }
}
.icon-question-circle1 {
  &:before {
    content: $icon-question-circle1; 
  }
}
.icon-play {
  &:before {
    content: $icon-play; 
  }
}
.icon-arrow-horizontal {
  &:before {
    content: $icon-arrow-horizontal; 
  }
}
.icon-nose_tongue {
  &:before {
    content: $icon-nose_tongue; 
  }
}
.icon-grid {
  &:before {
    content: $icon-grid; 
  }
}
.icon-list {
  &:before {
    content: $icon-list; 
  }
}
.icon-wet-tray {
  &:before {
    content: $icon-wet-tray; 
  }
}
.icon-pause {
  &:before {
    content: $icon-pause; 
  }
}
.icon-euro {
  &:before {
    content: $icon-euro; 
  }
}
.icon-price_euro {
  &:before {
    content: $icon-price_euro; 
  }
}
.icon-egg_timer {
  &:before {
    content: $icon-egg_timer; 
  }
}
.icon-chevron-right {
  &:before {
    content: $icon-chevron-right; 
  }
}
.icon-arrow_right1 {
  &:before {
    content: $icon-arrow_right1; 
  }
}
.icon-chevron-right1 {
  &:before {
    content: $icon-chevron-right1; 
  }
}
.icon-paw_01 {
  &:before {
    content: $icon-paw_01; 
  }
}
.icon-add_friend {
  &:before {
    content: $icon-add_friend; 
  }
}
.icon-price_pound {
  &:before {
    content: $icon-price_pound; 
  }
}
.icon-gbp {
  &:before {
    content: $icon-gbp; 
  }
}
.icon-ex-transparent {
  &:before {
    content: $icon-ex-transparent; 
  }
}
.icon-close {
  &:before {
    content: $icon-close; 
  }
}
.icon-remove {
  &:before {
    content: $icon-remove; 
  }
}
.icon-times {
  &:before {
    content: $icon-times; 
  }
}
.icon-calendar {
  &:before {
    content: $icon-calendar; 
  }
}
.icon-arrow-left1 {
  &:before {
    content: $icon-arrow-left1; 
  }
}
.icon-chevron-left {
  &:before {
    content: $icon-chevron-left; 
  }
}
.icon-chevron-left1 {
  &:before {
    content: $icon-chevron-left1; 
  }
}
.icon-information {
  &:before {
    content: $icon-information; 
  }
}
.icon-menu {
  &:before {
    content: $icon-menu; 
  }
}
.icon-bars {
  &:before {
    content: $icon-bars; 
  }
}
.icon-navicon {
  &:before {
    content: $icon-navicon; 
  }
}
.icon-reorder {
  &:before {
    content: $icon-reorder; 
  }
}
.icon-hamburger {
  &:before {
    content: $icon-hamburger; 
  }
}
.icon-home {
  &:before {
    content: $icon-home; 
  }
}
.icon-exclamation-circle {
  &:before {
    content: $icon-exclamation-circle; 
  }
}
.icon-exclamation {
  &:before {
    content: $icon-exclamation; 
  }
}
.icon-exclamation-triangle {
  &:before {
    content: $icon-exclamation-triangle; 
  }
}
.icon-refresh {
  &:before {
    content: $icon-refresh; 
  }
}
.icon-trash {
  &:before {
    content: $icon-trash; 
  }
}
.icon-repeat {
  &:before {
    content: $icon-repeat; 
  }
}
.icon-rotate-right {
  &:before {
    content: $icon-rotate-right; 
  }
}
.icon-checkout {
  &:before {
    content: $icon-checkout; 
  }
}
.icon-delivery {
  &:before {
    content: $icon-delivery; 
  }
}
.icon-truck {
  &:before {
    content: $icon-truck; 
  }
}
.icon-search {
  &:before {
    content: $icon-search; 
  }
}
.icon-camera {
  &:before {
    content: $icon-camera; 
  }
}
.icon-chevron-down {
  &:before {
    content: $icon-chevron-down; 
  }
}
.icon-angle-down {
  &:before {
    content: $icon-angle-down; 
  }
}
.icon-chevron-up {
  &:before {
    content: $icon-chevron-up; 
  }
}
.icon-check {
  &:before {
    content: $icon-check; 
  }
}
.icon-plus-transparent {
  &:before {
    content: $icon-plus-transparent; 
  }
}
.icon-pause-transparent {
  &:before {
    content: $icon-pause-transparent; 
  }
}
.icon-facebook-thin {
  &:before {
    content: $icon-facebook-thin; 
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook; 
  }
}
.icon-instagram-thin {
  &:before {
    content: $icon-instagram-thin; 
  }
}
.icon-instagram {
  &:before {
    content: $icon-instagram; 
  }
}
.icon-twitter-thin {
  &:before {
    content: $icon-twitter-thin; 
  }
}
.icon-twitter {
  &:before {
    content: $icon-twitter; 
  }
}
.icon-portion-cup-transparent {
  &:before {
    content: $icon-portion-cup-transparent; 
  }
}
.icon-tag-transparent {
  &:before {
    content: $icon-tag-transparent; 
  }
}
.icon-tooth-transparent {
  &:before {
    content: $icon-tooth-transparent; 
  }
}
.icon-user-transparent {
  &:before {
    content: $icon-user-transparent; 
  }
}
.icon-user {
  &:before {
    content: $icon-user; 
  }
}
.icon-weight-transparent {
  &:before {
    content: $icon-weight-transparent; 
  }
}
.icon-weight-lb1 {
  &:before {
    content: $icon-weight-lb1; 
  }
}
.icon-weight-st1 {
  &:before {
    content: $icon-weight-st1; 
  }
}
.icon-logo {
  &:before {
    content: $icon-logo; 
  }
}
.icon-logo-circle {
  &:before {
    content: $icon-logo-circle; 
  }
}
.icon-logo-circle-tm {
  &:before {
    content: $icon-logo-circle-tm; 
  }
}
.icon-delivery-thin {
  &:before {
    content: $icon-delivery-thin; 
  }
}
.icon-price-thin {
  &:before {
    content: $icon-price-thin; 
  }
}
.icon-paw-transparent {
  &:before {
    content: $icon-paw-transparent; 
  }
}
.icon-dog-transparent {
  &:before {
    content: $icon-dog-transparent; 
  }
}
.icon-tick_icon {
  &:before {
    content: $icon-tick_icon; 
  }
}
.icon-warning_icon {
  &:before {
    content: $icon-warning_icon; 
  }
}
.icon-salmon-treats-circle {
  &:before {
    content: $icon-salmon-treats-circle; 
  }
}
.icon-good-dog-treats-circle {
  &:before {
    content: $icon-good-dog-treats-circle; 
  }
}
.icon-poo-bags-circle {
  &:before {
    content: $icon-poo-bags-circle; 
  }
}
.icon-price {
  &:before {
    content: $icon-price; 
  }
}
.icon-star-rounded {
  &:before {
    content: $icon-star-rounded; 
  }
}
.icon-star-rounded-circle {
  &:before {
    content: $icon-star-rounded-circle; 
  }
}
.icon-awards-circle {
  &:before {
    content: $icon-awards-circle; 
  }
}
.icon-awards {
  &:before {
    content: $icon-awards; 
  }
}
.icon-chevron-right-circle-outline {
  &:before {
    content: $icon-chevron-right-circle-outline; 
  }
}
.icon-envelope-circle {
  &:before {
    content: $icon-envelope-circle; 
  }
}
.icon-bone {
  &:before {
    content: $icon-bone; 
  }
}
.icon-badge-3 {
  &:before {
    content: $icon-badge-3; 
  }
}
.icon-badge-2 {
  &:before {
    content: $icon-badge-2; 
  }
}
.icon-badge-1 {
  &:before {
    content: $icon-badge-1; 
  }
}
.icon-close-quote {
  &:before {
    content: $icon-close-quote; 
  }
}
.icon-open-quote {
  &:before {
    content: $icon-open-quote; 
  }
}
.icon-exclusion-wheat {
  &:before {
    content: $icon-exclusion-wheat; 
  }
}
.icon-exclusion-grain {
  &:before {
    content: $icon-exclusion-grain; 
  }
}
.icon-exclusion-maize2 {
  &:before {
    content: $icon-exclusion-maize2; 
  }
}
.icon-exclusion-maize {
  &:before {
    content: $icon-exclusion-maize; 
  }
}
.icon-exclusion-dairy {
  &:before {
    content: $icon-exclusion-dairy; 
  }
}
.icon-exclusion-fish {
  &:before {
    content: $icon-exclusion-fish; 
  }
}
.icon-exclusion-egg {
  &:before {
    content: $icon-exclusion-egg; 
  }
}
.icon-exclusion-beef {
  &:before {
    content: $icon-exclusion-beef; 
  }
}
.icon-exclusion-chicken {
  &:before {
    content: $icon-exclusion-chicken; 
  }
}
.icon-exclusion-lamb {
  &:before {
    content: $icon-exclusion-lamb; 
  }
}
.icon-exclusion-soya {
  &:before {
    content: $icon-exclusion-soya; 
  }
}
.icon-claim-29 {
  &:before {
    content: $icon-claim-29; 
  }
}
.icon-claim-28 {
  &:before {
    content: $icon-claim-28; 
  }
}
.icon-star {
  &:before {
    content: $icon-star; 
  }
}
.icon-lightning {
  &:before {
    content: $icon-lightning; 
  }
}
.icon-running {
  &:before {
    content: $icon-running; 
  }
}
.icon-file-picture {
  &:before {
    content: $icon-file-picture; 
  }
}
.icon-upload3 {
  &:before {
    content: $icon-upload3; 
  }
}
.icon-dental-circle {
  &:before {
    content: $icon-dental-circle; 
  }
}
.icon-dental {
  &:before {
    content: $icon-dental; 
  }
}
.icon-dental-badge {
  &:before {
    content: $icon-dental-badge; 
  }
}
.icon-offers-circle {
  &:before {
    content: $icon-offers-circle; 
  }
}
.icon-edit {
  &:before {
    content: $icon-edit; 
  }
}
.icon-plus {
  &:before {
    content: $icon-plus; 
  }
}
.icon-question {
  &:before {
    content: $icon-question; 
  }
}
.icon-clock-circle {
  &:before {
    content: $icon-clock-circle; 
  }
}
.icon-pause-circle {
  &:before {
    content: $icon-pause-circle; 
  }
}
.icon-weight-lb {
  &:before {
    content: $icon-weight-lb; 
  }
}
.icon-weight-st {
  &:before {
    content: $icon-weight-st; 
  }
}
.icon-weight-kg {
  &:before {
    content: $icon-weight-kg; 
  }
}
.icon-weight-lb-circle {
  &:before {
    content: $icon-weight-lb-circle; 
  }
}
.icon-weight-st-circle {
  &:before {
    content: $icon-weight-st-circle; 
  }
}
.icon-weight-kg-circle {
  &:before {
    content: $icon-weight-kg-circle; 
  }
}
.icon-calendar-circle {
  &:before {
    content: $icon-calendar-circle; 
  }
}
.icon-new-circle {
  &:before {
    content: $icon-new-circle; 
  }
}
.icon-edit-circle {
  &:before {
    content: $icon-edit-circle; 
  }
}
.icon-bin-circle {
  &:before {
    content: $icon-bin-circle; 
  }
}
.icon-user-circle {
  &:before {
    content: $icon-user-circle; 
  }
}
.icon-friend-circle {
  &:before {
    content: $icon-friend-circle; 
  }
}
.icon-facebook-circle {
  &:before {
    content: $icon-facebook-circle; 
  }
}
.icon-instagram-circle {
  &:before {
    content: $icon-instagram-circle; 
  }
}
.icon-google-circle {
  &:before {
    content: $icon-google-circle; 
  }
}
.icon-question-circle {
  &:before {
    content: $icon-question-circle; 
  }
}
.icon-checklist-circle {
  &:before {
    content: $icon-checklist-circle; 
  }
}
.icon-price-circle {
  &:before {
    content: $icon-price-circle; 
  }
}
.icon-wet-tray-circle {
  &:before {
    content: $icon-wet-tray-circle; 
  }
}
.icon-delivery-circle {
  &:before {
    content: $icon-delivery-circle; 
  }
}
.icon-blend-circle {
  &:before {
    content: $icon-blend-circle; 
  }
}
.icon-scoop-circle {
  &:before {
    content: $icon-scoop-circle; 
  }
}
.icon-scoop {
  &:before {
    content: $icon-scoop; 
  }
}
.icon-info {
  &:before {
    content: $icon-info; 
  }
}
.icon-phone {
  &:before {
    content: $icon-phone; 
  }
}
.icon-condition-1 {
  &:before {
    content: $icon-condition-1; 
  }
}
.icon-condition-2 {
  &:before {
    content: $icon-condition-2; 
  }
}
.icon-condition-3 {
  &:before {
    content: $icon-condition-3; 
  }
}
.icon-condition-4 {
  &:before {
    content: $icon-condition-4; 
  }
}
.icon-condition-5 {
  &:before {
    content: $icon-condition-5; 
  }
}
.icon-activity-1 {
  &:before {
    content: $icon-activity-1; 
  }
}
.icon-activity-2 {
  &:before {
    content: $icon-activity-2; 
  }
}
.icon-activity-4 {
  &:before {
    content: $icon-activity-4; 
  }
}
.icon-activity-5 {
  &:before {
    content: $icon-activity-5; 
  }
}
.icon-activity-3 {
  &:before {
    content: $icon-activity-3; 
  }
}
.icon-size-1 {
  &:before {
    content: $icon-size-1; 
  }
}
.icon-size-2 {
  &:before {
    content: $icon-size-2; 
  }
}
.icon-size-3 {
  &:before {
    content: $icon-size-3; 
  }
}
.icon-size-4 {
  &:before {
    content: $icon-size-4; 
  }
}
.icon-size-5 {
  &:before {
    content: $icon-size-5; 
  }
}
.icon-claim-1 {
  &:before {
    content: $icon-claim-1; 
  }
}
.icon-claim-5 {
  &:before {
    content: $icon-claim-5; 
  }
}
.icon-claim-25 {
  &:before {
    content: $icon-claim-25; 
  }
}
.icon-claim-2 {
  &:before {
    content: $icon-claim-2; 
  }
}
.icon-claim-3 {
  &:before {
    content: $icon-claim-3; 
  }
}
.icon-claim-9 {
  &:before {
    content: $icon-claim-9; 
  }
}
.icon-claim-4 {
  &:before {
    content: $icon-claim-4; 
  }
}
.icon-claim-6 {
  &:before {
    content: $icon-claim-6; 
  }
}
.icon-claim-7 {
  &:before {
    content: $icon-claim-7; 
  }
}
.icon-claim-8 {
  &:before {
    content: $icon-claim-8; 
  }
}
.icon-claim-10 {
  &:before {
    content: $icon-claim-10; 
  }
}
.icon-claim-11 {
  &:before {
    content: $icon-claim-11; 
  }
}
.icon-claim-14 {
  &:before {
    content: $icon-claim-14; 
  }
}
.icon-claim-13 {
  &:before {
    content: $icon-claim-13; 
  }
}
.icon-claim-24 {
  &:before {
    content: $icon-claim-24; 
  }
}
.icon-claim-12 {
  &:before {
    content: $icon-claim-12; 
  }
}
.icon-claim-15 {
  &:before {
    content: $icon-claim-15; 
  }
}
.icon-claim-16 {
  &:before {
    content: $icon-claim-16; 
  }
}
.icon-claim-17 {
  &:before {
    content: $icon-claim-17; 
  }
}
.icon-claim-19 {
  &:before {
    content: $icon-claim-19; 
  }
}
.icon-claim-20 {
  &:before {
    content: $icon-claim-20; 
  }
}
.icon-claim-26 {
  &:before {
    content: $icon-claim-26; 
  }
}
.icon-claim-21 {
  &:before {
    content: $icon-claim-21; 
  }
}
.icon-claim-27 {
  &:before {
    content: $icon-claim-27; 
  }
}
.icon-claim-22 {
  &:before {
    content: $icon-claim-22; 
  }
}
.icon-high_activity {
  &:before {
    content: $icon-high_activity; 
  }
}
.icon-claim-18 {
  &:before {
    content: $icon-claim-18; 
  }
}
.icon-claim-23 {
  &:before {
    content: $icon-claim-23; 
  }
}
.icon-list-alt {
  &:before {
    content: $icon-list-alt; 
  }
}
.icon-infinite {
  &:before {
    content: $icon-infinite; 
  }
}
.icon-filter {
  &:before {
    content: $icon-filter; 
  }
}
.icon-twitter-circle {
  &:before {
    content: $icon-twitter-circle; 
  }
}
.icon-quotes-right {
  &:before {
    content: $icon-quotes-right; 
  }
}
.icon-quotes-left {
  &:before {
    content: $icon-quotes-left; 
  }
}
.icon-account {
  &:before {
    content: $icon-account; 
  }
}
.icon-arrow-down-circle {
  &:before {
    content: $icon-arrow-down-circle; 
  }
}
.icon-arrow-left-circle {
  &:before {
    content: $icon-arrow-left-circle; 
  }
}
.icon-arrow-right-circle {
  &:before {
    content: $icon-arrow-right-circle; 
  }
}
.icon-arrow-up-circle {
  &:before {
    content: $icon-arrow-up-circle; 
  }
}
.icon-plus-circle {
  &:before {
    content: $icon-plus-circle; 
  }
}
.icon-minus-circle {
  &:before {
    content: $icon-minus-circle; 
  }
}
.icon-cross-circle {
  &:before {
    content: $icon-cross-circle; 
  }
}
.icon-check-circle {
  &:before {
    content: $icon-check-circle; 
  }
}
.icon-info-circle {
  &:before {
    content: $icon-info-circle; 
  }
}
.icon-shop {
  &:before {
    content: $icon-shop; 
  }
}
.icon-envelope-o {
  &:before {
    content: $icon-envelope-o; 
  }
}
.icon-trash-o {
  &:before {
    content: $icon-trash-o; 
  }
}
.icon-lock {
  &:before {
    content: $icon-lock; 
  }
}
.icon-ban {
  &:before {
    content: $icon-ban; 
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left; 
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right; 
  }
}
.icon-arrow-up {
  &:before {
    content: $icon-arrow-up; 
  }
}
.icon-arrow-down {
  &:before {
    content: $icon-arrow-down; 
  }
}
.icon-minus {
  &:before {
    content: $icon-minus; 
  }
}
.icon-asterisk {
  &:before {
    content: $icon-asterisk; 
  }
}
.icon-arrows-v {
  &:before {
    content: $icon-arrows-v; 
  }
}
.icon-arrows-h {
  &:before {
    content: $icon-arrows-h; 
  }
}
.icon-sign-out {
  &:before {
    content: $icon-sign-out; 
  }
}
.icon-sign-in {
  &:before {
    content: $icon-sign-in; 
  }
}
.icon-caret-down {
  &:before {
    content: $icon-caret-down; 
  }
}
.icon-caret-up {
  &:before {
    content: $icon-caret-up; 
  }
}
.icon-caret-left {
  &:before {
    content: $icon-caret-left; 
  }
}
.icon-caret-right {
  &:before {
    content: $icon-caret-right; 
  }
}
.icon-envelope {
  &:before {
    content: $icon-envelope; 
  }
}
.icon-angle-double-left {
  &:before {
    content: $icon-angle-double-left; 
  }
}
.icon-angle-double-right {
  &:before {
    content: $icon-angle-double-right; 
  }
}
.icon-angle-double-up {
  &:before {
    content: $icon-angle-double-up; 
  }
}
.icon-angle-double-down {
  &:before {
    content: $icon-angle-double-down; 
  }
}
.icon-spinner {
  &:before {
    content: $icon-spinner; 
  }
}
.icon-calendar-o {
  &:before {
    content: $icon-calendar-o; 
  }
}
.icon-play-circle {
  &:before {
    content: $icon-play-circle; 
  }
}
.icon-ticket {
  &:before {
    content: $icon-ticket; 
  }
}
.icon-paw {
  &:before {
    content: $icon-paw; 
  }
}
.icon-basket {
  &:before {
    content: $icon-basket; 
  }
}
.icon-plus1 {
  &:before {
    content: $icon-plus1; 
  }
}
.icon-plus2 {
  &:before {
    content: $icon-plus2; 
  }
}
.icon-cc-visa {
  &:before {
    content: $icon-cc-visa; 
  }
}
.icon-cc-mastercard {
  &:before {
    content: $icon-cc-mastercard; 
  }
}
.icon-cc-amex {
  &:before {
    content: $icon-cc-amex; 
  }
}
.icon-direct-debit {
  &:before {
    content: $icon-cc-direct-debit;
  }
}
.icon-facebook-official {
  &:before {
    content: $icon-facebook-official; 
  }
}
.icon-whatsapp {
  &:before {
    content: $icon-whatsapp; 
  }
}


html {
    font-size: $base-font-size;
}


.heading-xl {
    font-size: $font-size-xxl;
    line-height: 48px;
}

.heading-l {
    font-size: $font-size-xl;
    line-height: 40px;
}

.heading-m {
    font-size: $font-size-l;
    line-height: 32px;
}

.heading-s {
    font-size: $font-size-m;
    line-height: 32px;
}

.heading-xs {
    font-size: $font-size-s;
    line-height: 24px;
}

body {
    font-weight: normal;
}

.proper-noun {
    text-transform: initial !important;
}

header + * {
    margin-top: 20px;
}
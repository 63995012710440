body.help {

    background: $primary-white;

    .show-mobile {
        display: none;
    }

    .btn {
        font-size: $font-size-body;
        line-height: 2em;
    }

    .centered {
        text-align: center;
    }

    .contained {
        max-width: $container-max-width;
        margin: 0 auto;
    }

    .file-size-error {
        display: none;
    }

    .section {
        padding: 30px 30px;
        width: 100%;

        &.grey {
            background: $pastel-lighter-frost;
        }

        &:last-of-type {
            margin-bottom: 0;
        }

        h2 {
            &:first-child {
                margin-top: 0;
            }
        }
    }

    .sticky-cta {
        @include transition(top 200ms);

        &:not(.visible) {
            top: -20px;
        }
    }

    &.help-section {
        a.navigate-back {
            display: none;
            position: fixed;
            top: 0;
            left: 0;
            height: 50px;
            z-index: 4;
            text-align: center;
            padding: 5px 10px;
            color: #529DE8;
            cursor: pointer;
            font-size: 1.4rem;

            .icon {
                font-size: $font-size-body;
                display: inline-block;
            }

            .text {
                display: block;
                font-weight: normal;
                color: $navy-01;
            }

            @include until($nav-max) {
                display: block;
            }
        }

        .hamburger {
            left: 50px;
        }
    }

    .help-header {
        background: url(/static/images/help/header.jpg) center right no-repeat / cover;
        @include until($screen-tablet) {

            background-position-x: right;
        }
        height: 400px;
        @include display(flex);
        @include align-items(center);
        align-items: center;
        position: relative;

        .inner {
            text-align: center;
            width: 100%;
            max-width: 550px;
            margin: 0 auto;
            padding-left: 10px;
            padding-right: 10px;

            h1 {
                margin-bottom: 16px;
            }
        }

         + .centered.section {
                background: $pastel-lighter-frost;
        }
    }

    .input {
        position: relative;

        > input,
        > select,
        > textarea {
            line-height: 44px;
            min-height: 44px;
            width: 100%;
            border-radius: 50px;
            text-indent: 1.25em;
            border: none;
            outline: none;
            box-shadow: inset 0px 0px 4px 1px rgba(0, 0, 0, .1);
            @include transition(box-shadow 100ms);
            -webkit-appearance: none;

            &:focus {
                /* TODO */
            }
        }

        > input[type="file"] {
            box-shadow: none;
            line-height: 1;
            text-indent: 0;
            padding: 12px 0;
        }

        > textarea {
            border-radius: 8px;
        }

        &.search {

            > input {
                padding-right: 50px;
            }

            &:after {
                @include icon($icon-search);
                position: absolute;
                top: 0;
                right: .5em;
                line-height: 44px;
                font-size: 3rem;
                color: $navy-01;
            }

            &.loading:after {
                content: $icon-spinner;
                @include animation(spin 2s infinite linear);
            }
        }

    }

    .field {
        margin: 1em 0;

        &.horizontal {
            @include display(flex);

            label {
                @include flex-grow(.5);
                @include align-self(center);
                text-align: right;
                padding-right: 1em;
                margin-bottom: 0;
                width: 100%;
                max-width: 140px;
            }

            .input {
                @include flex-grow(1);
            }
        }

        label {
            font-size: $font-size-body;
            font-weight: bold;
        }

        .error {
            text-align: left;
            font-weight: normal;
            color: red;

            &:last-child {
                margin-bottom: 2em;
            }
        }
    }

    .boxes {
        $box-padding: 10px;

        font-size: 0;
        margin-left: -$box-padding;
        margin-right: -$box-padding;

        .box {
            position: relative;
            display: inline-block;
            width: (100% / 3);
            padding-top: 20%;

             > .inner {
                 position: absolute;
                 top: 0;
                 left: 0;
                 width: 100%;
                 height: 100%;
                 padding: $box-padding;
                 -webkit-background-clip: content-box;
                 background-clip: content-box;
                 -webkit-background-size: cover;
                 background-size: cover;
                 @include display(flex);
                 @include align-items(center);
                 @include justify-content(center);
                 @include flex-wrap(wrap);
                 @include flex-direction(column);
                 overflow: hidden;


                 &:after {
                     content: '';
                     @include position(absolute, 0 0 0 0);
                     background: transparentize($scss-navy-01,.5);
                     -webkit-background-clip: content-box;
                     background-clip: content-box;
                     padding: $box-padding;
                     @include transition(background-color 250ms);
                 }

                 &:hover:after {
                     background-color: transparentize($scss-navy-01,0);
                 }

                 > .view {
                     @include display(flex);
                     @include align-items(center);
                     @include justify-content(center);
                     @include flex-direction(column);
                     height: 100%;
                     width: 100%;
                     font-size: 1rem;
                     color: $primary-white;
                     z-index: 1;
                     padding: 10px;
                     @include transition(margin-left 400ms);
                     text-decoration: none !important;
                     a {
                        text-decoration: none !important;
                     }

                     .under-link {
                         position: absolute;
                         top: 0;
                         left: 0;
                         width: 100%;
                         height: 100%;
                     }

                     ul {
                         list-style: none;
                         padding: 0;
                         margin: 0;
                         line-height: 36px;
                         max-width: 100%;
                         position: relative;
                         z-index: 1;

                         li {
                             white-space: nowrap;
                             overflow: hidden;
                             text-overflow: ellipsis;
                             max-width: 100%;

                             &:hover {
                                text-decoration: underline;
                             }

                             &:nth-child(3) ~ li:not(:last-child) {
                                 display: none;
                             }

                             a {
                                 color: white;
                                 &.more {
                                     font-style: italic;
                                 }
                             }
                         }
                     }
                 }

                 &.rotate:hover > .view:first-child {
                     margin-left: -100%;
                 }

                 h3 {
                     color: $primary-white;
                     z-index: 1;

                     a {
                         color: inherit;
                     }
                 }
             }
        }
    }

    .video-player {
        background: $primary-white;
        padding: 25px;
        margin-top: 30px;

        > .inner {
            > .row {
                @include display(flex);
                @include align-items(center);
                margin: 0;

                .playlist {
                    @include flex-grow(1);

                    ul {
                        list-style: none;
                        padding: 0;
                        font-size: $font-size-body;
                        font-weight: bold;

                        li {
                            a {
                                text-decoration: underline;

                                &:hover {
                                    text-decoration: none;
                                }
                            }

                            ~ li {
                                margin-top: 12px;
                            }
                        }

                    }
                }
            }

            h4 {
                text-align: left;
                margin-top: .5em;
            }
        }
    }

    .dropdowns {

        .dropdown {
            background: $primary-white;
            margin: 32px 0;
            border-radius: 8px;
            overflow: hidden;

            .header {
                @include display(flex);
                @include align-items(center);
                @include justify-content(center);
                position: relative;
                font-size: 2.4rem;
                padding: 0 40px;
                padding-left: 80px;
                padding-right: 60px;
                text-decoration: none;
                min-height: 72px;
                @include transition(background 200ms);
                text-align: center;
                color: $navy-01;

                > span {
                    padding: 10px 4px;
                    display: block;
                }



                &:after {
                    @include icon($icon-chevron-down);
                    font-size: $font-size-l;
                    position: absolute;
                    top: 50%;
                    margin-top: -(40px / 2);
                    right: 20px;
                    @include transition(transform 200ms);
                    color: $secondary-03;
                }
            }

            .content {
                min-height: 0;
                padding: 24px 60px 45px;
                display: none;
                text-align: left;
                font-size: $font-size-body;
                line-height: 27px;

                img {
                    max-width: 100%;
                }
            }


            &.open,
            &:target {
                .header {
                    &:after {
                        @include transform(rotate(180deg));
                    }
                }

                .content {
                    display: block;
                }
            }
        }
    }

    #contact {
        .ribbon {
            margin: 10px 0;
            display: block;
            background: $pastel-lighter-frost;
            font-size: 2.8rem;
            font-weight: bold;
            margin-left: -30px;
            margin-right: -30px;
            line-height: 60px;
            margin-bottom: 30px;
            color: $navy-01;
            padding-top: 20px;
            padding-bottom: 20px;

            span {
                display: block;
            }

            .btn {
                width: 100%;
                max-width: 220px;
            }
        }

        &.hide-contact:not(.open) {
            padding-bottom: 0;

            .ribbon {
                margin-bottom: 0;
            }

            .contained {
                display: none;
            }
        }
    }

    .contact-options {
        max-width: 700px;
        margin: 30px auto;
        overflow: hidden;

        &.show-selected {
            .option {
                opacity: .45;

                &.selected {
                    opacity: 1;
                }
            }
        }

        .option {
            width: 25%;
            min-height: 160px;
            float: left;
            text-decoration: none;
            color: inherit;

            > .icon {
                font-size: 90px;
                color: $navy-01;
            }

            > .icon-messenger {
                display: block;
                width: 90px;
                height: 90px;
                margin: 0 auto;
                background: {
                    image: url('/static/images/fyf/fb-messenger.svg');
                    size: cover;
                }
            }

            .label {
                display: block;
                font-size: $font-size-body;
                font-weight: bold;
                text-transform: uppercase;
                margin-top: .8em;
            }

            .sub {
                font-style: italic;
                font-size: $font-size-s;
            }
        }
    }

    .contact-form {
        max-width: 500px;
        margin: 0 auto;
    }

    #contact.section {
        padding-top: 0;
    }

    .section-buttons {
        font-size: 0;
        text-align: left;
        margin-left: -8px;
        margin-right: -8px;
        margin-bottom: 30px;
        margin-top: 30px;

        > div {
            width: (100% / 3);
            display: inline-block;
            padding: 8px;
            vertical-align: top;

            ul {
                display: none;
            }

            .btn {
                display: block;
                border-radius: 0;
                background-color: $primary-white;
                color: $navy-04;
                font-weight: normal;

                &:hover, &:active {
                    background-color: $secondary-03;
                    color: $primary-white;
                }
                &.selected {
                    background-color: $primary-white;
                    color: $navy-01;
                }
            }
        }


    }

    .contact-toggleable {
        display: none;

        &:target {
            display: block;
        }
    }

    #phone {
        font-size: $font-size-body;
    }

    .social-container {
        max-width: 700px;
        margin: 0 auto;
        overflow: hidden;
        margin-top: -42px;

        .inner {
            width: 25%;
            float: right;
            font-size: $font-size-body;

            a {
                color: $navy-01;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    .popup.contact-form {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(255,255,255,0.75);
        max-width: none;
        z-index: 1000;
        display: none;
        @include justify-content(center);
        @include align-items(center);
        font-size: $font-size-body;

        &:target {
            display: -ms-flexbox;
            display: flex;
        }

        > form {
            background: url(/static/images/help/email-bg.jpg);
            -webkit-background-size: cover;
            background-size: cover;
            max-width: 880px;
            height: 880px;
            width: 100%;
            max-height: 100vh;
            @include display(flex);
            @include align-items(center);
            @include justify-content(center);

            > .inner {
                max-width: 640px;
                max-height: 100%;
                width: 100%;
                overflow: auto;
                background: $primary-white;
                padding: 10px;
                position: relative;

                h2 {
                    margin: 0 auto 1em;
                    max-width: 350px;
                }

                .horizontal.field {
                    max-width: 472px;
                    margin-left: auto;
                    margin-right: auto;
                }

                .btn {
                    width: 100%;
                    max-width: 120px;
                }

                a.icon.icon-cross-circle {
                    position: absolute;
                    top: 16px;
                    right: 16px;
                    font-size: 3.2rem;
                }
            }
        }

        p.smallprint {
            font-size: $font-size-xxs;
            margin-bottom: 0;
        }

        .alert {
            width: auto;
            height: auto;

            &.alert-error:after {
                display: none;
            }
        }

        .suggested-articles {
            max-width: 472px;
            margin-left: auto;
            margin-right: auto;

        }
    }

    .autocomplete-suggestion.no-results {
        color: #888;
        font-style: italic;
        pointer-events: none;
    }

    .section-links {
        margin: 20px;

        a {
            display: inline-block;
            margin: 5px 10px 5px 0;
            padding: 10px 15px;
            border-radius: 0;
            background: white;
            color: $navy-04;
            text-transform: uppercase;
            text-decoration: none;
            margin-right: 10px;

            &:hover, &:active {
                background-color: $secondary-03;
                color: $primary-white;
            }
            &.selected {
                background-color: $primary-white;
                color: $navy-01;
            }
        }
    }

    .suggested-articles {
        text-align: left;
        font-size: $font-size-body;
        display: none;

        &.has-results {
            display: block;
        }

        ul {
            list-style: none;
            border: 1px solid #e2e2e2;
            padding-left: 20px;
            line-height: 45px;
            font-weight: bold;

            a:hover {
                text-decoration: underline;
            }
        }
    }

    @include until($screen-lg-desktop) {
        .boxes {
            .box {
                width: 50%;
                padding-top: 28%;

                .inner.rotate:hover > .view:first-child {
                    margin-left: 0;
                }
            }
        }

        .video-player {
            > .inner {
                > .row {
                    flex-wrap: wrap;

                    .video-holder,
                    .playlist {
                        width: 100%;
                    }

                    .playlist {
                        margin-top: 30px;
                    }
                }

                h4 {
                    display: none;
                }
            }
        }

        .dropdowns {
            .dropdown {
                .header {
                    font-size: 2rem;
                }
            }
        }

        .help-header {
            align-items: initial;

            .inner {
                padding-top: 30px;
            }

        }


    }

    @include until($screen-tablet) {
        .show-mobile {
            display: block;
        }

        .hide-mobile {
            display: none;
        }

        .section {
            padding: 10px;
        }

        .section-buttons {
            margin-left: 0;
            margin-right: 0;

            > div {
                width: 50%;
                padding: 4px 10px;

                .btn {
                    padding-left: 0;
                    padding-right: 0;
                    font-size: 1.5rem;
                }
            }
        }

        .video-player {
            padding-left: 0;
            padding-right: 0;
            padding-top: 0;

            iframe {
                width: 414px;
                height: 366px;
            }
        }

        #contact {
            .ribbon {
                font-size: $font-size-m;
                margin-left: -10px;
                margin-right: -10px;
            }
        }

        .contact-options {
            .option {
                width: 50%;
            }
        }

        .social-container .inner {
            width: 50%;
        }

        .horizontal.field {
            @include flex-direction(column);

            label {
                max-width: none;
                text-align: left;
            }
        }

        .section-links {
            a {
                font-size: 2rem;
            }
        }

        .help-header {
            height: 250px;
            @include align-items(flex-start);

            .inner {
                max-width: 350px;
            }
        }
    }

    @include until($screen-phone) {
        .section {
            padding-left: 0;
            padding-right: 0;
        }

        .section-buttons {
            > div {
                width: 100%;
            }
        }

        .video-player {
            padding-left: 0;
            padding-right: 0;
            padding-top: 0;

            .video-holder {
                position: relative;
                padding-top: 88%;

                iframe {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .dropdowns {
            padding: 10px;

            .dropdown {
                .header {
                    font-size: $font-size-s;
                    padding-right: 40px;
                    padding-left: 50px;

                    &:before {
                        font-size: 2.5rem;
                        width: 50px;
                    }

                    &:after {
                        font-size: 26px;
                        margin-top: -13px;
                        right: 13px;
                    }
                }

                .content {
                    font-size: $font-size-s;
                    padding: 10px;
                }
            }
        }

        #contact {
            .ribbon {
                font-size: $font-size-body;
                line-height: 26px;
                margin-left: 0;
                margin-right: 0;
                padding: 10px;
            }
        }

        .contact-form {
            padding: 10px;
        }

        .help-header {
            @include align-items(flex-start);
            height: 250px;

            .inner {
                padding-top: 10px;
                max-width: none;

                input {
                    font-size: $font-size-s;
                }
            }
        }

        .popup.contact-form {
            background: none;
            overflow: auto;
        }

        .section-buttons {
            > div {
                &.has-submenu {
                    ul {
                        display: none;
                        font-size: $font-size-s;
                        list-style: none;
                        text-align: center;
                        margin: 0;
                        padding: 0;
                        border-bottom-left-radius: 8px;
                        border-bottom-right-radius: 8px;

                        li {
                            border-top: 1px solid $pastel-lighter-frost;
                            background: white;
                            color: $navy-04;

                            &.selected,
                            &:hover,
                            &:active {
                                color: $navy-01;
                            }

                            a {
                                color: $navy-04;
                                text-decoration: none;
                                line-height: 35px;
                                display: block;
                            }
                        }

                    }

                    &.open {
                        ul {
                            display: block;
                        }

                        .btn {
                            border-bottom-left-radius: 0;
                            border-bottom-right-radius: 0;
                            border-bottom: none;
                        }
                    }
                }
            }
        }

        .section-links {
            display: none;
        }
    }
}

body.show-photo-popup {
    .contact-form.popup {
        @include display(flex);
    }
}

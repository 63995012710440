.logos-section {
  background: #fff;

  .wrapped {
    @include container($container-max-width);
    padding: 20px 0;

    .label {
      margin-bottom: 50px;
    }
  }

  .slick-arrow {
    background: none;
    border: none;
    font-size: 0;
    padding: 0;
    width: 50px;
    position: absolute;
    top: 50%;
    margin-top: -20px;
    outline: none;

    &:before {
      @include icon();
      font-size: $font-size-l;
      color: $secondary-03;
    }

    &.slick-prev {
      left: 0;

      &:before {
        content: $icon-chevron-left;
      }
    }

    &.slick-next {
      right: 0;

      &:before {
        content: $icon-chevron-right;
      }
    }
  }

  .slick-list {
    overflow: hidden;
  }

  .label {
    text-align: center;
    font-size: 2.8rem;
  }

  .logos-array {
    overflow: hidden;
    position: relative;
    margin-bottom: 0;
    padding-left: 50px;
    padding-right: 50px;
    @include flex-grow(1);

    .logo-item {
      display: inline-block;
      text-align: center;
      outline: none;

      img {
        vertical-align: middle;
      }
    }
  }

  @include until($screen-tablet) {
    .wrapped {
      display: block;
    }

    .label {
      text-align: center;
      padding: 0 0 10px;
    }
  }
}

.flag {
	width: 16px;
	height: 11px;
	background:url(/static/images/flags.png) no-repeat
}

// .flag.flag-ad {background-position: -16px 0}
// .flag.flag-ag {background-position: -32px 0}
// .flag.flag-ai {background-position: -48px 0}
// .flag.flag-al {background-position: -64px 0}
// .flag.flag-an {background-position: -80px 0}
// .flag.flag-ar {background-position: -96px 0}
// .flag.flag-at {background-position: -112px 0}
// .flag.flag-aw {background-position: -128px 0}
// .flag.flag-ba {background-position: -144px 0}
// .flag.flag-bb {background-position: 0 -11px}
// .flag.flag-be {background-position: -16px -11px}
// .flag.flag-bg {background-position: -32px -11px}
// .flag.flag-bm {background-position: -48px -11px}
// .flag.flag-bo {background-position: -64px -11px}
// .flag.flag-br {background-position: -80px -11px}
// .flag.flag-bs {background-position: -96px -11px}
// .flag.flag-by {background-position: -112px -11px}
// .flag.flag-bz {background-position: -128px -11px}
// .flag.flag-ca {background-position: -144px -11px}
// .flag.flag-ch {background-position: 0 -22px}
// .flag.flag-cl {background-position: -16px -22px}
// .flag.flag-co {background-position: -32px -22px}
// .flag.flag-cr {background-position: -48px -22px}
// .flag.flag-cu {background-position: -64px -22px}
// .flag.flag-cw {background-position: -80px -22px}
// .flag.flag-cz {background-position: -96px -22px}
// .flag.flag-de {background-position: -112px -22px}
// .flag.flag-dk {background-position: -128px -22px}
// .flag.flag-dm {background-position: -144px -22px}
// .flag.flag-do {background-position: 0 -33px}
// .flag.flag-ec {background-position: -16px -33px}
// .flag.flag-ee {background-position: -32px -33px}
// .flag.flag-es {background-position: -48px -33px}
// .flag.flag-fi {background-position: -64px -33px}
// .flag.flag-fk {background-position: -80px -33px}
// .flag.flag-fo {background-position: -96px -33px}
// .flag.flag-fr {background-position: -112px -33px}
.flag.flag-gb {background-position: -128px -33px}
// .flag.flag-gd {background-position: -144px -33px}
// .flag.flag-gf {background-position: 0 -44px}
// .flag.flag-gi {background-position: -16px -44px}
// .flag.flag-gl {background-position: -32px -44px}
// .flag.flag-gp {background-position: -48px -44px}
// .flag.flag-gr {background-position: -64px -44px}
// .flag.flag-gt {background-position: -80px -44px}
// .flag.flag-gy {background-position: -96px -44px}
// .flag.flag-hn {background-position: -112px -44px}
// .flag.flag-hr {background-position: -128px -44px}
// .flag.flag-ht {background-position: -144px -44px}
// .flag.flag-hu {background-position: 0 -55px}
// .flag.flag-ie {background-position: -16px -55px}
// .flag.flag-is {background-position: -32px -55px}
// .flag.flag-it {background-position: -48px -55px}
// .flag.flag-jm {background-position: -64px -55px}
// .flag.flag-kn {background-position: -80px -55px}
// .flag.flag-ky {background-position: -96px -55px}
// .flag.flag-lc {background-position: -112px -55px}
// .flag.flag-li {background-position: -128px -55px}
// .flag.flag-lt {background-position: -144px -55px}
// .flag.flag-lu {background-position: 0 -66px}
// .flag.flag-lv {background-position: -16px -66px}
// .flag.flag-mc {background-position: -32px -66px}
// .flag.flag-md {background-position: -48px -66px}
// .flag.flag-me {background-position: -64px -66px}
// .flag.flag-mk {background-position: -80px -66px}
// .flag.flag-mq {background-position: -96px -66px}
// .flag.flag-ms {background-position: -112px -66px}
// .flag.flag-mt {background-position: -128px -66px}
// .flag.flag-mx {background-position: -144px -66px}
// .flag.flag-ni {background-position: 0 -77px}
// .flag.flag-nl {background-position: -16px -77px}
// .flag.flag-no {background-position: -32px -77px}
// .flag.flag-pa {background-position: -48px -77px}
// .flag.flag-pe {background-position: -64px -77px}
// .flag.flag-pl {background-position: -80px -77px}
// .flag.flag-pm {background-position: -96px -77px}
// .flag.flag-pr {background-position: -112px -77px}
// .flag.flag-pt {background-position: -128px -77px}
// .flag.flag-py {background-position: -144px -77px}
// .flag.flag-ro {background-position: 0 -88px}
// .flag.flag-rs {background-position: -16px -88px}
// .flag.flag-ru {background-position: -32px -88px}
// .flag.flag-se {background-position: -48px -88px}
// .flag.flag-si {background-position: -64px -88px}
// .flag.flag-sk {background-position: -80px -88px}
// .flag.flag-sm {background-position: -96px -88px}
// .flag.flag-sr {background-position: -112px -88px}
// .flag.flag-sv {background-position: -128px -88px}
// .flag.flag-sx {background-position: -144px -88px}
// .flag.flag-tc {background-position: 0 -99px}
// .flag.flag-tt {background-position: -16px -99px}
// .flag.flag-ua {background-position: -32px -99px}
// .flag.flag-us {background-position: -48px -99px}
// .flag.flag-uy {background-position: -64px -99px}
// .flag.flag-va {background-position: -80px -99px}
// .flag.flag-vc {background-position: -96px -99px}
// .flag.flag-ve {background-position: -112px -99px}
// .flag.flag-vg {background-position: -128px -99px}
// .flag.flag-vi {background-position: -144px -99px}

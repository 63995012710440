.tutorial-space-v2 {
  .bg-wrap {
    background: #E8EFF7;
    padding: 40px 5px 10px 0;
    margin-left: -15px;
    margin-right: -15px;

    @include at-least($screen-phone) {
      margin-left: -149px;
      margin-right: -149px;
      padding-left: 149px;
    }

    @include at-least($screen-tablet) {
      margin-right: -40px;
    }
  }
  .getting-started-tutorial {
    @include until($screen-tablet) {
      width: 100%;
      padding: 40px 1em 1em;
      height: initial;
    }

    .image-wrap {
      text-align: center;
      @include until($screen-tablet) {
        display: none;
      }
    }
  }

  .tutorial-space-v2-make-changes {
    .bold.tutorial-title {
      line-height: 1.8;
      text-align: left;
      @include at-least($screen-tablet) {
        text-align: center;
      }
    }

    .bold {
      &.tutorial-title, &.tutorial-step-title {
        padding-left: 10px;
      }
    }
  }

  .bottom-navigation {
    max-width: 330px;
    width: 100%;
    margin: 76px auto 60px;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: baseline;

    a {
      order: -1;
      padding: 0 5px;
    }

    @include until($screen-tablet) {
      width: 250px;
      margin: 60px auto;
      align-items: center;
      .next-step {
        margin-bottom: 20px;
      }
      flex-flow: column wrap;
      a {
        order: 1;
      }
    }

    .btn.btn-primary.next-step {
      font-weight: 600;
    }
  }

  .btn-primary:not(.step-btn) {
    min-width: 220px;
    padding-top: 13px;
    padding-bottom: 13px;
  }

  .btn-secondary {
    background: rgba(0, 0, 0, 0);
  }

  .width-fix {
    width: 100vw !important;
  }

  .center {
    text-align: center;
  }

  .getting-started-inner {
    box-shadow: 0 0 9px #777;
    border-radius: 10px;
    @media screen and (max-device-width: $screen-tablet) {
      border-radius: 0;
    }
    max-height: 72vh;
    overflow-y: scroll;

    h5 {
      font-weight: 400;
    }

    .getting-started-tutorial.lets-get-started {
      background: linear-gradient(180deg, rgba(255, 255, 255, 1) 36%, rgba(249, 247, 243, 1) 36%, rgba(249, 247, 243, 1) 100%);
      @include at-least($screen-desktop) {
        background: linear-gradient(180deg, white 39%, #f9f7f3 39%, #f9f7f3 100%);
      }
    }

    .go-to-dashboard {
      width: 100%;
      text-align: center;
      margin-top: -30px;
      margin-bottom: 10px;
      @media screen and (max-device-width: $screen-tablet) {
        margin-top: -50px;
        margin-bottom: 50px;
      }

      .btn-secondary {
        box-shadow: inset 0 0 0 2px $primary-cta;
        height: 17px;
        min-width: 200px;
        position: relative;
        margin-left: 21px;
        top: 2px;

        &:after {
          @include icon($icon-chevron-right1);
          font-size: 17px;
          font-weight: 900;
        }
      }

    }

    .steps {
      padding-left: 60px;
      padding-right: 60px;
      padding-top: 50px;
    }

    .step {
      background: $primary-white;
      border-radius: 8px;
      box-shadow: 0px 3px 5px 1px #a8a8a8a3;
      margin: 16px 16px 24px;
      padding: 16px;
      position: relative;
      bottom: 50px;

      @media screen and (max-device-width: $screen-desktop) {
        margin-left: 8px;
        margin-right: 8px;
      }

      button {
        width: initial;
      }
    }

    @media screen and (max-device-width: $screen-desktop) {
      .title {
        display: initial;
        text-align: center;
        background: #fff;
      }
    }

    @media screen and (max-device-width: $screen-desktop) {
      .steps {
        padding-left: 0;
        padding-right: 0;
        text-align: center;

        button {
          margin-left: 0;
          margin-right: 0;
          width: 75%;
        }
      }
    }

    .number-icon {
      @media screen and (max-device-width: $screen-desktop) {
        position: relative;
        bottom: 35px;
      }
    }

    .number-circle {
      height: 40px;
      width: 40px;
      border: solid;
      border-color: #CDCFD5;
      border-radius: 50%;
      display: inline-block;
      text-align: center;
      font-weight: 700;
      border-width: 1px;
      padding-top: 9px;
      padding-left: 2px;
      background: $primary-white;

      @media screen and (max-device-width: $screen-desktop) {
        position: relative;
        bottom: 35px;
      }
    }

    .title {
      h5 {
        @media screen and (max-device-width: $screen-desktop) {
          height: 0px;
          position: relative;
          bottom: 25px;
        }
      }
    }

    .svg {
      .title {
        h5 {
          @media screen and (max-device-width: $screen-desktop) {
            bottom: 48px;
          }
        }
      }
    }

    .step {
      @media screen and (max-device-width: $screen-desktop) {
        margin-bottom: 42px;
      }

      button {
        @media screen and (max-device-width: $screen-desktop) {
          margin-top: 35px;
        }
      }
    }

    .step.svg {
      button {
        @media screen and (max-device-width: $screen-desktop) {
          margin-top: 20px;
        }
      }
    }

    // Add photo step stuff
    .getting-started-tutorial {
      div.photo-container-outer {
        text-align: -webkit-center;
        text-align: -moz-center;
        display: table;
        margin: auto;
        padding-left: 30px;

        // TODO: check for tails variables
        div.photo-container:nth-child(1) {
          .pet-image-circle {
            background: #AAEECF;
          }
        }

        div.photo-container:nth-child(2) {
          .pet-image-circle {
            background: #FEBFBF;
          }
        }

        div.photo-container:nth-child(3) {
          .pet-image-circle {
            background: #FFDF99;
          }
        }
      }

      .photo-container {
        display: inline-block;

        h6 {
          text-align: -webkit-center;
          text-align: -moz-center;
          margin-left: -20px;
        }

        margin-bottom: 20px;
        border-radius: 50%;

        canvas {
          border-radius: 50%;
          overflow: hidden;
          cursor: pointer;
        }

        .pet-image-container {
          display: grid;
        }

        .pet-image-circle {
          border-radius: 50%;
          cursor: pointer;
          padding-left: 0;
          width: 120px;
          height: 120px;

          p {
            position: relative;
            text-align: -webkit-center;
            text-align: -moz-center;
            margin-top: -7px;
            padding: 0;
            left: 7px;
          }
        }

        .pet-image {
          width: 30px;
          margin-top: 29px;
          margin-left: 10px;
          // Fix alignment for Firefox
          @-moz-document url-prefix() {
            margin-left: 44px;
          }
          margin-right: 12px;
        }

        .croppa-container {
          background-color: transparent;
        }
      }

      .button-group {
        padding-top: 75px;
        margin: 0 auto;
        text-align: -webkit-center;
        text-align: -moz-center;
        max-width: 400px;

        a {
          margin-right: 20px;
          float: left;
          padding-top: 12px;
        }
      }

      @media screen and (max-width: $screen-tablet) {
        .button-group {
          padding-top: 0;
          max-width: 100vw;
          display: grid;

          .btn {
            max-width: 300px;
            padding-top: 20px;
          }

          a {
            display: inline;
            float: none;
            padding-top: 15px;
          }
        }
      }
    }

  }

  .getting-started-tutorial {
    a.back-link {
      @include until($screen-tablet) {
        position: absolute;
        top: 40px;
        left: 30px;
      }
      float: left;
      margin-left: -10px;
      margin-top: -10px;
      text-decoration: underline;
      vertical-align: -8px;
      margin-bottom: 10px;

      &:before {
        position: relative;
        left: -3px;
        display: inline-block;
        @include icon($icon-chevron-left);
        font-size: 1em;
        vertical-align: middle;
        text-decoration: none;
      }
    }
  }

  .close {
    display: inline-block;
    float: right;
    margin-right: -10px;
    margin-top: -10px;
    cursor: pointer;
    @media screen and (max-width: $screen-tablet) {
      border-radius: 50%;
      box-shadow: 0px 3px 5px 1px #a8a8a8a3;
    }
  }

  h3.add-a-photo {
    @include until($screen-tablet) {
      padding-left: 10px;
    }
  }

  .getting-started-tutorial .add-a-photo {
    text-align: center;
    @include until($screen-tablet) {
      text-align: left;
    }

    max-width: 500px;
    margin: 0 auto;
    padding-top: 20px;
    padding-right: 0px;
  }

  .getting-started-container {
    $desktop-modal-width: 900px;
    $desktop-padding: 40px;

    h3 {
      padding-bottom: 8px;
      padding-left: 20px;
      padding-right: 20px;

      &.tutorial-title {
        font-size: 2.6rem;
        @include until($screen-tablet) {
          text-align: left;
          margin-bottom: 0px;
          line-height: 40px;
          margin-top: 30px;
        }
      }

      &.tutorial-step-title.bold {
        margin-top: 22px;
        margin-bottom: 70px;
        font-size: 20px;
        @include until($screen-tablet) {
          text-align: left;
          margin-bottom: 0px;
          line-height: 40px;
          display: inline-block;
          width: 27em;
          margin-top: 30px;
        }
      }
    }

    h6 {
      font-size: 2.1rem;
      margin: 0;
      padding: 0;
      padding-top: 10px;

      &.avatar-pet-name {
        overflow-wrap: break-word;
      }
    }

    h5 {
      margin-left: 0px;
    }

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.45);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;

    .getting-started-tutorial {
      width: $desktop-modal-width;
      padding: $desktop-padding;
    }

    .getting-started-tutorial.does-overflow {
      height: 72vh;
    }

    .getting-started-tutorial .next-step {
      float: right;
      font-weight: 500;
      margin-bottom: 40px;
    }

    .getting-started-tutorial .prev-step {
      margin-bottom: 27px;
      font-weight: 500;
      text-decoration: none !important;
    }

    .getting-started-tutorial p a {
      padding-top: 10px;
      display: block;
      text-decoration: underline !important;
    }

    .getting-started-tutorial p {
      padding-top: 10px;
      display: block;
    }

    .getting-started-tutorial .subheading {
      font-size: larger;
    }

    .btn .next-step-right-chevron {
      display: inline;
    }

    .next-step .next-step-right-chevron svg {
      display: inline;
      width: auto;
      height: 17px;
      margin-left: 8px;
    }

    .getting-started-tutorial a .back-arrow svg {
      display: inline;
      width: auto;
      height: 17px;
      padding-right: 10px;
      margin-top: 3px;
    }

    .prev-step:hover {
      .back-arrow {
        svg {
          path {
            fill: #6DE2AD !important;
          }
        }

        cursor: pointer;
      }
    }

    .getting-started-tutorial p {
      margin-block-start: 0em;
      margin-block-end: 34px;
      padding-right: 0;
    }

    .getting-started-tutorial ul {
      padding-inline-start: 0px;

      li.ingredient-list {
        display: inline-flex;
      }

      li .prebiotic-image {
        height: 39px;
        width: 48px;
      }

      li .beet-image {
        height: 52px;
        width: 45px;
      }

      li .chondroitin-glucosamine {
        height: 52px;
        width: 45px;
      }

      li .omega3 {
        height: 32px;
      }

      li .ingredient-text p .ingredient-title {
        font-weight: bold;
      }

      img {
        margin-right: 14px;
        margin-top: 15px;
      }
    }

    .getting-started-tutorial .key-ingredients-title {
      font-weight: bold;
      margin-left: 0px;
    }

    .getting-started-tutorial .whats-in-dogs-food {
      font-weight: bold;
    }

    .getting-started-tutorial .getting-started {
      font-weight: bold;
    }

    .getting-started-tutorial .ingredients-rows {
      text-align: center;
      padding: 0px;
    }

    .getting-started-tutorial .ingredients-rows img {
      height: 40px;
    }

    .getting-started-tutorial .ingredients-rows.ingredients-text {
      font-weight: bold;
    }

    .getting-started-tutorial p .bold {
      font-weight: bold;
    }

    .seven-day-switch ul li * {
      display: inline-block;
    }

    .seven-day-switch ul li .switch-text {
      display: block;
      padding-left: 18px;
    }

    .seven-day-switch ul {
      list-style-type: none;
      padding-left: 0px;
    }

    .seven-day-switch ul li p {
      margin-block-end: 0px;
      font-size: 17px;
      padding-top: 0px;
      padding-bottom: 0px;
      display: table-row;
    }

    .seven-day-switch ul li .frac {
      font-size: 18px;
      display: inline-block;
    }

    .seven-day-switch ul li {
      display: flex;
      padding-top: 25px;
    }

    .seven-day-switch ul li .nearly-there {
      margin-top: 28px;
      margin-bottom: 28px;
      font-style: italic;
      padding-left: 82px;
    }

    .seven-day-switch ul li h6 {
      font-size: 18px;
      padding-top: 30px;
      display: table-row;
    }

    .seven-day-switch .placeholder {
      height: 50px;
      width: 60px;
      display: none;
      border-radius: 4px;
      background-color: #70cc87;
    }

    .getting-started-prompt {
      max-width: 550px;
      text-align: center;
      padding-top: 40px;
    }

    .getting-started-prompt p {
      padding-top: 15px;
      padding-left: 60px;
      padding-right: 60px;
    }

    .getting-started-prompt .buttons-wrapper-stacked {
      padding-top: 15px;
      width: 100%;
      padding-left: 100px;
      padding-right: 100px;
    }

    .getting-started-prompt .btn-secondary {
      box-shadow: none;
      color: #2f7f70;
    }

    .getting-started-prompt .buttons-wrapper-stacked button {
      width: 100%;
      padding-bottom: 10px;
    }

    .pad-8 {
      padding-bottom: 8px;
    }

    .pad-16 {
      padding-bottom: 16px;
    }

    .pad-32 {
      padding-bottom: 32px;
    }

    .pad-40 {
      padding-bottom: 40px;
    }


    .step {
      margin-bottom: 32px;
      display: flex;
    }

    .step h5 {
      font-size: large;
      padding-top: 10px;
      margin-left: 12px;
    }

    .step button {
      margin-left: auto;
      width: 160px;
      vertical-align: top;
    }

    .step .btn-visited {
      margin-left: auto;
      box-shadow: inset 0 0 0 2px #0a1f65;
      background-color: #fff;
      color: #0a1f65;
      font-weight: 400;
    }

    .step div,
    h5,
    button {
      margin-right: 10px;
      margin-left: 10px;
    }

    .tutorial-continue {
      float: right;
      margin-bottom: 40px;
    }

    .title {
      display: flex;
    }

    @media screen and (max-width: $screen-tablet) {
      h6 {
        font-size: 1.9rem;
      }
      .getting-started-inner {
        max-width: 100%;
        max-height: 100% !important;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        border-radius: 0px;
      }
      .getting-started-prompt {
        padding-top: 25vh;

        h3 {
          padding-left: 20px;
          padding-right: 20px;
        }

        p {
          padding-left: 20px;
          padding-right: 20px;
        }

        .buttons-wrapper-stacked {
          padding-left: 20px;
          padding-right: 20px;
        }
      }
      .step div,
      h5,
      button {
        margin-left: 10px;
      }
      .getting-started-tutorial p {
        margin-block-start: 0em;
        margin-block-end: 34px;
        padding-right: 20px;
      }
      .step button {
        margin-left: auto;
        width: 100%;
        margin-top: 1em;
      }
      .step {
        margin-bottom: 48px;
        display: block;
      }
      .photo-container {
        margin-right: 20px;
      }
      .button.btn-secondary.next-step {
        font-size: smaller;
      }
      .under-button {
        padding-top: 100px;
      }
    }

    @media screen and (min-device-width: 650px) and (max-device-width: 991px) {
      .getting-started-tutorial {
        padding: 100px;
      }
      .under-button {
        padding-top: 100px;
      }
    }
  }

  ::-webkit-scrollbar-track {
    background: none;
  }

  ::-webkit-scrollbar {
    width: 10px;
    @media screen and (max-width: $screen-tablet) {
      width: 6px;
    }
  }

  ::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 5px;
  }

  p {
    padding: 10px;
  }
}

.getting-started-tutorial {
  // seems to help with [ch44719]
  -webkit-overflow-scrolling: touch;

  .food-selection-img {
    max-width: 375px;
    margin-top: -40px;

    @include between(0px, 800px) {
      width: 100%;
    }
    @include between(800px, 1000px) {
      width: 100%;
      margin-top: -100px;
    }
  }
}

#mti-experiments-hat-icon.fa-graduation-cap {
  height: 20px;
  margin-right: 32px;
  margin-top: 4px;
  cursor: pointer;
}

#mti-experiments-tooltip {
  background: #040f2c;
  color: white;
  font-weight: bold;
  padding: 4px 8px;
  font-size: 13px;
  border-radius: 4px;
  top: 9px !important;
  font-weight: 400;
  display: none;

  #arrow,
  #arrow::before {
    position: absolute;
    width: 8px;
    height: 8px;
    z-index: -1;
  }

  #mti-experiments-tooltip {
    background: #040f2c;
    color: white;
    font-weight: bold;
    padding: 4px 8px;
    font-size: 13px;
    border-radius: 4px;
    top: 9px !important;
    font-weight: 400;
    display: none;

    #arrow,
    #arrow::before {
      position: absolute;
      width: 8px;
      height: 8px;
      z-index: -1;
    }

    #arrow::before {
      content: "";
      transform: rotate(45deg);
      background: #040f2c;
    }

    &[data-popper-placement^="top"] > #arrow {
      bottom: -4px;
    }

    &[data-popper-placement^="bottom"] > #arrow {
      top: -4px;
    }

    &[data-popper-placement^="left"] > #arrow {
      right: -4px;
    }

    &[data-popper-placement^="right"] > #arrow {
      left: -4px;
    }

    &[data-show] {
      display: block;
    }
  }

  .mti-experiments-notification-dot {
    height: 10px;
    width: 10px;
    top: -12px;
    right: 38px;
    background-color: #ed5f55;
    border-radius: 50%;
    display: inline-block;
    position: relative;
  }
}

.mti-experiments-notification-dot {
  height: 10px;
  width: 10px;
  top: -12px;
  right: 38px;
  background-color: #ed5f55;
  border-radius: 50%;
  display: inline-block;
  position: relative;
}


.tutorial-space-v2-new-food {
  @include at-least($screen-phone) {
    text-align: center;
    align-content: center;
    text-align: -webkit-center;
    text-align: -moz-center;
  }

  .sean-quote-box {
    margin-top: 50px;
    width: 35em;
    background-color: #F9F7F2;
    border-radius: 10px;

    @media screen and (max-device-width: 600px) {
      width: 90vw;
      margin-top: 30px;
    }

    #sean-avatar-round {
      border-radius: 60px;
      height: 60px;
      width: 60px;
      position: relative;
      bottom: 30px;

      @media screen and (max-device-width: 600px) {
        position: static;
        float: left;
        margin-left: 16px;
        margin-top: 23px;
      }
    }

    .quote-content {
      height: 150px;
      position: relative;
      bottom: 12px;
      @media screen and (max-device-width: 600px) {
        height: initial;
      }

      h5 {
        width: 325px;
        font-weight: bold;

        @media screen and (max-device-width: 600px) {
          padding-top: 36px;
          width: unset;
          margin-left: 90px;
          text-align: left;
          line-height: 28px;
        }
      }

      p {
        padding: 0px;
        margin-top: 10px;
        margin-right: 30px;
        margin-left: 30px;
        line-height: 28px;

        @media screen and (max-device-width: 600px) {
          text-align: left;
          margin-right: 20px;
          margin-left: 20px;
          line-height: 24px;
          margin-top: 25px;
          padding-bottom: 12px;
        }
      }
    }
  }

  .seven-day-switch {
    margin-top: 50px;

    h5 {
      font-weight: bold;

      &.title {
        font-size: 22px;
        margin-bottom: 36px;
        display: block;
        text-align: center;

        @media screen and (max-device-width: 600px) {
          text-align: left;
        }
      }
    }

    p {
      padding: 0px;
    }

    table.seven-day-switch-table {
      @media screen and (max-device-width: 600px) {
        display: none;
      }
      width: 35em;
      text-align-last: center;
      margin-bottom: 60px;

      #placeholder-round {
        border-radius: 60px;
        height: 90px;
        width: 90px;
      }

      tbody tr td p {
        margin-block-end: 0px;
        line-height: normal;
      }

      .switch-step {
        margin-bottom: 10px;
      }
    }

    ul.seven-day-switch-list {
      @media screen and (min-device-width: 600px) {
        display: none;
      }

      text-align-last: center;
      margin-bottom: 60px;

      img {
        margin-right: 0px;
        margin-top: 0px;
      }

      li {
        padding-top: 0px;
      }

      .switch-step-info {
        display: grid;
        text-align-last: left;
        align-self: center;
        position: relative;
        top: 6px;

      }

      #placeholder-round {
        border-radius: 60px;
        height: 90px;
        width: 90px;
      }

      p {
        margin-block-end: 0px;
        line-height: normal;
        margin: 0px;
      }

      .switch-step {
        margin: 0px;
      }

      .switch-step-text {
        font-size: 14px;
      }
    }

    .below-table {
      @media screen and (max-device-width: $screen-tablet) {
        width: initial;
        text-align: left;
      }

      p {
        line-height: 25px;
        width: 30em;

        @media screen and (max-device-width: $screen-tablet) {
          width: initial;
        }
      }

      a {
        text-decoration-line: underline;
      }
    }
  }
}


.tutorial-space-v2-make-changes {
  @include at-least($screen-phone) {
    text-align: center;
    align-content: center;
    text-align: -webkit-center;
    text-align: -moz-center;
  }
  padding-top: 140px;
  @include at-least($screen-tablet) {
    background-position-y: -389px;
  }

  p.tutorial-step-subtitle {
    padding-right: 0px;
    max-width: 29em;
    line-height: 28px;
    margin-block-end: 20px;

    @media screen and (max-device-width: $screen-tablet) {
      text-align: left;
      align-content: left;
      text-align: -webkit-left;
      text-align: -moz-left;
      margin-bottom: 0px;
    }
  }


  #dry-food-bag {
    z-index: 5;
    position: relative;
  }

  button.btn.btn-secondary.btn-section-block {
    box-shadow: inset 0 0 0 2px #6de2ad;
    height: 17px;
    min-width: 200px;
    position: relative;
    margin-left: 21px;
    top: 2px;
    margin-bottom: 30px;

    @media screen and (max-device-width: $screen-tablet) {
      margin-left: 0px;
      margin-top: 14px;
      margin-right: 90px;
      float: left;
    }

    span.next-step-right-chevron {
      svg {
        height: 17px;
        width: 20px;
        position: relative;
        margin-left: 21px;
        top: -37px;
        left: 64px;
      }
    }

    p {
      display: block;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding-top: 5px;
      margin-block-start: 0em;
      margin-block-end: 0px;
      padding-right: 0px;
      position: relative;
      bottom: 5px;
      left: -10px;
    }
  }

  .section-block {
    @include until($screen-tablet) {
      width: 100%;
      display: flex;
      flex-flow: column wrap;
      align-items: baseline;
      text-align: left;
    }
  }
}

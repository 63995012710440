body.account.christmas-campaign {

  .main {
    min-height: 0;
  }

  .image-header {
    max-width: 1260px;
    position: relative;

    .image {
      width: 100%;
      margin-top: -50px;

      height: 70vw;
      min-height: 320px;
      background: center top / cover url("/static/images/christmas/hero-mobile.jpg");

      @include until($screen-phone) {
        clip-path: circle(400px at 50% -30%);
      }

      @include at-least(700px) {
        background: url("/static/images/christmas/hero-desktop.jpg");
        background-size: cover;
        min-height: 0;
        height: 392px;
        max-width: 850px;
        text-align: right;
        margin: -100px auto 0 0;
        clip-path: none;
      }
    }

    .text {
      text-align: center;

      h1 {
        font-family: $christmas-font-family;
        margin: 20px 0;
        @include at-least(1150px) {
          width: 100%;
        }
      }

      p {
        font-size: 20px;
      }

      position: relative;
      margin: auto;
      width: 100%;
      padding: 20px;
      @include at-least(1050px) {
        position: absolute;
        width: 600px;
        right: 0;
        bottom: 140px;
        padding: 0;
      }
    }

    .red-circle {
      position: absolute;
      margin: auto;
      left: 265px;
      bottom: 215px;
      background-color: #B72336;
      color: white;
      width: 95px;
      height: 95px;
      border-radius: 50%;
      @include at-least($screen-phone) {
        left: 40px;
        bottom: 150px;
      }
      @include at-least(1050px) {
        left: 40px;
        bottom: 40px;
      }

      .inner-text {
        font-size: 1.7rem;
        line-height: 2.5rem;
        position: absolute;
        text-align: center;
        width: 95px;
        top: 25%;
        text-align: center;
      }

      img {
        position: absolute;
        bottom: 19px;
        right: 26px;
      }
    }
  }


  .content {
    padding-bottom: 50px;

    .container.clearfix {
      max-width: 1260px;
      @include until($screen-phone) {
        margin: 0;
        padding: 0;
      }
    }

    .products {
      background-color: $pastel-beige;
      position: relative;
      padding-bottom: 50px;
    }

    .heading {
      margin-top: 20px;
      @include at-least(1150px) {
        margin-top: 45px;
      }
    }

    .about-bullets {
      list-style: none;
      padding: 15px 0 25px 0;
      margin: 0 20px;
      @include at-least($screen-tablet) {
        display: flex;
        max-width: 1260px;
        margin: 0 auto;
        justify-content: space-around;
      }

      li {
        padding: 12px 0;
        line-height: 1.75;
        display: flex;
        align-items: center;

        img {
          padding-right: 10px;
          height: 40px;
          margin: auto 0;
        }
      }
    }

    .title-images {
      display: flex;
      justify-content: space-around;
      padding-top: 24px;
      max-width: 1260px;
      margin: 0 auto;

      .decoration {
        display: none;
      }

      @include at-least(950px) {
        max-width: 750px;
        .decoration {
          display: block;
        }
      }
      @include at-least(1150px) {
        max-width: 1260px;
        .decoration {
          display: block;
        }
      }

      .title {
        padding-top: 45px;
      }
    }

    .frame {
      img, .line {
        position: absolute;
      }

      .line {
        border: 2px solid #B72336;
        box-sizing: border-box;
      }

      .top {
        top: 40px;
        @include until($screen-phone) {
          top: 20px;
        }
      }

      .bottom {
        bottom: 40px;
        @include until($screen-phone) {
          bottom: 20px;
        }
      }

      .left {
        left: 40px;
        @include until($screen-phone) {
          left: 20px;
        }
      }

      .right {
        right: 40px;
        @include until($screen-phone) {
          right: 20px;
        }
      }

      .horizontal {
        height: 10px;
        border-left: none;
        border-right: none;
        left: 89px;
        right: 89px;
        @include until($screen-phone) {
          left: 69px;
          right: 69px;
        }
      }

      .vertical {
        width: 10px;
        border-top: none;
        border-bottom: none;
        top: 89px;
        bottom: 89px;
        @include until($screen-phone) {
          top: 69px;
          bottom: 69px;
        }
      }
    }

    .intro-text {
      max-width: 250px;
      text-align: center;
      margin: 40px auto;
      padding: 0;
      @include at-least(840px) {
        max-width: 500px;
      }
    }

    .section-title {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 50px 0 30px 0;
      h2 {
        font-family: $christmas-font-family;
        color: #B72336;
      }
      img {
        margin: 0 10px;
      }
    }

    .product-section {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin: 0 auto;
      align-items: center;
      max-width: 300px;

      @include at-least(840px) {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        max-width: 500px;
      }

      @include at-least(950px) {
        max-width: 750px;
      }

      @include at-least(1150px) {
        max-width: 975px;
      }

      .product-card {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        border-radius: 8px;
        width: 250px;
        margin-bottom: 20px;
        position: relative;

        @include at-least(840px) {
          width: 220px;
          &.large {
            max-width: 300px;
            width: 300px;
          }
        }
        @include at-least(1150px) {
          width: 220px;
        }

        .puppy-badge {
          background-color: #B72336;
          color: $primary-white;
          width: fit-content;
          padding: 0 10px;
          height: 28px;
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: $font-size-xs;
          border-radius: 5px;
          top: 185px;
          left: 5px;
        }

        .stock-badge-container {
          display: flex;
          justify-content: flex-end;

          .stock-badge {
            background-color: #7CAF8D;
            width: fit-content;
            padding: 0 15px;
            height: 32px;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: $font-size-xs;
            font-weight: bold;
            border-top-right-radius: 8px;
          }
        }

        .product-image {
          width: 100%;
          height: 220px;
          display: block;
          border-radius: 8px 8px 0 0;
        }

        .product-info {
          background-color: white;
          min-height: 110px;
          display: flex;
          flex-direction: column;
          border-radius: 0 0 8px 8px;
          padding: 15px 10px;

          .product-title {
            color: $primary-colour;
            font-weight: 500;
            text-decoration: none;
            font-size: $font-size-s;
          }

          .unit {
            color: $navy-03;
            font-size: $font-size-s;
          }

          .product-price {
            margin-top: 10px;
            font-size: $font-size-l;
            font-weight: 400;
          }
          .pre-discount {
            color: $navy-01;
          }
        }
      }
    }

    .bottom-image {
      width: 251px;
      height: 117px;
      margin: 40px auto;
      background-size: contain;
      background: url("/static/images/svgs/icon/christmas/plp_bottom_mobile.svg") no-repeat center top;

      @include at-least(1050px) {
        width: 850px;
        height: 140px;
        background: url("/static/images/svgs/icon/christmas/plp_bottom_desktop.svg") no-repeat center top;
      }
    }

    .section {
      text-align: center;
      margin: 40px auto 0 auto;

      img {
        height: 300px;
      }

      h2 {
        margin: 30px 0;
        font-family: $christmas-font-family;
      }
    }
  }
}

body.account.christmas-product {
  .container .clearfix {
    max-width: 940px;
  }

  .messaging-container {
    background-color: $pastel-lighter-frost;
    border: 2px solid $secondary-04;
    margin: 0 auto 27px auto;
    width: 100%;
    max-width: 550px;
    border-radius: 7px;
    padding: 15px;

    &:before {
      @include icon($icon-information);
      margin: 5px;
      font-size: 25px;
    }

    .messaging {
      width: calc(100% - 40px);
      display: inline-block;
      vertical-align: top;
      padding-left: 10px;
    }
  }

  .notifications {
    width: 95%;
    margin: auto;
  }

  .main {
    margin-top: 0;
  }

  .title {
    position: relative;
    margin: auto;
    width: 300px;
    height: 185px;
    @include at-least($screen-tablet) {
      height: 100px;
      width: 550px;
    }
    .text {
      @include flexbox(row, center, center);
      text-align: center;
      font-family: $christmas-font-family;
      color: #B72336;
      font-size: 3rem;
      position: absolute;
      top: 0;
      left: 100px;
      right: 100px;
      height: 155px;
      width: auto;
      @include at-least($screen-tablet) {
        left: 130px;
        right: 130px;
        height: 105px;
      }
    }

    .image {
      position: absolute;
      background-size: contain;
      background-repeat: no-repeat;
      width: 90px;
      height: 150px;
      top: 0;
      @include at-least($screen-tablet) {
        width: 115px;
        height: 96px;
      }
      &.left {
        left: 0;
        background-image: url("/static/images/svgs/icon/christmas/pdp-title-left-mobile.svg");
        @include at-least($screen-tablet) {
          background-image: url("/static/images/svgs/icon/christmas/pdp-title-left-desktop.svg");
        }
      }
      &.right {
        right: 0;
        background-image: url("/static/images/svgs/icon/christmas/pdp-title-right-mobile.svg");
        @include at-least($screen-tablet) {
          background-image: url("/static/images/svgs/icon/christmas/pdp-title-right-desktop.svg");
        }
      }
    }
    .line {
      position: absolute;
      border: 2px solid #B72336;
      box-sizing: border-box;
      height: 10px;
      border-left: none;
      border-right: none;
      left: 85px;
      right: 85px;
      @include at-least($screen-tablet) {
        left: 114px;
        right: 114px;
      }
      &.top {
        top: 1px;
      }
      &.bottom {
        top: 139px;
        @include at-least($screen-tablet) {
          top: 85px;
        }
      }
    }
  }

  .main-section {
    background-color: $pastel-beige;
    padding-bottom: 40px;

    .product-wrapper {
      @include at-least($screen-tablet) {
        width: 100%;
        max-width: 940px;
        min-height: 500px;
        margin: 0 auto;
        display: flex;
        align-items: center;
      }

      .product {
        @include at-least($screen-tablet) {
          display: flex;
        }
      }

      .product-description-wrapper {
        @include at-least($screen-tablet) {
          width: 60%;
          margin-left: 100px;
        }

        .product-description {
          padding: 0 15px;
          @include at-least($screen-tablet) {
            padding: 0;
          }

          @include at-least($screen-tablet) {
            p.description {
              margin-top: 0;
            }
          }

          .description {
            font-weight: 600;
          }

          .sub-text {
            font-size: $font-size-xs;
          }

          .charity-msg {
            background: #44825f;
            color: white;
            border-radius: 6px;
            padding: 16px;
            margin: 20px auto;

            display: flex;
            justify-content: center;
            align-items: center;

            .img-wrapper {
              display: inline-block;
              text-align: center;
            }

            img {
              height: 58px;
              margin-right: 16px;
            }

            .msg-wrapper {
              display: inline-block;
              flex: 1;
              font-weight: bold;
            }
          }
        }

        .divider {
          border-top: 1px solid $navy-00;
          width: 90%;
          margin: 30px auto 20px auto;
        }

        .price-details {
          padding: 20px;
          font-size: $font-size-body;

          @include at-least($screen-tablet) {
            padding: 0;
            text-align: left;
          }

          .price {
            font-size: $font-size-l;
            .small {
              font-size: $font-size-xs;
            }
            .pre-discount {
              color: $navy-01;
            }
          }

          .white-box {
            margin: 1rem 0 2rem 0;
            width: auto;
            display: flex;
            align-items: center;
            text-align: left;


            .white-box-content {
              background-color: $primary-white;
              padding: 1.5rem 1rem;
              border-radius: 5px;
              margin: auto;
              @include grid-blocks(3);

              @include at-least($screen-tablet) {
                margin: 0;
              }

              .icon {
                color: $primary-white;
                font-size: 18px;
                background-color: $navy-00;
                border-radius: 50%;
                padding: 10px;
                margin-right: 10px;
              }
            }
          }

          .counter {
            @include grid-blocks(3);
            margin: 20px auto 10px auto;
            @include at-least($screen-tablet) {
              @include grid-blocks(2.5);
            }
          }

          .save {
            font-weight: bold;
            @include grid-blocks(3);
            @include at-least($screen-tablet) {
              @include grid-blocks(2.5);
            }
          }
        }
      }

      .image-wrapper {
        margin: 15px 0 25px 0;

        @include at-least($screen-tablet) {
          margin: 0;
        }

        .product-image-inner {
          background-color: $pastel-beige;

        .stock-badge-container {
          display: flex;
          justify-content: flex-end;

          .stock-badge {
            background-color: #7CAF8D;
            width: fit-content;
            padding: 0 15px;
            height: 32px;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: $font-size-xs;
            font-weight: bold;
            border-top-right-radius: 8px;
          }
        }

          .product-image {
            border-radius: 8px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: top;
            background-origin: content-box;
            height: 350px;
            width: 350px;
            margin: auto;
          }
        }

        .thumbnail-images {
          margin: auto;
          display: flex;
          justify-content: center;
          align-items: center;


          .thumbnail-product-image {
            width: 50px;
            height: 50px;
            border-radius: 8px;
            background-color: rgba(255, 255, 255, 0.3);
            margin: 15px 7px;
            padding: 2px;
            cursor: pointer;

            &.selected {
              background-color: $primary-white;
            }
          }

          .icon {
            display: inline;
            font-size: $font-size-m;
            margin: 12px;
            cursor: pointer;
            color: rgba(128, 128, 128, 0.5);

            &.active {
              color: black;
            }
          }
        }
      }
    }
  }


  .content {
    padding-bottom: 0;

    .back-link {
      padding: 20px 10px;
      display: flex;
      text-decoration: none;

      span {
        padding-right: 5px;
        margin: auto 0;
      }
      @include at-least($screen-tablet) {
        padding: 40px;
      }
    }

    .box-contents {
      .contains-copy {
        padding: 20px 0 10px 0;
        font-size: $font-size-l;
      }

      .inner-product {
        max-width: 650px;
        margin: 20px 0 35px;

        .inner-product-info {
          display: flex;
          @include at-least($screen-tablet) {
            margin-bottom: 15px;
          }

          .inner-product-image {
            @include grid-blocks(1);
            height: 100%;
            border-radius: 8px;
            @include at-least($screen-tablet) {
              max-width: 150px;
            }
          }

          .inner-product-info-sub {
            padding: 0 20px;
            margin: auto 0;

            .inner-product-name {
              font-weight: bold;
              padding-bottom: 10px;
              line-height: 24px;
              .unit {
                font-weight: normal;
                color: $navy-03;
                @include at-least($screen-tablet) {
                  margin-left: 10px;
                }
              }
            }
          }
        }

        .expander {
          border-top: 1px solid #D8D8D8;
          border-bottom: 1px solid #D8D8D8;
          padding: 20px;
          color: #155654;
          font-weight: bold;

          summary {
            cursor: pointer;
            list-style-type: none;

            .grid {
              display: grid;
              grid-template-columns: 1fr 0fr;
              align-items: start;
              justify-content: start;
              justify-items: stretch;
            }

            .icon {
              color: #155654;
              font-size: $font-size-m;
              padding-right: 20px;
              vertical-align: text-bottom;
              font-weight: bold;
            }

            .icon-chevron-up {
              font-size: 17px;
              margin: auto 0;
            }

          }

          summary:focus {
            outline: none;
          }

          /* Firefox */
          summary::-webkit-details-marker {
            display: none;
          }

          /* Chrome */
          summary::marker {
            display: none;
          }
        }

        details {
          .icon-toggle-open {
            display: inline;
          }

          .icon-chevron-up {
            display: none;
          }
        }

        details[open=""] {
          .icon-toggle-open {
            display: none;
          }

          .icon-chevron-up {
            display: inline;
          }

          summary {
            padding-bottom: 15px;
          }
        }
      }
    }

    .info {
      max-width: 650px;
      margin-top: 50px;
      @include at-least($screen-tablet) {
        margin-top: 0;
      }

      .section-info {
        margin: 15px 0 20px 0;
        h4 {
          font-size: $font-size-l;
        }
      }
    }

    p.small {
      font-size: $font-size-xs;
    }

    .charity {
      margin-top: 40px;
    }

    .bottom-image {
      width: 251px;
      height: 117px;
      margin: 60px auto 40px auto;
      background-size: contain;
      background: url("/static/images/svgs/icon/christmas/plp_bottom_mobile.svg") no-repeat center top;

      @include at-least($screen-tablet) {
        width: 850px;
        height: 140px;
        background: url("/static/images/svgs/icon/christmas/plp_bottom_desktop.svg") no-repeat center top;
      }
    }
  }
}

.macro-portion-module {
  @include grid-blocks(6);
  display: flex;
  background-color: $primary-white;
  margin: 0 auto;
  padding: 15px;
  border-radius: 8px;
  @include at-least($screen-tablet){
    padding: 20px 40px 20px 20px;
  }
  .icons-info {
    margin-right: 15px;
  }
}

.macro-complete-section {
  .heading {
    background-color: $pastel-lighter-frost;
    padding: 20px;
    .title {
      @include grid-blocks(6);
      margin: 0 auto;
      margin-bottom: 20px;
    }
  }

  .complete-section-inner {
    padding: 0 20px;
    max-width: 680px;
    margin: 0 auto;
  }
}

.macro-kibble-module {
  @include grid-blocks(6);
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  flex-direction: column;
  .flavour {
    font-family: $handwritten-font-family;
    font-size: $font-size-m;
    padding: 10px 0 0 0;
    margin-bottom: $spacing-xs;

    &:first-letter {
      text-transform: uppercase;
    }
  }
  .summary-wrapper {
    display: flex;
    flex-direction: column;
    @include at-least($screen-desktop) {
      flex-direction: row;
      justify-content: space-between;
    }
  }
  .hypoallergenic-sticker {
      border-radius: 8px;
      background-color: $navy-01;
      color: $primary-white;
      height: 40px;
      width: 160px;
      display: flex;
      justify-content: center;
      align-items: center;
    & div {
      text-overflow: ellipsis;
      overflow: hidden;
      line-height: 40px;
      vertical-align: middle;
      text-align: center;
      font-size: $font-size-xs;
      &::before {
        content: '';
        background-image: url('/static/images/hypoallergenic/icons_info_hypoallergenic_light@2x.png');
        width: 20px;
        height: 22px;
        background-size: contain;
        background-repeat: no-repeat;
        display: inline-block;
        position: relative;
        margin-right: $spacing-xxs;
        top: 6px;
      }
    }
  }
  .kibbles {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: $spacing-m 0;
    @include at-least($screen-desktop) {
      flex-direction: row;
    }
  }
  .kibble {
    flex-basis: 50%;
    margin-top: 10px;
    @include grid-blocks(3);
    @include flexbox(row, center, flex-start);
    img {
      height: 65px;
      width: 65px;
    }
    .text {
      margin-left: 20px;
      p {
        margin: 0;
        @include at-least($screen-phone) {
          span {
            display: block;
          }
        }
      }
    }
  }
}

.macro-claims-module {
  @include grid-blocks(6);
  margin: 20px 0;
  img {
    width: 45px;
    margin-left: 20px;
  }
  .text {
    @include grid-blocks(4);
  }
  .claim {
    @include flexbox(row-reverse, flex-start, flex-end);
    margin: 0 auto;
    padding: 10px 20px;
    h5 {
      margin-top: 0;
    }
  }
}

.macro-ingredients {
  &.wet-food {
    padding: 0 20px;
    max-width: 680px;
    margin: 0 auto;
  }

  details {
    width: 100%;
    min-width: 280px;
    max-width: 640px;
    margin: 0 auto;
    border-top: 1px solid $pastel-darker-silver;
    border-bottom: 1px solid $pastel-darker-silver;
    margin-bottom: 20px;
    cursor: pointer;

    &[open]{
      > summary::after {
        transform: rotate(180deg);
      }
    }

    &.closing {
      > summary::after {
        transform: rotate(0deg);
      }
    }

    > summary {
      display: flex;
      height: 48px;
      align-items: center;
      justify-content: space-between;

      &:focus-visible {
        outline: none;
      }

      @media (hover: hover) {
        &:hover span {
          text-decoration: underline;
          color: $primary-colour;
        }
      }

      &:active span {
        color: $primary-cta;
        text-decoration: underline;
      }

      span {
        flex: 0 0 calc(100% - 60px);
        font-size: $font-size-s;
        color: $primary-text-link;
        padding-left: $spacing-xxs;
        transition: color .2s ease;

        &:focus-visible {
          outline: 0;
          outline: 0;
          box-shadow: 0 0 10px 2px $secondary-03;
        }
      }

      &::after {
        content: '';
        display: block;
        width: 48px;
        height: 48px;
        flex: 0 0 48px;
        margin-left: 12px;
        background-image: url("https://tails.cdn.prismic.io/tails/dae1ce8c-1790-40ac-8c9e-5b8d4ddcc661_accordion-chevron.svg");
        background-size: 28px;
        background-repeat: no-repeat;
        background-position: center center;
        transition: transform .4s ease;
      }
    }

    .accordion-content {
      padding: 4px 8px 10px;

      section {
        position: relative;

        &:not(:first-child) {
          padding-top: 20px;
        }

        .tip-input ~ .with-tip .tip {
          width: 100%;
          left: 0;
        }
        &.additives {
          p {
            margin-bottom: 0;
          }
          .additives-table {
            max-height: 200px;
            overflow: scroll;
            margin: 20px 0;
            font-size: $font-size-s;
          }
        }
        &.products{
          @include grid-blocks(6);
          @include flexbox(row, flex-start, space-between);
          display: flex;
          flex-wrap: wrap;
          font-size: $font-size-s;
          img {
            height: 55px;
            margin-right: 5px;
            border-radius: 12px;
          }
          .product {
            @include grid-blocks(3);
            @include flexbox(row, center, flex-start);
            padding: 10px;
            line-height: 1.5em;
          }
        }
        &.ingredients-link {
          margin-left: 20px;
        }
      }
    }
  }
}

.macro-nutribar {
  margin: 0 auto;
  padding: 0 10px;
  @include grid-blocks(8);
  @include at-least($screen-phone) {
    padding: 10px;
  }

  .active-calories:nth-of-type(1) {
    .calories-amount {
      background-color: $food-dry;
    }
  }
  .active-calories:nth-of-type(2) {
    .calories-amount {
      background-color: $food-wet;
    }
  }
  .active-calories:nth-of-type(3) {
    .calories-amount {
      background-color: $food-other;
    }
  }

  .calories-amount {
    background-color: $pastel-lighter-silver;
    font-size: 1.1rem;
    text-align: center;
    margin-bottom: 15px;
    width: 100%;
    padding: 15px;
    @include at-least($screen-tablet) {
      font-size: $font-size-xs;
      padding: 20px;
    }

    &.first-block {
      border-radius: 30px 0 0 30px;
      &.single-block {
        border-radius: 30px
      }
    }
    &.two-blocks-only{
      border-radius: 0px 30px 30px 0px;
    }
    &.last-block {
      border-radius: 0 30px 30px 0;
    }
  }
  .wrap {
    margin-top: 30px;
    @include flexbox(row, center, space-around);
    .food-type {
      .caret {
        @include triangle(25px, $pastel-lighter-silver, up);
      }
      &.active-calories:nth-of-type(1) {
        .caret {
          @include triangle(25px, $pastel-rose, up);
        }
      }
      &.active-calories:nth-of-type(2) {
        .caret {
          @include triangle(25px, $pastel-lighter-sage, up);
        }
      }
      &.active-calories:nth-of-type(3) {
        .caret {
          @include triangle(25px, $pastel-lavender, up);
        }
      }
      &.dry-food, &.wet-food, &.other-food {
      min-width: 115px;
      @include flexbox(column, center, space-around);
        @include at-least($screen-tablet) {
          min-width: 180px;
        }
        img {
          height: 45px;
        }
      }
      &.wet-food, &.other-food {
        margin-left: 1px;
        @include at-least($screen-tablet) {
          margin-left: 3px;
        }
      }
    }
  }
}

body.signup-price-summary-redesign {
    .nutribar-price-page {
        padding: 20px 0 20px 0;
        border-top: 1px solid $pastel-darker-silver;
      .macro-nutribar {
        h5 {
          font-family: $handwritten-font-family;
          font-size: $font-size-l;
        }
      }
    }
}

body.signup-price-summary-redesign {
  .nutribar-price-page {
    .macro-nutribar {
      h5.feeding-plan-v6 {
        font-family: $default-font-family;
        font-size: $font-size-m;
      }
    }
  }
}

body.signup-price-summary {
  .nutribar-price-page {
    margin-top: 40px;
    padding: 0;
    .macro-nutribar {
      h5 {
        font-size: $font-size-m;
      }
    }
  }
  .payment-summary-container {
    tbody tr.nutribar-row {
      background: transparent;
      td {
        padding: 0 0 30px 0;
      }
    }
  }
}


.nutribar-price-page {
  text-align: left;
  .macro-nutribar {
    padding: 0;
    .calories-amount{
      font-size: $font-size-m;
      &.last-block {
        background-color: $pastel-lavender;
      }
    }
    .wrap{
      .food-type {
        .calories-amount {
          margin-top: 8px;
        }
        &.dry-food, &.wet-food, &.other-food {
          min-width: 15%;
        }
        &.wet-food, &.other-food{
          margin-left:0;
        }
      }
    }
  }
}

.macro-treats-selection {
  background-color: $product-info-background;
  padding: 20px;

  .treat {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 28% auto;
    grid-template-rows: 50% 50%;

    @include at-least($screen-tablet) {
      display: grid;
      grid-template-columns: 20% auto;
      grid-template-rows: 40% 60%;

    }

    .image {
      width: 80px;
      height: auto;
      margin-right: 10px;

      @include at-least($screen-tablet) {
        width: 100px;
        grid-row-end: span 2;
        margin: 10px;
      }
    }

    .treat-info {
      display: inline-block;
      margin: auto 0;

      .sub-section {
        @include at-least($screen-tablet) {
          display: flex;
        }

        p {
          margin-bottom: 0;
          @include until($screen-tablet) {
            line-height: unset;
          }
        }
      }

      .category {
        font-weight: bold;
      }
    }

    .serving-size {
      grid-column-end: span 2;
      height: 70px;
      @include grid-blocks(6);
      border-radius: 8px;
      background-color: #FFFFFF;
      margin: 10px auto;
      padding: 12px;
      display: flex;
      align-items: center;

      @include at-least($screen-tablet) {
        width: 100%;
        margin: 5px 0;
        grid-column-end: span 1;
      }

      .icon {
        color: $navy-01;
        background-color: $pastel-lighter-lavender;
        border-radius: 50%;
        font-size: 28px;
        padding: 9px;
        margin: 0 10px 0 5px;
        height: 45px;
        width: 45px;
      }

      .amount {
        margin: 1rem 0 1rem 10px;
        line-height: 1.67;
      }
    }
  }
}

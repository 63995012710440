
/*
 * Payment details
 */
.panel-payment-summary {
    /* Should be in web.scss but don't want it to bleed over */
    .add-pet-form-group{
        text-align: right;
    }
    .add-pet {
        border: none;

        display: inline-block;
        padding: 2px 10px;
        > * {
            display: inline-block;
            vertical-align: middle;
            cursor: pointer;
        }
        i {
            margin-left: .4em;
        }
    }
    .add-pet {
        padding-right: 0px;
        margin-bottom: 5px;
    }
}

body.signup-payment {
    @include bg-cover-image($signup-background);
}

body.signup-payment,
body.signup-price-summary {
    .pet-roundal-selector {
        background: #fff;
        padding: 10px;
        margin-left: -10px;
        margin-right: -10px;
        a {
            text-decoration: none;
        }
    }

    h2 {
        margin-top: 50px;
    }

    .no-commitment {
        text-align: center;
        margin: 40px 0;
    }

    .upsell-widget-nav {
        background: #fff;
        display: flex;
        margin-left: -10px;
        margin-right: -10px;

        a {
            flex-grow: 1;
            text-align: center;
            padding: 10px;
            border-bottom: 2px solid #ccc;
            cursor: pointer;
            color: $navy-01;
            text-decoration: none;

            &.selected {
                background: $navy-01;
                color: #fff;
                border-bottom-color: #2583e2;
            }

            ~ a {
                border-left: 1px solid #e2e2e2;
            }
        }

        @include until ($screen-phone) {
            display: none;
        }
    }

    .widget-wrapper {
        text-align: center;
        h2 {
            text-align: center;
            margin-bottom: 15px;
        }
        .offer-text {
            color: $secondary-01;
        }
        .free {
            text-transform: uppercase;
            font-weight: bold;
            text-decoration: underline;
        }
    }

    .tails-view-single-product-widget {
        margin: 0 -15px;
        width: calc(100% + 30px);
        display: inline-block;

        @include at-least($screen-tablet) {
            margin: auto;
            width: 100%;
        }
    }

    .payment-summary-wrapper {
        position: relative;
        margin: 0 -10px;
        border-radius: 4px;

        .ongoing-deliveries {
            margin: 15px;
        }
        @include at-least($screen-tablet) {
            margin: 0;
            .ongoing-deliveries {
                margin: 15px 0;
            }
        }
    }

    .hack-wetfood {
        display: block;
    }
}
body.signup-price-summary,
body.signup-price-summary-redesign {
    .widget-wrapper {
        overflow: hidden;
        margin: 40px 0;
    }
    .widget-wrapper-redesign {
        margin: $spacing-s 0;
        padding: 0;
    }
    #promo-popup {
        display: none;
    }
    #promo-popup:checked ~ .promo-popup {
        opacity: 1;
        transform: scale(1);
        pointer-events: initial;
    }
    #promo-popup:checked ~ .promo-popup-cover {
        opacity: 0.4;
        pointer-events: initial;
    }
    .tails-view-promo-popup {
        @include inalign(middle);
        position: relative;
        width: 100%;
        z-index: 2;
        .promo-popup-cover {
            position: fixed;
            top: 0;
            left: 0;
            height: 100%;
            background: $primary-colour;
            width: 100%;
            z-index: 0;
            opacity: 0;
            pointer-events: none;
        }
        .offer-text {
            display: none;
            color: $secondary-01;
            white-space: normal;
        }

        @include at-least($screen-phone) {
            .offer-text {
                max-width: none;
            }
        }

        #nocode {
            margin-bottom: 5px;
        }

        .error {
            color: $product-error;
            font-weight: normal;
            margin: 0;
            &:empty {
                display: none;
            }
            span {
                margin: 10px 0;
                display: block;
            }
        }

        .promo-popup {
            @include transition(opacity 0.3s, transform 0.3s);
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            border-radius: 6px;
            transform: rotateX(90deg) scale(.7);
            opacity: 0;
            pointer-events: none;
            background-color: $primary-white;
            color: $navy-01;
            position: fixed;
            top: 40%;
            width: 90%;
            max-width: 610px;
            text-align: center;
            padding: 30px 10px;
            line-height: 1;
            input, button[type=submit] {
                @include inalign(middle);
                margin-bottom: 10px;
                margin-top: 10px;
            }
            input[type=text] {
                max-width: 80%;
                min-width: 80%;
                padding-right: 0;
                text-align: center;
                padding-left: 0;
                margin-top: 10px;
                margin-bottom: 10px;
            }
            button[type=submit] {
                display: block;
                margin: 20px auto;
                max-width: 60%;
                min-width: 60%;
            }
            label.close {
                cursor: pointer;
                top: 10px;
                right: 10px;
                position: absolute;
                box-shadow: none;
                opacity: 1;
                text-decoration: none;
                &:before {
                    @include icon($icon-cross-circle);
                }
            }
        }
    }

//    CALORIE DENSITY MODAL
    #calorie-density-popup {
        display: none;
    }
    #calorie-density-popup:checked ~ .calorie-density-popup {
        opacity: 1;
        transform: scale(1);
        pointer-events: initial;
    }
    #calorie-density-popup:checked ~ .calorie-density-popup-cover {
        opacity: 0.4;
        pointer-events: initial;
    }
    .tails-view-calorie-density-popup {
        @include inalign(middle);
        position: relative;
        width: 100%;
        z-index: 2;
        .calorie-density-popup-cover {
            position: fixed;
            top: 0;
            left: 0;
            height: 100%;
            background: $primary-colour;
            width: 100%;
            z-index: 0;
            opacity: 0;
            pointer-events: none;
        }
        .calorie-density-popup {
            @include transition(opacity 0.3s, transform 0.3s);
            background: $primary-white url(/static/images/signup/calorie-density.png) no-repeat ;
            background-size: 100% 170px;
            box-shadow: 0 0 10px rgba(0,0,0,0.3);
            border-radius: 6px;
            transform: rotateX(90deg) scale(.7);
            opacity: 0;
            pointer-events: none;
            color: $navy-01;
            position: fixed;
            margin-left: -15px;
            top: 10%;
            width: 100%;
            max-width: 600px;
            text-align: center;
            padding: 30px 10px 30px 10px;
            line-height: 1;
            @include at-least($screen-tablet) {
                background-size: 100% 280px;
                max-width: 610px;
                top: 20%;
                margin: 0;
            }
            input, button[type=submit] {
                @include inalign(middle);
                margin-bottom: 10px;
                margin-top: 10px;
            }
            label.close {
                cursor: pointer;
                top: 10px;
                right: 10px;
                position: absolute;
                box-shadow: none;
                opacity: 1;
                text-decoration: none;
                box-shadow: 0 0 10px rgba(0,0,0,0.3);
                &:before {
                    @include icon($icon-cross-circle);
                    color: $primary-white;
                }
            }
            .calorie-density-bg {
                height: 170px;
                @include at-least($screen-tablet) {
                    height: 280px;
                }
            }
        }
    }
    .calorie-density-link {
        padding: 10px 15px;
        label.modal-toggle {
            cursor: pointer;
            color: $primary-text-link;
            &:before {
                @include icon($icon-info-circle);
                @include inalign(middle);
                font-size: 2rem;
                padding-right: 10px;
            }
            &:hover {
                color: $secondary-01;
            }
            span {
                text-decoration: underline;
                font-weight: 400;
                font-size: $font-size-s;
            }
        }
    }
    .remove-wet-food-popup {
        input[type=radio] {
            display: none;
        }
        #dry-only:checked ~ .question [for="dry-only"] {
          @include radio_toggle_on;
        }
        #wet-other:checked ~ .question [for="wet-other"] {
          @include radio_toggle_on;
        }
        h5 {
            margin-bottom: 20px;
        }
        .radio-buttons {
            text-align: left;
            .radio-toggle-button {
                margin-bottom: 15px;
            }
            label span {
                font-weight: normal;
            }
        }
    }

    .why-try-tails, .ongoing-price {
        margin-top: 40px;
        h3 {
            text-align: center;
        }
        .ongoing-price-message {
            .price-message-header {
                margin-top: 56px;
                margin-bottom: $spacing-xs;
            }
            .price-message-description {
                text-align: left;
            }
        }
        .widget-wrapper {
            @include at-least($screen-tablet) {
                @include padding(0 20px);
            }
            .widget-inner-wrapper {
                @include flexbox(row, flex-start, flex-start);
                .widget-text {
                    margin-left: 20px;
                    text-align: left;
                    p {
                        margin: 0;
                        &:first-of-type {
                            margin-bottom: 5px;
                        }
                    }
                }
                .ongoing-widget-text {
                    text-align: left;
                    p {
                        margin: 0;
                        &:first-of-type {
                            margin-bottom: 5px;
                        }
                    }
                }
            }
        }
        .widget-wrapper-redesign {
            @include at-least($screen-tablet) {
                padding: 0;
            }
        }
        &.no-commitment-widget {
          margin: 40px 0;
          width: 100%;
          background-color: $pastel-lighter-silver;
          padding: $spacing-xs $spacing-xxs;
          border: none;

          @include at-least($screen-tablet) {
            padding: $spacing-s;
          }

          & .widget-wrapper {
            padding: 0;
            margin: 0;
          }
        }

        &.no-commitment-widget-price-comparison {
          background-color: $pastel-lighter-frost;
          margin: 10px 0 10px 0;
          padding-top: 17px;
        }
    }


    .notification-payment {
        display: none;
    }
}

body.signup-price-summary-redesign {
    .why-try-tails {
        margin-top: 0;
        padding: 30px;
        border-top: 1px solid $pastel-darker-silver;
    }
    .why-try-tails-redesign {
        padding: $spacing-xxs;
        padding-top: 0;
        border-top: none;
    }
    .ongoing-price {
        margin-top: 0;
        padding-left: $spacing-xxs;
        padding-right: $spacing-xxs;
        padding-top: $spacing-xxl;
    }
}


.payment-summary {
    $base-color: $navy-01;
    $offer-color: $product-error;
    margin: auto;
    text-align: left;
    width: 100%;
    max-width: ($scss-container-max-width / 12) * 8;
    margin-bottom: 20px;

    &.trial tbody tr{
        &:nth-child(odd) {
            background-color: rgba($offer-color, 0.1);
        }
        &.total {
            background-color: $offer-color;
        }
    }
    td {
        padding: 10px 15px;
    }
    thead {
        font-weight: bold;
    }
    tbody {
        tr:nth-child(odd) {
            background-color: $pastel-lighter-frost;
        }
        tr.highlight {
            //background-color: $secondary-01;
        }
        td {
            &.col-food {
                label {
                    margin: 0;
                }
                a {
                    cursor: pointer;
                }
            }
            .before-discount {
                font-weight: normal;
                text-decoration: line-through;
            }
            &:nth-child(2) {
                padding-left: 30px;
                font-weight: bold;
                text-align: right;
            }
            .btn {
                border: none;
                padding: 2px 15px;
                margin-top: 8px;
            }
        }
        tr.total {
            font-weight: bold;
        }
    }
}
.payment-info-row{
    text-align: right;
    .payment-cards {
        margin-right: 1rem;
        display: inline-block;
        vertical-align: middle;
    }
    .secure-server {
        margin-right: 1rem;
        color: #666;
        display: inline-block;
        vertical-align: middle;
        > * {
            display: inline-block;
            vertical-align: middle;
        }
        i {
            display: inline-block;
            line-height: 2rem;
            vertical-align: middle;
            font-size: 1.7rem;
            color: #7ed321;
            margin-left: .4rem;
        }
    }
}

.card-details-reason {
    dt {
        display: block;
        &:not(:first-of-type) {
            margin-top: 7px;
        }
        &:after {
            content: "";
        }
    }
}
.tails-view-payment {
    .payment-request-wrapper {
        background-color: $pastel-lighter-frost;
        text-align: center;
        padding: 40px 20px;
        margin-bottom: 40px;

        .terms {
            margin-bottom: 0 !important;
            a {
                color: $navy-01;
            }
        }

        .payment-provider-logo {
            height: 1.8em;
            margin-bottom: 15px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }
        &[data-payment-provider=google-pay] {
            .apple-pay {
                display: none;
            }
            .google-pay {
                display: initial;
            }
            .payment-provider-logo {
                background-image: url('/static/images/svgs/payment-icons/google-pay.svg');
            }
        }
        &[data-payment-provider=apple-pay] {
            .apple-pay {
                display: initial;
            }
            .google-pay {
                display: none;
            }
            .payment-provider-logo {
                display: none;
            }
        }
        &.hidden + .hr {
            display: none;
        }
    }
    .terms {
        margin: 15px 0 !important;
    }
    .tails-view-payment-address-lookup {
        .btn-group {
            margin-top: 15px;
        }
    }
}


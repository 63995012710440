$max-card-width: 488px;
$max-card-width-confirmed: 607px;

// Base element class so the styles don't bleed out to other parts of the site
.wftu-v1, .one-off-dry {
  // General styles shared across pages --------------------------------------------
  /* Bad hack to make the summary arrow thing be invisible */
  summary::marker {
    color: rgba(255, 255, 255, 0%);
  }

  .order-item-picture {
    height: 72px;
    width: 72px;
    background-color: $pastel-lighter-ocean-mist;
    border-radius: 10px;
    margin: 0 16px 16px 16px;
    .order-item-thumb {
        height: 72px;
        width: 72px;
      }
  }
  .dry-bag-picture {
    height: 80px;
    width: 80px;
    background-color: $pastel-lighter-beige;
    border-radius: 10px;
    margin: 0 16px 0 0;

    .dry-bag-thumb {
      height: 80px;
      width: 80px;
    }
  }
  .background-curve-container {
      background-color: $pastel-lighter-frost;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-bottom-right-radius: 50% 10%;
      border-bottom-left-radius: 50% 10%;
      height: fit-content;
      max-height: 650px;
      // Make bg curve to fit the content
      &.background-curve-container-open-notification {
        height: fit-content;
      }

      .hero-title {
        font-size: 32px;
        text-align: center;
        line-height: 32px;
        padding: 0 24px;
        font-family: $handwritten-font-family;
        margin: 40px 0;
      }

      .delay-info.notification.info {
      margin: 12px 0 0 0;
      border: 1px solid $secondary-08;
      text-align: start;
      display: flex;
      box-shadow: none;
      max-width: $max-card-width;
      @include until($screen-xs-min) {
        max-width: 276px;
      }

      &:before {
        @include icon($icon-information);
        border-radius: 50%;
        background-color: $secondary-04;
        color: $primary-white;
        font-size: $font-size-m;
        margin: 8px 0 5px 5px;
        height: 20px;
        -webkit-font-smoothing: subpixel-antialiased;
      }

      #close-notification {
        min-width: 0;
        z-index: 2;

        &:before {
          font-size: 16px;
        }
      }
    }

    .delay-info-text {
      padding: 0 10px 0 15px;
      z-index: 1;
    }
  }

  .hero-text {
    max-width: $max-card-width;
    text-align: center;
    position: relative;
    margin: 24px auto;
    h2 {
      font-size: $font-size-body;
      font-weight: 400;
      line-height: 24px;
    }
    &.your-delivery{
      margin-bottom: 16px;
    }
    .date {
      font-weight: 600;
    }
  }

  .tails-card {
    background-color: $primary-white;
    box-shadow: 0 3px 6px 0 rgba(3, 14, 45, 0.2);
    width: 100%;
    @media only screen and (min-width: $screen-sm-min) {
      border-radius: 9px;
    }
  }

  .main-content-cards-container {
    display: flex;
    flex-direction: column;
    margin: 0;
  }

  .content-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
    overflow: hidden;
    max-width: $max-card-width;
    margin: auto;
    h1 {
      font-weight: 200;
      line-height: 32px;
      font-family: $handwritten-font-family;
      font-size: $font-size-l;
      z-index: 1;
      position: relative;
      color: white;
      top: -70px;
    }
    form {
      width: inherit;
    }
  }

  .back-button {
    max-width: 488px;
    text-align: center;
    margin: auto;
    margin-top: 16px;
  }

  .continue {
    min-width: 280px;
    margin: 15px 0 30px 0;
    border-radius: 16px;
    font-size: 18px;
    font-weight: 500;
  }

  .delivery-window-container {
    position: relative;
    margin: 4px 20px 24px 20px;
    text-align: center;
    @media only screen and (min-width: $screen-sm-min) {
      margin: 12px 20px 32px 20px;
    }
  }

  .date {
    font-weight: 500;
    display: block;
    &.confirmed {
      font-weight: 600;
    }
  }

  .account-buttons-container {
    max-width: $max-card-width-confirmed;
    margin: auto;
    padding: 0 25px 16px 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .btn-secondary {
      border-radius: 16px;
      min-width: 280px;
    }
    @include at-least($screen-sm-min) {
      flex-direction: row;
      padding: 0 16px 32px 16px;
    }

    a {
      padding: 10px 30px 10px 30px;
      margin-bottom: 16px;
      @media only screen and (min-width: $screen-sm-min) {
        padding: 10px 45px 10px 45px;
        margin-bottom: 0;
      }
    }
  }
  .order-summary {
    width: 100%;
    display: flex;
    flex-direction: column;

    .order-summary-title {
      h2 {
        font-size: 20px;
        font-weight: 400;
        margin-bottom: 24px;
        line-height: 32px;
      }
    }
  }
  .order-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid $pastel-darker-silver;
    height: 48px;
    padding: 0 8px;
    align-items: center;
    @include until($screen-sm-min) {
      &.wet-food-order {
        height: fit-content;
        p {
          max-width: 232px;
        }
      }
    }
    &.info {
      padding: 0px;
      height: unset;
      align-items: unset;
      gap: 16px;
      padding-bottom: 16px;
      border-bottom: 1px solid $primary-colour;

      .order-item-picture {
        height: 136px;
        width: 136px;
        margin: 0;
        background-color: $pastel-beige;

        .order-item-thumb {
          height: 136px;
          width: 136px;
        }
      }

      @include until($screen-sm-min) {
        .order-item-picture {
          height: 80px;
          width: 80px;

          .order-item-thumb {
            height: 80px;
            width: 80px;
          }
        }
      }

      .order-info {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-left: 8px;

        .order-item-details {
          p {
            margin: 0;
            line-height: 32px;
          }

          .order-item-grams {
            color: $navy-02;
          }
        }

        .order-item-price {
          text-align: right;
          padding-right: 8px;
        }
      }
    }
  }
  // End of General styles --------------------------------------------

  // Let's get started page --------------------------------------------
  .lets-get-started-page {
    .content-card {
      border-radius: 8px;
    }

    .content-card .card-background-curve {
      background-color: $navy-01;
      border-radius: 0 0 50% 50%;
      position: absolute;
      z-index: 0;
      left: 0;
      top: -4em;
      width: 50em;
      height: 10em;
    }

    .content-card .card-background-curve-container {
      overflow: hidden;
      width: 1290px;
      height: 100px;
      position: relative;
      left: 260px;
    }

    .wet-trays-summary {
      display: flex;
      justify-content: flex-start;
    }

    .wet-tray-summary-text {
      width: 190px;
    }

    .wet-trays {
      min-width: 288px;
      max-width: 288px;
      position: relative;
      top: -10px;
    }

    .wet-tray-weight {
      font-size: $font-size-xs;
      margin-top: 10px;
    }

    .wet-tray-price {
      margin-top: 10px;
      position: absolute;
    }

    .ingredients-list {
      margin-top: 1em;
      width: 100%;
      z-index: 3;
      cursor: pointer;
    }

    .more-details {
      margin: 0 16px;
      details {
        margin: 20px 0 20px 0;
        z-index: 4;
        max-width: 288px;
        border-top: 1px solid $pastel-darker-silver;
        border-bottom: 1px solid $pastel-darker-silver;
        padding: 15px 8px;
        .accordion-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          line-height: 24px;
          color: $primary-text-link;
          .icon {
            margin-right: 10px;
            font-size: 18px;
            color: $primary-colour;
            &.icon-chevron-up {
              display: none;
            }
          }
        }
        p {
          margin-left: 16px;
        }
        &[open] .accordion-title {
          .icon-chevron-up {
            display: block;
          }
          .icon-chevron-down {
            display: none;
          }
        }
      }
    }

    .ingredients-list .ingredients-expand-title {
      margin: 14px 0 14px 20px;
    }

    .ingredients-list hr {
      border-color: #7d808a;
    }

    .continue {
      margin: 5px 10px 24px 10px;
    }
  } 
  // End of Let's get started page styles --------------------------------------------

  // Your delivery page --------------------------------------------
  /* Fix for border radius on select */
  select {
    -webkit-appearance: none;
    -webkit-border-radius: 3px;
    width: 230px;
    @media only screen and (min-width: $screen-xs-min) {
      width: 250px;
    }
  }

  .date-selector-form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .date-selector {
    position: relative;
    margin: 0 20px 20px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .select-chevron {
    font-size: $font-size-l;
    position: absolute;
    right: 11px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }

  .order-total {
    background-color: $primary-colour;
    color: white;
  }

  .payment-info {
    background-color: $pastel-lighter-silver;
    width: 100%;
    margin: 24px 24px 0 0;
    display: flex;
    justify-content: space-evenly;
    padding-right: 19px;
    @media only screen and (min-width: $screen-sm-min) {
      border-radius: 8px;
      padding-right: 0;
    }
  }

  .payment-info p, .payment-info .icon {
    margin: 15px 0 20px 15px;
  }

  .single-button-container {
    display: flex;
    justify-content: center;
    &.your-delivery {
      margin-top: 24px
    }
    .continue {
      margin: 0;
    }
  }

  .content-card-invisible {
    background-color: rgba(0, 0, 0, 0%);
    box-shadow: none;
  } 
  // End of Your delivery page styles --------------------------------------------

  // One last thing page styles --------------------------------------------
  .one-last-thing-card {
    display: flex;
    padding: 24px 16px;
    @include until($screen-sm-min) {
      flex-direction: column;
      align-items: center;
      padding: 16px;
      gap: 16px;
    }

    flex-direction: row;
    justify-content: center;
    max-width: $max-card-width;
    border-radius: 12px;
    border: 1px solid $pastel-silver;
    margin-bottom: 24px;

    .radios-message-container {
      display: flex;
      flex-direction: column;

      .delivery-message {
        text-align: left;

        @include until($screen-sm-min) {
          text-align: center;
        }

        p {
          font-size: $font-size-body;
          margin-top: 0;
          margin-bottom: 8px;
        }

        .date {
          font-weight: 600;
          margin-bottom: 16px;
        }

        border-bottom: 1px solid $pastel-darker-silver;
      }
    }
  }
  .overflow-visible{
    overflow: visible;
  }
  .radio-buttons-new.wtfu-one-last-thing {
    p {
      margin: 16px 0 0 0;
    }
    .radio-button-new{
      margin-top: 16px;
    }
  }
  // End One last thing page styles --------------------------------------------

  // Order confirmed page --------------------------------------------
  .confirmed-delivery-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: $max-card-width;
    margin: 32px auto 0;
    .icons-info-l {
      display: block;
    }

    .icons-info-m {
      display: none;
    }

    @include until($screen-xs-min) {
      .icons-info-m {
        display: block;
      }

      .icons-info-l {
        display: none;
      }
    }
    .hero-text {
      margin-top: 8px;
    }
  }
  .order-confirmed-page {
    .background-curve-container {
      // Higher than the curve on other pages due to image/length of hero text
      height: 325px;
      @media only screen and (min-width: $screen-sm-min) {
        top: -215px;
        height: 455px;
      }
    }
    .order-summary {
      @include until($screen-sm-min) {
        padding-bottom: 24px;
        border-bottom: 1px solid $pastel-darker-silver;
      }
    }

    .main-content-cards-container {
      margin: 0 auto 24px;
      max-width: $max-card-width-confirmed;
    }

    .estimated-delivery-container {
      position: relative;
      margin: 4px 20px 24px 20px;
      text-align: center;
      @media screen and (min-width: $screen-sm-min) {
        margin: 12px 20px 32px 20px;
      }
    }

    .delivery-info-container {
      background-color: $pastel-lighter-silver;
      width: 100%;
      margin-top: 28px;
      display: flex;
      justify-content: space-evenly;
      flex-direction: column;
      align-items: center;
      text-align: center;
      @media only screen and (min-width: $screen-sm-min) {
        margin-top: 24px;
        border-radius: 8px;
      }

      h2 {
        font-size: $font-size-m;
        line-height: 32px;
        margin-top: 18px;
      }

      p {
        margin: 0 22px 0 22px;
      }

      .icon {
        margin: 24px 0 10px 0;
      }
    }
  }
  .confirmed-subscription-recap {
    display: flex;
    flex-direction: row;
    max-width: $max-card-width-confirmed;
    min-height: 176px;
    border-radius: 12px;
    border: 1px solid $pastel-silver;
    padding: 16px;
    margin: 24px auto 0;

    @include at-least($screen-sm-min) {
      .dry-bag-picture {
        height: 136px;
        width: 136px;

        .dry-bag-thumb {
          height: 136px;
          width: 136px;
        }
      }
    }

    .delivery-message {
      p {
        margin-top: 0;
      }

      .date {
        font-weight: 600;
      }
    }
  }
  // End of Order confirmed page styles --------------------------------------------
}

.raf-widget {
    border-radius: 8px;
    padding: 30px;
    text-align: center;
    @include at-least($screen-desktop){
        padding: 30px 80px;
        width: 600px;
        margin: 0 auto;
    }
    hr {
        width: 60%;
        margin: 20px auto;
        opacity: 0.3;
        border-style: solid;
        border-width: 0.5px;
        border-color: $navy-01;
    }
    h4, h5 {
        text-align: center;
        margin-bottom: 10px;
    }
    .raf-widget-code {
        .customer-code {
            @include at-least($screen-desktop){
                font-size: 45px;
            }
            font-size: 36px;
            margin: 20px 0;
            font-weight: bold;
            display: block;
        }
        .raf-widget-your-code {
            border: 1px $primary-cta solid;
            border-radius: 3px;
            overflow: hidden;
            margin: 30px 0;
            #your-code, #your-link {
                width: 100%;
                padding: 5px;
                border: 0;
            }
            .btn-share.link, .native-share {
                color: $primary-colour;
                text-decoration: none;
                text-transform: uppercase;
                font-weight: 400;
                font-size: $font-size-s;
                .share-link {
                    width: 100%;
                    padding: 10px;
                    background-color: $primary-cta;
                    text-align: center;
                    &.copied {
                        display: none;
                    }
                }
                &.copy-active {
                    .copy {
                        display: none;
                    }
                    .copied {
                        display: block;
                    }
                }
            }

            .btn-share.your-code, .btn-share.your-link {
                &.copy-active {
                    .copy {
                        display: none;
                    }
                    .copied {
                        display: block;
                    }
                }
            }
        }
    }
    .raf-widget-share {
        .raf-widget-share-buttons {
            margin: 15px 0;
            @include flexbox(row, center, space-evenly);
            @include at-least($screen-desktop) {
                @include flexbox(row, center, space-between);
                flex-wrap: wrap;
            }
            .btn {
                padding: 8px 20px;
                font-weight: bold;
                color: $navy-01;
                width: 48px;
                min-width: 48px;
                margin: 5px 0;
                border-radius: 16px;
                min-height: 48px;
                line-height: 30px;

                @include at-least($screen-desktop) {
                    width: 48%;
                    min-width: 48%;
                }
                .share-description {
                    display: none;
                    @include at-least($screen-desktop) {
                        display: inline-flex;
                    }
                }
                &.share-email {
                    background-color: $secondary-01;
                    &:before {
                        @include icon($icon-envelope-o);
                    }
                }
                &.share-whatsapp {
                    color: $primary-white;
                    background-color: #25D366;
                    &:before {
                        @include icon($icon-whatsapp);
                    }
                    span {
                        color: $primary-white;
                    }
                }
                &.share-facebook {
                    color: $primary-white;
                    background-color: #4267B2;
                    &:before {
                        @include icon($icon-facebook);
                    }
                    span {
                        color: $primary-white;
                    }
                }
                &.share-messenger {
                    color: $primary-white;
                    background-color: #0099FF;
                    .fb-messenger-icon {
                        width: 24px;
                        min-width: 24px;
                        height: 24px;
                        margin-left: -8px;
                        margin-right: 8px;
                        background: {
                            image: url('/static/images/fyf/fb-messenger-white.svg');
                            size: cover;
                        }
                    }
                    span {
                        color: $primary-white;
                    }
                }
            }
        }
        p {
            font-size: $font-size-s;
        }
    }
    .raf-widget-footer {
        p {
            font-size: $font-size-s;
        }
    }
}

/*
 * Testimonials slider
 */
.testimonial-slider {
    margin: 0 auto;
    max-width: 800px;

    h2 {
        text-align: center;
    }

    .testimonial-slider-left,
    .testimonial-slider-right {
        text-align: center;
        width: 20px;
        display: table-cell;
        height: 100%;
        vertical-align: middle;
        font-size: 50px;
        outline: 0;
        font-weight: bold;

        &:link {
            text-decoration: none;
            color: $navy-01;
        }
    }

    .testimonial-slider-content {
        display: table-cell;
        vertical-align: middle;
        overflow: none;

        position: relative;

    }
}

.share-code {
  @include at-least($screen-desktop) {
    padding: 30px 80px;
    width: 100% !important;
    margin: 0 auto;
  }

  .share-link {
    &.copied {
      display: none;
    }
  }
}

// DISCOVERY PAGE STYLES //

body.discovery-page {
  // Wet dog food styles //
  &.wet-dog-food {
    .section {
      &.hero {
        .hero-text {
          background-color: transparent;
        }
      }
      &.feature-centered {
        .macro-feature-centered {
          padding-bottom: 0;
          .feature {
            width: 100%;
            padding: 0 30px;
            .content-wrapper {
              min-height: 200px;
              @include flexbox(column, center, center);
              margin: 0;
              p {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
  // Puppy food Styles //
  &.puppy-food {
    .feature-image-text {
      .feature.right {
        .content-wrapper {
          h3 {
            margin-bottom: 18px;
          }
          p {
            margin-bottom: 7px;
          }
        }
      }
    }
    .two-column + .feature-centered {
      display: none;
      @include at-least($screen-desktop) {
        display: flex;
      }
      .macro-feature-centered {
        padding-bottom: 0;
        .feature {
          background-color: $pastel-lighter-frost;
          flex-direction: column-reverse;
          padding-bottom: 0;
          padding-top: 0;
          .content-wrapper {
            margin-bottom: 0;
            h2 {
              margin-bottom: 0;
              padding-bottom: 0;
            }
          }
          img {
            object-fit: contain;
            width: 1262px;
            height: 462px;
          }
        }
      }
    }
    .feature-centered + .three-column {
      display: none;
        @include at-least($screen-desktop) {
          display: flex;
        }
      background-color: $pastel-lighter-frost;
      .column-wrapper {
        align-items: flex-start;
        .column {
          margin-top: 0;
          @include flexbox(column, center, center);
          p {
            width: 250px;
          }
        }
      }
    }
    .carousel {
      @include at-least($screen-desktop) {
        opacity: 0;
        height: 0;
      }
      background-color: $pastel-lighter-frost;
      .carousel-scrollbox-wrapper {
        min-height: 500px;
        .carousel-scrollbox {
          background-color: transparent;
          margin-bottom: 0;
          margin-top: 0;
          .carousel-item {
            padding-top: 0;
            padding-bottom: 0;
            .image-wrapper {
              height: 260px;
              align-items: flex-end;
            }
          }
          .description {
            margin-top: 0;
            img {
              width: 85px;
            }
            div {
              margin-top: 0;
            }
          }
        }
      }
      .slick-dots {
        margin-top:0;
        margin-bottom: 0;
        padding-top:0;
        li {
          button {
              background-color: $primary-colour;
          }
          &.slick-active button{
              background-color: $secondary-01;
          }
        }
      }
    }
  }
}


.fp-progress-wrapper {
  margin-bottom: 20px;
  .progress {
    .track {
      position: relative;
      .indicator {
        height: 15px;
        display: block;
        border: 3px solid $primary-white;
        border-left: hidden;
        border-right: hidden;
        position: absolute;
        top: 10px;
        @include at-least($screen-tablet) {
          border: 4px solid $primary-white;
          border-left: hidden;
          border-right: hidden;
        }
        &.grey {
          width: 78%;
          left: 0;
          right: 0;
          margin-left: auto;
          margin-right: auto;
          background-color: $pastel-lighter-frost;
          z-index: 2;
        }
        &.green {
          left: 12%;
          background-color: $secondary-01;
          z-index: 3;
        }
      }
      .bar {
        @include flexbox(row, flex-start, space-around);
        .step {
          @include flexbox(column, center, space-between);
          margin: 0 10px;
          width: 100%;
          .circle-white {
            background-color: $primary-white;
            width: 35px;
            height: 35px;
            border-radius: 50px;
            border: hidden;
            position: absolute;
            z-index: 1;
          }
          .circle {
            background-color: $pastel-lighter-frost;
            width: 27px;
            height: 27px;
            border-radius: 50px;
            border: hidden;
            position: relative;
            top: 4px;
            z-index: 4;
          }
          &[data-step=done] {
            .circle {
              background-color: $secondary-01;
              ::after {
                @include icon($icon-check);
                color: $primary-white;
                top: 4px;
                left: 4px;
                position: absolute;
              }
            }
          }
          &[data-step=doing] {
            .circle {
              background-color: $secondary-01;
            }
          }
          .description {
            font-size: 1.3rem;
            text-align: center;
            margin-top: 10px;
            @include at-least($screen-tablet) {
              display: none;
            }
          }
          .description-desktop {
            display: none;
            font-size: 1.5rem;
            text-align: center;
            margin-top: 10px;
            @include at-least($screen-tablet) {
              display: block;
            }
          }
        }
      }
    }
  }
}




@mixin uk-address-form() {
  .form-group.address-third_line {
    display: block;
  }
}

@mixin french-address-form() {
  .form-row.city-postcode {
    .form-group.address-postal_code {
      order: -1;
    }
  }
}
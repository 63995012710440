@media (max-width: $screen-xs-max) {
    .align-right-xs {
        text-align: right;
    }
}

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    .align-right-sm {
        text-align: right;
    }
}

@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
    .align-right-md {
        text-align: right;
    }
}

@media (min-width: $screen-lg-min) {
    .align-right-lg {
        text-align: right;
    }
}


@media (max-width: $screen-xs-max) {
    .stacked-col-margin-xs {
        [class*="col-"]:not(:first-child){
            margin-top: 10px;
        }
    }
}
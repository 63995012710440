#iubenda-cs-banner {
  font-family: $default-font-family !important;
  font-size: $font-size-s !important;
  background: 0 0 !important;
  line-height: 1.4 !important;
  position: fixed !important;
  z-index: 99999998 !important;
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
}

#iubenda-cs-banner .iubenda-banner-content:not(.iubenda-custom-content) *,
#iubenda-cs-banner [class*=" iub"],
#iubenda-cs-banner [class^="iub"] {
  font-family: $default-font-family !important;
  border: 0 !important;
}

#iubenda-cs-banner.iubenda-cs-overlay::before {
  content: "" !important;
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
  z-index: 1 !important;
  pointer-events: auto !important;
}

#iubenda-cs-banner.iubenda-cs-center {
  align-items: flex-end !important;
  justify-content: center !important;

  @include at-least($screen-tablet) {
    align-items: center !important;
  }
}

#iubenda-cs-banner .iubenda-cs-brand img {
  max-width: 192px !important;
  max-height: 56px !important;
}

#iubenda-cs-banner .iubenda-cs-content {
  position: relative !important;
  z-index: 1 !important;
  overflow: hidden !important;
  background-color: #fff !important;
  color: #000 !important;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  min-height: 296px !important;
  bottom: 0;

  @include at-least($screen-tablet) {
    max-width: 448px !important;
    border-radius: 8px !important;
  }
}

#iubenda-cs-banner .iubenda-cs-rationale {
  position: relative !important;
  display: flex !important;
  flex-direction: column !important;

  & .iubenda-cs-brand {
    display: none !important;
  }
}

#iubenda-cs-banner .iubenda-banner-content {
  font-weight: 300 !important;
  margin: $spacing-xs !important;
  margin-bottom: 0 !important;
  padding-bottom: $spacing-s !important;
}

#iubenda-cs-banner .iubenda-banner-content a {
  cursor: pointer !important;
  color: currentColor !important;
  opacity: 0.7 !important;
  text-decoration: underline !important;
}

#iubenda-cs-banner .iubenda-banner-content a:hover {
  opacity: 1 !important;
}

#iubenda-cs-banner #iubenda-cs-title {
  font-weight: 400 !important;
  font-size: $font-size-m !important;
  margin-bottom: $spacing-xs !important;
  text-align: center !important;
}

#iubenda-cs-banner #iubenda-cs-paragraph {
  text-align: center !important;
  font-size: $font-size-s !important;
}

#iubenda-cs-banner .iubenda-cs-opt-group {
  margin: $spacing-xs !important;
  z-index: 1 !important;
  display: flex !important;
  margin-top: 0 !important;
  flex-direction: column-reverse;
}

#iubenda-cs-banner .iubenda-cs-opt-group > div {
  display: flex !important;
}

#iubenda-cs-banner .iubenda-cs-opt-group button {
  appearance: none !important;
  padding: $spacing-xxs $spacing-m !important;
  border-radius: 16px !important;
  cursor: pointer !important;
  font-weight: 400 !important;
  font-size: $font-size-s !important;
  margin: 0 auto !important;
  margin-bottom: 4px !important;
  text-align: center !important;
  width: 100% !important;
  display: block;
  height: 48px !important;

  @include at-least($screen-tablet) {
    width: 280px !important;
  }

  &.iubenda-cs-customize-btn {
    color: #218380 !important;
    background: transparent !important;

    &:hover {
      color: #040e2b !important;
    }

    &:active {
      color: #21c9c4 !important;
      outline: none !important;
    }
  }

  &.iubenda-cs-accept-btn {
    color: #030d34 !important;
    background: #21c9c4 !important;

    &:hover {
      opacity: 0.5 !important;
    }

    &:focus {
      opacity: 0.8 !important;
    }
  }
}

#iubenda-cs-banner.iubenda-cs-default-floating .iubenda-cs-content {
  padding: $spacing-xxs !important;
}

.iubenda-cs-customize-btn {
  text-decoration: underline !important;
  border: 1px solid black;
  color: #218380 !important;
  font-weight: 100 !important;
}

/* Tracking preferences */
#iubenda-iframe {
  & * {
    font-family: unset !important;
  }
}

#iubenda-iframe #iubenda-iframe-popup {
  font-family: $default-font-family !important;
  border-radius: 8px !important;
  max-width: 448px !important;

  @include at-least($screen-tablet) {
    max-width: 448px !important;
    border-radius: 8px !important;
    max-height: 500px !important;
  }

  & #iubenda-iframe-content {
    border-radius: 8px !important;

    & .purposes-content {
      overflow-y: auto !important;
    }
  }

  & .purposes-header {
    background: white !important;
    color: #030e2d !important;
    padding: $spacing-s $spacing-xs !important;
    padding-bottom: 0 !important;

    & h1 {
      font-family: $default-font-family !important;
      font-size: $font-size-m !important;
      font-weight: 400 !important;
      margin-bottom: 10px !important;
    }

    & p {
      display: none !important;
    }
  }

  & .purposes-buttons {
    display: none !important;
  }

  & .purposes-item {
    border-bottom: 1px solid #dcdee0 !important;
  }

  & .purpose-item-3 {
    border-bottom: none !important;
  }

  & .purposes-item-5 {
    border-bottom: none !important;
  }

  & #purposes-container .purposes-item-title {
    & label {
      color: #030e2d !important;
      font-family: $default-font-family !important;
      font-size: $font-size-s !important;
    }

    & button {
      font-family: $default-font-family !important;
      font-size: $font-size-s !important;
      text-decoration: underline !important;
      color: #218380 !important;

      &:hover {
        color: #040e2b !important;
      }

      &:active {
        color: #21c9c4 !important;
        outline: none !important;
      }

      &::after {
        width: 14px !important;
        background-image: $icon-chevron-down !important;
        background-size: cover !important;
        opacity: 1 !important;
      }
    }
  }

  & .purposes-item-body {
    font-family: $default-font-family !important;
    color: #040e2b !important;
  }

  & #purposes-container .purposes-checkbox {
    margin: 0 !important;
    margin-right: 12px !important;

    & input.style1 {
      height: 25px !important;
      width: 25px !important;
      background-color: #eee !important;
      border-radius: 3px !important;
      background-image: none !important;
      transition: none !important;

      &:checked {
        @include icon($icon-tick_icon);
        background-color: $secondary-08 !important;
        background-image: url("/static/images/home/tick-s-white.png") !important;
        background-size: 15px 13px !important;
        background-position: center !important;
      }

      &[disabled] {
        opacity: 1 !important;
        background-color: #677789 !important;
      }
    }
  }

  & .purposes-item-header {
    display: flex !important;
    flex-direction: row-reverse !important;
    justify-content: space-between !important;
    padding: $spacing-xs 0 !important;
    position: unset !important;

    & .purposes-item-title {
      @include at-least($screen-desktop) {
        display: flex !important;
        flex-direction: column !important;
      }
    }
  }

  & .purposes-top {
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
    height: 89px !important;
    background-color: #e8eff7 !important;

    & .purposes-btn-cp {
      padding-top: 0 !important;
      background: transparent !important;

      & svg {
        display: none !important;
      }

      & span {
        text-decoration: underline !important;
        font-weight: 400 !important;
        color: #218380 !important;
        font-family: $default-font-family !important;

        &:hover {
          color: #040e2b !important;
        }

        &:active {
          color: #21c9c4 !important;
          outline: none !important;
        }
      }
    }

    & .iubenda-modal-navigation-logo {
      & img {
        width: 56px !important;
        height: 41px !important;
      }
    }
  }

  & .iubenda-iframe-footer {
    border-bottom-left-radius: 8px !important;

    & * {
      transition: none !important;
    }

    & #iubFooterBtn {
      background-color: $primary-cta !important;
      height: 48px !important;
      border-radius: 16px !important;
      color: #030d34 !important;
      font-weight: 400 !important;
      font-size: 18px !important;
      font-family: $default-font-family !important;
    }

    & #iubBackBtn {
      display: none !important;
    }
  }

  @include at-least($screen-desktop) {
    & .purposes-header {
      padding: $spacing-s !important;
      padding-bottom: 0 !important;
    }

    & .purposes-item-header {
      position: unset !important;
    }

    & .iubenda-iframe-footer {
      display: flex !important;
      flex-direction: column-reverse !important;
      align-items: center !important;

      & #iubFooterIabBtnContainer {
        display: none !important;
      }

      & #iubFooterBtnContainer {
        margin: 0 auto !important;

        & #iubFooterBtn {
          min-width: 280px !important;
          margin-top: $spacing-m !important;
          margin-bottom: $spacing-s !important;
        }
      }

      & #iubBackBtn {
        padding: none !important;
      }
    }

    & .bottom-border-radius {
      border-radius: 0 0 8px 8px !important;
    }
  }
}

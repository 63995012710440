
body.update-icomoon {
    text-align: center;

    h1 {
        margin-bottom: 40px;
    }

    form {
        padding: 40px;
        background: $pastel-lighter-frost;
        margin-bottom: 40px;
    }

    input[type=file] {
        font-weight: normal;
        font-size: $font-size-s;
        color: $navy-01;
        //&::-webkit-file-upload-button {
        //    @extend .btn;
        //    &:before {
        //        @include icon($icon-upload3);
        //    }
        //}
    }

    .event-list {
        text-align: left;
    }
}

body.your-price {
  background-color: $pastel-lighter-frost;
  .loader-shroud.show {
    position: fixed;
  }
  .content {
    h1, h2, h3, h4, h5, label {
      text-align: center;
      margin: 0;
      padding: 0;
    }
    p {
      text-align: center;
      &.lead {
        font-size: $font-size-m;
      }
    }
    ul.accordion .accordion-item {
      margin: 0;
      .accordion-item-header {
        border-top: 1px solid $secondary-01;
        border-bottom: 1px solid $secondary-01;
        background-color: transparent;
        @include padding(10px 0);
        :hover {
          .title, .toggle {
            color: $primary-colour;
          }
        }
        .title {
          font-weight: 400;
          color: $primary-colour;
          font-size: $font-size-body;
        }
        .toggle {
          color: $primary-colour;
          font-size: $font-size-m;
          line-height: 25px;
          :after {
            @include icon($icon-chevron-down);
          }
        }
      }
      .accordion-item-body {
        border-bottom: 1px solid $secondary-01;
        background-color: transparent;
        @include padding(0);
      }
      input[type=checkbox]:checked {
        ~ .accordion-item-header {
          .title, .toggle {
            color: $primary-colour;
          }
        }
        ~ .accordion-item-body {
          @include border-width(null 0 null 0);
          @include padding(20px null);
          @include margin(0);
        }
      }
    }

    .container {
      @include grid-blocks(9);
      @include padding(0);
      @include at-least($screen-tablet) {
        @include padding(null 15px);
      }
      .section {
        &.trial-price {
          @include padding(30px null);
          @include at-least($screen-tablet) {
            @include padding(30px null);
          }
          p {
            margin: 0;
          }
        }

        .ongoing-price {
          @include padding(20px null);
          @include at-least($screen-tablet) {
            width: 460px;
          }
          width: 320px;
          background-color: $primary-white;
          margin: 20px auto;
          border-radius: 3px;
        }

        &.wrapper {
          background-color: $primary-white;
          @include padding(0);
          @include at-least($screen-tablet) {
            @include padding(0 80px);
            border-radius: 8px;
          }
          hr {
            border-color: $primary-colour;
            border-width: 1px;
            width: 140px;
            border-style: solid;
          }

          .subsection {
            @include padding(30px);
            &.products {
              @include padding(30px 50px);
              @include at-least($screen-tablet) {
                @include padding(30px 70px);
              }
              h2 {
                margin-top: 30px;
                &:first-child {
                  margin-top: 0;
                }
              }
              .product-wrapper {
                @include flex-wrap(wrap);
                @include flexbox(row, flex-start, space-evenly);
                margin-top: 20px;
                @include at-least($screen-tablet) {
                  @include flexbox(row, flex-start, space-evenly);
                }
                .product {
                  position: relative;
                  width:211px;
                  img {
                    display: block;
                    margin: 0 auto;
                    @include at-least($screen-tablet) {
                      height: 220px;
                      width: 220px;
                    }
                    height: 150px;
                    width: 150px;
                  }
                  .offer {
                    height: 45px;
                    width: 50px;
                    background-color: $navy-01;
                    position: absolute;
                    right: 38px;
                    @include at-least($screen-tablet){
                      right: 18px;
                    }
                    top: 0;
                    border-radius: 3px;
                    &:before {
                      content: '';
                      display: block;
                      position: absolute;
                      height: 0;
                      width: 0;
                      border-top: 25px solid $navy-01;
                      border-left: 25px solid transparent;
                      border-right: 25px solid transparent;
                      margin-top: 44px;
                      border-radius: 1px;
                    }
                    p {
                      color: $primary-white;
                      padding: 5px;
                      text-align: center;
                    }
                     @include at-least($screen-tablet) {
                       height: 65px;
                       width: 80px;
                       &:before {
                         border-top: 45px solid $navy-01;
                         border-left: 40px solid transparent;
                         border-right: 40px solid transparent;
                         margin-top: 64px;
                       }
                       p {
                         @include margin(10px);
                         @include padding(0);
                         display: table-cell;
                         height: 80px;
                         width: 80px;
                         text-align: center;
                         vertical-align: middle;
                       }
                     }
                  }
                }
                .product-weight {
                  @include margin(0);
                  strong {
                    font-size: $font-size-xs;
                  }
                }
                .product-trial {
                  margin-bottom: 0;
                  margin-top: 14px;
                }
                .product-price {
                  margin-top: 0;
                }

              }
            }

            &.portion-scoop {
              @include at-least($screen-tablet) {
               @include padding(30px 80px);
              }
              .portion-scoop-inner-wrapper {
                @include flexbox(row, flex-start, center);
                margin-top: 27px;
                .portion-scoop-image {
                  background-image: url("/static/images/products/price-page/scoop.png");
                  background-color: $primary-white;
                  min-height: 60px;
                  min-width: 60px;
                  background-size: contain;
                  background-repeat: no-repeat;
                  background-position: center;
                  @include at-least($screen-tablet) {
                    min-height: 140px;
                    min-width: 140px;
                  }
                }
                .portion-scoop-text {
                  padding-left: 15px;
                  text-align: left;
                }
              }
            }

            &.upsell-widgets {
              @include at-least($screen-tablet) {
                @include padding(30px 80px);
              }
              .widget-wrapper {
                .slide-selector {
                  .slick-list {
                    height: auto;
                  }
                  .pets-name {
                    margin: 0;
                  }
                }
                .pets-name {
                  text-align: center;
                  font-size: 3.2rem;
                  font-weight: bold;
                  margin-bottom: 30px;
                  @include at-least($screen-tablet) {
                    font-size: 3.7rem;
                  }
                }
                .offer {
                  @include flexbox(row, flex-start, center);
                  @include at-least($screen-tablet) {
                    @include flexbox(row, center, center);
                  }
                  box-shadow: none;
                  padding: 0;
                  h3 {
                    text-align: left;
                  }
                  .description {
                    .actions {
                      @include flexbox(row, center, flex-start);
                    }
                  }
                  .image {
                    height: 60px;
                    width: 60px;
                    min-height: 60px;
                    min-width: 60px;
                    @include at-least($screen-tablet) {
                      height: 140px;
                      width: 140px;
                    }
                    &.wet-food {
                      background-image: url("/static/images/products/price-table/wet-food.jpg");
                      background-color: $primary-white;
                    }
                    &.treats {
                      background-image: url("/static/images/products/price-table/dental-dailies.jpg");
                      background-color: $primary-white;
                    }
                    &.good-dog-treats {
                      background-image: url("/static/images/products/price-table/good-dog-treats.jpg");
                      background-color: $primary-white;
                    }
                    &.salmon-treats {
                      background-image: url("/static/images/products/price-table/salmon-biscuits.jpg");
                      background-color: $primary-white;
                    }
                    &.duck-treats {
                      background-image: url("/static/images/products/price-table/duck-biscuits.jpg");
                      background-color: $primary-white;
                    }
                    &.poo-bags {
                      background-image: url("/static/images/products/price-table/poo-bags.jpg");
                      background-color: $primary-white;
                    }
                  }
                }
              }
            }

            &.promo-code {
              #promo-popup {
                display: none;
              }
              #promo-popup:checked ~ .promo-popup {
                opacity: 1;
                transform: scale(1) translateX(-50%);
                pointer-events: initial;
              }
              #promo-popup:checked ~ .promo-popup-cover {
                opacity: 0.4;
                pointer-events: initial;
              }
              .tails-view-promo-popup {
                margin: 0 auto;
                text-align: center;
                @include inalign(middle);
                position: relative;
                width: 100%;
                .offer-text {
                  color: $secondary-01;
                  white-space: normal;
                  text-decoration: underline;
                  font-weight: 400;
                  font-size: $font-size-body;
                }
                .promo-popup-cover {
                  position: fixed;
                  top: 0;
                  left: 0;
                  height: 100%;
                  background: $primary-colour;
                  width: 100%;
                  z-index: 1;
                  opacity: 0;
                  pointer-events: none;
                }
                #nocode {
                  margin-bottom: 5px;
                }
                .modal-toggle {
                  h5 {
                    margin-bottom: 5px;
                  }
                }
                .error {
                  color: $product-error;
                  font-weight: normal;
                  margin: 0;
                  &:empty {
                    display: none;
                  }
                  span {
                    margin: 10px 0;
                    display: block;
                  }
                }

                .promo-popup {
                  @include transition(opacity 0.3s, transform 0.3s);
                  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                  border-radius: 6px;
                  transform: rotateX(90deg) scale(.7);
                  opacity: 0;
                  pointer-events: none;
                  background-color: $primary-white;
                  color: $navy-01;
                  position: fixed;
                  top: 40%;
                  left: 50%;
                  width: 95%;
                  min-width: 95%;
                  text-align: center;
                  padding: 30px 10px;
                  line-height: 1;
                  z-index: 1;
                  @include at-least($screen-tablet) {
                    left: 50%;
                    width: 500px;
                    min-width: 500px;
                  }
                  input, button[type=submit] {
                    @include inalign(middle);
                    margin-bottom: 10px;
                    margin-top: 10px;
                  }
                  input[type=text] {
                    max-width: 80%;
                    min-width: 80%;
                    padding-right: 0;
                    text-align: center;
                    padding-left: 0;
                    margin-top: 10px;
                    margin-bottom: 10px;
                  }
                  button[type=submit] {
                    display: block;
                    margin: 20px auto;
                    max-width: 60%;
                    min-width: 60%;
                  }
                  label.close {
                    cursor: pointer;
                    top: 10px;
                    right: 10px;
                    position: absolute;
                    font-size: 2rem;
                    color: $navy-01;
                    box-shadow: none;
                    opacity: 1;
                    text-decoration: none;
                    &:before {
                      @include icon($icon-cross-circle);
                    }
                  }
                }
              }
            }

            &.price-breakdown {
              background-color: $pastel-lighter-frost;
              @include at-least($screen-tablet) {
                @include padding(30px 160px);
                border-radius: 8px;
              }
              .ongoing-breakdown-price {
                @include padding(20px);
                margin-bottom: 20px;
                background-color: $primary-white;
                border-radius: 3px;
                p {
                  margin: 0;
                }
              }
              .table-price-breakdown {
                margin: 0;
                h5 {
                  text-align: left;
                }

                tr {
                  &:last-child {
                    td {
                      &:nth-child(n+2) {
                        border-top: 1px solid $primary-colour;
                      }
                    }
                  }
                  &.empty {
                    height: 10px;
                  }
                  td {
                    padding: 4px 0;
                    color: $primary-colour;
                    width: 50%;
                    &:nth-child(n+2) {
                      text-align: right;
                    }
                  }
                }
                .total-price {
                  border-top: 1px solid $primary-colour;
                  margin-top: 10px;
                  padding-top: 10px;
                  margin-left: auto;
                  width: 80%;
                  @include flexbox(row, flex-start, space-between);
                  @include at-least($screen-tablet) {
                    width: 50%;
                  }
                  span {
                    &:first-child {
                      white-space: nowrap;
                      @include margin(null 20px);
                    }
                  }
                }
              }
            }

            &.ctas {
              @include flexbox(column, center, center);
              @include at-least($screen-tablet) {
                @include padding(30px 160px);
              }
              .btn {
                margin-bottom: 10px;
                width: 100%;
                @include at-least($screen-tablet) {
                  width: 270px;
                }
                :last-child {
                  margin: 0;
                }
              }
            }

            &.payment-cards {
              @include padding(20px);
              background-color: $pastel-lighter-frost;
              @include at-least($screen-tablet) {
                @include padding(30px 80px);
                border-radius: 8px;
              }

              .payment-card-wrapper {
                @include margin(8px null);
                @include flexbox(row, center, center);
                 img {
                  height: 40px;
                  @include margin(0 8px)
                }
              }
            }

            &.footer-notes {
              @include at-least($screen-tablet) {
                @include padding(30px 160px);
              }
              p {
                font-size: $font-size-xs;
                text-align: left;
              }
            }
          }
        }
      }
    }
  }
}


/*
 * Footer
 */
body .footer {
    text-align: left;
    position: relative;
    font-size: $font-size-body;
    font-family: $default-font-family;
    background-color: $primary-blueberry-10;
    @include at-least($screen-desktop) {
        font-size: $font-size-body;
        text-align: left;
        .group {
            text-align: left;
        }
    }
    padding: 30px 10px;
    height: initial;
    width: 100%;
    bottom: 0;
    max-width: none;
    -webkit-transition: all 0.3s;
    .container {
        @include flexbox(row, flex-start, space-between);
        @include flex-wrap(wrap);
        max-width: none !important;
        background-color: transparent !important;
        justify-content: space-around;
        width: 100%;
    }
    .navigation-content {
        flex-flow: column wrap;
        padding: 25px 15px;
        max-width: 1280px;
        margin: 0 auto;
    }

    h5 {
        color: $primary-blueberry;
        font-size: $font-size-s;
        font-family: $display-font-family;
        margin-bottom: 8px;
        letter-spacing: 0.04rem;
    }

    .small.first {
        margin-bottom: 50px;
    }

    .group {
        display: inline-block;
        vertical-align: top;
        width: calc(50vw - 20px);
        text-align: center;
        h5 {
            margin-bottom: 8px;
        }
        ul {
            padding: 0;
            li {
                list-style: none;
                display: block;
                padding-bottom: 10px;
                a {
                    color: $primary-black;
                    text-decoration: none;
                    font-size: 17px;
                }
            }
        }
        @include at-least($screen-tablet) {
            width: 25%;

            .wrapper {
                text-align: left;
                padding-left: 0;
                display: inline-block;
            }
        }
        @include until($screen-tablet) {
            flex: 0 1 35%;
        }
    }
    .change-your-country {
        width: 400px;
        text-align: center;
        margin: 20px 0;
        .country-selector {
            position: relative;
            @include at-least($screen-tablet) {
                width: 300px;
                margin: 0 auto;
            }
            select {
                width: 100%;
                padding: 10px 0 10px 10px;
            }
            &:after {
                @include icon($icon-chevron-down);
                position: absolute;
                color: $primary-black;
                top: 10px;
                right: 10px;
                pointer-events: none;
            }
        }
    }
    .social {
        margin: 48px 0;
        text-align: center;
        > * {
            display: inline-block;
            vertical-align: middle;
        }
        .links {
            .link {
                font-size: 1em;
                display: inline-block;
                vertical-align: middle;
                text-decoration: none;
                margin-left: 32px;
                color: $primary-black;

                    &.facebook:before {
                        @include icon($icon-facebook-thin);
                    }
                    &.twitter:before {
                        @include icon($icon-twitter-thin);
                    }
                    &.instagram:before {
                        @include icon($icon-instagram-thin);
                    }

                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }
    .copyright {
        font-size: 2.4rem;
        margin: 30px 0;
        text-align: center;
        color: $primary-black;
        img {
            width: 103px;
        }
        .show-cookie-modal {
            display: none;
        }
    }
}

body:not(#discovery-body) footer #change-your-country {
    display: none;
}

//static mixins

%upper {
    text-transform: uppercase;
    font-weight: bold;
}

%invisible-scrollbar {
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
        width: 0;
    }
}

@mixin min-size($size) {
    min-width: $size;
    min-height: $size;
}

//dynamic mixins

// rotate
@mixin rotate ($deg) {
  @include transform(rotate(#{$deg}deg));
}
// scale
@mixin scale($scale) {
     @include transform(scale($scale));
}
// skew
@mixin skew ($x, $y) {
   @include transform(skew(#{$x}deg, #{$y}deg));
}
//transform origin
@mixin transform-origin ($origin) {
    moz-transform-origin: $origin;
         -o-transform-origin: $origin;
        -ms-transform-origin: $origin;
    -webkit-transform-origin: $origin;
          transform-origin: $origin;
}
@mixin translated ($x, $y, $z) {
    @include transform(translate($x, $y));
    @include transform(translate3d($x, $y, $z));
}
@mixin inalign ($align) {
    display: inline-block;
    vertical-align: $align;
}
@mixin height-range ($min, $max) {
    min-height: $min;
    max-height: $max;
}
@mixin width-range ($min, $max) {
    min-width: $min;
    max-width: $max;
}
@include keyframes(slide-in) {
    0% {
        @include translated(0, -100%, 0);
    }
    100% {
        @include translated(0, 0, 0);
    }
}
@mixin at-least($device-width) {
    @media screen and (min-width: $device-width) {
        @content
    }
}

@mixin until($device-width) {
    @media screen and (max-width: $device-width - 1) {
        @content
    }
}

@mixin between($min-width, $max-width) {
    @media screen and (min-width: $min-width) and (max-width: $max-width - 1) {
        @content
    }
}

@function blocks($blocks) {
    @return ($scss-container-max-width / $grid-columns) * $blocks;
}

@mixin grid-blocks($blocks) {
    width: 100%;
    max-width: blocks($blocks);
}

@mixin flexbox($direction:"", $align-items:"", $justify-content:"") {
    @include display(flex);
    @if $direction != "" {
        @include flex-direction($direction);
    }
    @if $align-items != "" {
        @include align-items($align-items);
    }
    @if $justify-content != "" {
        @include justify-content($justify-content);
    }
}

@mixin bg-cover-image($url) {
    background: url($url) no-repeat center center / cover;
}

@mixin container($max-width) {
    max-width: $max-width;
    margin: 0 auto;
}


 @mixin page-recolor($color) {
        h1,h2,p {
            color: $color;
        }
 }

@mixin text-box($bg-col: $primary-white,$text-col: $primary-colour) {
  background-color: $bg-col;
  color: $text-col;
  font-size: $font-size-body;
  padding: 10px 15px;
  border-radius: 4px;
  font-weight: 400;
}

@mixin hero-header($images, $retina-images: null) {
    @include container($container-max-width);
    // Not every hero will have 2 versions of an image. This makes the mixin flexible to take either 1 or 2 urls for images and still display them
    $desktop-background: nth($images,1);
    $mobile-background: $desktop-background;
    @if (length($images) > 1) {
        $mobile-background: nth($images,2);
    }

    height: 560px;
    padding-left: 80px;
    @include display(flex);
    @include align-items(center);
    position: relative;
    @include until($screen-tablet) {
        @include flex-flow(column nowrap);
        height: inherit;
        padding: 0;
    }

    .background-image {
        @include position(absolute, 0 0 0 0);
        background-image: url($desktop-background);
        @if ($retina-images) {
            @include hidpi(2) {
                background-image: url(nth($retina-images,1));
            }
        }

        background-position: center center;
        background-size: cover;

        z-index: -5;
        @include until($screen-tablet) {
            position: static;
            background-image: url($mobile-background);

            @if ($retina-images and length($retina-images) > 1) {
                @include hidpi(2) {
                    background-image: url(nth($retina-images, 2));
                }
            }

            width: 100%;
            max-height: 350px;
            height: 45vh;
            background-position-x: center;
        }
        @include until($screen-phone) {
            min-height: 200px;
            height: 320px;
            max-height: 40vh;
        }
    }

    .hero-text {
        padding: 40px;
        text-align: center;
        padding-left: 30px;
        background: $primary-white;
        min-width: 290px;
        max-width: calc(50% - 40px);
        h1 {
            text-align: left;
        }

        @include until($screen-tablet) {
            max-width: 100%;
            width: 100%;
            padding: 20px 20px 0;
            text-align: center;
            h1 {
                text-align: center;
            }
        }
    }
}

@mixin promo {
        @include inalign(middle);
        position: relative;
        text-align: right;
        color: $navy-01;
        font-weight: bold;
        font-size: 1.3rem;
        width: 100%;
        padding-right: 15px;

        height: $nav-height-small;
        //line-height: $nav-height-small !important;
        @include at-least($nav-max) {
            height: $nav-height;
            //line-height: $nav-height !important;
            padding-right: 30px;
        }

        &:before {
            content: " ";
            @include inalign(middle);
            height: 100%;
        }

        > a {
            @include inalign(middle);
        }

        a {
            text-decoration: none;
        }

        .offer-text {
            display: none;
            text-decoration: underline;
            color: $primary-text-link;
            white-space: normal;
            &.disabled {
                color: $primary-colour;
                text-decoration: none;
            }
        }

        @include at-least($screen-phone) {
            .offer-text {
                max-width: none;
            }
        }

        &[data-has-promo="true"] {
            .offer-text {
                display: inline-block;
            }
            .promo-input {
                display: none;
            }
        }

        #nocode {
            margin-bottom: 5px;
        }

        label[for=tounge] {
            z-index: 10;

            > span {
                display: block;
                @media screen and (max-width: $nav-max) {
                    max-width: 130px;
                    max-width: 35vm;
                    max-width: 35vmin;
                    line-height: 1.4;
                    margin-top: 6px;
                }
                > span {
                    @media screen and (max-width: $screen-tablet) {
                        text-transform: capitalize;
                        &.hide-xs {
                            display: none;
                        }
                    }
                }
            }
        }

        label[for=code] {
            color: $navy-01;
            padding: 0 10px 0 10px;
            font-size: $font-size-m;
            font-weight: bold;
        }

        .error, .error-empty-input {
            color: $product-error;
            font-weight: normal;
            margin: 10px 0;
            &:empty {
                display: none;
            }
        }

        .cover {
            position: fixed;
            top: 0;
            left: 0;
            height: 100%;
            background: $primary-colour;
            width: 100%;
            opacity: 0;
            pointer-events: none;
        }

        .tounge {
            @include transition(opacity 0.3s, transform 0.3s);
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
            border-radius: 6px;
            transform: rotateX(90deg) scale(.7);
            opacity: 0;
            pointer-events: none;
            background-color: $primary-white;
            color: $navy-01;
            position: fixed;
            margin: 10% auto;
            left: 0;
            right: 0;
            top: 0;
            width: 100%;
            max-width: 610px;
            text-align: center;
            padding: 10px;
            line-height: 1;
            input, button[type=submit] {
                @include inalign(middle);
                margin-bottom: 10px;
                margin-top: 10px;
            }
            input[type=text] {
                display: block;
                margin: 0 auto;
                padding-right: $spacing-xxs;
                text-align: left;
                padding-left: $spacing-xxs;
            }
            .hint-text {
                font-size: 13px;
                margin: $spacing-xxs 0;
              }
            a {
                font-weight: normal;
                text-decoration: underline;
                line-height: 1.6;
                color: $primary-text-link;
            }
            .offer-text {
                display: inline;
                text-decoration: none;
                color: inherit;
                white-space: inherit;
                font-size: inherit;
            }
            .error-default-code {
                display: block;
            }
        }
    }

@mixin error-border {
    border-bottom-color: $product-error;
}

@mixin amy_box {
  .dry-food-amy-box-wrapper {
    @include grid-blocks(8);
    position: relative;
    @include at-least($screen-desktop) {
      @include flexbox(row, flex-start, space-between);
    }
    .amy-box-wrapper {
      @include at-least($screen-desktop) {
        width: 100%;
        height: 100%;
        position: relative;

      }
      .amy-box {
        @include flexbox(row, "", space-evenly);
        background-color: var(--primary-blueberry-light-10);
        border-radius: var(--border-radius-xxs);
        padding: 16px;
        padding-top: 40px;
        margin-top: 69px;
        margin-bottom: 32px;
        position: relative;
        @include at-least($screen-desktop) {
          width: 400px;
          height: auto;
          margin: 0;
          padding: 32px;
          position: absolute;
          top: 32px;
          right: -64px;
        }
        img {
          max-width: 80px;
          position: absolute;
          top: -45px;
          @include at-least($screen-desktop) {
            max-width: 120px;
            top: -32px;
            left: 32px;
          }
        }
        .amy-box-copy {
          .bold {
            text-align: center;
            font-size: $font-size-xs;
            margin-bottom: 16px;
            @include at-least($screen-desktop) {
              margin-left: 136px;
              text-align: left;
              font-size: $font-size-s;
            }
          }
          p {
            margin: 0;
            }
          p:nth-child(2) {
            @include at-least($screen-desktop) {
              margin-top: 32px;
          }
          }
        }
      }
    }
  }
}

body.consultation.select-country {
  .select-country-wrapper {
    @include flexbox(column, flex-start, center);
    padding: 80px 20px ;
    @include at-least($screen-tablet) {
      max-width: 640px;
      padding: 80px 0;
    }
    .delivery-body {
      .delivery-flag {
        img#flag {
          height: 75px;
          border: 1px solid $navy-01;
          border-radius: 50%;
          margin-top: 10px;
        }
      }
      h2 {
        margin-bottom: 20px;
      }
      margin: 0 auto;
      .selector-wrapper {
        position: relative;
        width: 100%;
          @include until($screen-tablet) {
            max-width: 325px;
          }
        select {
          $select-padding: 25px;
          $max-select-width-mobile: 325px;
          border-bottom: 1px solid $navy-01;
          padding: $select-padding;
          width: inherit;
          max-width: calc(#{$max-select-width-mobile} - (#{$select-padding} * 2));
          @include at-least($screen-tablet) {
            width: 100%;
            max-width: 100%;
          }
        }
        .selector-arrow {
          right: 10px;
          position: absolute;
          top: 28px;
          pointer-events: none;
          @include at-least($screen-tablet) {
             right: -30px;
          }
          &:before {
            @include icon($icon-chevron-down);
            color: $navy-01;
          }
        }
      }
    }
    .form.select-country {
      .btn {
        font-weight: bold;
        @include grid-blocks(2);
        @include at-least($screen-tablet) {
          @include grid-blocks(3);
        }
        margin-top: 20px;
      }
    }
  }
}

body.consultation.promotion {
  .promotion-wrapper {
    @include flexbox(column, flex-start, center);
    padding: $spacing-xxl 12px;

    h1 {
      font-size: $font-size-xl;
      margin-bottom: $spacing-m;
      text-align: left;
    }

    p {
      margin: 0 0 $spacing-xxs;
    }

    .error-text {
      color: $product-error;

      &:before {
        @include icon($icon-warning_icon);
        color: $product-error;
        margin-right: 4px;
      }
    }

    @include at-least($screen-tablet) {
      max-width: 640px;
      padding: 80px 0;
    }

    .code-input {
      @include input_style(4px);
      border: 1px solid $navy-01;
      max-width: 280px;
      margin-bottom: $spacing-xxs;
      width: 100%;
    }

    button {
      width: 280px;
      margin-top: $spacing-xs;
    }
  }
}

body.consultation {
  .alert-window {
    @include grid-blocks(6);
  }

  @mixin disabled_button {
    background-color: $pastel-lighter-frost !important;
    pointer-events: none;
  }

  @mixin toggle_on {
    border-radius: 0 !important;
    border: none !important;
    box-shadow: none !important;
    background-color: $primary-white !important;
    color: $navy-01 !important;
    border-bottom: none !important;
  }

  @include position(absolute, 0 0 null 0);
  text-align: left;
  overflow-x: hidden;

  .wrapper {
    position: relative;
    width: 100%;
    overflow: hidden;
    z-index: 1;
  }

  label.btn {
    font-weight: normal;
  }

  .top-nav .links, .top-nav .signout, .hamburger {
    display: none;
  }

  .main {
    font-size: $font-size-body;
    color: $primary-white;

  }

  h1 {
    display: inline-block;
    @include grid-blocks(6);
    margin-bottom: 10px;
    padding-bottom: 0;

    @include until($screen-tablet) {
      text-align: center;
    }

  }

  label {
    margin-bottom: 10px;
  }

  .radio-button-new {
    margin-bottom: 0;
  }

  hr {
    margin-top: 30px;
    margin-bottom: 30px;
    border: 0;
    border-top: 1px solid rgb(0, 33, 66);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  .alerts {
    padding: 15px;

    &:empty {
      display: none;
    }

    .alert.alert-info {
      background-color: $secondary-01;
      color: $primary-white;
      max-width: $scss-container-max-width / 2;
      margin: auto;
      border: none;
    }
  }

  .alert-window {
    @include grid-blocks(6);
  }

  .alert.alert-danger {
    background: none;
    border: none;
    color: $product-error;

    strong {
      color: $product-error;
      font-weight: bold;
    }
  }

  .choices + .alert.alert-info {
    margin-top: 15px;
  }

  .checkbox-list legend{
    text-align: left;
  }

  fieldset legend {
    text-align: center;
  }

  .radio-buttons-new legend {
    text-align: left;
  }

  fieldset legend:nth-child(n+2) {
    margin-top: 20px;
  }

  .step-actions {
    width: 100%;
    padding: 20px 0;
  }

  [data-step=pets], [data-step=profile] {
    .progress-wrapper {
      display: none;
    }

    .steps-wrapper {
      margin-top: 30px;
    }
  }

  .content {
    padding-bottom: 0;

    .container {
      padding: 0;
    }
  }

  .progress-wrapper {
    height: 100%;
    width: 100%;
    text-align: center;
    padding: 20px 15px 30px 15px;
    @media screen and (min-width: $screen-tablet) {
      padding: 40px 15px;
    }

    .progress {
      @include size(100% 10px);
      @include grid-blocks(8);
      display: none;

      .track {
        @include size(100%);
        border-radius: 50px;
        border: 1px solid $navy-01;

        .bar {
          position: relative;
          @include size(50% 100%);
          @include transition(width 0.3s);
          background-color: $navy-01;
          border-radius: 50px;

          .value {
            @include position(absolute, null 0 0 null);
            color: $navy-01;
            @include transform(translate(50%, 150%));
          }
        }

      }
    }

    &.show {
      @media screen and (min-width: $screen-tablet) {
        padding: 40px 15px;
      }

      .progress {
        display: inline-block;
      }
    }
  }

  .steps-wrapper {
    width: 100%;
    overflow: hidden;
  }

  .steps {
    white-space: nowrap;
    width: 100%;
    overflow: visible;

    .step {
      @include inalign(top);
      width: 100%;
      white-space: normal;
      word-spacing: normal;

      .form {
        color: $primary-colour;
        padding: 0 15px 3em 15px;
        @include until($screen-tablet) {
          padding-bottom: 0;
        }

        .desktop {
          display: none;
        }

        h2 {
          margin-bottom: 20px;
        }

        @media screen and (min-width: $screen-tablet) {
          text-align: center;
          .step-content {
            display: inline-block;
            vertical-align: middle;
            margin: 0 20px;
          }
        }

        .step-content {
          @include at-least($screen-tablet) {
            text-align: left;
            @include grid-blocks(7);
          }
          margin: auto;
          .radio-buttons {
            text-align: left;
            .radio-toggle-button {
              padding: 0;
              margin-right: 20px;
              margin-left: 0;
              margin-top: $spacing-xs;
              margin-bottom: 0;
            }
          }
          @include grid-blocks(8);
        }
      }

      &.in {
        @include animation(slide-in-right .3s normal forwards);
      }

      &.out {
        @include animation(slide-out-left .3s normal forwards);
        @include transition(opacity 0s);
        @include transition-delay(.3s);
        z-index: -10;
        //opacity: 0;
        position: absolute;
      }
    }

    &.back {
      .step.in {
        @include animation(slide-in-left .3s normal forwards);
      }

      .step.out {
        position: absolute;
        @include animation(slide-out-right .3s normal forwards);
      }
    }
  }

  // Step specific show/hides
  .tails-view-steps {
    &[data-step=pets], &[data-step=profile], &[data-step=start], &[data-step=promo-entry] {
      .step-actions {
        display: none;
      }
    }

    &[data-step=select-country] {
      .btn.back {
        display: none;
      }
    }

    &[data-step=select-country] {
      .btn.back {
        display: none;
      }
    }

    &[data-step=comms-preferences] {
      .btn.back {
        display: none;
      }
    }

    .btn.next, .btn.back {
      @include transition(all 0.3s);
    }

    &[data-state=back], &[data-state=submitting] {
      .btn {
        opacity: .5;
      }
    }

    &[data-state=back] {
      .btn.back {
        @extend .btn-progress;
      }
    }

    &[data-state=submitting] {
      .btn.next {
        @extend .btn-progress;
      }
    }
  }

  .steps .step {

    .form.promo-entry {
      text-align: center;
      padding-bottom: 50px !important;

      .step-content {
        @include at-least($screen-tablet) {
          max-width: 500px;
        }
      }

      .ribbon {
        position: relative;
        display: block;
        background-color: $secondary-01;
        color: $primary-white;
        padding: 15px 20px;
        width: 100%;
        margin: 0;
      }

      .input-wrapper {
        margin-bottom: 20px;
      }

      button.anchor {
        @extend a;
        border: none;
        background: none;
        outline: none;
        cursor: pointer;
        font-size: $font-size-body;
      }

      &[data-state=blocked] {
        .btn.next {
          @include disabled_button();
        }
      }

      .promo-input-wrapper {
        margin-top: 40px;

        &[data-state=progress], &[data-state=error], &[data-state=no-promo] {
          .promo-summary, .promo-description, a.change-promo {
            display: none;
          }
        }

        &[data-state=progress], &[data-state=error] {
          .btn.next {
            @include disabled_button();
          }

        }

        &[data-state=progress] {
          .confirm-promo {
            @extend .btn-progress;
          }
        }

        &[data-state=error] {
          input.change-promo {
            //@extend input.error;
          }

          .alerts {
            display: block;
          }
        }

        &[data-state=no-promo] {
          .confirm-promo {
            display: block;
          }
        }

        &[data-state=promo] {
          .default-link, button.confirm-promo {
            display: none;
          }

          input.change-promo {
            border-bottom: 1px solid $secondary-01;
          }
        }

        input.change-promo {
          margin: 0;
        }

        a.change-promo {
          line-height: 40px;
          white-space: nowrap;
          @include at-least($screen-phone) {
            position: absolute;
            right: 0;
            bottom: 0;
          }
        }

        .promo-description {
          text-align: center;
          color: $secondary-01;

          @include at-least($screen-phone) {
            text-align: left;
            margin-left: 28%;
          }
        }

        label {
          margin-bottom: 0;
          flex: 1 0 28%;
        }
      }

      .ribbon-tail {
        fill: $secondary-01;
        width: 15px;
        height: 100%;

        &.ribbon-tail-left {
          @include position(absolute, 0 null 0 0);
          @include transform(translate(-15px, 0));
        }

        &.ribbon-tail-right {
          @include position(absolute, 0 0 0 null);
          @include transform(translate(15px, 0));
        }
      }

      .input-wrapper, .input-wrapper input, .input-wrapper .mock-input {
        width: 100%;
      }

      .form-group.with-button .input-wrapper {
        @include flexbox(row, center, center);
        flex-direction: column;

        @include at-least($screen-phone) {
          flex-direction: row;
        }

        .btn {
          margin-left: 15px;
          border-radius: 0;
          padding: 5px 20px;
        }
      }

      .mock-input {
        // @extend input[type=text];
        display: block;
        text-align: left;
        padding: 13px 50px 0 5px;
      }

      .btn.next {
        @include grid-blocks(3);
        margin-top: 30px;
      }
    }

    .form.start {
      text-align: center;

      p {
        display: block;
        @include grid-blocks(6);
        margin: auto;
        margin-bottom: 30px;
      }

      .actions {
        .btn {
          @include grid-blocks(3);
          display: block;
          margin: auto;
          margin-bottom: 15px;
        }
      }
    }

    .form.select-country {
      @include flexbox(column, inherit, space-around);
      text-align: center !important;
      padding: 20px;

      .input-wrapper, .btn {
        @include grid-blocks(3);

        input {
          width: 100%;
        }
      }

      .delivery-flag {
        img#flag {
          height: 75px;
          border: 1px solid $navy-04;
          border-radius: 50%;
          margin-top: 10px;
        }
      }

      .delivery-body {
        margin: 0 auto;

        .selector-wrapper {
          position: relative;
          width: 100%;
           @include until($screen-tablet) {
              max-width: 325px;
            }

          select {
            border-bottom: 1px solid $navy-01;
            padding: 25px;
            width: 100%;
          }

          .selector-arrow {
            position: absolute;
            top: 28px;
            right: -30px;
            pointer-events: none;

            &:before {
              @include icon($icon-chevron-down);
              color: $navy-01;
            }
          }
        }
      }
    }

    .form.profile {
      $size: 70px;
      padding-top: 40px;
      padding-bottom: 50px !important;

      .view-feeding-plan:before {
        @include icon($icon-checklist-circle);
      }

      .page-actions {
        padding-top: 50px;
        flex-direction: column-reverse;

        @include at-least($screen-tablet) {
          flex-direction: row;
        }
      }

      .details {
        @include grid-blocks(6);
        margin: auto;
        display: table;
        color: $primary-colour;
        text-align: left;
        overflow: hidden;
        margin-top: 2rem;
        border-top: 1px solid $primary-colour-40;

        .detail {
          width: 100%;
          display: table-row;

          > * {
            display: table-cell;
            vertical-align: middle;
            border-bottom: 1px solid $primary-colour-40;
            padding: 15px;
          }

          .detail-container {
            @include at-least($screen-tablet) {
              display: grid;
              grid-template-columns: 1fr 2fr;
            }
          }

          .label {
            font-weight: bold;
            padding: 0;
            background-color: transparent;
            color: var(--primary-colour);

            @include at-least($screen-tablet) {
              display: table-cell;
              grid-column: 1;
              grid-row: 1;
            }
          }

          .value {
            margin: 0.5rem 0;

            @include at-least($screen-tablet) {
              margin: 0;
              display: table-cell;
              grid-column: 2;
              grid-row: 1;
            }
          }

          .actions {
            text-align: right;
            vertical-align: text-bottom;

            .edit {
              padding-right: 15px;
              background: transparent;
              border: none;
              outline: none;

              &:before {
                @include icon($icon-edit-circle);
                color: $secondary-03;
                font-size: 3rem;
              }
            }
          }
        }
      }
    }

    .form.breed {
      .input-wrapper {
        width: 100%;

        &.error {
          .action {
            color: $product-error;
          }

          select {
            @include error-border;
          }
        }
      }

      .tails-view-filter-list {
        height: auto !important;

        .list {
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);

          .item {
            font-weight: normal;

            &.selected {
              color: $navy-01;
            }
          }
        }
      }
    }

    .form.health-info {
      .step-wrapper {
        @include flexbox(column, center, center);
        background-color: var(--primary-blueberry-light-10);
        padding: $spacing-xs $spacing-xs $spacing-s $spacing-xs;
        border-radius: var(--border-radius-xxs);
        .bowl-image {
          flex: 1 0 auto;
          height: 224px;
          width: 224px;
          margin-top: $spacing-s;
          background-size: contain;
          background-repeat: no-repeat;
          background-image: url("/static/images/consultation/digestion_info/health-bowl.png");
        }
        .de-bowl-image {
          background-image: url("/static/images/consultation/digestion_info/de-bowl.png");
        }
        @include at-least($screen-tablet) {
          background: none;
          padding: 0;
          padding-top: $spacing-l;
          @include flexbox(row, flex-start, center);
          .bowl-image {
            background-image: url("/static/images/consultation/digestion_info/health-bowl.png");
            height: 320px;
            width: 320px;
            margin: 0 0 0 $spacing-m;
          }
          .de-bowl-image {
            background-image: url("/static/images/consultation/digestion_info/de-bowl.png");
          }
          .text-wrapper {
            position: relative;
            flex: 1 1 auto;
            padding: $spacing-m $spacing-m $spacing-xl $spacing-m;
            background-color: none;
            overflow: hidden;

            .text-wrapper-shape {
              position: absolute;
              content: '';
              width: 200%;
              height: 200%;
              border-radius: 50%;
              background-color: var(--primary-blueberry-light-10);
              bottom: 0;
              left: -50%;
              z-index: -1;
            }
          }
        }
      }
    }

    #form_overweight-info, #form_underweight-info, #form_weight-management-info {
      .weight-info-images {
        @include flexbox(row, flex-start, space-evenly);
        padding: 0 5px;
        img {
          margin: 5px;
          width: 50%;
        }
      }
    }

    #form_weight-motivation-info,
    #form_delivery-motivation-info,
    #form_ingredients-motivation-info,
    #form_taste-motivation-info,
    #form_health-motivation-info {
      .info-headline {
        width: 100%;
        text-align: center;
        margin-top: 20px;
        margin-bottom: 20px;
        @include at-least($screen-tablet) {
          text-align: left;
        }
      }
      .info-box-wrapper {
        padding: $spacing-s;
        margin-bottom: 20px;
        background-color: $pastel-lighter-frost;
        .info-box-headline-wrapper {
          &.row {
            @include flexbox(row, center, flex-start);
            @include at-least($screen-tablet) {
              @include flexbox(row, flex-start, flex-start);
            }
          }
          &.column {
            @include flexbox(column, center, flex-start);
          }
          flex-wrap: wrap;
          @include at-least($screen-tablet) {
            flex-wrap: nowrap;
          }
          .img {
            flex-shrink: 0;
            &.weight-motivation-info,
            &.health-motivation-info {
              background: center / contain no-repeat url("/static/images/consultation/conversation_flow/sean_img@2x.png");
              height: 70px;
              width: 70px;
            }
            &.delivery-motivation-info {
              background: center / contain no-repeat url("/static/images/consultation/conversation_flow/trustpilot@2x.png");
              height: 28px;
              width: 245px;
              margin-bottom: 10px;
            }
            &.ingredient-motivation-info,
            &.taste-motivation-info {
              background: center / contain no-repeat url("/static/images/consultation/conversation_flow/samantha_img@2x.png");
              height: 70px;
              width: 70px;
            }
          }
          > .info-box-headline.mobile {
            display: block;
            @include at-least($screen-tablet) {
              display: none;
            }
            margin-left: 20px;
            flex: 1 0 50%
          }
          .info-box-copy-wrapper {
            @include at-least($screen-tablet) {
              margin-left: 20px;
            }
            > .info-box-headline.desktop {
              display: none;
              @include at-least($screen-tablet) {
                display: block;
              }
            }
            .info-box-text {
              text-align: left;
              &:last-of-type {
                margin-bottom: 0;
              }
            }
          }
        }
      }
     .info-content-wrapper {
       @include flexbox(row, flex-start, flex-start);
       @include at-least($screen-tablet) {
         @include flexbox(row, center, flex-start);
       }
        .img {
          background-position: top;
          background-size: contain;
          background-repeat: no-repeat;
          flex-shrink: 0;
          &.weight-motivation-info {
            height: 112px;
            width: 112px;
            background-image: url("/static/images/consultation/conversation_flow/scoop_img@2x.png");
            @include at-least($screen-tablet) {
              height: 140px;
              width: 140px;
            }
          }
          &.delivery-motivation-info {
            background-image: url("/static/images/consultation/conversation_flow/box@2x.png");
            height: 122px;
            width: 122px;
            @include at-least($screen-tablet) {
              height: 140px;
              width: 140px;
            }
          }
          &.ingredient-motivation-info,
          &.taste-motivation-info,
          &.health-motivation-info {
            height: 91px;
            width: 91px;
            @include at-least($screen-tablet) {
              height: 140px;
              width: 140px;
            }
          }
          &.ingredient-motivation-info {
            background-image: url("/static/images/consultation/conversation_flow/food_img@2x.png");
          }
          &.taste-motivation-info {
            background-image: url("/static/images/consultation/conversation_flow/taste_img@2x.png");
          }
          &.health-motivation-info {
            background-image: url("/static/images/consultation/conversation_flow/health_img@2x.png");
          }
        }
      }
      .footer-note {
        margin: $spacing-s 0 0;
        grid-column-start: content;
        grid-column-end: image;
      }
    }
    .info-steps-v2 {
      #form_weight-motivation-info,
      #form_delivery-motivation-info,
      #form_ingredients-motivation-info,
      #form_taste-motivation-info,
      #form_health-motivation-info {
      .info-box-wrapper {
        @include flexbox(column, center, center);
        padding: $spacing-s $spacing-xs;
        margin-top: $spacing-xxxl;
        margin-bottom: 2.8rem;
        background-color: var(--primary-blueberry-light-10);
        border-radius: var(--border-radius-xxs);

        @include at-least($screen-tablet) {
          width: 100%;
          display: grid;
          grid-template-rows: max-content auto;
          grid-template-columns: 6fr 5fr;
          grid-template-areas:
            'headline image'
            'content image'
          ;
          padding: $spacing-s $spacing-m;
          margin: 7.2rem auto 2.8rem auto;
        }
        .info-box-headline-wrapper {
          grid-area: headline;
          margin-bottom: $spacing-xxs;
          @include flexbox(column, center, center);
          flex-wrap: wrap;
          @include at-least($screen-tablet) {
            @include flexbox(row, flex-start, center);
            margin-bottom: $spacing-s;
            flex-wrap: nowrap;
          }
          .img {
            flex-shrink: 0;
            &.weight-motivation-info-v2,
            &.weight-motivation-info-puppy,
            &.health-motivation-info-puppy,
            &.health-motivation-info-v2 {
              background: center / contain no-repeat url("/static/images/consultation/conversation_flow/sean_img@2x.png");
              height: 80px;
              width: 80px;
              margin-top: -$spacing-xxxl;
              @include at-least($screen-tablet) {
                height: 120px;
                width: 120px;
                margin-top: -$spacing-xxl;
              }
            }
            &.weight-motivation-info-v2-de,
            &.weight-motivation-info-puppy-de,
            &.health-motivation-info-puppy-de {
              background: center / contain no-repeat url("/static/images/consultation/conversation_flow/de-vet.png");
            }
            &.delivery-motivation-info-puppy,
            &.delivery-motivation-info-v2 {
              background: center / contain no-repeat url("/static/images/consultation/conversation_flow/trustpilot@2x.png");
              height: 28px;
              width: 245px;
              margin-bottom: $spacing-xs;
            }
            &.ingredient-motivation-info-v2,
            &.ingredient-motivation-info-puppy,
            &.taste-motivation-info-puppy,
            &.taste-motivation-info-v2 {
              background: center / contain no-repeat url("/static/images/consultation/conversation_flow/samantha_img@2x.png");
              height: 80px;
              width: 80px;
              margin-top: -$spacing-xxxl;
              @include at-least($screen-tablet) {
                height: 120px;
                width: 120px;
                margin-top: -$spacing-xxl;
              }
            }
            &.ingredient-motivation-info-v2-de,
            &.ingredient-motivation-info-puppy-de,
            &.taste-motivation-info-v2-de,
            &.taste-motivation-info-puppy-de,
            &.health-motivation-info-v2-de {
              background: center / contain no-repeat url("/static/images/consultation/conversation_flow/de-vet.png");
            }
          }
          > .info-box-headline.mobile {
            display: block;
            @include at-least($screen-tablet) {
              display: none;
            }
            margin: $spacing-xxs 0;
            flex: 1 0 50%;
            font-size: $font-size-xs;
            text-align: center;
          }
          .info-box-copy-wrapper {
            @include at-least($screen-tablet) {
              margin-left: $spacing-xs;
            }
            > .info-box-headline.desktop {
              display: none;
              @include at-least($screen-tablet) {
                margin: 0;
                display: block;
              }
            }
          }
        }
      }
      .info-content-wrapper {
        @include at-least($screen-tablet) {
          grid-area: content;
          align-self: start;
        }
      }
      .info-image-wrapper {
        margin-top: $spacing-s;
        @include at-least($screen-tablet) {
          grid-area: image;
          justify-self: end;
          align-self: center;
          margin-top: 0;
        }
        .product-shot-hero-text {
          position: relative;
          font-weight: bold;
          width: auto;
          display: inline-block;
          text-transform: capitalize;
        }
        .img {
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          width: 224px;
          height: 224px;
          @include at-least($screen-tablet) {
            width: 288px;
            height: 288px;
          }
          position: relative;
          .product-shot-hero-text {
            position: absolute;
          }
          &.weight-motivation-info-v2 {
            background-image: url("/static/images/consultation/conversation_flow/info-screens-v2/motivation_weight_v2.png");
            .product-shot-hero-text {
              top: 30%;
              left: 49%;
              transform: rotate(0.03turn) scale(0.98);
            }
            @include at-least($screen-sm) {
              background-image: url("/static/images/consultation/conversation_flow/info-screens-v2/motivation_weight_v2.png");
              .product-shot-hero-text {
                top: 32%;
                left: 49%;
                transform: rotate(0.18rad);
              }
            }
          }
          &.weight-motivation-info-puppy {
            background-image: url("/static/images/consultation/conversation_flow/info-screens-v2/motivation_puppy_growth.png");
            .product-shot-hero-text {
              display: none;
            }
          }
          &.weight-motivation-info-v2-de,
          &.weight-motivation-info-puppy-de {
            background-image: url("/static/images/consultation/conversation_flow/info-screens-v2/de-weight-bag.png");
            .product-shot-hero-text {
              display: none;
            }
          }
          &.delivery-motivation-info-v2 {
            background-image: url("/static/images/consultation/conversation_flow/info-screens-v2/motivation_delivery.png");
            .product-shot-hero-text {
              top: 30%;
              left: 36%;
              transform: rotate(-0.040turn) scale(0.98);
            }
            @include at-least($screen-sm) {
              background-image: url("/static/images/consultation/conversation_flow/info-screens-v2/motivation_delivery_desktop.png");
              .product-shot-hero-text {
                top: 31%;
                left: 36%;
                transform: rotate(-0.25rad);
              }
            }
          }
          &.delivery-motivation-info-v2-de {
            background-image: url("/static/images/consultation/conversation_flow/info-screens-v2/motivation_delivery-de.png");
            @include at-least($screen-sm) {
              background-image: url("/static/images/consultation/conversation_flow/info-screens-v2/motivation_delivery-de.png");
            }
          }
          &.delivery-motivation-info-puppy {
            background-image: url("/static/images/consultation/conversation_flow/info-screens-v2/motivation_puppy_delivery.png");
            .product-shot-hero-text {
              top: 30%;
              left: 36%;
              transform: rotate(-0.040turn) scale(0.98);

              @include at-least($screen-tablet) {
                top: 31%;
                transform: rotate(-0.25rad);
              }
            }
          }
          &.delivery-motivation-info-v2-de,
          &.delivery-motivation-info-puppy-de {
            background-image: url("/static/images/consultation/conversation_flow/info-screens-v2/motivation_delivery-de.png");
            @include at-least($screen-sm) {
              background-image: url("/static/images/consultation/conversation_flow/info-screens-v2/motivation_delivery-de.png");
            }
          }
          &.ingredient-motivation-info-v2 {
            background-image: url("/static/images/consultation/conversation_flow/info-screens-v2/motivation_ingredients.png");
            @include at-least(768px) {
              background-image: url("/static/images/consultation/conversation_flow/info-screens-v2/motivation_ingredients_desktop.png");
            }
          }
          &.ingredient-motivation-info-puppy {
            background-image: url("/static/images/consultation/conversation_flow/info-screens-v2/motivation_puppy_ingredients.png");
          }
          &.ingredient-motivation-info-v2-de,
          &.ingredient-motivation-info-puppy-de {
            background-image: url("/static/images/consultation/conversation_flow/info-screens-v2/de-ingredients-motivation.png");
          }
          &.taste-motivation-info-v2 {
            background-image: url("/static/images/consultation/conversation_flow/info-screens-v2/motivation_taste.png");
            @include at-least($screen-sm) {
              background-image: url("/static/images/consultation/conversation_flow/info-screens-v2/motivation_taste_desktop.png");
            }
          }
          &.taste-motivation-info-puppy {
            background-image: url("/static/images/consultation/conversation_flow/info-screens-v2/motivation_puppy_taste.png");
          }
          &.taste-motivation-info-v2-de,
          &.taste-motivation-info-puppy-de {
            background-image: url("/static/images/consultation/conversation_flow/info-screens-v2/de-taste-motivation.png");
          }
          &.health-motivation-info-v2 {
            background-image: url("/static/images/consultation/conversation_flow/info-screens-v2/motivation_health.png");
            .product-shot-hero-text {
              top: 20%;
              left: 48%;
              transform: rotate(-0.050turn) scale(0.98);
            }
            @include at-least($screen-sm) {
              background-image: url("/static/images/consultation/conversation_flow/info-screens-v2/motivation_health_desktop.png");
              .product-shot-hero-text {
                top: 27%;
                left: 51%;
                transform: rotate(-0.28rad);
              }
            }
          }
          &.health-motivation-info-puppy {
            background-image: url("/static/images/consultation/conversation_flow/info-screens-v2/motivation_puppy_digestion.png");
            .product-shot-hero-text {
              top: 21%;
              left: 48%;
              transform: rotate(-0.050turn) scale(0.98);

              @include at-least($screen-tablet) {
                transform: rotate(-0.28rad);
              }
            }
          }
          &.health-motivation-info-v2-de,
          &.health-motivation-info-puppy-de {
            background-image: url("/static/images/consultation/conversation_flow/info-screens-v2/de-health-motivation.png");
            .product-shot-hero-text {
              display: none;
            }
          }
        }
      }
    }
    #form_delivery-motivation-info {
      .info-box-wrapper {
        margin-top: 0;
      }
    }
    }
    .form-health-info, .info-steps-v2 {
      ul {
        padding-left: $spacing-s;
        margin: 0;

        > li {
          padding-left: $spacing-xs;
          & + li {
            margin-top: $spacing-xxs;
          }
        }
        @include at-least($screen-tablet) {
          padding-left: $spacing-m;
        }
      }
    }
  }

  .pet-processing {
    color: $navy-01;
    overflow: hidden;
    @include position(fixed, 0 0 0 0);
    z-index: 99999;

    .spinner {
      @include tails_spinner(50px, 15px);
    }
  }

  ul.accordion {
    border-top: 1px solid $pastel-lighter-frost;
  }

  ul.accordion.small-print .accordion-item {
    border-bottom: 1px solid $pastel-lighter-frost;

    .accordion-item-header {
      border: 0;

      :hover {
        .title, .toggle {
          color: $navy-01;
        }
      }

      .title {
        color: $navy-01;
      }

      .toggle {
        color: $pastel-lighter-frost;
        font-size: 2.5rem;
        line-height: 25px;

      }

      .toggle:after {
        @include icon($icon-chevron-down);
      }
    }



    .accordion-item-body {
      border-top: 1px solid $pastel-lighter-frost;

      section {
        @include margin(0 0 20px 0);

        :last-child {
          margin-bottom: 0;
        }

        @include at-least($screen-tablet) {
          @include margin(0 15px 20px 15px);
          :last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    input[type=checkbox]:checked {
      ~ .accordion-item-header {
        background-color: $primary-white;

        .title, .toggle {
          color: $navy-01;
        }
      }

      ~ .accordion-item-body {
        @include border-width(null 0 0 0);
        @include padding(20px null);
        @include margin(0);
      }
    }
  }
}

body.consultation.flow-my-dog {
  $form-font-size: 16px;
  $error-font-size: 16px;
  $form-font-weight: normal;
  $image-margin: 10px;
  $field-margin-side-mobile: 10px;
  $field-margin-side-tablet: 15px;
  $drop-down-box-shadow: 0 2px 4px rgba(0,0,0,.3);
  $input-text-color: $primary-colour;
  .btn {
    -webkit-appearance: none;
  }
  .steps {
    overflow: visible;
  }
  #notifications {
    overflow: auto;
  }
  .step-content {
    select {
      border-radius: 3px;
      font-weight: $form-font-weight;
      font-size: $form-font-size;
      padding: 12px 40px 12px 10px;
      box-sizing: border-box;
      margin-left: $field-margin-side-mobile;
      margin-right: $field-margin-side-mobile;
      margin-bottom: 10px;
      @include at-least($screen-tablet) {
        margin-left: $field-margin-side-tablet;
        margin-right: $field-margin-side-tablet;
      }
    }
    input {
      font-weight: $form-font-weight;
      font-size: $form-font-size;
      color: $input-text-color;
    }
    h2 {
      text-align: center;
    }
    p {
      margin-top: 10px;
    }

    .radio-buttons-new .hint {
      margin-top: 0;
    }
    .dry-food-section {
      margin-top: 0;
    }
    .has-errors {
      background-color: $product-error-background;
    }
    .chevron-down {
      width: 0;
      height: 0;
      position: absolute;
      pointer-events: none;
    }
    .chevron-down:before{
      @include icon($icon-chevron-down);
      font-size: 2.5rem;
      position: relative;
      left: -45px;
      top: 10px;
      @include at-least($screen-tablet) {
        left: -50px;
      }
    }
    .chevron-weight:before {
      top: 18px;
    }
    .notification.error {
      li {
        font-size: $error-font-size;
      }
    }
    @include at-least($screen-tablet) {
      h2 {
        text-align: left;
      }
    }
    .step-header-wrapper {
      @include at-least($screen-tablet) {
        @include flexbox(row, flex-start, flex-start);
        &.dominant {
          margin-bottom: 0;
        }
      }
      .step-header {
        @include at-least($screen-tablet) {
           flex: 2 2 auto;
        }
        p {
          margin-bottom: 30px;
        }
      }
    }
    .step-image-wrapper {
       .image {
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
      }
      .image.your-dog {
        background-image: url("/static/images/consultation/conversation_flow/your_dog/your_dog@2x.png");
      }
      .image.dominant-breed,
      .image.unknown-breed{
        background-image: url("/static/images/consultation/conversation_flow/breed/unknown-breed.png");
      }
      .image.puppy-birthday {
        background-image: url("/static/images/consultation/conversation_flow/puppy_birthday/puppy.png");
      }
      .image.pregnancy {
        background-image: url("/static/images/consultation/conversation_flow/pregnancy/nursing.png");
      }
      .image.weight {
        background-image: url("/static/images/consultation/conversation_flow/weight/weight@2x.png");
      }
      .image.health {
        background-image: url("/static/images/consultation/conversation_flow/health/health_img@2x.png");
      }
      .image.diet {
        background-image: url("/static/images/consultation/conversation_flow/diet/diet@2x.png");
      }
      .image.current-food {
        background-image: url("/static/images/consultation/conversation_flow/current_food/current_food@2x.png");
      }
      .image.new-food {
        background-image: url("/static/images/consultation/conversation_flow/new_food/new_food@2x.png");
      }
      .image.email {
        background-image: url("/static/images/consultation/conversation_flow/email/img_dog_rolling.png");
      }
    }
    .step-image-wrapper.mobile {
      @include flexbox(column, center, center);
      @include at-least($screen-tablet) {
        display: none;
      }
      .image.dominant-breed,
      .image.unknown-breed,
      .image.puppy-birthday,
      .image.pregnancy {
        height: 214px;
        width: 300px;
      }
      .image.your-dog,
      .image.weight,
      .image.diet,
      .image.current-food,
      .image.new-food,
      .image.email {
        height: 88px;
        width: 130px;
      }
      .image.health {
        height: 40px;
        margin-top: 10px;
        width: 130px;
      }
      .image.pregnancy {
        margin-top: 35px;
      }
      .image.unknown-breed {
        margin-top: 35px;
        @include at-least($screen-desktop) {
            margin-top: 0;
        }
      }
    }
    .step-image-wrapper.desktop {
      @include at-least($screen-tablet) {
        display: block;
        flex: 1 1 auto;
        @include flexbox(row, flex-start, flex-end);
      }

      .image.dominant-breed,
      .image.unknown-breed,
      .image.puppy-birthday {
        width: 320px;
        height: 228px;
      }
      .image.pregnancy {
        width: 175px;
        height: 125px;
      }
      .image.your-dog,
      .image.weight,
      .image.diet,
      .image.current-food,
      .image.new-food,
      .image.email {
        width: 170px;
        height: 86px;
      }
      .image.health {
        height: 50px;
        width: 170px;
      }
    }
  }

  .form.info-steps-v2 {
    #form_overweight-info, #form_underweight-info, #form_weight-management-info {
        .weight-info-images {
          display: flex;
          flex-direction: column;
          position: relative;

          .weight-image {
            width: 203px;
            height: 201px;
            position: relative;
            background-size: contain;
            background-repeat: no-repeat;
            margin: 5px;

            @include at-least($screen-tablet){
              width: 279px;
              height: 279px;
            }

            &.weight-image-overweight-before {
              background-image: url('/static/images/consultation/weight-info/weight_overweight_before_img@2x.png');
            }

            &.weight-image-overweight-after {
              align-self: flex-end;
              margin-bottom: 0;
              background-image: url('/static/images/consultation/weight-info/weight_overweight_after_img@2x.png');
            }

            &.weight-image-underweight-before {
              background-image: url('/static/images/consultation/weight-info/weight_underweight_before_img@2x.png');
            }

            &.weight-image-underweight-after {
              align-self: flex-end;
              margin-bottom: 0;
              background-image: url('/static/images/consultation/weight-info/weight_underweight_after_img@2x.png');
            }

            .weight-info-caption {
              width: 200px;
              font-family: $handwritten-font-family;
              font-size: 24px;
              text-align: center;
              position: absolute;
              transform: rotate(-45deg);
              left: -39%;
              top: -1%;

              @include at-least($screen-tablet) {
                left: -25%;
                top: 3%;
              }

              &.caption-after {
                right: -36%;
                left: unset;
                top: 0;
                transform: rotate(45deg);
                @include at-least($screen-tablet) {
                  left: -25%;
                  top: 3%;
                  right: unset;
                  transform: rotate(-45deg);
                }
              }
            }
          }
          @include at-least($screen-tablet) {
            @include flexbox(row, flex-start, space-between);
            & .weight-image-overweight-after, .weight-image-underweight-after {
              margin-top: $spacing-xs;
            }
          }
        }
        .step-explanation-wrapper {
          display: flex;
          justify-content: center;
          @include at-least($screen-tablet){
            margin-top: -70px;
          }
          .step-explanation {
            background-color: var(--primary-blueberry-light-10);
            padding: 20px 20px 20px 20px;
            margin: $spacing-xs 0 0 0;
            width: 287px;
            height: 287px;
            border-radius: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .icon {
              position: relative;
              width: 100%;
              display: block;
              &.icon-quotes-left::before {
                position: absolute;
                left: -10px;
                top: -20px;
              }
              &.icon-quotes-right {
                text-align: right;
                &:before {
                  position: absolute;
                  bottom: -20px;
                  right: -20px;
                }
              }
            }
            .step-explanation-copy {
              text-align: center;
              margin: -10px 0;
              padding: 0 $spacing-xs;
              display: block;
              font-size: $font-size-body;
            }
          }
          .step-author {
            text-align: center;
            padding: 0 50px 0 50px;
            font-weight: bold;
            margin: 0;
            margin-top: $spacing-s;
            font-size: $font-size-xs;
          }
        }
      }
    }

  .dry-recipe-choice {

    .claims-section {
      padding-bottom: 10px;

      .claim-with-icon {
        display: flex;

        .icon-wrapper{
          display: flex;
          img {
            width: 50px;
            display: inline-block;
            @include at-least($screen-tablet) {
              width: 60px;
            }
          }
        }

        .claim-wrapper {
          display: inline-block;
          padding: 15px;
          p {
            margin: -4px 0;
            line-height: 1.8;
          }
        }

      }
    }

    .blend-boxes {
      @include at-least($screen-tablet) {
        display: flex;
      }
    }

    .macro-dry-blend {
      margin: 0 7px 30px 7px;
      display: flex;
      flex-direction: column;
      position: relative;

      @include at-least($screen-tablet) {
         width: 50%;
      }

      .price-info {
        text-align: right;
        padding-bottom: 20px;

        .full-price {
          margin-bottom: 5px;
        }

        .discounted-price {
          text-align: center;
          background-color: $pastel-lavender;
          padding: 5px 10px 20px;
          @include grid-blocks(1);
          margin-left: auto;
          border-top-right-radius: 12px;
          border-top-left-radius: 12px;

          .price {
            font-weight: bold;
            padding-top: 5px;
            padding-bottom: 5px;
            font-size: $font-size-m;
            line-height: 1;
          }
          .label {
            padding-bottom: 5px;
          }
        }
      }

      .dry-display {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        background-color: $pastel-lighter-silver;
        border-radius: 12px;
        margin-top: -15px;
        border: 1px solid rgba(0, 0, 0, 0);
        box-shadow: 0px 0px 0 3px rgba(0, 0, 0, 0);

        &.dry-display-border-purple {
          border: 1px solid $primary-colour;
          box-shadow: 0px 0px 0 3px $secondary-02;
        }

        .image-wrapper {
          display: flex;

          img {
            height: 170px;
            margin: auto;
          }
        }

        .info-wrapper {
          flex-grow: 1;

          .title {
            text-align: center;
            font-weight: bold;
            font-size: $font-size-m;
            max-width: 85%;
            margin: auto;
          }

          .blend-info {
            padding: 10px 15px 0 15px;
            margin-bottom: 60px;

            > div:nth-of-type(1) {
              .background-odd {
                background-color: $pastel-rose;
              }

              .background-even {
                background-color: $pastel-beige;
              }
            }

            > div:nth-of-type(2) {
              .background-odd {
                background-color: $pastel-lighter-sage;
              }

              .background-even {
                background-color: $pastel-frost;
              }
            }

            > div:nth-of-type(3) {
              .background-odd {
                background-color: $pastel-lavender;
              }

              .background-even {
                background-color: $pastel-lighter-ocean-mist;
              }
            }

            > div {
              display: flex;
              margin-top: 10px;

              .icon-container {
                  width: 50px;
                  height: 50px;

                  .icon-background {
                      border-radius: 50%;
                      width: 40px;
                      height: 40px;
                      margin-left: 10px;
                      margin-top: 10px;

                      img {
                          width: 50px;
                          margin-left: -10px;
                          margin-top: -10px;
                      }
                  }
              }
            }

            p {
              padding-left: 15px;
              line-height: 1.8;
              margin-top: 0;
            }
          }
        }

        .details-toggle {
          font-size: $font-size-s;
          text-align: center;
          margin-bottom: 30px;
          position: absolute;
          bottom: 0;
          width: 100%;
        }

        .radio-buttons {
          background-color: $primary-colour;
          border-top-right-radius: 12px;
          border-top-left-radius: 12px;
          padding: 5px 15px 15px 15px;
          color: $primary-white;

          &.selected {
            background-color: $primary-colour;
          }

        .recipe-hide {
            display: none;
        }

          .radio-toggle-button {
            > label {
              font-size: $font-size-s;
              margin: 0;
              @include at-least($screen-tablet) {
                font-size: $font-size-body;
              }

              > span {
                white-space: normal;
              }
            }
          }

          .flavour {
            font-family: $handwritten-font-family;
            font-size: $font-size-m;
            padding: 10px 0 0 40px;

            &:first-letter {
              text-transform: uppercase;
            }
          }
        }
      }
    }
  }

  .details-popup {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 1;

    .details-popup-inner {
      background: white;
      top: 13%;
      bottom: 3%;
      right: 3%;
      left: 3%;
      position: absolute;
      overflow: auto;
      padding: 10px;
      border-radius: 12px;
      @include at-least($screen-tablet) {
        width: 50%;
        margin: auto;
      }

      .product-header:nth-child(1) {
        margin-top: 70px;
      }

      .title-bar {
        display: flex;
        position: fixed;
        background: $primary-white;
        width: 90%;
        margin-top: -10px;

        @include at-least($screen-tablet) {
          width: 48%;
        }

        .icon {
          cursor: pointer;
              margin: 15px 5px auto auto;
          }
          .title {
              padding: 20px 10px;
          }
      }

      .expand-sections {
        border-bottom: 1px solid black;

        .expander {
          border-top: 1px solid black;
          padding: 20px;

          .expand-bar {
            display: flex;
            cursor: pointer;

            .icon {
              font-size: $font-size-m;
              padding-right: 20px;
            }

            .icon-close {
              display: none;
              font-size: 17px;
            }
          }

          .to-expand {
            display: none;
            padding-top: 15px;

            > ul {
              list-style: none;
              padding-left: 0;
            }
          }

          &.auto-expanded {
            .icon-plus1 {
              display: none;
            }

            .icon-close {
              display: block;
            }

            .to-expand {
              display: block;
            }

          }
        }
      }
    }
  }

  .email {
    .step-form-wrapper.email {
      margin-bottom: 20px;
      @include flexbox(column, center, flex-start);
      @include at-least($screen-tablet) {
        margin-bottom: 28px;
        @include flexbox(row-reverse, flex-start, flex-start);
      }
      .tails-bag {
        background-color: $pastel-lavender;
        position: relative;
        height: 224px;
        width: 224px;
        -webkit-clip-path: circle(112px);
        clip-path: circle(112px);

        &::before {
          content: '';
          width: 212px;
          height: 212px;
          -webkit-clip-path: circle(106px);
          clip-path: circle(106px);
          background-size: cover;
          background-position: center center;
          background-repeat: no-repeat;
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;
        }
        @include at-least($screen-tablet) {
          flex: 1 0 auto;
          height: 320px;
          width: 320px;
          -webkit-clip-path: circle(160px);
          clip-path: circle(160px);
          margin-left: $spacing-m;

          &::before {
            height: 304px;
            width: 304px;
            -webkit-clip-path: circle(152px);
            clip-path: circle(152px);

          }
        }
        .product-shot-hero-text {
          position: absolute;
          font-weight: bold;
          width: auto;
          display: inline-block;
          text-transform: capitalize;
        }
        &.dry, &.dry-treats, &.None {
          &::before {
            background-image: url(/static/images/consultation/conversation_flow/email/email-step-dog-mobile.png);
            height: 225px;
            width: 225px;
            -webkit-clip-path: circle(115px);
            clip-path: circle(115px);
          }
          .product-shot-hero-text {
            top: 17%;
            left: 36.2%;
            transform: rotate(-0.045turn) scale(0.9);
          }
          @include at-least($screen-tablet) {
            &::before {
              background-image: url(/static/images/consultation/conversation_flow/email/email-step-dog-desktop.png);
              height: 320px;
              width: 320px;
              -webkit-clip-path: circle(162px);
              clip-path: circle(162px);
            }
            .product-shot-hero-text {
              top: 20%;
              left: 37%;
              transform: rotate(-0.28rad);
            }
          }
        }
        &.dry-wet {
          &::before {
            background-image: url(/static/images/consultation/conversation_flow/email/dry_wet_img@2x.png);
          }
          .product-shot-hero-text {
            top: 20%;
            left: 42%;
            transform: rotate(-0.045turn) scale(0.9);
          }
          @include at-least($screen-tablet) {
            &::before {
              background-image: url(/static/images/consultation/conversation_flow/email/dry_wet_img_desktop@2x.png);
            }
            .product-shot-hero-text {
              top: 20%;
              left: 44%;
              transform: rotate(-0.28rad);
            }
          }
          &.premium-wet {
            &::before {
              background-image: url(/static/images/consultation/conversation_flow/email/dry_prem_wet_img@2x.png);
            }
            .product-shot-hero-text {
              top: 21%;
              left: 46%;
              transform: rotate(-0.045turn) scale(0.9);
            }
            @include at-least($screen-tablet) {
              &::before {
                background-image: url(/static/images/consultation/conversation_flow/email/dry_prem_wet_img_desktop@2x.png);
              }
              .product-shot-hero-text {
                top: 23%;
                left: 47%;
                transform: rotate(-0.28rad);
              }
            }
          }
        }
        &.dry-wet-treats {
          &::before {
            background-image: url(/static/images/consultation/conversation_flow/email/dry_wet_treats_img@2x.png);
          }
          .product-shot-hero-text {
            top: 30%;
            left: 41%;
            transform: rotate(-0.045turn) scale(0.75);
          }
          @include at-least($screen-tablet) {
            &::before {
              background-image: url(/static/images/consultation/conversation_flow/email/dry_wet_treats_img_desktop@2x.png);
            }
            .product-shot-hero-text {
              top: 20%;
              left: 45.5%;
              transform: rotate(-0.28rad);
            }
          }
          &.premium-wet {
            &::before {
              background-image: url(/static/images/consultation/conversation_flow/email/dry_prem_wet_treats_img@2x.png);
            }
            .product-shot-hero-text {
              top: 32%;
              left: 41%;
              transform: rotate(-0.045turn) scale(0.75);
            }
            @include at-least($screen-tablet) {
              &::before {
                background-image: url(/static/images/consultation/conversation_flow/email/dry_prem_wet_treats_img_desktop@2x.png);
              }
              .product-shot-hero-text {
                top: 33%;
                left: 42.5%;
                transform: rotate(-0.28rad) scale(0.85);
              }
            }
          }
        }
        &.email-redesign-gb {
          display: none;
          @include at-least($screen-tablet) {
            display: block;

            &::before {
              top: 8px;
              left: 8px;
              transform: scale(1.05);
              background-image: url(/static/images/consultation/conversation_flow/email/email-step-redesign-alfie.png);
            }
          }
        }
      }
      .email-form-wrapper {
        @include flexbox(column, flex-start, flex-start);
        .recipe-text {
          margin: $spacing-xxs 0;
        }
        @include at-least($screen-tablet) {
          flex: 1 1 auto;
          .recipe-text {
            margin: 0 0 $spacing-xxs 0;
          }
        }
        .input-container, .checkbox-wrapper-alt {
          width: 100%;
        }
        .input-container {
        //   margin-bottom: $spacing-m;
          @include at-least($screen-tablet) {
            max-width: 288px;
          }
        }
        input#email {
          width: 100%;
          margin: 0;
          @include until($screen-tablet) {
            max-width: none;
          }
          &.has-errors {
            background-color: $product-error-background;
          }
        }
        .email-inline-error, .opt-in-inline-error {
            display: none;
            align-items: center;

            p {
                margin-left: 10px;
            }
        }
        .login-link {
          margin-bottom: $spacing-s;
        }
        .intro {
          background-color: $pastel-lighter-beige;
          padding: $spacing-xs;
          margin-bottom: $spacing-xs;

          .comms-text {
            margin: 0;
          }
        }

        .email-hidden {
          display: none;
          margin: 20px 0 0 0;
        }

        .email-step-submit {
          width: 100%;
          @include at-least($screen-tablet) {
            width: auto;
          }
        }

        .email-back {
          margin-top: 3rem;
        }

        .validation-error {
          &:before {
            @include icon($icon-exclamation-circle);
            @include inalign(middle);
            font-size: $font-size-s;
            padding-right: 5px;
          }

          span {
            @include inalign(middle);
          }

          color: $product-error;
        }

        .radio-buttons {
          position: relative;
          white-space: nowrap;

          .radio-toggle-button {
            margin-right: $spacing-s;
          }
        }

        .radio-buttons-new {
          margin-top: $spacing-xs;
        }

        .comms-text {
          line-height: 1.5;
        }
        .modal {
          @include until($screen-tablet) {
            margin-left: calc(50% - 140px);
            width: 280px;
          }

          .modal-header {
            font-size: 28px;
            margin-top: 48px;

            @include at-least($screen-tablet) {
              font-size: 31px;
              margin-top: 30px;
            }
          }
          .modal-body {
            padding: 0 10px;

            @include at-least($screen-tablet) {
              padding: 4px 80px;
            }
          }
        }

        > p {
          margin: 0;
        }
      }

      .flow-buttons {
        @include at-least($screen-tablet) {
          display: block;
        }
      }
      .modal-btn {
        @include until($screen-tablet) {
          width: 90%;
        }

        @include at-least($screen-tablet) {
          padding: 12px 150px;
          margin-left: 0;
          margin-bottom: 20px;
        }
      }
      .back-btn {
        @include at-least($screen-tablet) {
          display: block;
        }
      }
    }
  }

  .tails-view-steps {
    .progress-wrapper.show {
      @include flexbox(column, center, flex-start);
      padding: 5px 15px 0 15px;
      @media screen and (min-width: $screen-tablet) {
        padding: 10px 15px 0 15px;
      }
      .progress-image {
        display: inline-block;
        @include size(100% 40px);
        @include grid-blocks(8);
        @media screen and (min-width: $screen-tablet) {
          @include size(100% 48px);
        }
        .step-image {
          height: 40px;
          width: 40px;
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          @media screen and (min-width: $screen-tablet) {
            height: 60px;
            width: 60px;
            background-position: 0 45%;
          }
        }
      }
      .progress {
        width: 100%;
        @include grid-blocks(8);
        margin-bottom: 20px;
        @media screen and (min-width: $screen-tablet) {
          margin-bottom: 30px;
        }
        .track {
          border: 0;
          background-color: $pastel-darker-frost;
          .bar {
            background-color: $primary-blueberry;
          }
        }
      }
    }
    &[data-step=basics], &[data-step=puppy_birthday], &[data-step=pregnant] {
      .step-image {background-image: url("/static/images/consultation/progress/progress-bar.png");}
    }
    &[data-step=breed], &[data-step=unknown] {
      .step-image {background-image: url("/static/images/consultation/progress/progress-bar.png");}
    }
    &[data-step=dominant], &[data-step=current-diet] {
      .step-image {background-image: url("/static/images/consultation/progress/progress-bar.png");}
    }
    &[data-step=motivation], &[data-step=delivery-motivation-info], &[data-step=health-motivation-info],
    &[data-step=weight-motivation-info], &[data-step=ingredients-motivation-info],
    &[data-step=taste-motivation-info], &[data-step=current-food] {
      .step-image {background-image: url("/static/images/consultation/progress/progress-bar.png");}
    }
    &[data-step=weight], &[data-step=overweight-info], &[data-step=underweight-info],
    &[data-step=weight-management-info], &[data-step=flavours] {
      .step-image {background-image: url("/static/images/consultation/progress/progress-bar.png");}
    }
    &[data-step=activity], &[data-step=textures] {
      .step-image {background-image: url("/static/images/consultation/progress/progress-bar.png");}
    }
    &[data-step=health], &[data-step=no-condition-info], &[data-step=pancreatitis-info],
    &[data-step=digestion-info], &[data-step=skin-info], &[data-step=multiple-condition-info],
    &[data-step=joints-info], &[data-step=feeding-preference] {
      .step-image {background-image: url("/static/images/consultation/progress/progress-bar.png");}
    }
    &[data-step=exclusions], &[data-step=exclusions-preferences], &[data-step=email] {
      .step-image {background-image: url("/static/images/consultation/progress/progress-bar.png");}
    }
  }
  .step-header-container {
    margin-bottom: $spacing-m;
    @include at-least($screen-tablet) {
      margin-bottom: $spacing-l;
    }

    .step-sub-header {
      h2, h4 {
        text-align: left;
        font-size: $font-size-s;
        font-family: $default-font-family;
        color: $primary-black;
        font-weight: $font-weight-regular;
        letter-spacing: 0;
      }

      &.email {
        > h4 {
          line-height: 24px;
        }
      }
    }
  }

  .email-step-container-redesign {
    padding: 14rem 0 1rem 0;
    width: 100vw;
    position: relative;
    left: calc(-50vw + 50%);
    background-color: $pastel-lighter-frost;

    @include at-least($screen-tablet) {
      padding: 0;
    }

    .email-redesign-gb {
        &::before {
            content: '';
            width: 100%;
            background-position: 0 60%;
            height: 200px;
            top: 0;
            min-height: 200px;
            background-size: cover;
            background-repeat: no-repeat;
            clip-path: circle(750px at 50% -300%);
            position: absolute;
            background-image: url(/static/images/consultation/conversation_flow/email/email-step-redesign-alfie-mobile.png);
        }

      @include at-least($screen-tablet) {
        display: none;
      }
    }
  }

  .step-header {
    padding-top: 35px;
    padding-bottom: $spacing-xs;
    h1 {
      font-family: $handwritten-font-family;
      font-size: $font-size-xxl;
      text-align: left;
      margin: 0;
      padding: 0;
    }
  }
  .email-step-header-redesign {
      padding: 15px;

      @include at-least($screen-tablet) {
          margin: 0 auto;
          text-align: left;
          padding-bottom: 20px;
          padding-left: 0;
          padding-right: 0;
          @include grid-blocks(7);
      }
  }

  p {
    margin-top: 0;
  }
  .step-form-wrapper {
    margin-bottom: 20px;

    .checklist-wrapper {
      padding: 0;

      label {
        @include flexbox(row, center, flex-start);
      }
    }
  }
  .radio-buttons {
    position: relative;
    white-space: nowrap;

    .radio-toggle-button {
      margin-right: 20px;
      padding: 10px 0;
      span {
        font-weight: normal;
        white-space: pre-wrap;
      }
    }
  }
  input[type='range'] {
    background-color: $secondary-08;
    border: 1px solid $secondary-08;
    border-radius: 15px;
    height: 10px;
    padding: 5px 0;
    margin: 0;
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 40px;
      height: 40px;
      background: $primary-white;
      box-shadow: none;
      border: 1px solid $primary-colour;
      cursor: pointer;
    }
  }
  .info-box-wrapper {
    background-color: var(--primary-blueberry-light-10);
    padding: 12px 16px;
    border-radius: var(--border-radius-xxs);
    margin-top: 10px;

    @include at-least($screen-tablet) {
      padding: $spacing-s;
      width: calc(100% + 106px);
      margin: 0 -53px 30px -53px;
    }

    p {
      width: 100%;
    }
  }
  .flow-buttons {
    .back {
      order: 0;
      margin-left: 0;
    }
    .js-step-next.btn.btn-primary {
      order: 1;
      width: 280px;

      span {
        font-size: 16px;
        line-height: 30px;
        font-weight: bold;
      }
    }
    @include until($screen-tablet) {
      flex-direction: column-reverse;
      justify-content: space-around;
      align-items: center;
    }
    @include at-least($screen-tablet) {
      .back {
        margin-right: 30px;
      }
    }
  }

  .trustpilot-block {
    padding: 5px 16px;
    margin: 24px auto 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .trustpilot-header {
      font-size: 43px;
      font-family: $handwritten-font-family;
      line-height: 48px;
      text-align: center;
      flex: 0 0 100%;
      margin-bottom: 20px;
    }

    .trustpilot-img-container {
      flex: 0 0 50%;

      .trustpilot-img {
        height: 16px;
        margin: 8px calc((100% - 150px) / 2);
        @include at-least($screen-tablet) {
          margin-top: 4px;
          height: 18px
        }
      }
    }

    .trustpilot-text {
      text-align: center;
      flex: 0 0 100%;
    }
  }
  .footer-banner {
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;

    .footer-banner-text {
      text-align: center;
    }
    @include at-least($screen-tablet) {
      margin-top: 12px;

      .footer-banner-text {
        text-align: left;
      }
    }
  }
  #form_basics {
    input[type='text'] {
      display: block;
      margin-left: 0;
      width: 100%;

      @include at-least($screen-tablet) {
        width: 60%;
      }
    }
    .pet-name {
      margin-top: 0;
      margin-bottom: 30px;
    }
    input[type='number'] {
      width: 48px;
    }
    #age-age_years {
      margin-left: 0;
    }
    .age-text {
      display: block;
    }
    #age-data {
      margin-bottom: 20px;

      #years, #months {
        margin: 0 $spacing-xxs;
      }
      .years, .months {
        display: inline-block;
        width: 64px;
      }
    }
    .neutered-container {
      margin-top: 10px;
    }
    .step-text {
      margin-bottom: 0;
    }
  }
  #form_breed {
    input[type='text'] {
      margin-left: 0;
      margin-bottom: 0;
    }
    .breed-name {
      margin-top: $spacing-xxs;
    }
    .step-text {
      margin: 0;
    }
    .breed-select-container {
      display: flex;
      flex-flow: row;
      flex-wrap: wrap;
    }
    .crossbreed, .purebreed {
      margin-bottom: $spacing-s;
      flex: 0 0 100%;
    }
    .purebreed-breed-container {
      flex: 0 0 100%;
      order: 0;
    }
    .crossbreed-breed-container {
      margin-top: 0;
      order: 2;
    }
    .radio-toggle-button {
      padding-bottom: 0;
    }
    @include at-least($screen-tablet) {
      .purebreed-breed-container, .crossbreed-breed-container {
        margin-bottom: 12px;
        flex: 0 0 30%;
        order: 0;
      }
      .crossbreed, .purebreed {
        flex: 0 0 60%;
      }
    }
    .unknown-breed-container {
      flex: 0 0 100%;
      margin-top: 0;
      order: 5;
    }
    .purebreed {
      order: 1;
    }
    .crossbreed {
      order: 3;
    }
    .notification.breed-alert {
      order: 6;

      p {
        margin: 0;
      }
    }
    label {
      margin-top: $spacing-xs;
      margin-bottom: 0;
    }
    #breed-type-label-unknown {
      margin-top: $spacing-s;
    }
    .hint-text {
      font-size: 13px;
      margin: $spacing-xxs 0;
    }
  }
  #form_pregnant {
    .radio-select-wrapper {
      flex: 0 0 100%;
      @include at-least($screen-tablet) {
        flex: 0 0 50%;
      }
    }
    .step-image-wrapper.desktop {
        flex: 0 0 50%;
        width: 320px;
      }
    @include at-least($screen-tablet) {
      .step-image-wrapper {
        display: flex;
        align-items: flex-start;
      }
    }
  }
  #form_dominant, #form_unknown {
    .content-wrapper {
      @include flexbox(column, flex-start, center);
      @include at-least($screen-desktop) {
        @include flexbox(row, center, flex-start);
      }
      select {
        margin-left: 0;
      }
    }
    .radio-select-wrapper {
      margin-top: 10px;
    }
    p {
      margin: 0;
    }
    @include at-least($screen-tablet) {
      .selections {
        align-self: flex-start;
      }
      .step-image-wrapper {
        display: flex;
        align-items: flex-start;
        margin-left: auto;
      }
    }
  }
  #form_puppy_birthday {
    p {
      margin-top: 0;
    }
    .step-image-wrapper {
      align-items: flex-start;
      margin-top: 20px;
      display: flex;
    }
  }

  #form_health {
    .checkbox-wrapper-alt label {
      align-items: flex-start;
    }
  }

  #form_exclusions {
     ul.multiselect span.label {
      min-height: 44px;
      max-height: 44px;
      line-height: 44px;
    }
    .exclusions-choices ul.multiselect {
      text-align: left;
      margin-top: 10px;
    }
    .exclusions-choices {
      margin-top: 10px;
      label#allergen-category-1-checkbox {
        margin-bottom: 20px;
      }
      .label {
        min-width: 135px;
      }
    }
    .radio-buttons {
      display: flex;
      @include at-least($screen-desktop) {
        display: block;
      }
      input[type='radio'] {
        &:checked + .radio-toggle-button label {
          @include radio_toggle_on;
        }
      }
    }
  }

  #form_exclusions-preferences {
    .exclusions-preferences-form .multi-select-grid label span {
        font-size: $font-size-s;
    }
  }

  #form_weight {
    .pet-condition {
      padding-top: $spacing-xs;
    }

    .weight-section-my-dog {
      @include flexbox(column, flex-start, flex-start);
      .weight {
        @include flexbox(row, center, flex-start);
        .weight-input .pet-weight-input-group {
          @include flexbox(row);
          margin: 5px 0;
          input {
            width: 70px;
            text-align: left;
            margin-left: 0px;
          }
          .pet-weight-input {
            width: 70px;
            margin-left: 10px;
            &:first-child {
              margin: 0;
            }
          }
        }
        .weight-input.error {
          input {
            background-color: $product-error-background;
          }
        }
        .weight-select-dropdown {
          margin-top: $spacing-xxs;
          margin-bottom: $spacing-m;
        }
      }
    }
    .radio-buttons {
      display: block;
    }
  }

  .form.activity {
    .radio-buttons {
      display: inline-block;
    }
    .activity-slider {
      .values {
        @include flexbox(row, flex-start, space-between);
        p {
          font-size: $font-size-xs;
        }
      }
    }
    .activity-box {
      border-radius: 8px;
      padding: 20px;
      margin: 20px 0;
      @include at-least($screen-tablet) {
        padding: 20px 30px 20px 25px;
      }
      .activity-level {
        @include flexbox(row, flex-start, space-between);
        p {
          line-height: 1.6;
        }
        .activity-image {
          width: 140px;
          min-width: 140px;
          max-width: 140px;
          height: 120px;
          background-repeat: no-repeat;
          background-position: center;
          margin-top: 0;
          background-size: cover;
        }
        .activity-image-0 {background-image: url("/static/images/consultation/activity/activity-2.png");}
        .activity-image-1 {background-image: url("/static/images/consultation/activity/activity-3.png");}
        .activity-image-2 {background-image: url("/static/images/consultation/activity/activity-0.png");}
        .activity-image-3 {background-image: url("/static/images/consultation/activity/activity-1.png");}
      }
    }
    .working-dog {
      margin-bottom: 20px;
      .working-dog-text {
        margin-top: 10px;
        display: flex;
        align-items: center;
        .notify-icon {
            background-color: $primary-blueberry-10;
            color: $primary-black;
            padding: 5px;
            border-radius: 50%;
            margin-right: 5px;
        }
      }
      .working-dog-notification {
        border-radius: 8px;
        padding: 2.4rem;
        margin-top: 3rem;

        .hidden {
          display: none;
        }

        .title {
          margin: 0;
          font-weight: var(--font-weight-bold);
        }

        .description {
          margin-bottom: 0;

          a {
            color: $primary-black;
          }
        }
      }
      .working-dog-notification.warning {
        background-color: var(--warn-background);
      }
      .working-dog-notification.error {
        background-color: var(--error-background);
        border: 1px solid #F50110;
      }
      label.working-dog-my-dog {
        cursor: pointer;
        color: $primary-text-link;
        margin-bottom: 0 !important;
        &:hover {
          color: $primary-text-link;
        }
      }
    }
  }

  .form.current-diet {
    .icon-dry-food {
      background: url('/static/images/signup/feeding-plan/icons_info_supplement.svg') no-repeat;
      background-size: contain;
     }
    .icon-wet-food {
      background: url('/static/images/signup/feeding-plan/icons_info_wet-food.svg') no-repeat;
      background-size: contain;
     }
    .icon-other-food {
      background: url('/static/images/signup/feeding-plan/icons_info_treats.svg') no-repeat;
      background-size: contain;
     }
    @media (max-width: 350px){
      .label {
        max-width: 50%;
      }
    }
  }

  #form_current-food {
    @include amy_box();
    .list {
      box-shadow: 0 2px 4px rgba(0,0,0,.3);
    }
    .error {
      input {
        background-color: $product-error-background;
        & + .status {
          display: none;
        }
      }
      .action {
        color: $product-error;
      }
    }
    .disabled {
        background-color: $pastel-lighter-frost;
        border-color: $navy-04;
    }
    select {
      &.error {
        background-color: $product-error-background;
      }
    }
    label#no-brand-checkbox {
      justify-content: flex-start;
      width: 100%;
      &:before {
        height: 42px;
        min-height: 42px;
        min-width: 42px;
        width: 42px;
      }
    }
    .checkbox-wrapper-alt {
        display: block;

        @include at-least($screen-tablet) {
          display: inline-block;
          margin-bottom: 20px;
        }
    }
    .pet-wet-food {
      width: 100%;
      @include at-least($screen-tablet) {
        width: 50%;
      }
    }
    .pet-current-diet-form, .pet-current-diet-text, .pet-no-dry-food-text,
    .pet-wet-food-text, .pet-wet-food-amount {
      display: inline-block;
    }
    .pet-dry-food-brand-form {
      display: block;
      vertical-align: middle;
      .search {
        margin-left: 0;
        margin-bottom: 10px;
      }
      .hint-text {
        font-size: 13px;
        margin: 0 $spacing-xxs 0 0;
      }
      @include at-least($screen-tablet) {
        display: inline-block;
        margin: 0 $spacing-xs $spacing-m 0;
        vertical-align: bottom;
      }
    }
    .pet-wet-food-amount {
      margin-left: 0;
      select {
        margin-left: 0;
      }
      .chevron-down:before {
        top: 12px;
      }
    }
    .pet-wet-food-text {
      margin-right: 15px;
    }
    .pet-other-food-amount,
    .pet-wet-food-format {
      .chevron-down:before {
        @include at-least($screen-tablet) {
           top: 22px;
        }
      }
      select {
       margin-left: 0px;
       max-width: 100%;
        @include at-least($screen-tablet) {
          margin-top: 10px;
        }
      }
    }
    .pet-other-food-amount {
      select {
        margin-left: 0;
      }
      @include at-least($screen-tablet) {
        margin-right: 2px;
      }
    }
    .searcher {
      min-width: 260px;
      width: 100%;
      .status {
        display: none;
      }
    }
    .pet-other-food-text {
      margin-right: 16px;
    }
    @include at-least($screen-tablet) {
      .pet-other-food-text, .pet-other-food-amount {
        display: inline-block;
      }
    }
    .food-section {
      margin-top: 20px;
    }
    .food-section-subheader {
      font-weight: bold;
      margin-bottom: 15px;
    }
    .pet-dry-food-brand-text {
      display: inline-block;
      margin-bottom: 0;
        @include at-least($screen-tablet) {
          display: block;
        }
    }
    .pet-wet-food-text {
      display: block;
    }
    #wet-food-amount-text {
      margin-right: 15px;
    }
    @include until($screen-tablet) {
      .dry-food-section {
        margin-top: 20px;
      }
      .pet-wet-food-amount {
        margin-top: 10px;
      }
      .pet-dry-food-brand-form {
        margin-top: 0px;
        margin-bottom: $spacing-m;
      }
    }
    .pet-wet-food-format {
      display: inline-block;
      max-width: 100%;
      position: relative;
      .chevron-down {
        top: 0;
        right: 0;
      }
    }
    .pet-other-food-amount, .radio-buttons {
      display: block;
    }
    .radio-toggle-button {
      padding: 2px 0;
    }
    .radio-buttons {
      margin: 10px 0 5px;
      @include until($screen-tablet) {
        margin-bottom: 0;
      }
    }
    .pet-other-food-text {
      margin-top: 4px;
    }
  }
  #form_textures, #form_flavours {
    .checklist-wrapper {
      padding-left: 0;

      label {
        &:before {
          height: 36px;
          line-height: 36px;
          min-height: 36px;
          min-width: 36px;
          width: 36px;
        }
        @include at-least($screen-tablet) {
          &:before {
            height: 42px;
            line-height: 42px;
            min-height: 42px;
            min-width: 42px;
            width: 42px;
          }
        }
      }
    }
  }
  #form_feeding-preference {
    .step-sub-header{
      @include at-least($screen-tablet){
        h4 {
          width: 70%;
        }
      }
    }

    .form-text {
      margin-bottom: 20px;
    }
    .feeding-preference-wrapper {
      @include grid-blocks(6);
    }
    .image-dry {
      width: 200px;
      @include at-least($screen-phone) {
        width: 360px;
      }
    }
    .image-dry-only {
      display: block;
      height: 200px;
      margin-bottom: 10px;
      shape-outside: circle(50%);
      background: url('/static/images/consultation/feeding-preferences/Dry.png') no-repeat center / contain;
      @include at-least($screen-tablet) {
        display: none;
      }
    }
    @keyframes fp-slide {
      from { right: -1500px; left: 1500px; opacity: 1; }
      to { opacity: 1; }
    }
    @keyframes fp-slide-left {
      from { right: 1500px; left: -1500px; opacity: 1; }
      to { opacity: 1; }
    }
    @keyframes fp-fadeout {
      from { opacity: 1; display: block; }
      to { opacity: 0; display: none; }
    }
    .image-wrapper {
      position: relative;
      width: 250px;
      margin: auto;
      @include at-least($screen-tablet) {
        width: 360px;
      }
    }
    .image {
      position: absolute;
      height: 120px;
      width: 120px;
      opacity: 0;
      display: none;
      animation: fp-fadeout 0.5s ease-in-out;
    }
    .image-dry-wet {
      top: -305px;
      left: 0;
      background: url('/static/images/consultation/feeding-preferences/wet\ tray_top.png') no-repeat center / contain;
        @include until($screen-tablet) {
          &.has-all-choices {
           top: -357px;
        }
      }

      &.premium-wet {
        background: url('/static/images/consultation/feeding-preferences/wet\ tray_premium_top.png') no-repeat center / contain;
      }
    }
    .image-dry-wet-alt {
      top: -260px;
      left: -35px;
      z-index: -1;
      background: url('/static/images/consultation/feeding-preferences/wet\ tray_behind.png') no-repeat center / contain;
      @include until($screen-tablet) {
          &.has-all-choices {
           top: -295px;
        }
      }
      &.premium-wet {
        background: url('/static/images/consultation/feeding-preferences/wet\ tray_premium_behind.png') no-repeat center / contain;
      }
    }
    .image-dry-wet-treats {
      top: -290px;
      right: -40px;
      height: 200px;
      background: url('/static/images/consultation/feeding-preferences/Treats\ group.png') no-repeat center / contain;
    }
    @include at-least($screen-tablet) {
      .image-dry-wet {
        top: -150px;
        left: 300px;
        width: 200px;
        height: 181px;

        &.has-all-choices {
          top: -175px;
        }
      }
      .image-dry-wet-alt {
        top: -86px;
        left: 250px;
        width: 180px;
        height: 180px;

        &.has-all-choices {
          top: -95px;
        }
      }
      .image-dry-wet-treats {
        top: -100px;
        right: -450px;
        width: 400px;
        height: 300px;
      }
    }

    /* Animations */
    .radio-buttons-new.dry-wet .image-wrapper .image-dry-wet,
    .radio-buttons-new.dry-wet .image-wrapper .image-dry-wet-alt {
      opacity: 1;
      display: block;
      animation: fp-slide 0.3s ease-in-out;
    }

    .radio-buttons-new.dry-wet-treats ~ .image-wrapper {
      .image-dry-wet-treats,
      .image-dry-wet,
      .image-dry-wet-alt {
        opacity: 1;
        display: block;
        animation: fp-slide 0.3s ease-in-out;
      }
    }

    @include until($screen-tablet) {
      .radio-buttons-new.dry-wet .image-wrapper .image-dry-wet,
      .radio-buttons-new.dry-wet .image-wrapper .image-dry-wet-alt {
        animation: fp-slide-left 0.3s ease-in-out;
      }
    }

    @include at-least($screen-tablet) {
      .image-dry-only-desktop {
        float: right;
        padding-right: 420px;
        height: 260px;
        width: 370px;
        margin: -160px -75px 10px 0;
        shape-outside: circle(60% at 100px -60px);
        background: url('/static/images/consultation/feeding-preferences/Dry.png') no-repeat center / contain;
        display: none;
        @include at-least($screen-tablet) {
          display: block;
        }
        &.has-all-choices {
          margin-top: -200px;
        }
      }
    }
  }
}

body.consultation {
  [data-step=pets] {
    .notifications {
      padding: 0 15px;
      .notification-removed-pet {
        margin-bottom: 2px;
      }
    }
  }
  .pets {
    h2 {
      text-align: center;
      @include at-least($screen-desktop) {
        text-align: left;
      }
    }
    margin: auto;
    width: 100%;
    max-width: 640px;
    button.btn-to-link {
      cursor: pointer;
      background: none;
      border: none;
      padding: 0;
      span {
        font-size: $font-size-s;
        color: $primary-text-link;
        text-decoration: underline;
        &:hover {
          color: $primary-text-link;
        }
        @media screen and (min-width: $screen-tablet) {
          font-size: $font-size-body;
        }
      }
    }
    .btn {
      width: 100%;
      margin-bottom: 10px;

      @media screen and (min-width: $screen-tablet) {
        width: auto;
        margin: 0;
      }

      &.lock:before {
        @include icon($icon-lock);
        font-size: $font-size-m;
        margin: 0 5px 0 0;
      }
    }

    label.modal-toggle.btn {
      text-decoration: none;
      color: $navy-01;
      margin: 0;
    }

    .pet {
      @include transition(margin-left 0.3s, opacity 0.3s);
      position: relative;
      word-spacing: normal;
      color: $navy-01;
      border-radius: 4px;
      margin-bottom: 30px;
      display: inline-block;
      background: $primary-white;
      @include flexbox(column, flex-start, space-between);
      width: 100%;
      box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, .1);
      padding: 20px;
      text-align: left;

      .title-wrapper {
        @include flexbox(row, center, space-between);
        width: 100%;
      }

      h5 {
        font-weight: bold;
      }

      p {
        &.percentage {
          font-size: $font-size-xs;
        }

        &:last-of-type{
          margin-bottom: 0;
        }
      }
      .progress-wrapper {
        display: block;
        height: 100%;
        width: 100%;
        text-align: center;
        padding: 10px 0;

        .progress {
          @include size(100% 10px);
          @include grid-blocks(8);
          display: block;

          .track {
            @include size(100%);
            border-radius: 50px;
            border: 0;
            background-color: $pastel-lighter-frost;

            .bar {
              position: relative;
              @include size(50% 100%);
              @include transition(width 0.3s);
              background-color: $navy-01;
              border-radius: 50px;

              .value {
                @include position(absolute, null 0 0 null);
                color: $secondary-01;
                @include transform(translate(50%, 150%));
              }
            }

          }
        }

        &.show {
          @media screen and (min-width: $screen-tablet) {
            padding: 40px 15px;
          }

          .progress {
            display: inline-block;
          }
        }
      }

      hr {
        border: 0;
        border-top: 1px solid #D2D2D2;
        margin: 10px 0;
        width: 100%;
      }
    }

    .page-actions {
      @include flexbox(column, center, space-evenly);
      @media screen and (min-width: $screen-tablet) {
        @include flexbox(row, center, space-evenly);
      }
    }
  }

  .steps .step .form.profile.conversation-edit-profile {
    .details {
      box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.1);
      padding: 20px;
      border-top: hidden;
      border-radius: 3px;

      .detail {
        @include flexbox(row, flex-start, space-between);

        &:not(:last-child) {
          border-bottom: 1px #D2D2D2 solid;
        }

        .detail-container {
          @include flexbox(column, flex-start, space-evenly);
          border: 0;

          label {
            padding: 0;
            background-color: transparent;
            color: var(--primary-colour);
          }
        }

        .actions {
          border: 0;
        }
      }
    }

    .page-actions.flow-buttons {
      .btn {
        width: auto;
        margin-left: 10px;

        &.lock:before {
          @include icon($icon-lock);
          font-size: $font-size-m;
          margin: 0 5px 0 0;
        }
      }

      label.modal-toggle.btn {
        text-decoration: none;
        color: $navy-01;
      }
    }
  }
}


@include keyframes(spin) {
  from {
    @include transform(rotate(0deg));
    //@include transform(rotateZ(0deg));
  }
  to {
    @include transform(rotate(360deg));
    //@include transform(rotateZ(360deg));
  }
}

@include keyframes(fade-out) {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@include keyframes(slide-out-left) {
  0% {
    @include translated(0, 0, 0);
  }
  100% {
    @include translated(-100%, 0, 0);
    display: none;
  }
}

@include keyframes(slide-in-right) {
  0% {
    @include translated(100%, 0, 0);
  }
  100% {
    @include translated(0, 0, 0);
  }
}

@include keyframes(slide-in-left) {
  0% {
    @include translated(-100%, 0, 0);
  }
  100% {
    @include translated(0%, 0, 0);
  }
}

@include keyframes(slide-out-right) {
  0% {
    @include translated(0%, 0, 0);
  }
  100% {
    @include translated(100%, 0, 0);
    display: none;
  }
}

body.alert-window-visible {
    overflow: hidden;
}
@include keyframes(fade-in) {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@include keyframes(fade-in-with-slide-down) {
    0% {
        transform: scale3d(0.5,0.5,1);
    }
    50% {
        transform: scale3d(1.1,1.1,1);
    }
    100% {
        transform: scale3d(1,1,1);
    }
}

.alert-shade {
    pointer-events: none;
    position: fixed;
    z-index: 1000;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: center;
    @include animation(fade-in 0.4s);
    &:before {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
        margin-right: -0.25em; /* Adjusts for spacing */
    }
    &:after {
        content: '';
        @include position(absolute, 0 0 0 0);
        background-color: rgba(0,0,0,0.5);
        z-index: -1;
    }
}


.alert-window {
    pointer-events: auto;
    @include grid-blocks(4);
    @include inalign(middle);
    background: rgba(239, 239, 239, 0.9);
    padding: 25px;
    border-radius: 4px;
    background: $primary-white;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.25);
    transform: scale3d(0.5, 0.5, 1);
    @include animation(fade-in-with-slide-down ease-in-out 0.15s);
    @include animation-fill-mode(forwards);
    @include animation-delay(0.15s);

    .hd {
        margin-bottom: 15px;

        .title {
            font-size: 2.4rem;
            margin: 0;
            margin-top: 8px;
        }

        &.hide {
            display: none;
        }

        .icon {
            font-size: 50px;
            margin-right: 15px;
            margin-left: -35px;
        }

        .title, .icon {
            @include inalign(middle);
        }
    }

    .bd {

        .title {
            font-size: 2.4rem;
            margin: 0;
            margin-top: 8px;
        }
    }

    .ft {
        margin-top: 1em;
    }

    button {
        margin: 5px;
        text-transform: capitalize;
    }

    &[data-level=error] {
        .icon:before {
            @include icon($icon-exclamation-circle);
            color: $product-error;
        }
    }
    &[data-level=warning] {
        .icon:before {
            @include icon($icon-exclamation-circle);
            color: $secondary-03;
        }
    }
    &[data-level=info] {
        .hd .icon:before {
            @include icon($icon-exclamation-circle);
            color: $secondary-01;
        }
    }
}

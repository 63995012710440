body.account.treatment {

    .main-content {
        max-width: 1024px;
        @media (min-width: $screen-sm-min) {
            display: flex;
        }

        .notification {
            position: absolute;
            z-index: 1;
            margin: 5%;
            width: 90%;
            @media (min-width: $screen-sm-min) {
                position: initial;
                width: 95%;
                margin: 10px;
            }
        }

        .strong {
            font-weight: bolder;
        }

        .left {
            @media (min-width: $screen-sm-min) {
                width: 60%;
                flex: 2;
            }

            .back-button {
                display: block;
                margin: 66px 16px 16px;

                @media (min-width: $screen-sm-min) {
                    margin: 16px 16px 16px 48px;
                }
            }

            .left-box {
                @media (min-width: $screen-sm-min) {
                    margin: 20px 48px;
                }
            }

            .info-box {
                @media (min-width: $screen-sm-min) {
                    padding-top: 0;
                }

                ul {
                    padding-left: 20px;

                    li {
                        padding-bottom: 10px;
                    }
                }
            }

            .sean-box {
                background-color: $pastel-lighter-beige;
                margin-top: 43px;

                @media (min-width: $screen-md-min) {
                    margin-top: 60px;
                    margin-bottom: 40px;
                }

                .heading {
                    .spacer {
                        height: 100px;
                    }

                    @media (min-width: $screen-sm) {
                        padding-bottom: 40px;
                    }

                    .sean-info {
                        text-align: center;
                        margin: -43px 0 16px 0;

                        @media (min-width: $screen-sm) {
                            display: flex;
                            flex-flow: wrap;
                            margin: -80px 0 0 180px;
                        }

                        p {
                            margin: 0;
                            font-size: $font-size-xs;
                            @media (min-width: $screen-sm) {
                                font-size: $font-size-s;
                            }
                        }
                    }

                    .sean-image {
                        width: 86px;
                        position: absolute;
                        margin-top: -43px;
                        margin-left: calc(50% - 43px);
                        border-radius: 50%;

                        @media (min-width: $screen-sm) {
                            width: 120px;
                            position: absolute;
                            margin-top: -30px;
                            margin-left: 32px;
                        }
                    }
                }

                ul {
                    margin: 0 16px 25px;
                }

                .icon-group {
                    display: flex;
                    justify-content: space-evenly;

                    div {
                        text-align: center;
                    }
                }
            }

            .image-wrapper {
                .product-image-inner {
                    background: $pastel-ocean-mist;
                    margin: auto;
                    @media (min-width: $screen-sm) {
                        width: 320px;
                    }
                    @media (min-width: $screen-md) {
                        width: 510px;
                        height: 510px;
                        margin: 20px auto;
                    }
                }

                .product-image {
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: top;
                    background-origin: content-box;
                    min-height: 100vw;
                    @media (min-width: $screen-sm) {
                        min-height: 320px;
                    }
                    @media (min-width: $screen-md) {
                        min-height: 510px;
                        max-width: 510px;
                        margin: auto;
                    }
                }

                .thumbnail-images {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .thumbnail-product-image {
                        width: 56px;
                        border-radius: 4px;
                        background-color: rgba(255, 255, 255, 0.3);
                        margin: 15px 4px;
                        padding: 2px;
                        cursor: pointer;

                        @media (min-width: $screen-sm-min) {
                            width: 72px;
                        }

                        &.selected {
                            background-color: $primary-white;
                            border: solid 2px black;
                        }
                    }

                    .icon {
                        display: inline;
                        font-size: $font-size-m;
                        margin: 12px;
                        cursor: pointer;
                        color: rgba(128, 128, 128, 0.5);

                        &.active {
                            color: black;
                        }

                        &.mobile-icon {
                            display: block;
                        }
                    }
                }
            }
        }

        .mobile {
            display: block;
            @media (min-width: $screen-sm-min) {
                display: none;
            }
        }

        .right {
            display: none;
            @media (min-width: $screen-sm-min) {
                display: block;
                margin: 16px 28px 16px 28px;
                flex: 1;
            }

            .right-container {
                @media (min-width: $screen-sm-min) {
                    max-width: 376px;
                }
            }
        }

        .price {
            font-size: $font-size-m;
            display: flex;
            gap: 10px;
            align-items: center;

            span {
                font-size: $font-size-xs;
                line-height: 1.6;
            }
        }

        .churned {
            border: 1px solid $pastel-silver;
            border-radius: 12px;
            margin: 0 12px 12px 12px;
            padding-top: 16px;
            display: flex;

            @media (min-width: $screen-sm-min) {
                margin: 0 0 16px 0;
            }

            div {
                margin: 0 18px 0 20px;
            }

            p {
                margin: 0 16px 16px 0;
                font-size: $font-size-xs;
            }
        }

        .subscription-box {
            background-color: $pastel-lighter-lavender;
            padding: 15px 20px 0 20px;
            @media (min-width: $screen-sm-min) {
                position: sticky;
                top: 106px;
            }

            .buttons {

                @media (min-width: $screen-sm-min) {
                    width: 280px;
                }

                select {
                    width: calc(100% - 60px);
                }

                .btn {
                    margin: 0 0 16px 0;
                }
            }

            p {
                font-size: $font-size-xs;
                margin-top: 0;
            }

            a {
                font-size: $font-size-xs;
            }

            .choose {
                font-size: $font-size-s;
            }

            .select-dropdown {
                margin-bottom: 16px;
                display: block;
            }

            .btn {
                width: 100%;
                margin: 5px 0 5px 0;
            }

        }

        .container {
            padding: 20px 20px 15px 20px;
            @media (min-width: $screen-sm-min) {
                padding: 0;
            }

        }

        .expander {
            border-top: 1px solid #D8D8D8;
            border-bottom: 1px solid #D8D8D8;
            padding: 20px 20px 20px 0;
            color: #155654;

            summary {
                cursor: pointer;
                list-style-type: none;

                .grid {
                    display: grid;
                    grid-template-columns: 1fr 0fr;
                    align-items: start;
                    justify-content: start;
                    justify-items: stretch;
                }

                .icon {
                    color: #155654;
                    font-size: $font-size-m;
                    padding-right: 20px;
                    vertical-align: text-bottom;
                }

                .icon-chevron-up {
                    font-size: 17px;
                    margin: auto 0;
                }
            }

            summary:focus {
                outline: none;
            }

            /* Firefox */
            summary::-webkit-details-marker {
                display: none;
            }

            /* Chrome */
            summary::marker {
                display: none;
            }

            .section {
                padding: 16px 0 16px 0;

                .semi-title {
                    font-weight: bold;
                }

                .worm {
                    padding-top: 8px;
                }
            }

        }

        details {
            .icon-toggle-open {
                display: inline;
            }

            .icon-chevron-up {
                display: none;
            }
        }

        details[open=""] {
            .icon-toggle-open {
                display: none;
            }

            .icon-chevron-up {
                display: inline;
            }

            summary {
                padding-bottom: 15px;
            }
        }

        .specs {
            margin: 32px 0 48px;
        }
    }
}


body.forgot-password {
    text-align: center;

    .content {
        padding-top: 60px;

        .container {
            @include grid-blocks(6);

            h1 {
                margin-bottom: 30px;
            }

            .form-group > label {
                text-transform: none;
                align-self: flex-start;
            }

            .form-group {
                max-width: 296px;
                margin: 0 auto;
                display: flex;
                flex-direction: column;

                input {
                    align-self: center;
                }
            }
        }
    }
}

body.signin {
    .content {
        padding-top: 40px;
        padding-bottom: 40px;
        text-align: center;

        .container {
            padding: 0;

            h1 {
                margin-bottom: 30px;
            }

            .login-form-container {
                margin: 0 auto 40px auto;
                width: 100%;
                max-width: 500px;
                background-color: $pastel-lighter-frost;
                text-align: left;
                padding: 20px 15px;
            }

            .login-form-content {
                margin: 0 auto;
                width: 100%;
                max-width: 300px;

                .hint-text {
                    font-size: 13px;
                    margin: 0 0 $spacing-m 0;
                }

                .form-group {
                    label {
                        margin-bottom: $spacing-xxs;
                        font-weight: 400;
                    }

                    input {
                        border: 1px solid $navy-01;
                        border-radius: 4px;
                        margin-top: 0;
                    }

                    &.password {
                        input {
                            max-width: 256px;
                            margin-right: $spacing-xxs;
                            margin-bottom: $spacing-xxs;
                        }

                        p {
                            margin: 0 0 $spacing-xxs 0;
                        }
                    }

                    &.email {
                        input {
                            margin-bottom: $spacing-xxs;
                        }}

                    &.has-error {
                        input {
                            border: 2px solid $product-error;
                            background-color: $product-error-background;
                            margin-bottom: 0;
                        }

                        .help-block.errors p {
                            color: $product-error;
                            margin-bottom: 20px;
                        }
                    }

                    .toggle-password-visibility {
                        color: $navy-01;
                        margin-left: 268px;
                        font-size: 2.6rem;
                        top: -45px;

                        &:focus {
                            outline: none;
                        }
                    }
                }

                .btn-primary {
                    margin: 0 auto 15px;
                    width: 100%;
                    display: block;
                }

                .forgotten-password {
                    margin: 0;
                    text-align: center;
                }
            }

            .btn-brand-google {
                margin: 40px auto;
            }
        }
    }
}

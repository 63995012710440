@mixin typography-main {
    color: $primary-colour;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: $default-font-family;
    font-style: normal;
}
body.settings-reskin {
    .account-settings-content {
        .notifications {
            padding-top: 1px;
            .notification {
                max-width: 550px;
            }
        }
        display: flex;
        flex-wrap: wrap;
        margin: 40px auto;
        max-width: calc(900px + 5vw);
    
        @include until($screen-desktop) {
            flex-direction: column;
        }
    }
    
    .account-settings-main {
        position: relative;
        top: 3em;
        max-width: 900px;
        margin: auto;
    
        a {
            font-weight: 500;
            color: $primary-text-link;
        }
    
        .account-settings-title {
            h1 {
                font-feature-settings: 'clig' off, 'liga' off;
                font-family: $handwritten-font-family;
                font-size: $font-size-xl;
                font-style: normal;
                font-weight: $font-weight-bold;
                line-height: 40px;
    
                @include until($screen-desktop) {
                    text-align: center;
                    text-align: -webkit-center;
                }
            }
        }
    
        .settings-pane {
            h2 {
                font-size: 20px;
                font-weight: 600;
                line-height: 32px;
                margin: 0px 0px 16px 0px;
                @include typography-main;
            }
    
            h3 {
                font-size: 16px;
                font-weight: 800;
                line-height: 24px;
                @include typography-main;
            }
    
            border-radius: 12px;
            border: 1px solid $primary-blueberry-30;
            background: $primary-white;
            padding: 19px 24px;
            margin: 12px;
    
            @include at-least($screen-desktop) {
                max-width: 480px;
            }
        }
    
        .pane-top {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            
            h2.settings-panel-title {
                min-width: 100%;
                word-break: break-word;
                &.communication-preferences-panel-title {
                    word-break: unset;
                    hyphens: auto;
                }
            }
            a.update-link {
                text-align: left;
                margin-left: 0px;
                margin-top: -4px;
                margin-bottom: 12px;
            }
        }
    
        .pane-top.two-lines {
            @include at-least(380px) {
                flex-wrap: nowrap;
            }
            h2.settings-panel-title {
                @include at-least(380px) {
                    margin-top: 5px;
                    min-width: auto;
                }
            }
            a.update-link {
                @include at-least(380px) {
                    max-width: 130px;
                    margin-left: 12px;
                    margin-top: 10px;
                }
            }
        }
    
        .pane-top.two-lines-extended {
            @include at-least(460px) {
                flex-wrap: nowrap;
            }
            h2.settings-panel-title {
                @include at-least(460px) {
                    margin-top: 5px;
                    min-width: auto;
                }
            }
            a.update-link {
                @include at-least(460px) {
                    max-width: 130px;
                    margin-left: 12px;
                    margin-top: 10px;
                }
            }
        }
    
        .pane-top.two-lines-always {
            display: flex;
            flex-wrap: wrap;
        }

        .your-details {
            .your-details-listed {
                .your-details-item {
                    .label {
                        color: $primary-colour;
                        font-feature-settings: 'clig' off, 'liga' off;
                        font-family: $default-font-family;
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 24px;
                    }
                }
            }
            hr {
                margin: 26px 0px;
                border-top: 0 solid var(--primary-blueberry-10);
            }
        }
    
        .paypal-logo {
            max-width: 89px;
            margin: 6px 19px 0px 0px;
        }
    
        .ideal-logo {
            max-width: 60px;
            margin: 5px 16px -8px 0px;
        }   
    
        .card-brand-logo {
            margin: 10px 20px 10px 0px;
        }
    
        .item {
            display: flex;
            margin: 0px 0px 12px 0px;
            align-items: flex-end;
    
            .interaction-icon, .info-icon {
                width: 24px;
                height: 24px;
                margin: 0px 10px 20px 0px;
            }
            .interaction-icon.icons-interaction-delivery, .interaction-icon.icon-interaction-chat {
                align-self: start;
                margin: 4px 10px 20px 0px;
            }
            .item-text > .label {
                font-size: 13px;
                font-weight: 400;
                line-height: 24px;
                margin: 0px 0px 0px 0px;
                background-color: transparent;
                padding: 0;
                @include typography-main;
            }
            .item-text > .value {
                line-height: 24px;
                margin: 0px 0px 0px 0px;
                @include typography-main;
            }
        }
    
        .lozenge {
            height: 26px;
            border-radius: 8px;
            background-color: $pastel-lighter-rose;
            margin: 0px 0px 10px 0px;
            width: fit-content;
            padding: 0px 10px 0px 10px;
    
            p {
                font-size: 13px;
                font-weight: 400;
                line-height: 24px;
                @include typography-main;
            }
        }
    
        .not-sticky-on-desktop {
            order: 1;
            @include at-least($screen-desktop) {
                flex-basis: 0;
                flex-grow: 999;
                min-width: 40%;
                margin-right: 10%;
            }
        }
        .not-sticky-on-desktop.your-details {
            order: 0;
            @include at-least($screen-desktop) {
                margin-right: 0px;
            }
        }
        .not-sticky-on-desktop.payment-method {
            order: 2;
        }
        .not-sticky-on-desktop.delivery-details {
            order: 3;
        }
        .not-sticky-on-desktop.communication-preferences {
            order: 5;
        }
        .not-sticky-on-desktop.cancel-deliveries {
            order: 6;
        }
    
        .sticky-on-desktop {
            @include at-least($screen-desktop) {
                flex-grow: 1;
                flex-basis: 196px;
                align-self: start;
                position: sticky;
                top: 16rem;
                margin: 10px 0px 0px 30px;
            }
        }
    
        .sticky-on-desktop.raf {
            order: 4; 
            text-align: center;
            margin: 40px 10px 10px 10px;
    
            @include at-least($screen-desktop) {
                order: 1; 
                max-width: 320px;
                margin: 10px 0px 0px 30px;
            }
    
            .image-container {
                position: relative;
                max-width: 210px;
                margin: 20px auto;
            }
    
            h2 {
                text-align: center;
                font-size: 20px;
                font-weight: 500;
                line-height: 32px;
                color: $primary-blueberry;
                font-family: var(--display-font-family);
            }
    
            p {
                text-align: center; 
                font-weight: 400;
                line-height: 24px;
                margin-bottom: 16px;
                @include typography-main;
            }
    
            .unclaimed-credit {
                border-radius: 12px;
                background: $secondary-kibble-light-10;
                padding: 1px 0px;
                max-width: 210px;
                margin: 20px auto;
    
                .title {
                    text-align: center;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    margin: 6px 0px;
                    @include typography-main;
                }
                .credit {
                    text-align: center;
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 32px;
                    margin: 0px 0px 6px 0px;
                    @include typography-main;
                }
            }
    
            .other-balances {
                width: 100%;
                margin-top: 22px;
    
                .other-balance {
                    display: flex;
                    justify-content: space-between;
                }
    
                .title {
                    @include typography-main;
                    font-size: 13px;
                    text-align: left;
    
                }
                .credit {
                    @include typography-main;
                    font-size: 13px;
                    text-align: right;
                }

                hr {
                    border-top: 0 solid var(--primary-blueberry-10);
                }
            }
        }
    }

    .main .content {
        max-width: 100% !important;
    }
}

ul.accordion {
    @include margin(0 auto);
    padding: 0;

    .accordion-item {
        list-style: none;

        input[type=checkbox] {
            display: none;
            &:checked ~ .accordion-item-header {
                background-color: $secondary-03;
                color: white;
                border-bottom-color: darken($scss-secondary-03, 10);
                .toggle {
                    @include transform(rotate(180deg));
                    color: white;
                }
            }
            &:checked ~ .accordion-item-body {
                @include transition(border-color 0.3s, opacity 0.3s);
                border-color: $secondary-03;
                opacity: 1;
                max-height: none;
                margin-bottom: 10px;
            }
        }

        .accordion-item-header {
            display: block;
            background-color: white;
            border-bottom: 3px solid #DCDCDC;
            padding: 15px 10px;
            position: relative;
            text-align: left;
            @include transition(background-color 0.3s, border-color 0.3s);
            @include user-select(none);
            cursor: pointer;

            font-weight: normal;
            color: $primary-colour;
            text-decoration: none;
            text-transform: none;
            font-size: $font-size-body;
            margin: 0;
            .title, .toggle {
                @include inalign(middle);
            }
            .title {
                width: 90%;
                width: calc(100% - 30px);
            }
            .toggle {
                @include transition(all .3s);
                color: $secondary-03;
                font-size: 2rem;
                line-height: 20px;
                &:after {
                    @include icon($icon-arrow-down-circle);
                    pointer-events: none;
                }
            }
            &:hover .toggle {
                color: darken($scss-secondary-03, 10);
            }
        }

        .accordion-item-body {

            display: block;
            max-height: 0;
            opacity: 0;
            margin: 0;
            padding: 0;
            overflow: hidden;
            font-size: $font-size-body;
            list-style: none;

            @include transition(max-height 0.3s);
            border: 4px solid transparent;
            border-top: none;
            text-align: left;
            background-color: white;
            > * {
                $margin: 15px;
                margin: 0 $margin;
                &:first-child {
                    margin-top: $margin;
                }
                &:last-child {
                    margin-bottom: $margin;
                }
            }
        }
    }
}

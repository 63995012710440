body.reactivation {
    text-align: center;
    .align {
        .date {
            text-align: center;
            display: inline-block;
            margin-bottom: 15px;
        }
    }
    .specify {
        text-align: center;
        margin-bottom: 30px;
    }
    .input-wrapper {
        width: 100%;
        select {
            width: 100%;
        }
    }
    .hr {
        @include flexbox(row, center, center);
        margin: 30px 0;
        font-size: $font-size-m;
        .hr-left, .hr-right {
            flex-grow: 2;
            margin: 0 15px;
            border-radius: 5px;
            background: #ddd;
            height: 5px;
            text-transform: uppercase;
            border-bottom: 1px solid rgba(255, 255, 255, 0.8);
        }
    }
    .delivery-window {
        margin: 30px 0;
        .title {
            font-size: $font-size-m;
        }
        .pill {
            margin-top: 5px;
            display: inline-block;
        }
    }
    .payment-summary {
        margin-bottom: 40px;
        tr.highlight {
            font-weight: bold;
        }
    }
    .alert-info {
        color: white;
        margin-bottom: 30px;
    }

    .panel-white {
        margin-bottom: 1em;
    }

    .icon.icon-exclamation-circle {
        color: $secondary-03;
        font-size: 3.2rem;
    }

    .notification {
        max-width: inherit;
        margin: 27px auto;
    }

    .tails-view-vue-delivery .form-group {
        margin-bottom: 40px;
    }
}

.reactivation-details {
    .background-curve-container .background-curve {
        background-color: $pastel-lighter-silver;
        border-radius: 0 0 50% 50%;
        position: relative;
        z-index: -1;
        height: 100%;
        left: -63vw;
        width: 230vw;

        @media only screen and (min-width: $screen-sm-min) {
            left: -15vw;
            width: 130vw;
        }
    }

    .background-curve-container {
        overflow: hidden;
        position: absolute;
        height: 350px;
        width: 100%;
        left: 0;
        top: -170px;
        pointer-events: none;
    }

    .background-curve-container.higher {
        top: -200px;

        @media only screen and (min-width: $screen-desktop) {
            top: -230px;
        }
    }


    .hero-text {
        max-width: 520px;
        margin: 20px auto;
        width: 89%;
        margin-top: 120px;

        h1 {
            font-weight: 200;
            line-height: 32px;
            font-family: $handwritten-font-family;
            font-size: 24px;
        }

        h2 {
            line-height: 32px;
            margin-top: 11px;
            font-weight: $font-weight-bold;
            font-family: $default-font-family;
            color: $primary-black;
            font-size: $font-size-s;
        }

        @media only screen and (max-width: $screen-desktop) {
            margin-top: 80px;

            h1 {
                font-size: $font-size-m
            }
        }
    }


    .tails-card {
        background-color: $primary-white;
        width: 100%;
        border-radius: 9px;

        @media only screen and (max-width: $screen-sm-min)  {
            width: 95%;
        }
    }

    .tails-card.item-list {
        @media only screen and (max-width: $screen-sm-min)  {
            width: 100%;
        }
    }

    .content-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
        max-width: 520px;
        margin: auto;

        form {
            width: inherit;
        }
    }

    .message {
        display: flex;
        margin-bottom: 20px;
        border-radius: 8px;
        background: $primary-blueberry-light-10;

        p {
            padding: 15px;
        }

        .dog-jumping {
            width: 60px;
            height: 60px;
            margin: 16px 0 16px 16px;
        }

        img {
            height: 75px;
            margin: -2px;
        }

        .strong {
            font-weight: 800;
        }
    }

    .invisible-box {
        margin: auto;
        margin-top: 70px;
        max-width: 520px;
        width: 100%;
        padding: 0px 20px 0px 20px;

        @media only screen and (min-width: $screen-sm-min) {
            margin-top: 80px;
        }
    }

    .delivery-callouts {
        z-index: 10;
        position: relative;
        margin-bottom: 40px;

        .delivery, .kibble, .time-left {
            display: flex;
            flex-direction: column;
            margin-bottom: 22px;
            align-items: center;
            text-align-last: center;
        }

        .icons-info {
            margin-right: 12px;

            @media only screen and (min-width: 768px) {
                margin-bottom: 12px;
            }
        }
    }

    .main-content-cards-container {
        display: flex;
        flex-direction: column;
        margin: 0 0 40px;
    }

    .delivery-confirmation.main-content-cards-container {
        margin: 14px auto 30px;
        max-width: 520px;
    }

    @media only screen and (min-width: $screen-sm-min) {
        .main-content-cards-container {
            margin: 14px 0 30px;
        }
    }

    @media only screen and (max-width: 530px) {
        .delivery-confirmation.main-content-cards-container {
            padding: 10px 12px 10px 12px;
        }
    }

    .next-box-title {
        margin-bottom: 10px;
    }

    .profile-wrapper {
        height: 100%;
        position: relative;

        .profile.reactivation.items {
            padding: 0;
        }

        .profile.reactivation {
            padding: 0px 10px 20px 10px;

            @media only screen and (min-width: $screen-sm-min) {
                padding: 8px 32px 32px 32px;
            }

            .attributes.reactivation {
                padding: 0 16px;
            }

            tr {
                height: 80px;
            }

            tr:nth-child(2n+1) {
                background-color: $primary-blueberry-light-10;
            }

            tr.total {
                color: #F4F4F4;
                background-color: #3e4c60;
            }

            .reactivation-item.grey {
                border-bottom: none;
                background-color: #F4F4F4;
                padding: 12px;
            }

            .reactivation-item.white {
                padding: 12px;
                border-bottom: none;
            }

            .reactivation-attribute, .reactivation-item {
                border-bottom: 1px solid #d1d1d1;
                overflow: hidden;
                text-align: right;
                padding: 12px 0px 12px 0px;

                display: flex;
                justify-content: space-between;

                .text {
                    font-weight: 600;
                    width: 100%;
                    align-self: center;
                }

                .value-and-update {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-end;

                    .link {
                        height: 10px;
                    }

                    .edit {
                        font-size: 14px;
                    }
                }

                .attribute-name, .item-name {
                    display: flex;
                    max-width: 200px;
                    padding: 10px 0;
                    text-align: left;
                }
            }
        }

        .show-more-less-toggle {
            position: absolute;
            bottom: 0px;
            width: 100%;
            text-align: center;
            background: linear-gradient(0deg,$primary-white 50%, transparent 100%);
            height: 90px;
            padding-top: 54px;
        }
    }

    .profile-wrapper.collapsed {
        height: 590px;
    }

    .delivery-options-title {
        margin: 20px 0 10px 0;
    }

    .delivery-window-title {
        margin-top: 26px;
        font-weight: $font-weight-bold;
        font-family: $default-font-family;
        font-size: $font-size-m;
    }

    .radio-inline {
        display: block;
        position: relative;
        padding-left: 35px;
        cursor: pointer;
        font-size: 22px;
        user-select: none;
        margin-bottom: 10px;
    }

    .radio-inline input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: white;
        border: 1px black solid;
        border-radius: 50%;
        cursor: pointer;
    }

    /* On mouse-over, add a grey background color */
    .radio-inline:hover input ~ .checkmark {
        background-color: #ccc;
    }

    /* When the checkbox is checked, add a blue background */
    .radio-inline input:checked ~ .checkmark {
        background-color: #2196F3;
        border: none;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the checkmark when checked */
    .radio-inline input:checked ~ .checkmark:after {
        display: block;
    }

    /* Style the checkmark/indicator */
    .radio-inline .checkmark:after {
        top: 9px;
        left: 9px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: white;
    }

    .future-delivery-dropdown {
        width: 100%;
        max-width: 518px;
    }

    .future-delivery-dropdown.hidden {
        display: none;
    }

    #dates {
        width: 98%;
        padding: 0px;
        height: 40px;
        max-width: 510px;
        padding-left: 10px;
    }

    .totals {
        border-top: 1px solid #d1d1d1;
        margin-top: 20px;

        .total-prices {
            padding-top: 12px;
            overflow: hidden;
            text-align: right;
            display: flex;
            justify-content: space-between;
        }
    }

    .payment-date {
        display: flex;
        align-items: center;
        justify-content: start;
        background-color: #F4F4F4;
        margin-top: 30px;
        padding: 14px;
        margin-bottom: 30px;

        .payment-icon {
            font-size: 48px;
            width: 48px;
            height: 48px;
            margin-bottom: 5px;
        }
        p {
            width: 100%;
            padding: 10px 15px;
        }
    }


    .reactivation-buttons {
        max-width: 520px;
        margin: auto;
        margin-bottom: 60px;
        text-align: center;
        padding: 0px 20px 0px 20px;

        .back-link {
            display: inherit;
        }

        .btn, .btn-primary, .btn-secondary {
            width: 100%;
            max-width: 280px;
            margin: 10px auto;
        }

        .submit-specific {
            display: none;
        }
    }

    .reactivation-buttons.delivery-page {
        button {
            margin-bottom: 20px;
            font-size: 18px;
        }
    }

    .reactivation-buttons.confirmed-page {
        a {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        @media only screen and (min-width: $screen-sm-min) {
            display: flex;
            a {
                width: 46%;
            }
        }
    }

    .profile.reactivation .payment-summary-container tr.non-order-item {
        height: 40px;
        background-color: #ffffff;
        font-weight: bold;
    }
    .profile.reactivation .payment-summary-container tr.non-order-item.total {
        background-color: $primary-blueberry;
    }
    .profile.reactivation .payment-summary-container tr.non-order-items-separator {
        height: 0px;
        background-color: #ffffff;

        .line {
            display: block;
            border-top: solid 1px #d2d2d2;
            width: 150%;
            height: 2px;
        }
        td {
            padding: 0px;
        }
    }
}

/* Styles for wet food content block - Owner experiment (June 2022) */
.reactivation-details.wet-food-info {
    text-align: center; // Bit of a hacky way to get round needing to use inline-block on wet-food-info-card-container
    // to be able to add the correct top margin at all breakpoints
}

.reactivation-details.wet-food-info .hero-text {
    text-align: left; // Part of the above hacky-ness
}

.reactivation-details.wet-food-info .background-curve-container {
    height: 290px;
}

.reactivation-details .wet-food-info-card,
.reactivation-details .order-wet-food-card {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border: 1px solid $pastel-silver;
    border-radius: 12px;
    text-align: center;
    width: 512px;
    max-width: 100%;
    margin: 25px auto 22px auto;
    @media only screen and (min-width: 768px) {
        margin: 25px auto 30px auto;
    }
}

.reactivation-details .wet-food-info-card {
    max-width: 95%;
    margin: 25px auto 0 auto;
    display: inline-block;
    text-align: left;
    background-color: $primary-white;
    @media only screen and (min-width: 768px) {
        max-width: 89%;
    }

    + div.hero-text {
        margin-top: 25px;
    }
}

.reactivation-details .wet-food-info-card .header {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-image: url("/static/images/reactivation/wet-food-banner.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    max-width: 512px;
    padding: 24px 24px 26px 24px;
    border-radius: 12px;
    &.expand { // Remove rounded bottom corners when hidden content revealed
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}

.reactivation-details .wet-food-info-card .header h1,
.reactivation-details .order-wet-food-card h1 {
    font-family: $handwritten-font-family;
    font-weight: 200;
    color: $primary-white;
    font-size: $font-size-m;
    line-height: 32px;
}

.reactivation-details .wet-food-info-card .header a {
    font-size: $font-size-s;
    font-weight: 400;
    line-height: 24px;
    color: $primary-white;
    cursor: pointer;
}

.reactivation-details .wet-food-info-card .header #chevron-open,
.reactivation-details .wet-food-info-card .header #chevron-close {
    display: inline-block;
    text-decoration: none;
    padding: 0 2px 0 7px;
    font-weight: bold;
}

.reactivation-details #wet-food-info-text p {
    font-size: $font-size-s;
    font-weight: 400;
    line-height: 24px;
    color: #030E2D;
    margin: 16px;
}

.reactivation-details .order-wet-food-card .header {
    max-width: 520px;
    padding: 60px 20px 12px 20px;
    background-image: url("/static/images/reactivation/wet-food-chicken.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;

}

.reactivation-details .order-wet-food-card .body {
    padding: 16px 16px 24px 16px;
}

.reactivation-details .order-wet-food-card p {
    font-size: $font-size-s;
    font-weight: 400;
    line-height: 24px;
    color: #030E2D;
    padding: 0 0 16px 0;
    margin: 0;
}
/* End of styles for wet food content block - Owner experiment (June 2022) */

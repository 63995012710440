.gocardless-wrapper {

  .local-details {
        display: none;
    }

    .form-control input {
      @extend .td-form-field;
      @extend .form-control;
      margin-bottom: 20px;
    }

    label[for="local-details-toggle"] {
      cursor: pointer;
      padding: 10px 0;
    }

    input#local-details-toggle {
      display: none;
      &:checked ~ .local-details {
          display: block;
      }
    }

  .form-control.address-info {
    display: none;
  }
  input[name=address-use_delivery_address] {
    display: none;
    &#delivery-address-no:checked {
      ~ .form-control.address-info {
          display: block;
      }
      ~ .form-group .radio-buttons label[for=delivery-address-no] {
        @include radio_toggle_on;
      }
    }
    &#delivery-address-yes:checked {
      ~ .form-group .radio-buttons label[for=delivery-address-yes] {
        @include radio_toggle_on;
      }
    }
  }

  .error {
    padding: 10px;
    margin: 15px 0;
    border-radius: 4px;
    background: $product-error;
    color: $primary-white;
    display: none;
    &.showError {
      display: block;
    }
  }

  .payment-details-confirmation {
    display: none;
    h5 {
      text-align: center;
      font-weight: 100;
      padding: 10px 0;
    }
    .action-buttons {
      padding: 10px 0;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      button {
        margin: 5px;
        &.icon-lock:before {
          @include icon($icon-lock);
        }
        &.icon-chevron-left1:before {
          @include icon($icon-chevron-left1);
        }
      }
    }
    .payment-info-fields {
      display: flex;
      flex-flow: column wrap;
      .payment-info-field {
        p.field-name {
          font-weight: bold;
        }
      }
    }
  }

  &.confirmDetails {
    .payment-inputs {
      display: none;
    }
    .payment-details-confirmation {
      display: block;
    }
  }

}

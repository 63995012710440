$icomoon-font-family: "tails-icons" !default;
$icomoon-font-path: "fonts" !default;

$icon-price-pig: "\e90f";
$icon-poo-bag: "\e941";
$icon-lock1: "\e94f";
$icon-salmon: "\e953";
$icon-phone1: "\e954";
$icon-chicken: "\e955";
$icon-email: "\e957";
$icon-edit1: "\e958";
$icon-minus1: "\e95e";
$icon-column: "\e960";
$icon-poobag-holder: "\e961";
$icon-treat-selection: "\e963";
$icon-bone1: "\e964";
$icon-duck: "\e965";
$icon-arrow-vertical: "\e966";
$icon-lamb: "\e967";
$icon-upload: "\e969";
$icon-clock: "\e96a";
$icon-price-dollar: "\e96d";
$icon-credit-card: "\e96f";
$icon-pig: "\e970";
$icon-checklist: "\e971";
$icon-download: "\e977";
$icon-question-circle1: "\e978";
$icon-play: "\e979";
$icon-arrow-horizontal: "\e97a";
$icon-nose_tongue: "\e976";
$icon-grid: "\e905";
$icon-list: "\e97b";
$icon-wet-tray: "\e968";
$icon-pause: "\e97c";
$icon-euro: "\e974";
$icon-price_euro: "\e974";
$icon-egg_timer: "\e972";
$icon-chevron-right: "\e93e";
$icon-arrow_right1: "\e93e";
$icon-chevron-right1: "\e93e";
$icon-paw_01: "\e94b";
$icon-add_friend: "\e97d";
$icon-price_pound: "\e96e";
$icon-gbp: "\e96e";
$icon-ex-transparent: var(--icon-close);
$icon-close: var(--icon-close);
$icon-remove: var(--icon-close);
$icon-times: var(--icon-close);
$icon-calendar: "\e97f";
$icon-arrow-left1: "\e973";
$icon-chevron-left: "\e973";
$icon-chevron-left1: "\e973";
$icon-information: "\e930";
$icon-menu: "\e980";
$icon-bars: "\e980";
$icon-navicon: "\e980";
$icon-reorder: "\e980";
$icon-hamburger: "\e980";
$icon-home: "\e981";
$icon-exclamation-circle: "\e95f";
$icon-exclamation: "\e982";
$icon-exclamation-triangle: "\e982";
$icon-refresh: "\e983";
$icon-trash: "\e984";
$icon-repeat: "\e985";
$icon-rotate-right: "\e985";
$icon-checkout: "\e962";
$icon-delivery: "\e986";
$icon-truck: "\e986";
$icon-search: "\e987";
$icon-camera: "\e988";
$icon-chevron-down: var(--icon-chevron-down);
$icon-angle-down: var(--icon-chevron-down);
$icon-chevron-up: "\e98a";
$icon-check: "\e98b";
$icon-plus-transparent: "\e94e";
$icon-pause-transparent: "\e952";
$icon-facebook-thin: var(--icon-new-facebook-thin);
$icon-facebook: var(--icon-new-facebook-thin);
$icon-instagram-thin: var(--icon-new-instagram-thin);
$icon-instagram: var(--icon-new-instagram-thin);
$icon-twitter-thin: var(--icon-new-twitter-thin);
$icon-twitter: var(--icon-new-twitter-thin);
$icon-portion-cup-transparent: "\e98c";
$icon-tag-transparent: "\e98d";
$icon-tooth-transparent: "\e98e";
$icon-user-transparent: var(--icon-user-transparent);
$icon-user: var(--icon-user-transparent);
$icon-weight-transparent: "\e990";
$icon-weight-lb1: "\e991";
$icon-weight-st1: "\e992";
$icon-logo: "\e96b";
$icon-logo-circle: "\e96c";
$icon-logo-circle-tm: "\e902";
$icon-delivery-thin: "\e959";
$icon-price-thin: "\e95a";
$icon-paw-transparent: "\e95c";
$icon-dog-transparent: "\e95b";
$icon-tick_icon: var(--icon-tick);
$icon-warning_icon: var(--icon-warn);
$icon-salmon-treats-circle: "\e95d";
$icon-good-dog-treats-circle: "\e94d";
$icon-poo-bags-circle: "\e94c";
$icon-price: "\e94a";
$icon-star-rounded: "\e946";
$icon-star-rounded-circle: "\e947";
$icon-awards-circle: "\e948";
$icon-awards: "\e949";
$icon-chevron-right-circle-outline: "\e944";
$icon-envelope-circle: "\e940";
$icon-bone: "\e93f";
$icon-badge-3: "\e93d";
$icon-badge-2: "\e93b";
$icon-badge-1: "\e93c";
$icon-close-quote: "\e939";
$icon-open-quote: "\e93a";
$icon-exclusion-wheat: "\e938";
$icon-exclusion-grain: "\e938";
$icon-exclusion-maize2: "\e938";
$icon-exclusion-maize: "\e943";
$icon-exclusion-dairy: "\e931";
$icon-exclusion-fish: "\e932";
$icon-exclusion-egg: "\e933";
$icon-exclusion-beef: "\e934";
$icon-exclusion-chicken: "\e935";
$icon-exclusion-lamb: "\e936";
$icon-exclusion-soya: "\e937";
$icon-claim-29: "\e92d";
$icon-claim-28: "\e92e";
$icon-star: "\e92c";
$icon-lightning: "\e92a";
$icon-running: "\e92b";
$icon-file-picture: "\e927";
$icon-upload3: "\e9c8";
$icon-dental-circle: "\e924";
$icon-dental: "\e925";
$icon-dental-badge: "\e926";
$icon-offers-circle: "\e923";
$icon-edit: "\e921";
$icon-plus: "\e91f";
$icon-question: "\e920";
$icon-clock-circle: "\e91d";
$icon-pause-circle: "\e91c";
$icon-weight-lb: "\e916";
$icon-weight-st: "\e919";
$icon-weight-kg: "\e907";
$icon-weight-lb-circle: "\e91a";
$icon-weight-st-circle: "\e91b";
$icon-weight-kg-circle: "\e909";
$icon-calendar-circle: "\e90d";
$icon-new-circle: "\e90e";
$icon-edit-circle: "\e910";
$icon-bin-circle: "\e911";
$icon-user-circle: "\e91e";
$icon-friend-circle: "\e912";
$icon-facebook-circle: "\e913";
$icon-instagram-circle: "\e914";
$icon-google-circle: "\e915";
$icon-question-circle: "\e917";
$icon-checklist-circle: "\e918";
$icon-price-circle: "\e922";
$icon-wet-tray-circle: "\e908";
$icon-delivery-circle: "\e90a";
$icon-blend-circle: "\e90b";
$icon-scoop-circle: "\e90c";
$icon-scoop: "\e906";
$icon-info: "\e904";
$icon-phone: "\e942";
$icon-condition-1: "\e800";
$icon-condition-2: "\e801";
$icon-condition-3: "\e802";
$icon-condition-4: "\e803";
$icon-condition-5: "\e804";
$icon-activity-1: "\e805";
$icon-activity-2: "\e806";
$icon-activity-4: "\e80d";
$icon-activity-5: "\e945";
$icon-activity-3: "\e807";
$icon-size-1: "\e808";
$icon-size-2: "\e809";
$icon-size-3: "\e80a";
$icon-size-4: "\e80b";
$icon-size-5: "\e80c";
$icon-claim-1: "\e60d";
$icon-claim-5: "\e60d";
$icon-claim-25: "\e60d";
$icon-claim-2: "\e60e";
$icon-claim-3: "\e60f";
$icon-claim-9: "\e60f";
$icon-claim-4: "\e610";
$icon-claim-6: "\e610";
$icon-claim-7: "\e611";
$icon-claim-8: "\e612";
$icon-claim-10: "\e613";
$icon-claim-11: "\e613";
$icon-claim-14: "\e613";
$icon-claim-13: "\e614";
$icon-claim-24: "\e614";
$icon-claim-12: "\e615";
$icon-claim-15: "\e615";
$icon-claim-16: "\e616";
$icon-claim-17: "\e617";
$icon-claim-19: "\e618";
$icon-claim-20: "\e619";
$icon-claim-26: "\e619";
$icon-claim-21: "\e61a";
$icon-claim-27: "\e61a";
$icon-claim-22: "\e61b";
$icon-high_activity: "\e61c";
$icon-claim-18: "\e61d";
$icon-claim-23: "\e61d";
$icon-list-alt: "\f022";
$icon-infinite: "\ea30";
$icon-filter: "\ea5c";
$icon-twitter-circle: "\ea93";
$icon-quotes-right: "\e608";
$icon-quotes-left: "\e609";
$icon-account: "\e602";
$icon-arrow-down-circle: "\e603";
$icon-arrow-left-circle: "\e604";
$icon-arrow-right-circle: "\e605";
$icon-arrow-up-circle: "\e606";
$icon-plus-circle: "\e60b";
$icon-minus-circle: "\e929";
$icon-cross-circle: "\e900";
$icon-check-circle: var(--icon-check-circle);
$icon-info-circle: var(--icon-info-circle);
$icon-shop: "\e60c";
$icon-envelope-o: "\f003";
$icon-trash-o: "\f014";
$icon-lock: "\f023";
$icon-ban: "\f05e";
$icon-arrow-left: "\f060";
$icon-arrow-right: "\f061";
$icon-arrow-up: "\f062";
$icon-arrow-down: "\f063";
$icon-minus: "\f068";
$icon-asterisk: "\f069";
$icon-arrows-v: "\f07d";
$icon-arrows-h: "\f07e";
$icon-sign-out: "\f08b";
$icon-sign-in: "\f090";
$icon-caret-down: "\f0d7";
$icon-caret-up: "\f0d8";
$icon-caret-left: "\f0d9";
$icon-caret-right: "\f0da";
$icon-envelope: "\f0e0";
$icon-angle-double-left: "\f100";
$icon-angle-double-right: "\f101";
$icon-angle-double-up: "\f102";
$icon-angle-double-down: "\f103";
$icon-spinner: "\f110";
$icon-calendar-o: "\f133";
$icon-play-circle: "\f144";
$icon-ticket: "\f145";
$icon-paw: "\f1b0";
$icon-basket: "\e61e";
$icon-plus1: "\e975";
$icon-plus2: "\e975";
$icon-cc-visa: "\f1f0";
$icon-cc-mastercard: "\f1f1";
$icon-cc-amex: "\f1f3";
$icon-cc-direct-debit: "\f1f4";
$icon-facebook-official: "\f230";
$icon-whatsapp: "\f232";


html {
    height: 100%;
}
body.signup {
    .signup-header {
        .brand  {
            line-height: inherit;
            img {
                margin: 0;
                width: 180px;
            }
        }
    }
    .signup-footer {
        padding-top: 14px;
        padding-left: 7px;
    }

    .promo-change-banner {
        background-color: rgba(146,194,227, 0.7);
        color: $navy-01;
        font-weight: bold;
        text-transform: uppercase;
        position: relative;
        top: -70px;
        margin: 0 -15px;
        padding: 10px 20px 10px;
        margin-bottom: -55px;
        text-align: center;
        font-size: 1.2rem;
        strong {
            color: #fff;
        }

        p {
            margin: 0 15px;
        }

    }
}

@media (min-width: $screen-xs-min) {
    body {
        // padding to compensate for zopim chat window covering buttons EDIT: Don't think this is needed anymore
        &.signup {
            .content > .container {
                padding-bottom: 290px;
            }

            .promo-change-banner {
                margin: 0 -115px;
                position: relative;
                top: -110px;
                padding: 10px 20px 10px;
                margin-bottom: -80px;

                font-size: $font-size-s;

                p {
                    margin: 0 115px;
                }
            }
        }
    }
}

/*
 * Flow styles
 */
#signup-start {

    .no-voucher-code {
        font-weight: normal;
    }

    p {
        margin-bottom: 20px;
    }
}

#signup-pet-details-1 {
    .checklist.neutered {
        list-style: none;
    }
}

.form-group.buttons {
    @include clearfix;
    margin-top: 1em;
}

.weight-warning {
    position: relative;

    @media (min-width: $screen-xs-min) {
        &:after {

            width: 0;
            height: 0;

            position: absolute;
            top: -40px;
            left: 15%;
            content: " ";
            border: solid transparent;
            border-bottom-color: $product-error;
            border-width: 20px;
            margin-left: -10px;
            z-index: 1000;
        }
    }
}
.estimate-weight .use-estimate {
    margin-top: $spacing-s;
    min-width: unset;
    max-width: 280px;
    width: 100%;

    @media (max-width: $screen-xs-max) {
        margin: 14px auto;
    }
}
.pet-name {
    button {
        height: 34px;
        min-width: 52px;
    }
}
.pet-name-container {
    margin: 10px;
}
.pet-condition .row:not(:first-of-type) {
    margin-top: 7px;
}
.new-pet-names {
    button.disabled {
        background-color: #EDECEA !important;
    }
}
.add-pet-form-group{
    text-align: right;
}
.add-pet, a.with-icon {
    display: inline-block;
    padding: 2px 10px;
    > * {
        display: inline-block;
        vertical-align: middle;
        cursor: pointer;
    }
    i {
        margin-left: .4em;
    }
}
a.help-tip {
    display: block;
    margin: 7px 0;
    float: right;
    text-align: right;

    &:link, &:visited, &:active, &:hover  {
        text-decoration: none;
    }
    &:hover {
        color: lighten($scss-primary-colour, 10%);
    }
}

//TODO: Factor out
.help-tip-content {
    position: relative;
    clear: both;
    padding: 30px;
    margin: 10px;
    border-radius: 7px;
    background: $pastel-lighter-frost;
    color: white;
    display: none;

    &:after {
        width: 0;
        height: 0;

        position: absolute;
        top: -20px;
        left: 85%;
        content: " ";
        border: solid transparent;
        border-bottom-color: $pastel-lighter-frost;
        border-width: 10px;
        margin-left: -5px;
        z-index: 1000;
    }
    ul {
        padding-left: 20px;
    }
}


.breeds {
    .breed-wrap {
        display: flex;
        justify-content: space-between;
        select {
          overflow: hidden;
          flex-grow: 1;
          margin-right: 15px;
        }
    }
    .breed {
        margin: 10px 0;
        h3 {
            margin-bottom: 5px;
        }
    }
}
.breeds-help-tip {
    a {
    }
    @media screen and (min-width: $screen-sm-min) {
    }
}
.breed-actions {
    text-align: right;
    padding-right: 25px;

    .help-tip {
        padding-right: 3px;
    }
}

.tails-view-pet-breed {
  .add-breed {
    &:focus {
        color: initial;
    }
  }
}

.guarantee {
    background: lighten($scss-secondary-03, 40);
    margin: 1rem;
    padding:    1rem;

    .icon {
        color: darken(rgb(240, 231, 216), 30);
        line-height:    5rem;
        padding: .7rem;
        font-size: 3rem;
        text-align: center;
    }
}
.comparison-table {
    th,
    td:not(:first-child) {
        text-align: center;
    }
}
.feeding-plan-quantities {
    margin-top: 0;
    padding-left: 20px;

    @media (min-width: $screen-xs-min) {
        li {
            font-size: $font-size-body;
        }
    }
}
.panel-breakdown {
    margin-bottom: 0;
}

.signup-pet-details {
    .checklist.allergens {
        user-select: none;
        -webkit-user-select: none;
        li {
            label, input {
                cursor: pointer;
            }
            label {
                user-select: none;
                -webkit-user-select: none;
            }
        }
        @media screen and (min-width: $screen-phone) {
            li {
                display: inline-block;
                width: calc(50% - 5px);
            }
        }
    }
}

.pet-processing {
    display: none;
    @include position(fixed, 0 0 0 0);
    height: 100vh;
    width: 100vw;
    opacity: 0;
    background: white;
    white-space: nowrap;
    @include transition(opacity 0.3s);

    .pet-processing-content {
        @include size(100%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        white-space: normal;
        text-align: center;
        padding: 0 12px;

        & > * {
            max-width: $scss-container-max-width / 2;
        }

        .loader-animation {
            margin: $spacing-xs 0;
        }

        h2 {
            font-family: $handwritten-font-family;
            font-size: $font-size-xl;
        }
    }

    &.show {
        display: block;
        opacity: 1;
        z-index: 1000;
    }
}

@include keyframes(processing-message-1) {
    0% {
        opacity: 0;
    }
    6.25% {
        opacity: 1;
    }
    43.75% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}
@include keyframes(processing-message-2) {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    56.25% {
        opacity: 1;
    }
    93.75% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@function mod($a, $b) {
  @return $a - floor($a / $b) * $b;
}

$spacing: 14px;
$dim: 4;

.copyright-footer {
    color: darken($scss-navy-04, 35%);
    font-size: 1.3rem;
    padding-left: 7px;

    a {
        display: inline-block;
        border-bottom: 1px dotted $navy-04;

        &:link, &:visited, &:hover, &:active {
            color: darken($scss-navy-04, 35%);
            text-decoration: none;
        }
    }
}

.address-search {
    .input-group {
        max-width: 300px;
    }
}

.input-wrapper {
    position: relative;
    .twitter-typeahead {
        display: inline-block !important;
    }
    .show-all {
        position: absolute;
        font-size: 2rem;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        top: 0;
        right: 0;
        text-align: center;
        width: 40px;
        line-height: 32px;
        color: white;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        z-index: 2;
        border-bottom-width: 2px;
        border-bottom-style: solid;
    }
}

.signup-pet-details .panel {
    overflow: visible;
}

/* Country flag in navbar */
body.consultation, body.signup.signup-price-summary, body.signup.signup-price-summary-redesign, body.signup.checkout {
    nav.top-nav {
        .navigation-content {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            position: relative;
        }
        .logo-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-left: var(--spacing-xs);
            position: unset;
        }

        .other-links {
            width: 100% !important;
            margin: 0 10px 0 5px !important;
            min-width: auto !important;
            padding: 0 !important;
            .country {
                .country-flag {
                    width: 20px;
                }
            }
        }
    }
}

body.consultation, body.feeding-plan, body.signup.signup-price-summary, body.signup.signup-price-summary-redesign, body.signup.checkout {
    nav.top-nav {
        .other-links {
            margin-left: 0;
            min-width: 100%;
            padding: 0 10px;
            .promo {
                display: block;
                padding: 0;
                @include at-least($screen-desktop) {
                    width: auto;
                }
            }
            @include at-least($screen-desktop) {
                margin-left: auto;
                min-width: 320px;
                padding: 0;
            }
        }
    }
    .cs-flow {
        display: none;
    }
    [data-action="change-address"] {
        display: none;
    }
    .tails-view-your-address {
        fieldset.address-lookup {
            margin-bottom: 0px;
        }
    }
    .toggle-btn {
        margin-top: 15px;

        &[data-action="get-dates"] {
            margin-bottom: 15px;
        }

        &[data-action="change-address"] {
            margin-bottom: 45px;
        }
    }
    .postcode-validation-error-dsm {
        display: none;
    }
    .error-inline {
        color: $product-error;
    }

    .payment-options {
        .radio-buttons-new {
            display: flex;
            flex-flow: column wrap;
            align-items: baseline;

            .radio-button-new {
                align-items: center;

                &.stripe {
                    order: -1;
                }
                &.paypal {
                    order: 0;
                }
                &.gocardless {
                    order: 1;
                }
            }
        }
    }
}


div.address-consent > fieldset.radio-buttons {

    text-align: left;
    display: inline-block;
    .radio-toggle-button:first-child {
        margin-left: 0;
    }
    .radio-toggle-button span {
        font-weight: normal;
    }
    .radio-toggle-button {
        display: inline-block;
        margin: 0 25px;

        input[type=radio]:checked ~ label {
            @include radio_toggle_on;
        }
    }
}

.icons-info-colour-1 {
  background: radial-gradient(0.75em circle at right 0.40625em bottom 0.40625em, $pastel-rose 50%, transparent 51%);
}
.icons-info-colour-2 {
  background: radial-gradient(0.75em circle at right 0.40625em bottom 0.40625em, $pastel-lighter-sage 50%, transparent 51%);
}
.icons-info-colour-3 {
  background: radial-gradient(0.75em circle at right 0.40625em bottom 0.40625em, $pastel-lavender 50%, transparent 51%);
}
.icons-info-colour-4 {
  background: radial-gradient(0.75em circle at right 0.40625em bottom 0.40625em, $pastel-beige 50%, transparent 51%);
}
.icons-info-colour-5 {
  background: radial-gradient(0.75em circle at right 0.40625em bottom 0.40625em, $pastel-frost 50%, transparent 51%);
}
.icons-info-colour-6 {
  background: radial-gradient(0.75em circle at right 0.40625em bottom 0.40625em, $pastel-darker-frost 50%, transparent 51%);
}

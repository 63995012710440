body.reblend {
    text-align: center;
    padding-top: 40px;
    background-color: $pastel-lighter-frost;

    .intro-text {
        text-align: center;
        margin-bottom: 60px;
    }

    .panel {
        text-align: center;
        background-color: transparent;
        .panel-content {
            @include grid-blocks(6);
            margin: auto;
        }
    }

    .tiles {
        .tile {
            position: relative;
            text-align: center;
            @include flexbox(row, center, center);
            text-decoration: none;
            background-color: $primary-white;
            margin-bottom: 40px;

            .thumb {
                @include inalign(middle);
                @include min-size(80px);
                background-size: cover;
                background-position: center center;
            }
            .name {
                @include flex-grow(1);
                text-align: left;
                margin-left: 20px;
            }

            .chevron {
                padding: 20px;
                &:before {
                    @include icon($icon-chevron-right);
                    color: $secondary-03;
                    font-size: $font-size-l;
                    line-height: 40px;
                }
            }

            &[data-mode=all] {
                .thumb {
                    background-image: url(/static/images/reblend/tiles/all.jpg);
                }
            }

            &[data-mode=some] {
                .thumb {
                    background-image: url(/static/images/reblend/tiles/some.jpg);
                }
            }

            &[data-mode=other] {
                .thumb {
                    background-image: url(/static/images/reblend/tiles/other.jpg);
                }
            }
            
        }
    }

    .kibbles {
        @include flexbox(row, center, center);
        @include flex-wrap(wrap);
        margin-left: -10px;
        margin-right: -10px;

        .kibble {
            max-width: 130px;
            margin: 20px;
            cursor: pointer;
            vertical-align: top;

            input[type="checkbox"] {
                display: none;

                &:checked + .image {
                    border-color: $product-error;

                    $circle-size: 40px;
                    $bg-circle-size: $circle-size * 0.95;

                    &:before,
                    &:after {
                        position: absolute;
                        left: 50%;

                    }

                    &:before {
                        content: '';
                        width: $bg-circle-size;
                        height: $bg-circle-size;
                        border-radius: 100%;
                        background: #fff;
                        margin-left: -($bg-circle-size / 2);
                        bottom: -($bg-circle-size / 2) - 3px; /* 3px for border */
                    }

                    &:after {
                        font-size: $circle-size;
                        color: $product-error;
                        margin-left: -($circle-size / 2);
                        bottom: -($circle-size / 2) - 3px; /* 3px for border */
                        @include icon($icon-cross-circle);
                    }
                }
            }

            .image {
                position: relative;
                display: block;
                background: mix($scss-primary-white, $scss-secondary-03, 50);
                border: 6px solid transparent;
                padding: 10px;

                img {
                    max-width: 100%;
                    -webkit-user-drag: none;
                    -webkit-user-select: none;
                    pointer-events: none;
                }
            }

            .name {
                font-weight: bold;
                margin-top: 1em;
                display: block;
            }
        }
    }
    
    .reblend-error {
        color: $product-error;

        &:before {
            @include icon($icon-exclamation-circle);
            font-size: 19px;
            margin-right: 20px;
            vertical-align: top;
            line-height: 25px;
        }
    }

    .contact-options {
        margin-top: 60px;
        text-align: center;
        
        .option {
            display: inline-block;
            margin: 0 30px;
            text-decoration: none;

            &:before {
                font-size: 90px;
                display: block;
                margin-bottom: 20px;
                color: #4A90E2;
            }

            &.email:before {
                @include icon($icon-envelope-circle);
            }

            &.phone:before {
                @include icon($icon-phone);
            }

            span {
                font-weight: bold;
                color: $navy-01;
                text-transform: uppercase;
            }
        }

        &.toggleable {
            display: block;

            .option {
                margin: 0;
                transition: opacity 150ms;
            }

            .option:not(.selected):not(:hover) {
                opacity: .3;
            }

            .option:not(.selected) {
                &.email {
                    float: left;
                }
                
                &.phone {
                    float: right;
                }
            }

            .option.selected {
                &.email {
                    margin-right: -90px;
                }

                &.phone {
                    margin-left: -90px;
                }
            }
        }

    }

    .contact-content {
        margin-top: 30px;

        &.phone p {
            margin-top: 30px;
        }
    }

    .field {
        text-align: left;
        width: 100%;
        label {
            display: none;
        }
        .input-wrapper, input, select, textarea {
            width: 100%;
        }
        textarea {
            min-height: 200px;
        }
        margin-bottom: 20px;
    }

    @include until($screen-phone) {

        .contact-options.toggleable {
            .option {
                margin: 0 10px !important;
                float: none !important;
            }
        }
    }

}

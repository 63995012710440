@import "../helpers/_mixins";
@import "../typography/_icon-variables";
@import "../typography/_tails-icons";

body.home {
  .main {
    h1, h2, h3, h4, h5 {
      text-align: center;
    }

    //HERO SECTION MACRO
    .hero-home {
      @include hero-header(("/static/images/home/sections/hero-desktop.jpg", "/static/images/home/sections/hero-mobile.jpg"));
      margin-top: 0 !important;

      @include at-least($screen-tablet) {
        padding-left: 5%;
        .hero-text {
          width: blocks(11);
        }
      }

      .hero-text {
        @include at-least($screen-phone) {
          min-width: 420px;
        }

        h1, .hero-text-extra {
          text-align: center;
        }

        .btn-primary-hero {
          margin-bottom: 20px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .hero-text-extra {
          .intro-paragraph {
            font-size: $font-size-s;
            @include at-least($screen-tablet) {
              font-size: $font-size-body;
            }
          }

          .trustpilot-reviews {
            @include at-least($screen-tablet) {
              margin: 0 auto;
              @include grid-blocks(3);
              .trustpilot-widget.desktop {
                margin-right: -40px;
              }
            }
          }
        }

        .btn {
          white-space: nowrap;
        }
      }
    }

    .trustpilot-reviews {
      padding: 0;
      text-align: left;
      @include flexbox(row, center, center);
      @include until($screen-desktop) {
        flex-flow: column wrap;
      }

      p {
        flex: 1 0 56%;
      }

      a {
        text-decoration: none;
      }

      .trustpilot-widget.mobile {
        padding: 0;
      }

      > a {
        padding: 0;
      }
    }

    .trustpilot-widget.desktop {
      display: none;

      a {
        color: white;
      }

      @include at-least($screen-desktop) {
        display: block;
      }
    }

    .trustpilot-widget.mobile {
      display: block;
      padding-top: 15px;

      a {
        color: white;
      }

      @include at-least($screen-desktop) {
        display: none;
      }
    }

    //THREE COLUMN MACRO
    .three-column-home {
      @include flexbox(column, center, center);
      @include at-least($screen-tablet) {
        h3 {
          margin: 20px 0;
        }
      }

      .column-wrapper {
        @include flexbox(column, center, center);
        @include at-least($screen-tablet) {
          @include flexbox(row, flex-start, center);
        }

        .column {
          text-align: center;
          margin: 10px 30px;
          @include at-least($screen-tablet) {
            margin: 20px;
            width: 33.33%;
          }

          img {
            height: 90px;
            margin: 10px 0;
          }
        }
      }

      .cta {
        margin-bottom: 20px;
        @include at-least($screen-desktop) {
          margin: 40px;
        }
      }
    }

    //FEATURES ALTERNATING MACRO
    .features-alternating-home {
      padding: 0;

      .title {
        margin: 20px 0;
      }

      .feature {
        padding: 20px;
        @include flexbox(column, center, center);
        @include at-least($screen-tablet) {
          @include flexbox(row, center, space-evenly);
          &:nth-child(even) {
            @include flexbox(row-reverse, center, space-evenly);

            img {
              max-width: 400px;
            }
          }
          padding: 0;
        }

        img {
          max-width: 350px;
          @include at-least($screen-desktop) {
            max-width: 100%;
            margin: 0;
          }
          @include at-least($screen-tablet) {
            max-width: 400px;
            margin: 40px;
          }
        }

        .content-wrapper {
          margin: 20px 0;
          text-align: center;

          li, p {
            text-align: left;
          }

          @include at-least($screen-tablet) {
            margin: 20px;
            max-width: 40%;
            width: 40%;
            text-align: left;
            h3 {
              text-align: left;
            }
          }
        }

        .feature-button-wrapper {
          @include flexbox(row, center, center);
          .btn-secondary {
            width: 280px;
            background-color: transparent;
          }
          @include at-least($screen-desktop) {
            @include flexbox(row, center, flex-start);
          }
        }
      }
    }

    //  REVIEWS CAROUSEL MACRO
    .reviews-home {
      background-color: $primary-white;
      text-align: center;

      .reviews-wrapper {
        width: 99%;
        box-sizing: border-box;
        white-space: nowrap;
        padding-top: 20px;
        overflow: hidden;
        display: inline-block;
        @include user-select(none);
        max-width: $container-max-width;
        margin-bottom: 40px;

        h2 {
          width: 100%;
          text-align: center;
          margin: 32px 0;
          padding: 0 40px;
        }

        &.nojs {
          .margin {
            display: none;
          }

          .reviews {
            width: 100%;
            overflow: auto;

            .review {
              width: 100%;
              @include at-least($screen-phone) {
                width: 50%;
              }
              @include at-least($screen-tablet) {
                width: 33.333%;
              }
            }
          }
        }

        .carousel {
          @include flexbox(row, center, center);
          padding: 10px 0;
        }

        .margin {
          display: none;
          @include at-least($screen-phone) {
            text-align: center;
            color: white;
            @include inalign(middle);
            padding: 0 25px;
            .button {
              color: $secondary-03;
              font-size: $font-size-l;
              cursor: pointer;
              text-align: center;

              &:hover {
                color: darken($scss-secondary-03, 10);
              }
            }
          }
        }

        .reviews {
          position: relative;
          overflow: hidden;
          margin: auto;
          @include inalign(middle);
          font-size: 0;
          cursor: pointer;
          cursor: move;
          cursor: hand;
          cursor: grab;
          cursor: -moz-grab;
          cursor: -webkit-grab;

          &.grabbing {
            cursor: grabbing;
            cursor: -moz-grabbing;
            cursor: -webkit-grabbing;
          }

          .review {
            @include inalign(top);
            font-size: $font-size-body;
            outline: none;
            padding: 25px 15px 0 15px;
            min-height: 530px;
            margin: 5px 10px;
            box-shadow: 0 4px 10px 1px rgba(0, 0, 0, .05);

            .image {
              width: 75%;
              padding: 8px;
              box-shadow: 0 3px 26px rgba(0, 0, 0, .2);
              border-radius: 4px;
            }

            .description {
              width: 100%;
              text-align: center;
              white-space: normal;
              padding: 20px 5px 0 5px;
              box-sizing: border-box;
              text-size-adjust: none;
              -moz-text-size-adjust: none;
              -webkit-text-size-adjust: none;

              p {

                &:first-child:before {
                  @include icon($icon-quotes-right);
                  display: block;
                  text-align: center;
                  @include transform(rotate(180deg));
                  font-size: 1.2em;
                  color: $navy-01;
                }
              }
            }
          }
        }
      }
    }

    //PRESS CAROUSEL MACRO
    .logos-section.press {
      padding-left: 10px;
      padding-right: 10px;

      .label {
        font-weight: bold;
        font-size: $font-size-m;
        margin-bottom: 35px;
      }

      .wrapped {
        border-top: 2px solid #f4f4f4;
      }

      img {
        height: 43px;
        vertical-align: top;
      }

      .logo-item {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }
}

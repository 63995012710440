.raf-widget-wrapper {
    .footer-background-v4 {
        height: 550px;
        width: calc(100% + 31px);
        position: absolute;
        top: 0;
        left: -15px;
        z-index: -1;

        @include at-least($screen-desktop) {
            width: 100%;
            left: 0;
        }
    }
    
    .raf-widget-top-title-v4 {
        font-family: $handwritten-font-family;
        font-size: $font-size-xxl;
        padding: $spacing-l $spacing-m $spacing-xs $spacing-m;
        margin-bottom: 0 !important;
    }
    
    .raf-widget-v4 {
        border-radius: 8px;
        padding: $spacing-xs;
        text-align: center;
        background: $primary-white;
        box-shadow: 0 2px 4px 0 $pastel-darker-silver;
        border: 1px solid $pastel-darker-silver;
        position: relative;

        @include at-least($screen-desktop) {
            padding: $spacing-m 32px;
            width: 600px;
            margin: 0 auto;
        }
        
        .dog-widgets {
            display: flex;
            align-items: stretch;
            justify-content: space-around;
            margin-top: $spacing-m;
        }

        .btn {
            max-width: 296px;
            display: block;
        }

        .raf-widget-header {
            .raf-friend {
                padding: $spacing-xs;
                text-align: center;
                margin: $spacing-m auto;
            }
        }
        
        .raf-widget-title {
            font-family: $handwritten-font-family;
            text-align: left;
            font-size: 26px;
            line-height: 32px;
        }

        h4, h5 {
            text-align: center;
            margin-bottom: 10px;
        }

        .raf-widget-code {
            background-color: $pastel-lighter-silver;
            padding: $spacing-xs $spacing-xxs;
            border-radius: 4px;

            .share-link-now {
                margin-bottom: 4px;
                font-weight: bold;
            }

            .raf-widget-your-code {
                #your-code {
                    width: 100%;
                    margin-bottom: $spacing-xxs;
                }

                .btn-share.link, .native-share {
                    margin: 0 auto 0 auto;

                    .share-link {
                        &.copied {
                            display: none;
                        }
                    }

                    &.copy-active {
                        .copy {
                            display: none;
                        }
                        .copied {
                            display: block;
                        }
                    }
                }
            }
        }

        .raf-widget-share {
            h5 {
                margin-top: $spacing-xs;
            }

            .raf-widget-share-buttons {
                margin: $spacing-xs 0;
                .btn {
                    margin: $spacing-xs auto;
                }
            }
            p {
                font-size: $font-size-s;
            }
        }

    }
}

.raf-widget-footer-v4 {
    margin-top: $spacing-xs;
    margin-bottom: $spacing-m;
    font-size: 13px;

    * {
        font-size: $font-size-xs;
    }
}

.raf-v4-dog-widget {
    background-color: #fff;
    width: 45%;
    
    
    .your-dog-widget-img {
        width: 90px;
    }

    .friends-dog-widget-img {
        width: 90px;
    }
    
    .copy-box {
        padding: $spacing-m $spacing-xs;
        background-color: #fff;
        box-shadow: 0px 14px 30px 0px #0000000F;
        display: grid;
        justify-content: center;
        align-content: center;
        min-height: 100%;
        border-radius: 12px;
        position: relative;

        .dog-widget-img {
            width: 90px;
            position: absolute;
            top: -40px;
            margin: 0 auto;
            left: 0;
            right: 0;
        }
        
        .copy {
            margin: 0;
        }

        .discount-box {
            background-color: $secondary-03;
            color: var(--primary-white);
            font-weight: 600;
            font-size: 20px;
            display: inline-block;
            margin: 0;
            padding: $spacing-xxs;
            position: absolute;
            bottom: -20px;
            margin: 0 auto;
            left: 0;
            right: 0;
            width: 75%;
        }
    }

}

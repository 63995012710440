@import "../helpers/_mixins";
@import "../typography/_icon-variables";
@import "../typography/_tails-icons";


//HERO SECTION MACRO
.hero {
  @include hero-header(("/static/images/home/sections/hero-desktop.jpg", "/static/images/home/sections/hero-mobile.jpg"));
  margin-top: 0 !important;

  .hero-header {
    width: 100%;
  }

  @include at-least($screen-tablet) {
    padding-left: 5%;
    .hero-text {
      width: blocks(11);
    }
  }

  .hero-text {
    @include at-least($screen-phone) {
      min-width: 420px;
    }

    h1, .hero-text-extra {
      text-align: center;
    }

    .btn-primary-hero {
      margin-bottom: 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .hero-text-extra {
      .intro-paragraph {
        font-size: $font-size-s;
        @include at-least($screen-tablet) {
          font-size: $font-size-body;
        }
      }

      .trustpilot-reviews {
        @include at-least($screen-tablet) {
          margin: 0 auto;
          @include grid-blocks(3);
          .trustpilot-widget.desktop {
            margin-right: -40px;
          }
        }
      }
    }

    .btn {
      white-space: nowrap;
    }
  }
}

.trustpilot-reviews {
  padding: 0;
  text-align: left;
  @include flexbox(row, center, center);
  @include until($screen-desktop) {
    flex-flow: column wrap;
  }

  p {
    flex: 1 0 56%;
  }

  a {
    text-decoration: none;
  }

  .trustpilot-widget.mobile {
    padding: 0;
  }

  > a {
    padding: 0;
  }
}

.trustpilot-widget.desktop {
  display: none;

  a {
    color: white;
  }

  @include at-least($screen-desktop) {
    display: block;
  }
}

.trustpilot-widget.mobile {
  display: block;
  padding-top: 15px;

  a {
    color: white;
  }

  @include at-least($screen-desktop) {
    display: none;
  }
}

//THREE COLUMN MACRO
.macro-three-column {
  width: 100%;
  min-height: 130px;
  padding: 30px;

  &.paper {
    background-color: $pastel-lighter-frost;
  }

  @include flexbox(column, center, center);
  h3 {
    margin: 20px;
    text-align: center;
  }
  @include at-least($screen-tablet) {
    h3 {
      margin: 20px 0;
    }
  }

  .column-wrapper {
    @include flexbox(column, center, center);
    width: 100%;
    @include at-least($screen-desktop) {
      @include flexbox(row, flex-start, space-evenly);
    }

    .column {
      text-align: center;
      margin: 10px;
      @include at-least($screen-tablet) {
        margin: 20px;
        width: 33.33%;
      }

      .image {
        margin: 10px 0;
        &.small {
          img {
            height: 90px;
          }
        }
        &.large {
          img {
            height: 180px;
          }
        }
      }
    }
  }

  .cta {
    text-align: center;
    width: 100%;
    margin-bottom: 20px;
    @include at-least($screen-desktop) {
      margin: 40px;
    }

    h4 > a {
      @extend .btn;
      @extend .btn-primary-hero;
      color: $primary-colour;
      max-width: 420px;
      text-transform: uppercase;
      min-height: 40px;
      @include until($screen-tablet) {
        font-size: $font-size-s;
        padding: 11px 4px;
      }
    }
  }
}


//FEATURE CENTERED MACRO
.macro-feature-centered {
  margin: 0;
  @include flexbox(column, center, center);

  .feature {
    width: 100%;
    padding: 30px;

    &.paper {
      background-color: $pastel-lighter-frost;
    }

    @include flexbox(column, center, center);

    .center-image {
      width: 100%;
    }

    .content-wrapper {
      width: 100%;
      text-align: center;
      margin: 10px 0;
      @include at-least($screen-tablet) {
        width: 50%;
      }
    }

    .vimeo {
      width: auto;
      height: auto;
      @include at-least($screen-tablet) {
        width: 640px;
        height: 360px;
      }
    }
  }

  .cta {
    text-align: center;
    width: 100%;
    margin-bottom: 20px;

    h4 > a {
      @extend .btn;
      @extend .btn-primary-hero;
      color: $primary-colour;
      max-width: 420px;
      min-height: 40px;
      @include until($screen-tablet) {
        font-size: $font-size-s;
        padding: 11px 4px;
      }
    }
  }
}

//FEATURES ALTERNATING MACRO
.macro-features-alternating {
  padding: 0;

  .title {
    margin: 20px 0;
  }

  .feature {
    padding: 20px;
    @include flexbox(column, center, center);
    @include at-least($screen-tablet) {
      @include flexbox(row, center, space-evenly);
      &:nth-child(even) {
        @include flexbox(row-reverse, center, space-evenly);

        img {
          max-width: 400px;
        }
      }
      padding: 0;
    }

    img {
      max-width: 350px;
      @include at-least($screen-desktop) {
        max-width: 100%;
        margin: 0;
      }
      @include at-least($screen-tablet) {
        max-width: 400px;
        margin: 40px;
      }
    }

    .content-wrapper {
      margin: 20px 0;
      text-align: center;

      li, p {
        text-align: left;
      }

      .cta {
        text-transform: none;
        text-decoration: none;
        color: $navy-01;
        font-size: $font-size-s;
        font-weight: bold;
        box-shadow: 0px 2px 0px 0px $secondary-03;
      }

      @include at-least($screen-tablet) {
        margin: 20px;
        max-width: 40%;
        width: 40%;
        text-align: left;
        h3 {
          text-align: left;
        }
      }
    }
  }
}

//FEATURE IMAGE TEXT MACRO
.macro-feature-image-text {
  h2 {
    margin-bottom: 20px;
    text-align: center;
  }

  .feature {
    &.paper {
      background-color: $pastel-lighter-frost;
    }

    padding: 20px 20px 0 30px;
    @include flexbox(column, center, center);
    @include at-least($screen-tablet) {
      padding: 0;
      &.left {
        @include flexbox(row, center, space-evenly);
      }
      &.right {
        @include flexbox(row-reverse, center, space-evenly)
      }
    }

    img {
      @include at-least($screen-desktop) {
        margin: 0;
      }
      @include at-least($screen-tablet) {
        max-width: 400px;
        margin: 40px;
      }
    }

    .content-wrapper {
      margin: 20px 0;
      text-align: center;

      li, p {
        text-align: left;
      }

      .cta {
        text-align: center;
        width: 100%;
        margin-bottom: 20px;
        margin-top: 30px;
        @include at-least($screen-tablet) {
          margin-top: 50px;
        }

        h4 > a {
          @extend .btn;
          @extend .btn-primary-hero;
          color: $primary-colour;
          max-width: 420px;
          min-height: 40px;
          @include until($screen-tablet) {
            font-size: $font-size-s;
            padding: 11px 4px;
          }
        }
      }

      @include at-least($screen-tablet) {
        margin: 20px;
        max-width: 40%;
        width: 40%;
        text-align: left;
        h3 {
          text-align: left;
        }
      }
    }
  }
}

//TWO COLUMN MACRO
.macro-two-column {
  width: 100%;

  &.paper {
    background-color: $pastel-lighter-frost;
  }
  @include flexbox(column, center, center);
  .title-section {
    text-align: center;
    padding: 20px;
    @include at-least($screen-desktop) {
      max-width: 50%;
    }
  }
  .feature-wrapper {
    .feature {
      padding: 20px 30px;
      @include flexbox(column, center, center);
      @include at-least($screen-desktop) {
        padding: 20px 40px;
      }

      img {
        max-width: 100px;
        margin-bottom: 20px;
      }

      @include at-least($screen-desktop) {
        flex: 0 1 40%;
      }
    }

    @include at-least($screen-desktop) {
      @include flexbox(row, flex-start, center);
      @include flex-wrap(wrap);
    }
  }
}

// CAROUSEL //
.macro-carousel {
  width: 100%;
  padding-bottom: 20px;

  &.paper {
    background-color: $pastel-lighter-frost;
  }

  .carousel-wrapper {
    @include flexbox(column, center, center);
    box-sizing: border-box;
    height: 100%;

    .label {
      max-width: 550px;
      margin: 20px 10px 0 10px;
      @include at-least($screen-tablet) {
        margin: 20px 10px;
      }

      p {
        text-align: center;
        margin: 0;
      }

      h3 {
        text-align: center;
        padding-bottom: 10px;
      }
    }

    .carousel-scrollbox-wrapper {
      width: 80%;
      min-height: 550px;
      @include flexbox(column, center, center);
      @include at-least($screen-tablet) {
        @include flexbox(row, center, center);
        padding: 10px 0;
      }

      .carousel-scrollbox {
        @include flexbox(row, center, flex-start);
        margin-top: 20px;
        min-height: 550px;
        width: 100%;
        background-color: $primary-white;
        overflow: hidden;
        cursor: pointer;
        cursor: move;
        cursor: hand;
        cursor: grab;
        cursor: -moz-grab;
        cursor: -webkit-grab;
        @include at-least($screen-tablet) {
          padding-bottom: 0;
          margin: 0;
        }

        .carousel-item {
          @include flexbox(column, center, center);
          padding: 20px;
          @include at-least($screen-desktop) {
            @include flexbox(row, center, center);
          }

          h5 {
            text-align: center;
          }

          p {
            text-align: center;
          }

          height: 100%;
          width: 100%;
        }

        .no-js-carousel-item {
          flex: 0 0 100%;
        }

        &.grabbing {
          cursor: grabbing;
          cursor: -moz-grabbing;
          cursor: -webkit-grabbing;
        }

        .image-wrapper {
          @include flexbox(row, center, center);
          margin: 10px;
          @include at-least($screen-tablet) {
            margin: 20px;
          }

          img {
            width: 228px;
            @include at-least($screen-tablet) {
              width: 100%;
            }
          }
        }

        .description {
          @include flexbox(column, center, center);
          margin: 10px;
          @include at-least($screen-tablet) {
            margin: 20px;
          }
          .icon-wrapper {
            height: 70px;
            @include flexbox(column, center, flex-start );
            img {
              margin: 0 auto;
              width: 85px;
            }
          }
          div {
            margin-top: 20px;
            p {
              max-width: 400px;
              margin-bottom: 0;
            }
          }
        }
      }

      .margin {
        display: none;
        @include at-least($screen-tablet) {
          text-align: center;
          color: white;
          @include inalign(middle);
          padding: 0 25px;
          .button {
            color: $secondary-03;
            font-size: $font-size-l;
            cursor: pointer;
            text-align: center;

            &:hover {
              color: darken($scss-secondary-03, 10);
            }
          }
        }
      }

      .margin-no-js {
        display: none;
      }

      .carousel-scrollbox-no-js {
        overflow: hidden;
      }

      .slick-prev {
        display: none;
      }

      .slick-track {
        @include flexbox('row', 'center', 'center');
        cursor: hand;
      }
    }

    .slick-dots {
      list-style: none;
      display: block;
      text-align: center;
      padding-top: 0;
      width: 100%;
      margin-top: 0;
      @include at-least($screen-tablet) {
        padding-top: 15px;
      }

      li {
        position: relative;
        display: inline-block;
        @include size(20px);
        margin: 0 5px;
        padding: 0;
        cursor: pointer;

        button {
          border: 0;
          display: block;
          @include size(10px);
          outline: none;
          line-height: 0;
          font-size: 0;
          color: transparent;
          padding: 5px;
          cursor: pointer;
          background-color: rgba(0, 0, 0, 0.1);
          border-radius: 50%;
          @include transition(all 0.3s);

          &:hover, &:focus {
            outline: none;
          }
        }

        &.slick-active button {
          background-color: $secondary-03;
          @include transform(scale(1.2));
          @include transform(scale3d(1.2, 1.2, 1));
        }
      }
    }
  }
}

// CTA MACRO
.macro-cta {
  padding: 20px;
  text-align: center;

  .btn.btn-primary {
    text-transform: uppercase;
    font-weight: 400;
  }
}

//SMALL PRINT SLICE
.macro-small-print {
  margin: 0;

  p {
    font-size: $font-size-s;
    text-align: left;
  }
}

// MULTI-CAROUSEL //
.section.multi-carousel {
  .multi-carousel-wrapper {
    width: 100%;
    background-color: $pastel-lighter-frost;
    @include flexbox(column, center, center);
    @include at-least($screen-desktop) {
      @include flexbox(row, center, space-evenly);
    }

    .macro-carousel {
      width: 100%;
      @include at-least($screen-desktop) {
        width: calc(100% / 3);
      }

      .carousel-wrapper {
        @include at-least($screen-desktop) {
          min-height: 550px;
        }
        @include at-least($screen-lg-desktop) {
          min-height: 500px;
        }

        .carousel-scrollbox-wrapper {
          background-color: $pastel-lighter-frost;
          width: inherit;
          min-height: inherit;

          .button {
            color: $primary-colour;
          }

          .carousel-scrollbox {
            background-color: transparent;
            min-height: inherit;
            width: inherit;
            margin-bottom: 0;
            align-items: flex-start;

            .carousel-item {
              background-color: transparent;
              width: 100%;
              padding: 0;
              @include flexbox(column, center, flex-start);

              .image-wrapper {
                padding-bottom: 0px;
                @include size(235px);
                flex: 0 0 100%;
              }
            }
          }
        }
      }
    }

    // SLICK OVERIDES //
    .slick-slide {
      width: 100%;
    }

    .slick-slider {
      align-items: flex-start;
    }

    .slick-arrow {
      color: $primary-colour;
    }

    .slick-dots {
      margin-top: 0;
      padding-top: 0;

      li {
        button {
          background-color: $primary-colour;
        }

        &.slick-active button {
          background-color: $secondary-01;
        }
      }
    }
  }
}


// TRUSTPILOT
.prismic-trustpilot {
  padding-bottom: 30px;
  &.paper {
    background-color: $pastel-lighter-frost;
  }
  .title-section {
    margin: 0 auto;
    padding: 20px 30px;
    text-align: center;
    @include at-least($screen-tablet) {
      width: 50%;
    }
  }
  .trustpilot-widget {
    margin: 0 auto;
    width: 80%;
    &.mobile {
      display: block;
    }
    &.desktop {
      display: none;
    }
    @include at-least($screen-tablet) {
      &.desktop {
        display: block;
      }
      &.mobile {
        display: none;
      }
    }
  }
}


// ACCORDION
.macro-accordion {
  text-align: center;
  padding: 30px;
  h3 {
    margin-bottom: 20px;
  }
  p {
    margin: 0;
    &.intro {
      margin-bottom: 20px;
    }
  }
  &.paper {
    background-color: $pastel-lighter-frost;
  }

  .accordion-wrapper {
    margin: 0 auto;
    @include grid-blocks(6);

    .feature {
      border-top: 1px solid $primary-colour;
      &:last-child {
        border-bottom: 1px solid $primary-colour;
      }

      .content-wrapper {
        padding: 15px;
        label {
          cursor: pointer;
          @include flexbox(row, center, flex-start);
          .icon-plus {
            display: block;
            font-size: 30px;
            @include transition(all 0.5s ease);
          }
          p {
            margin-left: 15px;
            font-size: 22px;
            font-weight: 400;
          }
        }

        .accordion-content {
          display: block;
          max-height: 0;
          opacity: 0;
          overflow: hidden;
          text-align: left;
          margin-left: 45px;
          @include transition(all 0.5s ease);
        }

        input[type=checkbox]:checked {
          ~ .accordion-content {
            opacity: 1;
            max-height: none;
          }

          ~ label .icon-plus {
            @include transform(rotate(45deg));
          }

        }

      }
    }
  }
}

.search-panel{
    position: relative;
    margin: 20px 0 20px 0;
    border-radius: 50px;
    display: inline-block;

    input {
        border: 8px solid $secondary-03;
        min-height: 45px;
        margin-bottom: 15px;
    }
    input, button {
        @include grid-blocks(12);
    }
    @include at-least($screen-tablet) {
        white-space: nowrap;
        input, button {
            @include inalign(middle);
        }
        input {
            margin-bottom: 0;
        }
        button {
            width: auto;
        }
    }
}
body {
    &.faqs {

        background-color: $pastel-lighter-frost;
        .content {
            .container {
                background: none;
                outline: none;
                padding-bottom: 40px;
            }
        }
        .article-link{
            padding: 5px 0px;
            padding-left: 5px;
        }
    }
    &.faqs-index{

        text-align: center;
        .addition{
            margin: 20px 0;
        }
        .signup{
            color: $product-error;
            text-align: center;
        }
    }
    &.faqs-article{

        text-align: left;
        .header {
            margin-bottom: 20px;
        }
        .content {
            .container {
                background: none;
                outline: none;
                padding-bottom: 40px;
            }
        }
        .article{
            padding: 20px;
            background: white;
            border-radius: 10px;
            box-shadow: 0 4px 0 $navy-04;
            @media screen and (min-width: $screen-tablet) {
                padding: 50px;
            }
        }
    }
}


@mixin side-nav-show {
    box-shadow: 2px 0 20px rgba(0, 0, 0, 0.2);
    @include transform(translate(0, 0));
    @include transform(translate3d(0, 0, 0));
}

@mixin small_logo {
    @include transform(translate(0, -15px) scale(0.75));
    @include transform(translate3d(0,-15px,0) scale(.75));
    @media screen and (max-width: $nav-max) {
        @include transform(translate(0, -15px) scale(0.6));
        @include transform(translate3d(0, -15px, 0) scale(0.6));
    }
}

#hamburger {
    @include position(absolute, 0 null null 0);
    display: none;
    z-index: 999;
}

#hamburger:checked {
    &~ .side-nav {
        @include side-nav-show;
    }
    &+ .hamburger {
        .icon {
            @include transform(rotateY(180deg));
            &::before {
                content: $icon-arrow-right;
            }
        }
    }
    &~ .top-nav .logo {
        //@include small_logo;
    }
}

.navigation-content {
    max-width: 1280px;
    margin: 0 auto;
    position: relative;
    @include flexbox(row,center,space-around);
}

body.side-nav-open {
    .side-nav {
        @include side-nav-show;

    }
    .hamburger {
        .icon {
            @include transform(rotateY(180deg));
            &::before {
                content: $icon-arrow-right;
            }
        }
    }
}

.hamburger {
    display: none;
    @include position(fixed, null null null 0);
    @include user-select(none);
    height: 50px;
    z-index: 4;
    text-align: center;
    color: $primary-colour;
    cursor: pointer;
    font-size: 1.4rem;
    line-height: 50px;
    padding-left: 5px;
    font-family: $default-font-family;
    @include transition(color 1s);
    .icon {
        width: 30px;
        font-size: $font-size-m;
        vertical-align: middle;
        display: inline-block;
        @include transition(transform 0.3s ease-in-out);
        @include transform(rotateY(0deg));
    }
    .text {
        font-weight: normal;
        color: $primary-black;
        @include inalign(middle);
    }
}

.hamburger.has-alert {
    .icon::after {
        content: $icon-exclamation-circle;
        color: $product-error;
        padding-left: 58px;
    }
    &~ .side-nav .links .link:nth-child(2), ~ .top-nav .links .link:nth-child(2){
        color: $product-error;
        font-weight: normal;
        &:hover, &.active {
            background-color: $product-error;
            color: $primary-white;
        }
        &::after {
            @include icon;
            content: $icon-exclamation-circle;
            margin-left: 5px;
            @include inalign(middle);
        }
    }
    &~ .top-nav .links .link:nth-child(2) .main-link {
        @include inalign(middle);
    }
}

.side-nav {
    @include position(fixed, 50px null 0 0);
    width: 100%;
    background-color: $primary-white;
    z-index: 2;
    line-height: 50px;
    @include transform(translate(-100%, 0));
    @include transform(translate3d(-100%, 0, 0));
    @include transition(transform 0.3s);
    max-height: calc(100vh - 50px);
    overflow: auto;
    text-align: center;
    font-family: $default-font-family;
    @include flexbox(column,flex-start,flex-start);
    .btn-signup {
        display: none;
    }
    .links {
        width: 100%;
        display: inline-block;
        text-align: left;

        .how-it-works {
            margin-top: 4px;
        }

    }

    .spacer {
        display: none;
    }

    .raf-experiment-main {
        position: relative;

        .raf-heart-icon {
            position: absolute;
            top: 5px;
            height: 15px;
            width: 15px;
        }
    }

    .signin, .signout {
        margin: 12px 0;
    }

    .link {
        display: block;
        font-size: 1.4rem;
        text-transform: none;
        text-decoration: none;
        padding: 0 30px;
        margin: 24px 0;
        color: $primary-colour;
        line-height: 1.6;
        text-align: start;
        ul {
            list-style: none;
            padding-left: 0;
            font-size: 1.4rem;
            li {
               padding: 10px;
            }
        }
        a.main-link, a.sub-link {
            color: $primary-colour;
            font-size: $font-size-s;
            font-weight: 400;
            &.active {
                border-bottom: 1px solid $primary-black;
            }
        }
    }

    .other-links {
        margin-right: 16px;
        width: 100%;
        .account {
            width: 100%;
            text-align: left;
            a.link {
                color: $primary-black;
            }
        }

        .basket {
            display: none;
        }
    }
}

.top-nav {
    @include position(fixed, 0 0 null 0);
    height: $nav-height;
    background-color: $primary-white;
    z-index: 3;
    font-family: $default-font-family;
    border-bottom: 1px solid #e3e8ef; /* This colour isn't currently in v1.0.0 of the shared css */

    .links {
        text-transform: none;
        font-size: 1.4rem;
        margin-left: 130px;
        z-index: 1;
        @include user-select(none);
        &::before {
            content: "";
            @include inalign(middle);
            margin-left: -5px;
            height: 100%;
        }
        .main-link {
            padding-bottom: 4px;
            font-size: 14px;
            &:hover {
                color: $primary-colour;
                background-color: initial;
            }
            &.christmas-icon {
                font-weight: var(--font-weight-medium);
                .text::after {
                    content: "";
                    display: inline-block;
                    background: url(/static/images/svgs/icon/christmas/gift.svg) no-repeat;
                    width: 23px;
                    height: 23px;
                    vertical-align: middle;
                    background-size: contain;
                    margin-left: 5px;
                }
            }
        }

        .raf-experiment-main {
            position: relative;

            .raf-heart-icon {
                position: absolute;
                top: 5px;
                right: -2px;
                height: 15px;
                width: 15px;
            }
        }
        .link {
            color: $primary-colour;
            @include inalign(middle);
            height: auto;
            position: relative;
            text-decoration: none !important;
            border-bottom: 1px solid transparent;
            font-size: 14px;
            margin: 12px 10px 3px;
            padding-bottom: 5px;

            .text {
                padding: 10px 0;
                color: $primary-colour;
            }

            > span {
                display: block;
            }

            > .spacer {
                display: none;
                font-weight: bold;
                visibility: hidden;
                height: 0;
            }

            &:focus, &:focus-visible {
                background-color: var(--tertiary-cta-hover);
            }

            &:hover, &.active {
                .main-link {
                    border-bottom: 1px solid $primary-black;
                }
            }

            &:hover {
                .subnav {
                    display: block;
                }
            }

            &.home {
                text-align: center;
                font-size: 2rem;
                padding: 8px 9px;
                line-height: 1;
                .text, .spacer {
                    display: none;
                }
            }

            &.has-subnav {
                padding: 0;
            }

            a.text {
                color: inherit;
                text-decoration: none;
                padding: 10px 5px;
                display: inline-block;
            }

            .subnav-outer {
                position: absolute;
                top: 38px;
                left: 0;
                padding-top: 8px;
            }

            .subnav {
                padding: 0;
                margin: 0;
                white-space: nowrap;
                background: $primary-white;
                border: 1px solid rgba(0,0,0,.15);
                border-radius: 3px;
                -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
                box-shadow: 0 6px 12px rgba(0,0,0,.175);
                display: none;

                li {
                    list-style: none;
                    font-size: 1.4rem;
                    margin: 10px 20px;
                    text-align: left;
                    &:first-child {
                      margin: 20px 20px 10px 20px;
                    }

                    a.sub-link {
                        font-weight: normal;
                        display: inline-block;
                        padding-bottom: 4px;
                        color: $primary-colour;
                        text-decoration: none;
                        border-bottom: 1px solid transparent;
                    }

                    a.sub-link:hover,
                    a.sub-link.active {
                        border-bottom: 1px solid $primary-black;
                    }
                }
            }
        }
    }

    .logo-wrapper {
        @include position(absolute, 0 0 0 0);
        text-align: center;
        .logo {
            position: relative;
            display: inline-block;
            height: 100%;
            z-index: 2;
            object {
                @include size(100%);
            }
            img {
                height: 100%;
                width: 95px;
            }
            a:focus, a:hover {
                background-color: initial;
            }
        }
        @include at-least($nav-max) {
            text-align: left;
            .logo {
                height: 100%;
                margin: 0 16px;
                img {
                    height: 100%;
                    width: 103px;
                }
            }
        }
    }
    .other-links {
        @include flexbox(row, center, flex-end);
        line-height: 90px;
        height: 90px;
        z-index: 1;
        > * {
            line-height: 1.4;
            line-height: initial;
        }
        .link {
            text-align: center;
            > * {
                @include inalign(middle);
            }
            .icon {
                margin-left: 5px;
                font-size: 3rem;
            }
        }

        .nav-btn {
            background-color: transparent;
            padding: 0 8px;
            border: none;
            color: $primary-colour;
            text-decoration: none;
            .icon {
                color: $primary-colour;
            }
        }
        .account {
            @include flexbox(row, center, center);
            .link {
                @include flexbox(row, center, center);
                color: $primary-black;
                font-size: 1.3rem;
            }
            .btn-signup {
                padding: 10px 18px;
                background: $secondary-03;
                font-weight: 400;
                min-width: 0;
            }
        }
        .country {
            padding: 0 5px;
            pointer-events: none;
            height: 20px;
            .country-flag {
                width: 24px;
            }
        }
        span,a.link {
            font: {
                weight: 400;
                size: 14px;
            }
            letter-spacing: 0;

        }
    }
    .user-login {
        display: flex;
    }
}
@media screen and (min-width: $nav-max) {
    .side-nav {
        display: none;
    }
}
@include until($nav-max) {
    .top-nav {
        height: 50px;
        .links {
            display: none;
        }
        .other-links {
            right: 5px;
            line-height: 50px;
            height: 50px;
            margin: 0 10px 0 auto;
            .account {
                .signin, .signout {
                    display: none;
                }
                .btn-signup {
                    background: transparent;
                }
            }

        }
        .signin-out-button {
            display: none;
        }
    }
    .hamburger {
        display: block;
    }
    .main {
        top: 50px;
    }
}

/* Page Cases */

body.signup:not(.new) {
    .hamburger {
        display: none !important;
    }
    .top-nav {
        .links, .other-links .link {
            display: none !important;
        }
    }
}

nav {
    #tounge {
        display: none;
    }
    #tounge:checked ~ .other-links .tounge {
        opacity: 1;
        transform: scale(1);
        pointer-events: auto;
    }
    #tounge:checked ~ .other-links .cover {
        opacity: .4;
        pointer-events: auto;
    }
    #tounge:checked ~ .logo-wrapper .logo {
        @media screen and (max-width: $screen-tablet) {
            //@include small_logo;
        }
    }

    .other-links {
        .account {
            display: inline-block;
            white-space: nowrap;

            .signin-out-button {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                padding: 10px 12px;
                margin: 12px 0 12px 20px;
                border-radius: 4px;
                height: 40px;
                min-height: 40px;
                width: fit-content;
                min-width: fit-content;
            }

            .signin {
                border: 1px solid $primary-white;
                color: $primary-white !important;
                background-color: $primary-blueberry;
            }

            .signin:hover {
                background-color: $secondary-cta-pressed;
            }

            .signout {
                border: 1px solid $primary-black;
            }

            .signin:hover {
                background-color: $primary-cta-hover;
            }

            .signout:hover {
                background-color: $secondary-cta-hover;
            }

            .logout-icon {
                min-width: 20px !important;
                width: 20px;
                height: 20px;
                margin-right: 8px;
            }

            .login-button {
                filter: brightness(0) invert(1);
            }

            &[data-state=in] {
                .signout {
                    .icon-user-transparent {
                        font-size: 1.2em;
                    }
                }
                .signin {
                    display: none;
                }
            }
            &[data-state=out] {
                .signin {

                }
                .signout {
                    display: none;
                }
            }
        }

        .basket {
            position: relative;

            @include until($screen-desktop) {
                margin-right: 10px;
            }

            img {
                vertical-align: middle;
            }

            .basket-badge {
                top: 6px;
                right: 0;
                width: 18px;
                height: 18px;
                position: absolute;
                fill: $secondary-pear-light;
                z-index: 4;

                .circle-text {
                    text-anchor: middle;
                    dominant-baseline: central;
                    fill: $primary-black;
                    font-size: $font-size-xs;
                    font-weight: 600;
                }
            }
        }

        @include at-least($screen-desktop) {
            margin: 0 20px 0 auto;
        }
        .promo {
            @include at-least($screen-desktop) {
                margin-left: 20px;
            }
            label[for="tounge"] {
                vertical-align: sub;
                &.promo-input {
                    @include until($screen-tablet) {
                        margin: 5px 0;
                    }
                }
                &.tounge-close {
                    text-align: right;
                    display: block;
                    margin: 0;
                    &:before {
                        @include icon($icon-cross-circle);
                        font-size: $font-size-body;
                        cursor: pointer;
                    }
                }
                .offer-text {
                    cursor: pointer;
                    overflow: hidden;
                    display: -webkit-box;
                    color: $primary-black;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    max-width: 120px;
                    @include at-least($screen-tablet) {
                        -webkit-line-clamp: 1;
                        max-width: 240px;
                    }
                }
                .badge-wrapper {
                    display: flex;
                    align-items: center;
                    column-gap: 3px;

                    .offer-badge {
                        cursor: pointer;
                        overflow: hidden;
                        color: var(--primary-black);
                        background-color: var(--success-background);
                        padding: 3px 4px;
                        border-radius: 4px;
                        font-size: var(--font-size-xs);
                        font-weight: var(--font-weight-medium);
                        text-decoration: underline;
                        display: flex;
                        gap: 4px;

                        @include at-least($screen-tablet) {
                            -webkit-line-clamp: 1;
                            max-width: 240px;
                        }

                        svg path {
                            fill: var(--secondary-pear);
                        }

                        .promo-description {
                            margin-top: 1px;
                            font-size: var(--font-size-xs);
                            font-weight: var(--font-weight-medium);
                        }
                    }
                }
            }
        }
        .country-selector {
            overflow: hidden;
            position: relative;
            width: 18px;
            min-width: 18px;
            max-width: 18px;
            height: 15px;
            margin-right: 10px;
            @include at-least($screen-desktop) {
                margin: 0;
            }
            select {
                border: none;
                width: 18px;
                height: 18px;
                padding: 0;
            }
            .selector-arrow {
                position: absolute;
                width: 18px;
                height: 18px;
                top: -2px;
                right: 0;
                pointer-events: none;
                &:before {
                   @include icon($icon-chevron-down);
                   color: $primary-colour;
                }
            }
        }
    }

    .promo {
        @include promo();
        display: none;
    }
    .promo-input {
        @include until($nav-max) {
            font-size: 1.2rem;
        }
    }
}

/* Overrides */
body.full .content {
    margin-bottom: 0 !important;
    min-height: calc(100% - 300px) !important;
    padding-bottom: 0;
}
body.account {

}
.main {
    @include position(relative, 0 0 null 0);
    margin-top: 90px;
    z-index: 0;
    min-height: calc(100% - 442px);
    > * {
        margin-left: auto;
        margin-right: auto;
    }
    @media screen and (max-width: $nav-max) {
        margin-top: 50px;
    }
}
body {
    .promo-change-banner {
        display: none;
    }
    &.signup {
        .promo-change-banner {
            display: none;
        }
    }
}

/* shared */
body.home, body.signup-feeding-plan.new {
    .main {
        background-color: $primary-white;
    }
    font-size: $font-size-s;
    @media screen and (min-width: $screen-tablet) {
        h2 {
            font-size: 3.2rem;
        }
    }
    @media screen and (min-width: $screen-desktop) {
        font-size: $font-size-body;
        h2 {
            font-size: $font-size-l;
        }
    }

    h1.mainheader {
        font-size: 3.2rem;
        letter-spacing: 1px;
    }

    h1.subheader {
        font-size: $font-size-m;
        font-weight: 400;
    }
    @media screen and (min-width: $screen-phone) {
        h1.mainheader {
            font-size: 4.8rem;
        }
        h1.subheader {
            font-size: 2.4rem;
        }
    }
}

body.survey {
    .checklist.parent {
        .children {
            display: none;
        }
        .children-toggle {
            &:checked ~ .children,
            &:checked ~ .more-details {
                display: block !important;
            }
        }
    }
}
body.account.one-step-wet {
    .content {
        @include at-least($screen-desktop) {
            @include grid-blocks(12);
        }
        .promo-notification {
            text-align: center;
            background-color: $secondary-03;
            padding: 10px;
            margin: 0;
            height: unset;
            width: 100%;
            @include at-least($screen-desktop) {
                @include grid-blocks(12);
            }
        }

        .all-upper {
            font-weight: bold;
            text-transform: uppercase;
        }

        .macro-ingredients {
            .title {
                font-size: $font-size-body;
            }

            details {
                margin: 20px 0;
            }

            summary {
                display: -webkit-box;

                .toggle {
                    float: right;
                }
            }

            .ingredients-link {
                margin-left: 0;
                margin-top: 10px;
            }
        }

        .wet-hero {
            padding-top: 40px;
            background-image: none;
            @include at-least($screen-desktop) {
                display: flex;
                background-image: url("/static/images/products/wet-food/hero/wet_hero.png");
                background-repeat: no-repeat;
                height: 392px;
                margin-bottom: 50px;

            }
        }

        .wet-hero-mobile {
            background-image: url("/static/images/products/wet-food/hero/wet_hero.png");
            background-repeat: no-repeat;
            height: 392px;
            @include at-least($screen-desktop) {
                background-image: none;
            }
        }

        .text-wrapper {
            padding: 20px;
            margin: auto;
            width: fit-content;
            @include at-least($screen-desktop) {
                position: absolute;
                margin-left: 645px;
            }
            .heading-text {
                font-family: $handwritten-font-family;
            }
            .claims-wrapper {
                margin: 40px 0;
                .claim {
                    margin-top: 15px;
                    display: flex;
                    p {
                        align-self: center;
                        margin-left: 10px;
                    }
                }
            }
        }

        .opening {
            padding-top: 0;
            @include at-least($screen-tablet) {
                padding-top: 20px;
            }
        }

        .opening, .selection-and-portions, .buttons, .wet-other-update {
            margin: 10px 20px;
            @include at-least($screen-tablet) {
                @include grid-blocks(8);
                margin: auto;
            }

            .btn {
                margin: 0 0 15px 0;
                @include at-least($screen-tablet) {
                    margin: 0 15px 0 0;
                }
            }
        }

        .selection-and-portions {
            display: grid;
        }

        .wet-selection {
            order: 1;
            @include at-least($screen-tablet) {
                order: 2;
            }
        }

        .feeding-plan {
            order: 2;
            padding: 10px 0 20px 0;
            @include at-least($screen-tablet) {
                order: 1;
            }

            .macro-portion-module {
                padding: 10px 0 0 0;
                margin-left: 0;
            }
        }
    }
}

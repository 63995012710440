.headline-main {
    font-size: $font-size-xxl;
    color: $primary-blueberry;
    font-family: $default-font-family;
    font-weight: 400;

    &.left {
        text-align: left;
    }

    @include until($screen-tablet) {
        font-size: $font-size-xxl;
    }
}

.line-pad {
    margin-top: 27px;
    @include until($screen-tablet) {
        margin-top: 24px;
    }
}

.user-controls {
    select {
        border-width: 1px;
        border-style: solid;
        border-radius: 3px;
        font-weight: 400;
        padding: 5px 5px 3px 5px;
    }

    select:focus, input:focus {
        box-shadow: 0px 0px 0px 2px $secondary-02;
    }
}

.notification:empty {
    display: none;
}

.notification {
    margin: 27px auto;
    width: 100%;
    border-radius: $spacing-xxs;
    padding: 15px;
    text-align: start;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    .notify-icon {
        position: relative;
        top: 3px;
        display: inline-block;
    }

    .action {
        margin-top: $spacing-xxs;
    }

    .notification-content {
        width: calc(100% - 40px);
        display: inline-block;
        vertical-align: top;
        padding: 0 $spacing-xxs;
        flex-grow: 1;
        font-size: $font-size-body;

        h5 {
            display: inline-block;
            font-size: $font-size-body;
            color: $primary-black;
            font-weight: $font-weight-bold;
            margin: 0 0 5px 0;
        }

        a {
            color: $primary-black;
        }

        p {
            margin: 0 !important;
            font-size: $font-size-body;
        }

        ul {
            padding-left: 5px;
            list-style-type: none;
            margin: 0;
            font-weight: $font-weight-regular;
            
            li {
                font-size: $font-size-body;
                margin-bottom: 5px;
            }
        }

        .notification-link {
            color: $primary-colour;
            font-size: $font-size-body;
            background-color: rgba(179, 0, 0, 0);
            box-shadow: inset 0 0 0 1px $navy-01;
            width: 100%;
            @include at-least($screen-tablet) {
                min-width: 90%;
            }
        }

        .raf-link {
            width: 100%;
            margin-top: $spacing-xxs;
            @include at-least($screen-tablet) {
                min-width: 90%;
            }
        }

        &:before {
            padding-right: 10px;
            font-size: 21px;
            vertical-align: top;
        }
        span#dismiss-notification {
            &:after {
                @include icon($icon-times);
                content: $icon-times;
                font-size: 14px;
                color: $primary-colour;
                float: right;
                margin-top: 4px;
            }
        }
    }

    &.error {
        background: $product-error-background;

        .success-content {
            display: none;
        }

        .failed-content {
            display: block;
        }
    }

    &.success {
        background: $product-success-background;
    }

    &.warning {
        background: $product-warning-background;
    }

    &.info {
        background: $product-info-background;
    }

    &.dismissible {
        background: $product-success-background;
        height: 40px;
        padding: 10px 12px;
        max-width: 833px;
        @include until($screen-tablet) {
            margin-left: 12px;
            margin-right: 12px;
            width: calc(100% - 24px);
        }

        .notification-content {
            width: 100%;
            padding-left: 0;
            p {
                display: inline-block;
                text-align: center;
                height: 24px;
                color: $product-success;
                font-size: $font-size-s;
                letter-spacing: 0;
                line-height: 24px;
                width: calc(100% - 14px);
            }
        }
    }

    &.promotion {
        background: $product-success-background;
        padding: 10px 12px;
        max-width: 833px;
        @include until($screen-tablet) {
            margin-left: 12px;
            margin-right: 12px;
            width: calc(100% - 24px);
        }
        .notification-content {
            width: 100%;
            padding-left: 0;
            p, h5 {
                display: inline-block;
                text-align: left;
                height: 24px;
                font-size: $font-size-s;
                letter-spacing: 0;
                line-height: 24px;
                width: calc(100% - 14px);
            }
            p {
              margin-left: $spacing-m;
            }
            h5 {
              font-weight: bold;

              &:before {
                @include icon($icon-check-circle);
                content: $icon-check-circle;
                font-size: $font-size-s;
                line-height: 24px;
                color: $product-success;
                float: left;
                margin-right: $spacing-xs;
              }
            }
        }
    }
}

.vertical-buttons {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    padding: 0 10px;
}

.horizontal-buttons {
    display: flex;
    flex-flow: row wrap;

    &.center {
        justify-content: center;
    }

    @include until($screen-tablet) {
        flex-flow: column wrap;
    }

    .btn {
        margin: 5px;
    }
}

.progressive-select {
    margin-top: 10px;

    input, .selections {
        max-width: 425px;
        width: 100%;
        padding: 13px;
        border: 1px solid $navy-04;
        border-radius: 3px;
        font-size: 1.8rem;

        &:focus {
            outline: none;
        }

        &:focus ~ .selections {
            display: block;
        }
    }

    input:focus {
        box-shadow: 0 0 2px 2px darken($scss-pastel-lighter-lavender, 15%);
    }

    .selections {
        margin: 10px auto;
        padding: 0;
        display: none;

        .selection {
            padding: 8px;

            &:hover {
                background: $pastel-lighter-lavender;
                cursor: pointer;
            }
        }
    }
}

.td-form-field, .form-group.td-form-field {
    box-shadow: 0 0 0 1px $primary-colour;
    border-radius: 3px;
    border-bottom: 0;
    margin: 5px 0;

    &:focus {
        box-shadow: 0 0 0 1px $primary-colour, 0 0 0 3px $secondary-02;
    }
}

label {
    &.error-inline {
        color: $product-error;
    }
}

div {
    &.error-inline {
        border-left: 5px solid $product-error;
        padding-left: 20px;
    }
}

button, a {
    &.btn-heavy {
        &:after {
            color: $primary-colour;
        }
    }

    &.btn-white {
        color: $primary-colour;
        background-color: $primary-white;
        border: 1px solid $primary-colour;
    }
}

.delivery-methods {
    .delivery-method {
        display: flex;
        justify-content: space-between;
        box-shadow: 0 0 0 2px $navy-01;
        border-radius: 5px;
        padding: 23px 13px;
        margin: 19px 0;
        color: $navy-01;
        font-size: $font-size-m;
        cursor: pointer;

        .icon-chevron-right {
            border-radius: 50%;
            padding: 12px;
            font-size: 2rem;
        }
    }

    .delivery-method-selected {
        box-shadow: 0 0 0 4px $navy-01;
    }
}

.collection-points-list {
    padding: 20px 0;
    h4 {
        text-align: center;
    }
    .single-point {
        margin: 25px 0;
        input[type=radio] {
            display: none;
            &:checked {
                ~ .point-details {
                    .opening-hours {
                        display: block;
                    }
                }
                ~ .collection-point {
                    label {
                        @include radio_toggle_on;
                        span {
                            font-weight: bold;
                        }
                    }
                }
            }
        }
        .opening-hours {
            display: none;
        }
    }
    .collection-point {
        margin: 17px 0;
        .radio-buttons input[type=radio]:checked ~ label span {
            font-weight: bold;
        }
    }
    .map {
        display: none;
        height: 400px;
        margin-left: -30px;
        margin-right: -30px;
    }
    .collection-label {
        color: $primary-colour;
        background-color: $pastel-lighter-lavender;
        font-family: $default-font-family;
        font-size: 16px;
        text-align: center;
        white-space: nowrap;
        border-radius: 6px;
        // Keep this wide for translations
        width: 160px;
        // Override Google Maps API in-line styling (too brittle to target with specificity)
        margin-left: -80px !important;
    }
}
.point-details p {
    line-height: 1;
    margin-left: 34px;
}

.vue-app {
    display: none;
    .form-group {
        input[name='address-first_line'] {
            margin-bottom: 20px;
        }
    }
}

a.unstyle {
    color: inherit;
    text-decoration: inherit;
}

.progress-spinner {
    content: "";
    @include tails_spinner(50px, 15px);
}

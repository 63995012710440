.icons-info-age { content: url(./svg/icons_info_age.svg); }
.icons-info-balance { content: url(./svg/icons_info_balance.svg); }
.icons-info-beef { content: url(./svg/icons_info_beef.svg); }
.icons-info-beet { content: url(./svg/icons_info_beet.svg); }
.icons-info-birthday { content: url(./svg/icons_info_birthday.svg); }
.icons-info-breed-size { content: url(./svg/icons_info_breed-size.svg); }
.icons-info-breed { content: url(./svg/icons_info_breed.svg); }
.icons-info-calendar { content: url(./svg/icons_info_calendar.svg); }
.icons-info-chat { content: url(./svg/icons_info_chat.svg); }
.icons-info-chew { content: url(./svg/icons_info_chew.svg); }
.icons-info-chicken { content: url(./svg/icons_info_chicken.svg); }
.icons-info-concentrated-formula, .icons-info-dry-claim-9 { content: url(./svg/icons_info_concentrated-formula.svg); }
.icons-info-delivery { content: url(./svg/icons_info_delivery.svg); }
.icons-info-dental, .icons-info-dry-claim-19, .icons-info-dry-claim-24, .icons-info-treat-claim-2 { content: url(./svg/icons_info_dental.svg); }
.icons-info-digestion, .icons-info-dry-claim-1, .icons-info-dry-claim-5, .icons-info-dry-claim-25 { content: url(./svg/icons_info_digestion.svg); }
.icons-info-discount { content: url(./svg/icons_info_discount.svg); }
.icons-info-energy, .icons-info-dry-claim-10 { content: url(./svg/icons_info_energy.svg); }
.icons-info-exclusions { content: url(./svg/icons_info_exclusions.svg); }
.icons-info-fish { content: url(./svg/icons_info_fish.svg); }
.icons-info-gender { content: url(./svg/icons_info_gender.svg); }
.icons-info-grain-free, .icons-info-dry-claim-16, .icons-info-dry-claim-22, .icons-info-treat-claim-1 { content: url(./svg/icons_info_grain-free.svg); }
.icons-info-growth-and-vitality, .icons-info-dry-claim-17, .icons-info-dry-claim-26, .icons-info-wet-claim-2 { content: url(./svg/icons_info_growth-and-vitality.svg); }
.icons-info-heart, .icons-info-dry-claim-11 { content: url(./svg/icons_info_heart.svg); }
.icons-info-house { content: url(./svg/icons_info_house.svg); }
.icons-info-hydrolised-protein { content: url(./svg/icons_info_hydrolised-protein.svg); }
.icons-info-hypoallergenic { content: url(./svg/icons_info_hypoallergenic.svg); }
.icons-info-included { content: url(./svg/icons_info_included.svg); }
.icons-info-joints, .icons-info-dry-claim-21, .icons-info-dry-claim-27 { content: url(./svg/icons_info_joints.svg); }
.icons-info-kibble, .icons-info-dry-claim-12, .icons-info-dry-claim-13, .icons-info-dry-claim-15 { content: url(./svg/icons_info_kibble.svg); }
.icons-info-kidney, .icons-info-dry-claim-14 { content: url(./svg/icons_info_kidney.svg); }
.icons-info-lamb { content: url(./svg/icons_info_lamb.svg); }
.icons-info-linseed { content: url(./svg/icons_info_linseed.svg); }
.icons-info-maize { content: url(./svg/icons_info_maize.svg); }
.icons-info-mental-development, .icons-info-dry-claim-7 { content: url(./svg/icons_info_mental-development.svg); }
.icons-info-natural-antioxidants { content: url(./svg/icons_info_natural-antioxidants.svg); }
.icons-info-no-additives, .icons-info-dry-claim-20, .icons-info-wet-claim-3 { content: url(./svg/icons_info_no-additives.svg); }
.icons-info-no-commitment { content: url(./svg/icons_info_no-commitment.svg); }
.icons-info-nose-to-tail { content: url(./svg/icons_info_nose-to-tail.svg); }
.icons-info-oil { content: url(./svg/icons_info_oil.svg); }
.icons-info-pancreatitis { content: url(./svg/icons_info_pancreatitis.svg); }
.icons-info-paws-for-cause { content: url(./svg/icons_info_paws-for-cause.svg); }
.icons-info-payment { content: url(./svg/icons_info_payment.svg); }
.icons-info-peas, .icons-info-pea { content: url(./svg/icons_info_peas.svg); }
.icons-info-poos { content: url(./svg/icons_info_poos.svg); }
.icons-info-potato { content: url(./svg/icons_info_potato.svg); }
.icons-info-probiotics { content: url(./svg/icons_info_probiotics.svg); }
.icons-info-profile-avatar { content: url(./svg/icons_info_profile-avatar.svg); }
.icons-info-protein, .icons-info-dry-claim-8, .icons-info-dry-claim-18, .icons-info-dry-claim-23, .icons-info-wet-claim-4 { content: url(./svg/icons_info_protein.svg); }
.icons-info-recycle { content: url(./svg/icons_info_recycle.svg); }
.icons-info-rice { content: url(./svg/icons_info_rice.svg); }
.icons-info-rosemary { content: url(./svg/icons_info_rosemary.svg); }
.icons-info-scoop { content: url(./svg/icons_info_scoop.svg); }
.icons-info-seaweed { content: url(./svg/icons_info_seaweed.svg); }
.icons-info-security { content: url(./svg/icons_info_security.svg); }
.icons-info-skin-and-coat, .icons-info-dry-claim-2, .icons-info-wet-claim-1 { content: url(./svg/icons_info_skin-and-coat.svg); }
.icons-info-strong-immune-system, .icons-info-dry-claim-4, .icons-info-dry-claim-6 { content: url(./svg/icons_info_strong-immune-system.svg); }
.icons-info-supplement { content: url(./svg/icons_info_supplement.svg); }
.icons-info-sweet-potato { content: url(./svg/icons_info_sweet-potato.svg); }
.icons-info-treats { content: url(./svg/icons_info_treats.svg); }
.icons-info-water-drip { content: url(./svg/icons_info_water-drip.svg); }
.icons-info-weight, .icons-info-dry-claim-3 { content: url(./svg/icons_info_weight.svg); }
.icons-info-wet-food { content: url(./svg/icons_info_wet-food.svg); }
.icons-info-wet-food-premium { content: url(./svg/icons_info_wet-food-premium.svg); }
.icons-info-wheat { content: url(./svg/icons_info_wheat.svg); }
.icons-info-winds { content: url(./svg/icons_info_winds.svg); }
.icons-info-yucca { content: url(./svg/icons_info_yucca.svg); }
.icons-info-zero-waste { content: url(./svg/icons_info_zero-waste.svg); }
.icons-info-lice { content: url(./svg/icons_info_lice.svg); }
.icons-info-flea { content: url(./svg/icons_info_flea.svg); }
.icons-info-worm { content: url(./svg/icons_info_worm.svg); }
.icons-info-tick { content: url(./svg/icons_info_tick.svg); }
.icons-info-user { content: url(./svg/icons_info_user.svg); }
.icons-interaction-email { content: url(./svg/icons_interaction_email.svg); }
.icons-interaction-phone { content: url(./svg/icons_interaction_phone.svg); }
.icons-interaction-error { content: url(./svg/icons_interaction_error.svg); }
.icons-interaction-delivery { content: url(./svg/icons_interaction_delivery.svg); }
.icons-interaction-chat { content: url(./svg/icons_interaction_chat.svg); }
.icons-raf-heart { content: url(./svg/icons_raf_heart.svg) };


body.products {
    margin-top: 0;
    .header{
        max-width: 1280px;
    }
    h1 {
        padding-top: 20px;
    }
    max-width: 100%;
    text-align: center;
    .wrapper {
        max-width: 800px;
        padding: 15px;
        margin: 0 auto;
        .action {
            cursor: pointer;
            text-align: left;
            text-decoration: none;
            &.btn {
                min-width: 130px !important;
            }
            &.edit {
                &:before {
                    @include icon($icon-edit-circle);
                }
            }
            &.add {
                &:before {
                    @include icon($icon-plus);
                    color: $primary-white;
                    font-size: $font-size-m;
                }
            }
            &.secondary {
                box-shadow: none !important;
                font-size: 2rem;
                &:after {
                   @include icon($icon-edit-circle);
                    font-size: 2.4rem;
                }

            }
            &.edit-delivery span {
                text-decoration: underline;
            }
        }
    }
    .reviews {
        h4 {
            margin-bottom: 20px;
        }
        padding: 20px;
        display: flex;
        flex-direction: column;
        background-color: $pastel-lighter-frost;
        .review {
            margin-bottom: 40px;
            img {
                margin-top: 10px;
                height: 30px;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .section {
        position: relative;
        background: white;
        border-radius: 4px;
        margin: 15px -5px;
        text-align: left;
        display: flex;
        align-items: center;
        overflow: hidden;
        box-shadow: 0 2px 8px rgba(0,0,0,0.1);
        margin-bottom: 20px !important;
        .product {
            font-size: 2.1rem;
            font-weight: bold;
        }
        .details {
            font-size: 14px;
            padding-bottom: 10px;
            @include at-least($screen-phone) {
                font-size: $font-size-s;
            }
        }
        .bar {
            @include position(absolute, 0 null 0 0);
            min-width: 120px;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            background-position-y: top;
            background-size: contain;

            .icon {
                margin-right: 0;
                display: block;
                text-align: center;
                line-height: 84px;
                padding: 0 5px;
                &:before {
                    color: white;
                    font-size: 42px;
                }
            }
            .price {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                text-align: center;
                background-color: $navy-01;
                padding: 5px;
                font-size: $font-size-s;
                color: white;
            }
        }
        .quantity {
            color: $secondary-01;
            &.low {
                color: $secondary-03;
            }
            &.disabled {
                color: $navy-04;
            }
        }
        .info {
            min-height: 100px;
            margin-left: 100px;
            flex-grow: 1;
            max-width: 100%;
            overflow: hidden;
            .top {
                position: relative;
                padding: 15px;
                padding-left: 40px;
                .price {
                    position: absolute;
                    bottom: 15px;
                    right: 15px;
                }
                span {
                    line-height: 30px;
                    &.not-suitable {
                        color: $secondary-03;
                    }
                }
            }
        }
        @include until($screen-phone) {
            margin: 15px -10px;
            .info {
                .top {
                    padding: 10px;
                    padding-left: 10px;
                }
            }
            .bar {
                min-width: 100px;
                .icon {
                    padding: 0 15px;
                    line-height: 96px;
                    &:before {
                        font-size: 50px;
                    }
                }
            }
        }
        .actions {
            width: 100%;
            justify-content: flex-end;
            a {
                text-decoration: none;
            }
            label {
                align-items: right;
            }
        }
        @keyframes drop-in {
            0% {
                opacity: 0;
                transform: translate(0, -100%);
            }
            20% {
                opacity: 0;
                transform: translate(0, -100%);
            }
            40% {
                opacity: 0.5;
                transform: translate(0, 0%);
            }
            50% {
                opacity: 0.8;
                transform: translate(0, -10%);
            }
            70% {
                opacity: 1;
                transform: translate(0, 0%);
            }
            80% {
                transform: translate(0, -1%);
            }
            100% {
                transform: translate(0, 0);
                opacity: 1;
            }
        }
        .expanded {
            height: 0;
            overflow: hidden;
            transition: height 0.2s ease-out;
            text-align: center;
            > * {
                @include inalign(middle);
            }
            > .contents > * > * {
                opacity: 0;
            }
            &:before {
                content: " ";
                height: 100%;
                @include inalign(middle);
                margin-left: -5px;
            }
            .options {
                .btn {
                    width: 100%;
                    width: calc(100% - 15px);
                    margin-bottom: 5px;
                    @include at-least($screen-phone) {
                        width: auto;
                    }
                }
            }
        }
        .checkbox {
            display: none;
            &:checked ~ .info .expanded {
                height: 120px;
                > .contents > * > * {
                    animation: drop-in 0.8s ease-in-out;
                    @include animation-fill-mode(forwards);
                }
                > .contents > * > *:nth-child(2) {
                    animation-delay: 0.15s;
                }
                @include at-least($screen-phone) {
                    height: 80px;
                }
            }
        }
        .bar {
            background: {
                    padding: 50px;
                    margin: -50px;
                    color: $pastel-lighter-frost;
                    size: contain;
                    position: center center;
                    position-y: top;
                    repeat: no-repeat;
                };
        }
        &.dry-food {
            .bar {
                background-image: url('/static/images/products/dry-food/thumb.png');
            }
        }
        &.wet-food {
            .bar {
                background-image: url('/static/images/products/wet-food/thumb.png');
            }
        }
        &.dental-dailies {
            .bar {
                background-image: url('/static/images/products/dental-dailies/thumb.png');
            }
        }
        &.good-dog-treats {
            .bar {
                background-image: url('/static/images/products/good-dog-treats/thumb.png');
            }
        }
        &.salmon-treats {
            .bar {
                background-image: url('/static/images/products/salmon-treats/thumb.png');
            }
        }
        &.duck-treats {
            .bar {
                background-image: url('/static/images/products/duck-treats/thumb.png');
            }
        }
        &.lamb-treats {
            .bar {
                background-image: url('/static/images/products/lamb-treats/thumb.png');
            }
        }
        &.pork-treats {
            .bar {
                background-image: url('/static/images/products/pork-treats/thumb.png');
            }
        }
        &.poo-bags {
            .bar {
                background-image: url('/static/images/products/poo-bags/thumb.png');
            }
        }
        &[data-status=disabled] {
            .bar {
                background-color: $pastel-lighter-frost;
            }
        }
    }
    .alert.alert-warning {
        p, a {
            color: $primary-colour;
        }
    }
    .raf-button {
        margin-top: 15px;
    }
    .raf-banner-experiment {
        margin: 20px;
        @include at-least($screen-tablet) {
            margin: 30px auto;
            padding: 20px;
            @include grid-blocks(10);
        }

        h4 {
            margin-bottom: 20px;
            color: $navy-00;
        }
        .background-image {
            border-radius: 8px;
            padding: 0;
            background-repeat: no-repeat;
            background-size: cover;
            background-position-y: 100px;
            height: auto;
            min-height: 350px;
            width: auto;
            max-width: 400px;
            margin: 0 auto;
            @include at-least($screen-tablet) {
                padding: 0;
                min-height: 205px;
                max-width: 100%;
                height: 205px;
                margin-top: unset;
                background-position: top;
                background-size: cover;
            }
        }

        .raf-container {
            width: 100%;
            height: 100%;
            overflow: hidden;
            @include at-least($screen-tablet) {
                display: flex;
                align-items: center;
            }
        }

        .raf-banner-wrap {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            @include at-least($screen-tablet) {
                background-color: white;
                height: 300px;
                width: 300px;
                margin: 0 auto;
                margin-left: 2%;
                border-radius: 50%;
            }
            position: relative;

            h4 {
                @include at-least($screen-tablet) {
                    max-width: 260px;
                }
                text-align: center;
                max-width: 300px;
            }

            .btn {
                min-width: unset;
                width: 240px;
                box-sizing: border-box;
            }
        }
    }
}

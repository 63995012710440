body.account.update-portions {
    .content {
        .narrow-container {
            @include grid-blocks(6);
            margin: auto;
            font-size: $font-size-s;

            h2 {
                margin-top: 25%;
            }
        }

        .progress-bar {
            display: flex;
            padding: 25px 10px;
            align-items: center;
            @include at-least($screen-tablet) {
                padding: 25px 0px;
            }

            .step-circle {
                background-color: $pastel-darker-frost;
                width: 35px;
                height: 35px;
                border-radius: 50%;
                text-align: center;
                line-height: 35px;
                font-weight: bold;
                position: relative;
            }

            .active {
                background-color: $secondary-03;
                color: $primary-white;
            }

            .bardiv {
                border-top: 5px dotted $pastel-darker-frost;
                flex-grow: 1;
                border-spacing: 10px;
            }
        }

        .explanation {
            margin-top: 10px;
        }

        .daily-portion-sub-heading {
            font-size: $font-size-s;
            margin-bottom: 16px;
            font-weight: 500;
        }

        .daily-portion-trays {
            margin-bottom: 12px;
        }

        .recommended {
            border-radius: 3px;
            margin-top: 5px;
            background-color: $secondary-potato-light;
            color: $primary-colour;
            font-size: $font-size-xs;
            font-weight: bold;
            margin-left: 35px;
            padding: 5px 10px;
            display: table;
        }

        .radio-buttons {
            margin: 10px 0;
            display: grid;
        }

        .radio-toggle-button {
            margin: 10px 10px 15px 0;
            display: block;
            line-height: 1.5;
        }

        .btn {
            margin: 5px 0;
            padding: 0 20px;
            min-width: 220px;
        }

        .all-upper {
            font-weight: bold;
            text-transform: uppercase;
        }

        .food-block {
            padding: 5px 0 30px 0;
            display: flex;

            &.dry {
                @include at-least($screen-phone) {
                    justify-content: normal;
                }
            }

            .icons-info {
                margin: auto 10px auto 0;
            }

            > span.circle {
                line-height: 55px;
                position: relative;

                &:before {
                    font-weight: normal;
                    font-size: 3rem;
                }

                @include at-least($screen-phone) {
                    margin: 0 10px 0 0;
                }
            }

            .icon-times {
                margin: auto 20px;
                font-size: $font-size-xs;

                @include at-least($screen-phone) {
                    margin: auto 20px auto 10px;
                }
            }

            .wet-grams, p {
                margin: auto 15px;

                @include at-least($screen-phone) {
                    margin: auto 0;
                }
            }

            .strong {
                font-weight: 800;
            }

            .dry-setting {
                margin: 5px auto;
            }
        }

        .price-change {
            background-color: $pastel-lighter-frost;
            padding: 20px 0;
            margin: 5px -15px 15px -15px;
            @include at-least($screen-phone) {
                margin: 5px -400px 15px -400px;
            }
        }

        .price-change-table {
            margin: 0 15px;
            @include at-least($screen-phone) {
                margin: 0 400px;
            }

            .main-headline {
                font-size: $font-size-body;
                margin: 20px 0;
                line-height: 30px;
                @include at-least($screen-phone) {
                    font-size: $font-size-m;
                }
            }

            p {
                font-size: $font-size-s;
            }

            .full-block {
                padding: 5px 0;
            }

            .grey {
                border: .5px solid #CDCFD5;
            }

            .blue {
                border: .5px solid $primary-colour;
            }

            .specifics {
                font-size: $font-size-xs;
                margin: 0;
            }

            .main-information {
                display: flex;
                justify-content: space-between;

                p {
                    margin: 0;
                }

                .price {
                    text-align: right;
                }
            }

        }

        .divider {
            border-bottom: solid 1px $primary-blueberry-light;
            margin: 16px 0 16px 0;
        }

        .tray-size-info-panel {
            padding: 24px;
            background: $primary-blueberry-light-10;
            border-radius: 8px;
        }

        .add-subtract-bar {
            border: 1px solid black;
            border-radius: 50px;
            margin-bottom: 16px;
            @include at-least($screen-phone) {
                @include grid-blocks(2)
            }

            select:disabled {
                color: inherit;
                background-color: inherit;
                border: none;
                font-weight: initial;
            }

            select {
                padding: 0;
                border: none;
                width: 100%;
                text-align-last: center;

                option {
                    display: none;
                }
            }

            .contents {
                display: flex;
                justify-content: space-between;

                .icon {
                    padding: 10px;
                    margin: auto 0;
                    font-size: $font-size-l;
                    color: $navy-04;
                }

                .icon.clickable {
                    color: $primary-colour;
                    cursor: pointer;
                }
            }
        }

        #input-grams-link {
            display: block;
            margin: 15px 5px 20px 5px;
        }

        #input-grams-form {
            padding: 10px 0 15px 0;

            span {
                padding: 0 0 50px 10px;
                font-weight: 700;
            }
        }

        .form-optional-other {
            display: none;
            padding: 10px 0 5px 35px;

            input {
                @include grid-blocks(1);
                min-height: unset;
                padding: 0 0 3px 5px;
            }

            span {
                display: inline-flex;
                vertical-align: bottom;
            }

            &.show {
                display: block;
            }
        }

        .form-optional {
            display: none;
            padding: 0 0 15px 0;

            input {
                @include grid-blocks(1);
            }

            span {
                display: inline-flex;
                vertical-align: bottom;
            }

            &.show {
                display: block;
            }
        }

        .effect-message {
            margin: 25px 0;
        }

        .back {
            text-align: center;
            margin: 10px;
            font-size: $font-size-body;
            @include at-least($screen-phone) {
                margin: auto 10px;
            }
        }

        .horizontal-buttons {
            margin: 0;

        }

        #dry-portion-keep:checked ~ .radio-toggle-button label[for=dry-portion-keep],
        #dry-portion-flex:checked ~ .radio-toggle-button label[for=dry-portion-flex] {
            @include radio_toggle_on;
        }

        #other-portion--1:checked ~ .radio-toggle-button label[for=other-portion--1],
        #other-portion-0:checked ~ .radio-toggle-button label[for=other-portion-0],
        #other-portion-3:checked ~ .radio-toggle-button label[for=other-portion-3],
        #other-portion-8:checked ~ .radio-toggle-button label[for=other-portion-8],
        #other-portion-30:checked ~ .radio-toggle-button label[for=other-portion-30] {
            @include radio_toggle_on;
        }
    }
}


// DISCOVERY-PAGE BASE STYLES //
body.discovery-page {
  .sticky-cta[data-state='hide'] {
    @include translated(0, -100%, 0);
    @include transition(all 0.3s);
  }
  &.scroll-down {
    .sticky-cta[data-state='show'] {
      @include translated(0, 0, 0);
    }
  }
  .section {
    @include container($container-max-width);
  }
}
